import {
  ButtonView,
  CustomModal,
  InputView,
  SpinnerView,
  TextView,
} from 'components';
import SettingsHeader from 'features/Settings/components/SettingsHeader';
import React, { useEffect, useState } from 'react';
import ConditionInfo from './components/ConditionInfo';
import {
  ConditionType,
  DispositionType,
  ICondition,
  IConditionRule,
  IConditionRules,
  IDispositionOption,
  IGroup,
} from './shared/contract';
import DropDownView from 'components/antd/DropDownView';
import './index.css';
import AddGroup from './components/AddGroup';
import ConditionalGroup from './components/ConditionalGroup';
import { dereferenceObject } from 'features/Settings/shared/helper';
import { Dropdown, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import {
  editCondition,
  saveCondition,
  updateConditionStatus,
} from './shared/api';
import { capitalizeFirstLetter, isObjectEmpty } from 'utils/helper';
import {
  sampleCondition,
  sampleGroup,
  showViewLogic,
} from './shared/constants';
import {
  conditionOptions,
  getSelectedConditionOption,
  verifyCondition,
} from './shared/helper';
import DeleteComponent from 'features/Settings/components/DeleteComponent';
import BreadCrumb from './components/BreadCrumb';
import { useNavigate } from 'react-router';
import { InputViewV3 } from 'componentsV3';
import DropDownViewV3 from 'componentsV3/DropDownViewV3';

interface ICreateConditionRule {
  open: boolean;
  handleCreateCondition: (value: boolean) => void;
  dispositionOptions: IDispositionOption[];
  conditionRule: IConditionRules;
  isEdit: boolean;
  handelUserAction?: (action: string, ruleId: number) => void;
  editRule?: IConditionRule;
  isModal?: boolean;
}

const sampleRule: IConditionRule = {
  ruleName: '',
  ...sampleGroup,
  ruleStatus: 'drafted',
  systemGenerated: false,
  library: true,
};

const CreateConditionRule = ({
  open,
  handleCreateCondition,
  dispositionOptions,
  conditionRule,
  editRule,
  isEdit,
  isModal = true,
  handelUserAction,
}: ICreateConditionRule) => {
  const [rule, setRule] = useState<IConditionRule>({
    ...sampleRule,
  });
  const [updated, setUpdated] = useState(false);
  const [isView, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (editRule && !isObjectEmpty(editRule)) {
      setRule(editRule);
    }
  }, [editRule]);

  useEffect(() => {
    if (editRule?.ruleId) {
      setView(!isEdit);
    } else {
      setView(false);
    }
  }, [isEdit, editRule]);

  useEffect(() => {
    if (!open) {
      setRule({
        ...sampleRule,
      });
    }
  }, [open]);

  const validateGroupsHaveCondition = (group: IGroup): boolean => {
    // Check if the current group has at least one condition
    if (!group.logicalOperator) {
      return false;
    }

    const isConditionDataNotEmpty = !!(group.conditions?.length || 0);
    let isConditionDataValid = true;

    for (const conditionToVerify of group.conditions || []) {
      if (!verifyCondition(conditionToVerify)) {
        isConditionDataValid = false;
        break;
      }
    }

    const isGroupsDataNotEmpty = !!(group.groups?.length || 0);
    let isGroupsDataValid = true;

    // Recursively validate each nested group
    if (group.groups && group.groups.length > 0) {
      for (const nestedGroup of group.groups) {
        if (!validateGroupsHaveCondition(nestedGroup)) {
          isGroupsDataValid = false; // If any nested group fails validation, return false
          break;
        }
      }
    }
    return isConditionDataNotEmpty
      ? isConditionDataValid &&
          (isGroupsDataNotEmpty ? isGroupsDataValid : true)
      : isGroupsDataNotEmpty && isGroupsDataValid;
  };

  const isButtonDisabled = !(
    validateGroupsHaveCondition(rule) &&
    !!rule.ruleName.length &&
    !!rule.logicalOperator?.length
  );

  const addORRemoveConditionAndGroup = (
    pathToChange: string,
    isAdd: boolean,
    object: IConditionRule | IGroup,
    newElement: ICondition | IGroup | null
  ) => {
    const pathParts = pathToChange.split('.').filter((part) => part !== '');

    const navigateToTarget = (obj: IGroup, pathParts: string[]): IGroup => {
      let val = obj;
      for (let i = 0; i < pathParts.length - 1; i++) {
        const currPath = pathParts[i];
        if (currPath.startsWith('[')) {
          val = (val as any)[`${currPath.split('')[1]}`];
        } else {
          val = (val as any)[`${currPath}`];
        }
      }
      return val;
    };

    const targetGroup: any = navigateToTarget(object, pathParts);
    const lastPart = pathParts[pathParts.length - 1];

    if (isAdd) {
      targetGroup.push(newElement);
    } else {
      const index = lastPart.split('')[1];
      targetGroup.splice(index, 1);
    }
    return object;
  };

  const saveRule = (ruleStatus: string) => {
    const newPayload = { ...rule, ruleStatus };
    setLoading(true);
    if (rule.ruleId) {
      editCondition(newPayload)
        .then((response) => {
          if (response.errorMessage) {
            message.error(response.errorMessage);
          } else {
            message.success('Condition updated successfully');
            handleCreateCondition(true);
          }
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      saveCondition(newPayload)
        .then((response) => {
          if (response.errorMessage) {
            message.error(response.errorMessage);
          } else {
            message.success('Condition saved successfully');
            handleCreateCondition(true);
          }
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isSaveAsDraftDisabled =
    isButtonDisabled || ['ACTIVATED', 'DEACTIVATED'].includes(rule.ruleStatus);
  const isResetDisabled = isSaveAsDraftDisabled || !!rule.ruleId;

  const items = [
    {
      key: '1',
      label: (
        <div
          className={
            isSaveAsDraftDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }
          style={{
            color: isSaveAsDraftDisabled ? '#D0D5DD' : '',
          }}
          onClick={() => {
            if (!isSaveAsDraftDisabled) {
              saveRule('DRAFTED');
            }
          }}
        >
          Save as draft
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className={isResetDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}
          style={{
            color: isResetDisabled ? '#D0D5DD' : '',
          }}
          onClick={() => {
            if (!isResetDisabled) {
              setRule({
                ...sampleRule,
              });
            }
          }}
        >
          Reset
        </div>
      ),
    },
  ];

  const handleRuleStatusChange = (ruleStatus: string) => {
    setLoading(true);
    updateConditionStatus(rule.ruleId || 0, ruleStatus.toLocaleLowerCase())
      .then((response) => {
        if (response.errorMessage) {
          message.error(response.errorMessage);
        } else {
          setUpdated(true);
          message.success('Condition rule updated successfully');
          setRule({ ...rule, ruleStatus });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getButton = (ruleStatus: string) => {
    const status = ['ACTIVATED', 'DEACTIVATED'];
    const modifiedStatus = status.filter((item) => item !== ruleStatus);
    return modifiedStatus.map((item) => {
      return (
        <ButtonView
          variant="tertiary"
          key={item}
          label={capitalizeFirstLetter(item.toLowerCase())}
          onClick={() => handleRuleStatusChange(item)}
        />
      );
    });
  };

  const response = (
    <>
      {' '}
      <SpinnerView spinning={loading}>
        <section className={`${isModal ? 'px-4 py-6' : 'py-6'} overflow-auto`}>
          {isModal && (
            <article>
              {isView && (
                <BreadCrumb
                  data={[
                    {
                      name: 'Home',
                      isActive: false,
                      onClick: () => navigate('/report'),
                    },
                    {
                      name: 'Condition List',
                      isActive: false,
                      onClick: () => {
                        handleCreateCondition(updated);
                      },
                    },
                    {
                      name: rule.ruleName,
                      isActive: true,
                      onClick: () => {},
                    },
                  ]}
                />
              )}
              <SettingsHeader
                heading={
                  isView
                    ? rule.ruleName
                    : isEdit
                    ? 'Edit condition rule'
                    : 'Create condition rule'
                }
                description={''}
                rightComponent={
                  <div className="flex gap-2">
                    {!isView && (
                      <div
                        className="px-2 py-1 w-fit h-fit rounded-lg"
                        style={{ border: '1px solid #D0D5DD' }}
                      >
                        <Dropdown menu={{ items }}>
                          <MoreOutlined />
                        </Dropdown>
                      </div>
                    )}
                    {!isView && (
                      <ButtonView
                        label={'Exit'}
                        variant="tertiary"
                        onClick={() => {
                          if (isView) {
                            handleCreateCondition(updated);
                          } else {
                            setShowDelete(true);
                          }
                        }}
                      />
                    )}
                    {isView && (
                      <ButtonView
                        label={'Edit'}
                        variant="primary"
                        onClick={() => {
                          if (handelUserAction)
                            handelUserAction('edit', rule.ruleId || 0);
                        }}
                      />
                    )}
                    {!isView && (
                      <div>
                        <ButtonView
                          disabled={isButtonDisabled}
                          label={
                            ['ACTIVATED', 'DEACTIVATED'].includes(
                              rule.ruleStatus
                            )
                              ? 'Save'
                              : 'Save & Activate'
                          }
                          onClick={() =>
                            saveRule(
                              ['ACTIVATED', 'DEACTIVATED'].includes(
                                rule.ruleStatus
                              )
                                ? rule.ruleStatus
                                : 'ACTIVATED'
                            )
                          }
                        />
                      </div>
                    )}
                    {isView && (
                      <div className="flex gap-2">
                        {getButton(rule.ruleStatus)}
                      </div>
                    )}
                  </div>
                }
              />
            </article>
          )}
          <article className={`flex ${isModal ? 'py-6' : ''} gap-6`}>
            <section className="flex flex-col flex-1 overflow-y-auto">
              <section className="flex gap-4 items-center">
                <div className="flex">
                  <TextView
                    text={`Condition rule ${isView ? '' : 'name'}`}
                    size={14}
                    lineHeight={20}
                  />
                  <TextView
                    text={'*'}
                    size={14}
                    lineHeight={20}
                    color="#FF4D4F"
                  />
                </div>
                {!isView && (
                  <div className="flex-1">
                    <InputViewV3
                      disabled={!!rule.ruleId}
                      size="s"
                      placeholder="Please enter rule name"
                      value={rule.ruleName}
                      onChangeText={(value) => {
                        if (!rule.ruleId) {
                          value = value?.toString().trimStart();
                          setRule((prev) => ({
                            ...prev,
                            ruleName: value || '',
                          }));
                        }
                      }}
                    />
                  </div>
                )}
              </section>
              <article className="flex-1 pt-6 overflow-y-auto">
                {isView ? (
                  showViewLogic(rule.logicalOperator || '')
                ) : (
                  <>
                    <TextView
                      text={'Link all groups'}
                      color="#697586"
                      lineHeight={20}
                      size={14}
                      weight="400"
                    />
                    <DropDownViewV3
                      triggerWidth={154}
                      size="s"
                      type={'standard'}
                      value={rule.logicalOperator}
                      options={conditionOptions}
                      placeholder="Select an operator"
                      onChange={(value: string) => {
                        setRule((prev) => ({
                          ...prev,
                          logicalOperator: value,
                        }));
                      }}
                    />
                  </>
                )}
                {rule.logicalOperator && (
                  <div className="flex flex-col pl-4">
                    <ConditionalGroup
                      dispositionOptions={dispositionOptions}
                      path=""
                      conditionGroup={{
                        logicalOperator: rule.logicalOperator,
                        groups: rule.groups,
                        conditions: rule.conditions,
                      }}
                      addORRemoveConditionAndGroup={(
                        pathToChange: string,
                        isAdd: boolean,
                        isCondition: boolean
                      ) => {
                        const newRule = addORRemoveConditionAndGroup(
                          pathToChange,
                          isAdd,
                          dereferenceObject(rule),
                          isCondition ? sampleCondition : sampleGroup
                        );
                        setRule(dereferenceObject(newRule));
                      }}
                      onChange={(newGroup: IGroup) => {
                        setRule((prev) => {
                          return {
                            ...prev,
                            ...newGroup,
                          };
                        });
                      }}
                      conditionRule={conditionRule}
                      isView={isView}
                    />
                    {!isView && (
                      <section className="flex">
                        <div
                          className="h-[17px] w-[20px]"
                          style={{
                            borderBottom: '2px solid #eef2f6',
                            borderLeft: '2px solid #eef2f6',
                          }}
                        ></div>
                        <AddGroup
                          onAddClicked={(isCondition) => {
                            const newRule = addORRemoveConditionAndGroup(
                              isCondition ? 'conditions.[0]' : 'groups.[0]',
                              true,
                              dereferenceObject(rule),
                              isCondition ? sampleCondition : sampleGroup
                            );
                            setRule(dereferenceObject(newRule));
                          }}
                        />
                      </section>
                    )}
                  </div>
                )}
              </article>
            </section>
            {!isView && (
              <aside>
                <ConditionInfo isEdit={isEdit} />
              </aside>
            )}
          </article>
        </section>
      </SpinnerView>
      <DeleteComponent
        showDelete={showDelete}
        headingText={'Are you sure you want to exit.'}
        subHeadingText={
          'Any unsaved progress will be lost. Are you sure you want to exit?'
        }
        onDelete={(confirmDelete: boolean) => {
          if (confirmDelete) handleCreateCondition(updated);
          else setShowDelete(false);
        }}
        cancelText="No, Cancel"
        confirmText="Yes, Exit"
      />
    </>
  );

  if (isModal) {
    return <CustomModal open={open}>{response}</CustomModal>;
  }
  return response;
};

export default CreateConditionRule;
