import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGetAllocationConfigListInitialState,
  IAllocationConfigListResponse,
} from '../../../redux/types/GetAllocationConfigList.type';
import { APIStatus } from '../../../services/api/apiStatusCode';

export const allocationConfigList: IAllocationConfigListResponse = {
  allocationConfigs: [],
  count: 0,
  message: '',
};

export const getAllocationConfigListSliceInitialState: IGetAllocationConfigListInitialState =
  {
    status: APIStatus.INIT,
    data: allocationConfigList,
    isLoading: false,
    error: {},
  };

export type AllocationConfigPayload = {
  recordLimit: {
    offset: number;
    limit: number;
  };
  filters?: any;
  lenderId: string;
};
const GetAllocationConfigListSlice = createSlice({
  name: 'getAllocationConfigList', // Updated the name here
  initialState: getAllocationConfigListSliceInitialState,
  reducers: {
    fetchGetAllocationConfigList: (
      state: IGetAllocationConfigListInitialState,
      _action: PayloadAction<AllocationConfigPayload>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchGetAllocationConfigListSucceeded: (
      state: IGetAllocationConfigListInitialState,
      action: PayloadAction<IAllocationConfigListResponse>
    ) => {
      const { allocationConfigs, message, count } = action.payload;
      state.data.allocationConfigs = allocationConfigs;
      state.data.count = count;
      state.data.message = message;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchGetAllocationConfigListFailed: (
      state: IGetAllocationConfigListInitialState,
      action: PayloadAction<any> // Replace 'any' with your actual error type
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
      state.data.allocationConfigs = [];
      state.data.count = 0;
      state.data.message = '';
    },
    resetGetAllocationConfigListState: () =>
      getAllocationConfigListSliceInitialState,
  },
});

export const {
  fetchGetAllocationConfigList, // Updated the action names here
  fetchGetAllocationConfigListSucceeded,
  fetchGetAllocationConfigListFailed,
  resetGetAllocationConfigListState,
} = GetAllocationConfigListSlice.actions;

export default GetAllocationConfigListSlice.reducer;
