import React from 'react';
import YbCoreModal from '@yubi/yb-core-modal-custom';
import { useStyles } from '@yubi/yb-style-provider';
import TextView from '../TextView';
import SpinnerView from 'components/SpinnerView';
import DividerView from 'components/DividerView';

interface IModalViewProps {
  modalContent: React.ReactNode;
  title?: string | React.ReactNode;
  visible: boolean;
  onCancel: () => void;
  footerContent?: React.ReactNode; // Add the "footer" prop
  footerAlignment?:
    | 'baseline'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'stretch'; // Add the "footerAlignment" prop
  openDir?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
  showCloseIcon?: boolean; // Add the "showCloseIcon" prop
  closeOnOutsideClick?: boolean; // Add the "closeOnOutsideClick" prop
  maxWidth?: number;
  modalHeight?: number | string;
  modalWidth?: number | string;
  fullHeight?: boolean;
  containerPadding?: Spacing;
  footerPadding?: Spacing;
}

interface Spacing {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}
const ModalView: React.FC<IModalViewProps> = ({
  modalContent,
  title,
  visible,
  onCancel,
  footerContent,
  footerAlignment = 'baseline',
  openDir,
  modalHeight,
  fullHeight,
  modalWidth,
  showCloseIcon = true,
  closeOnOutsideClick = true,
  containerPadding,
  footerPadding,
}) => {
  const { styleConnector } = useStyles();

  return (
    <YbCoreModal
      open={visible}
      closeOnOutsideClick={closeOnOutsideClick} // Use the "closeOnOutsideClick" prop
      showCloseIcon={showCloseIcon} // Use the "showCloseIcon" prop
      footer={footerContent} // Use the "footer" prop
      footerPadding={footerPadding || { top: 5, bottom: 5 }}
      onDismiss={onCancel}
      containerStyle={{ height: '100%' }}
      contentContainerStyle={{ height: '100%' }}
      modalHeight={modalHeight}
      modalWidth={modalWidth}
      fullHeight={fullHeight}
      styleConnector={styleConnector}
      footerAlignment={footerAlignment} // Use the "footerAlignment" prop
      openDir={openDir} // Use the "openDir" prop
      containerPadding={containerPadding || { top: 15 }}
    >
      <div className="flex flex-col flex-1 h-scr h-full">
        {title && (
          <div className="mb-5 sticky top-0 bg-white z-1000">{title}</div>
        )}
        {modalContent}
      </div>
    </YbCoreModal>
  );
};

export default ModalView;
