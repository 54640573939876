// apiStatusCodes.ts

// Enum for different API status codes
export enum ApiStatusCodes {
  SUCCESS = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}
export enum APIStatus {
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  NOT_FOUND = 'NOT_FOUND',
  NOT_ACCEPTABLE = 'NOT_ACCEPTABLE',
}

// Object to map status codes to corresponding messages
export const ApiStatusMessages: Record<ApiStatusCodes, string> = {
  [ApiStatusCodes.SUCCESS]: 'Success',
  [ApiStatusCodes.CREATED]: 'Resource created successfully',
  [ApiStatusCodes.BAD_REQUEST]: 'Bad Request',
  [ApiStatusCodes.UNAUTHORIZED]: 'Unauthorized',
  [ApiStatusCodes.FORBIDDEN]: 'Forbidden',
  [ApiStatusCodes.NOT_FOUND]: 'Not Found',
  [ApiStatusCodes.SERVER_ERROR]: 'Internal Server Error',
};
