import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getLMSFieldsRequestFailed,
  getLMSFieldsRequestSucceeded,
  getLMSFieldsRequested,
} from 'redux/slice/settings/LoanBook/GetLMSFields.slice';
import { settingsEndpoints } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';

function* getLMSFieldsSaga() {
  try {
    const response: AxiosResponse<any> = yield HTTPClient.GetRequest(
      settingsEndpoints.LMS_FIELDS
    );
    if (response.data.success) {
      yield put(getLMSFieldsRequestSucceeded(response.data.data));
    } else {
      throw new Error('Error');
    }
  } catch (err) {
    yield put(
      getLMSFieldsRequestFailed({ error: { message: 'Error' } } as any)
    );
  }
}

export function* watchGetLMSFieldsSaga() {
  yield takeLatest(getLMSFieldsRequested.type, getLMSFieldsSaga);
}
