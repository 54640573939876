import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchCreateConfig,
  fetchCreateConfigFailed,
  fetchCreateConfigSucceeded,
} from '../../slice/allocation-config/CreateConfig.slice';

export function* createConfigSaga() {
  try {
    const result = { allocationId: '', message: '' };

    if (result) {
      yield put(fetchCreateConfigSucceeded(result));
    } else {
      throw new Error('Invalid response');
    }
  } catch (error) {
    yield put(
      fetchCreateConfigFailed({
        error: { message: 'Failed to create config' },
      })
    );
  }
}

export function* watchCreateConfigSaga() {
  yield takeLatest(fetchCreateConfig.type, createConfigSaga);
}
