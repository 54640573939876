import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  inviteAgencyFormSuccess,
  inviteAgencyFailed,
  inviteAgencyForm,
  remindReinviteAgencyForm,
  remindReinviteAgencyFormSuccess,
} from '../../slice/manage-agency/inviteAgency.slice';
import {
  IAgencyListPayload,
  InviteAgencyFormParam,
  RemindReinviteActionFormParam,
  IinviteAgencyListPayload,
} from '../../types/ManageAgency.type';
import { HTTPClient } from 'services/api/http-client';
import { manageAgency } from 'services/api/endPoints';
import { formatInviteResponse } from 'features/LenderAgency/function';
import {
  addInvitedAgencyList,
  fetchAgency,
} from 'redux/slice/manage-agency/agency.slice';
import { EmsMessageStatus } from 'constants/constant';

function* inviteAgencyAction({
  payload,
}: PayloadAction<InviteAgencyFormParam>) {
  try {
    const result: IinviteAgencyListPayload = yield HTTPClient.PostRequest(
      `${manageAgency.INVITE_ACTION_URL}`,
      payload
    );

    if (result?.data?.errors) {
      let msg;
      if (result.data.errors[0].error.includes('ALREADY_EXISTS')) {
        msg = 'A previously sent invite already exist for this agency';
      } else if (
        result.data.errors[0].message.includes('AGENCY_CANNOT_BE_INVITED')
      ) {
        msg = 'Previously sent invite hasn’t yet expired';
      } else {
        msg = 'Onboarding invitation request to agency failed';
      }

      yield put(
        inviteAgencyFailed({
          error: {
            message: msg,
          },
        })
      );
    } else {
      const inviteData = formatInviteResponse(result?.data?.data);
      yield put(addInvitedAgencyList(inviteData[0]));
      const msg = 'Onboarding invitation sent to agency successfully';
      yield put(inviteAgencyFormSuccess(msg));
    }
  } catch (err) {
    yield put(
      inviteAgencyFailed({
        error: {
          message: 'Onboarding invitation request to agency failed.',
        },
      })
    );
  }
}

function* remindReinviteAction({
  payload,
}: PayloadAction<RemindReinviteActionFormParam>) {
  const text = payload.action === 'remind' ? 'Reminder' : 'Re-invite ';

  const errMsg =
    payload.action === 'remind'
      ? 'Invitation time limit expired. Please send invite again'
      : 'Previously sent invite hasn’t yet expired';
  try {
    const URL =
      payload.action === 'remind'
        ? manageAgency.REMIND_URL
        : manageAgency.REINVITE_URL;

    const result: IAgencyListPayload = yield HTTPClient.GetRequest(
      `${URL}?inviteID=${payload.invite_id}`
    );

    if (result?.data?.errors) {
      if (result.data?.errors[0]?.message?.includes(EmsMessageStatus.REMIND_SENT_LIMIT_EXHAUSTED)) { 
        yield put(
          inviteAgencyFailed({
            error: {
              message: 'Alert! Reminder limit exhausted.',
            },
          })
        );
      } else {
        yield put(
          inviteAgencyFailed({
            error: {
              message: errMsg,
            },
          })
        );
      }
    } else {
      const message = `${text} sent to agency successfully`;
      yield put(
        fetchAgency({
          listType: 'invitedagency',
        })
      );
      yield put(remindReinviteAgencyFormSuccess(message));
    }
  } catch (err) {
    yield put(
      inviteAgencyFailed({
        error: {
          message:
            'Sorry, we encountered an issue, Please re-try after sometime.',
        },
      })
    );
  }
}

export function* watchInviteAgencySagas() {
  yield takeLatest(inviteAgencyForm.type, inviteAgencyAction);
  yield takeLatest(remindReinviteAgencyForm.type, remindReinviteAction);
}
