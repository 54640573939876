import { AuthService, MFAService } from '@yubi/yb-module-auth';
import axios from 'axios';
import Cookies from 'js-cookie';
import { store } from '../../redux/store/store';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config?: any) => {
    const state = store.getState();
    const userId = AuthService.getUserId();
    const entityId = state.userConfiguration.entityId; // Adjust the path to the EntityId according to your store structure
    const currentEntityId = Cookies.get('Current-Entity-Id');
    const currentGroup = Cookies.get('Current-Group');

    const jwtToken = AuthService.getAccessToken();
    const mfaToken = MFAService.getMFAToken();

    const userGroup = state.userConfiguration.userGroup;

    config.headers = {
      ...config.headers,
      'User-Id': userId,
      'Entity-Id': entityId || currentEntityId,
      Group: userGroup || currentGroup,
      'Mfa-Token': mfaToken,
      Authorization: `Bearer ${jwtToken}`,
      'Content-Security-Policy': "default-src 'self'",
      'Strict-Transport-Security':
        'max-age=31536000; includeSubDomains; preload',
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'DENY',
      'Referrer-Policy': 'strict-origin-when-cross-origin',
      'User-Name': store.getState().userEntities?.userData?.name || '',
      'Lender-Id': state.userConfiguration.lenderId,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized - 401 error');
      await AuthService.logout();
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
