import { TextView } from 'components';
import React from 'react';

const creditConstant = {
  english: {
    single: 160,
    multi: 153,
  },
  others: {
    single: 70,
    multi: 67,
  },
};
const creditCalculation = (textLength: number, language: string) => {
  if (textLength <= 0) return 0;
  if (
    textLength <= creditConstant[language as keyof typeof creditConstant].single
  ) {
    return 1;
  }
  return Math.ceil(
    textLength / creditConstant[language as keyof typeof creditConstant].multi
  );
};

type CreditScoreProps = {
  textLength: number;
  language: string;
};
const CreditScore: React.FC<CreditScoreProps> = ({ textLength, language }) => {
  return (
    <div>
      <TextView color="#475467" text={`Credits used: `} />
      <TextView
        color="#475467"
        weight="700"
        text={
          language.toLowerCase() === 'english'
            ? creditCalculation(textLength, 'english')
            : creditCalculation(textLength, 'others')
        }
      />
    </div>
  );
};

export default CreditScore;
