import React, { useEffect, useState } from 'react';
import YbCorePills from '@yubi/yb-core-pills';

interface TextStyles {
  fontWeight?:
    | 'normal'
    | 'bold'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | undefined;
  fontSize?: number;
  lineHeight?: number;
  color?: string;
}

interface IPillViewProps {
  data: string[];
  textAlignment?: 'flex-start' | 'flex-end' | 'center';
  onCancel?: () => void;
  initialPills?: string[];
  textStyle?: TextStyles;
  disableClick?: boolean;
  onChange?: (selectedPills: string[]) => void; // Add the onChange prop
  pillColor?: string;
  pillColorOnSelected?: string;
  pillColorHover?: string;
}

const PillView: React.FC<IPillViewProps> = ({
  data,
  textAlignment = 'center',
  onCancel,
  initialPills = [] as string[],
  disableClick,
  textStyle,
  onChange, // Add the onChange prop to the component
  pillColor,
  pillColorOnSelected,
  pillColorHover,
}: IPillViewProps) => {
  const [selectedPills, setSelectedPills] = useState<string[]>([]);

  useEffect(() => {
    setSelectedPills(initialPills);
  }, [initialPills.length]);

  const handlePillClick = (value: string) => {
    let updatedPills;

    if (selectedPills?.includes(value)) {
      updatedPills = selectedPills.filter((pill) => pill !== value);
    } else {
      updatedPills = [...selectedPills, value];
    }

    setSelectedPills(updatedPills);

    // Call the onChange prop with the updated selectedPills array
    if (onChange) {
      onChange(updatedPills);
    }
  };

  return (
    <div className="flex gap-2 flex-wrap">
      {data?.map((value, index) => {
        const isSelected = selectedPills?.includes(value);

        return (
          <div key={`${value}${index}`}>
            <YbCorePills
              pillText={value}
              pillTextAlignment={textAlignment}
              customRightIcon={onCancel ? undefined : <></>}
              pillTextStyles={
                textStyle || {
                  color: isSelected ? '#FFFFFF' : '#101828',
                  lineHeight: 18,
                  fontSize: 12,
                  fontWeight: '700',
                }
              }
              customBackgroundColorHover={pillColorHover || '#FFEBE5'}
              customBackgroundColorDefault={
                isSelected
                  ? pillColorOnSelected || '#FD7149'
                  : pillColor || '#FFEBE5'
              }
              onPillClick={() => disableClick || handlePillClick(value)} // Toggle selection
            />
          </div>
        );
      })}
    </div>
  );
};

export default PillView;
