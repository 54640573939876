import React from 'react';
import styled from 'styled-components';

interface IBackDropViewProps {
  children?: React.ReactNode;
  blurBackground?: boolean;
  className?: string;
  onClick?: () => void;
}

const SpinnerContainer = styled.div<{ blurBackground?: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Spinner = styled.div`
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto; /* Enable pointer events on the overlay */
  background: transparent; /* Make the overlay transparent */
`;

// Use React.memo to memoize the children
const MemoizedChildren = React.memo(
  ({ children }: { children?: React.ReactNode }) => <>{children}</>
);

function BackDropView({
  children,
  blurBackground,
  onClick,
  className,
}: IBackDropViewProps) {
  const handleClick = () => {
    if (onClick) onClick();
  };
  if (blurBackground) {
    return (
      <SpinnerContainer className={className} blurBackground>
        <Spinner>
          <MemoizedChildren>{children}</MemoizedChildren>
          <Overlay />
        </Spinner>
      </SpinnerContainer>
    );
  }
  return (
    <div
      onClick={handleClick}
      className={`${onClick ? 'cursor-pointer' : ''} ${className} `}
    >
      <MemoizedChildren>{children}</MemoizedChildren>
    </div>
  );
}

export default BackDropView;
