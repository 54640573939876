import { combineReducers } from 'redux';
import {
  IComplianceState,
  complianceInitialState,
  complianceReducer,
} from './Compliance';
import {
  IPreferenceState,
  preferenceInitialState,
  preferenceReducer,
} from './Preferences';
import {
  loanBookInitialState,
  loanBookReducer,
  ILoanBookState,
} from './LoanBook';
import syncHierarchyReducer, {
  syncHierarchyInitialState,
  ISyncHierarchyInitialState,
} from '../hierarchy-management/syncHierarchySlice.slice';

export interface ISettingsState {
  compliance: IComplianceState;
  preference: IPreferenceState;
  loanBook: ILoanBookState;
  syncHierarchy: ISyncHierarchyInitialState;
}

export const settingsInitialState = {
  compliance: complianceInitialState,
  preference: preferenceInitialState,
  loanBook: loanBookInitialState,
  syncHierarchy: syncHierarchyInitialState,
};

export const settingsReducer = combineReducers({
  compliance: complianceReducer,
  preference: preferenceReducer,
  loanBook: loanBookReducer,
  syncHierarchy: syncHierarchyReducer,
});
