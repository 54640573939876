import React, { useEffect, useState } from 'react';
import { IChannelPreferenceData, IWeekCompliance } from '../shared/contract';
import { isObjectEmpty } from 'utils/helper';
import {
  addIsActiveAndIsDefault,
  dereferenceObject,
  modifyPolicyData,
  updateNestedProperty,
} from '../shared/helper';
import { SettingsTableView } from '../components/SettingsTableView';
import { Weeks, complianceConfigColumns } from '../shared/constants';
import TabsView from 'components/TabsView';
import { updatedDiff } from 'deep-object-diff';

import './index.css';
import useAmplitude from 'hooks/useAmplitude';
import { AmplitudeEvents } from 'constants/constant';

interface IComplianceTable {
  validationData: IWeekCompliance;
  data: IWeekCompliance;
  getValidData: (data: IWeekCompliance, isDataValid: boolean) => void;
  channels: IChannelPreferenceData[];
  resetClicked: number;
  handleReset: () => void;
  getEditingWeeks?: (weeks: Array<string>) => void;
  showWeeks?: Array<string>;
  showPolicyStatus?: boolean;
  hideAction?: boolean;
  rightComponent?: React.ReactNode;
}

export const ComplianceTable = ({
  validationData,
  data,
  getValidData,
  channels,
  resetClicked,
  handleReset,
  getEditingWeeks,
  showWeeks,
  showPolicyStatus = false,
  hideAction = false,
  rightComponent,
}: IComplianceTable) => {
  const [persistedData, setPersistedData] = useState<IWeekCompliance>({});
  const [updatedData, setUpdatedData] = useState<IWeekCompliance>({});
  const [weeks, setWeeks] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [editableRows, setEditableRows] = useState<{ [key: string]: number[] }>(
    {}
  );

  const { sendAmplitudeEvent } = useAmplitude();

  const newData = () => {
    const isDataEmpty = isObjectEmpty(data);
    let stateData = dereferenceObject(isDataEmpty ? validationData : data);
    stateData = addIsActiveAndIsDefault(
      stateData,
      channels,
      isDataEmpty,
      showPolicyStatus
    );
    setPersistedData(dereferenceObject(stateData));
    setUpdatedData(dereferenceObject(stateData));
    return stateData;
  };

  useEffect(() => {
    if (resetClicked) {
      setEditableRows({});
      newData();
      handleReset();
    }
  }, [resetClicked]);

  useEffect(() => {
    const stateData = newData();
    const policyWeeks = Object.keys(stateData);
    setWeeks(policyWeeks);
    setActiveTab(policyWeeks[0]);
    setEditableRows({});
  }, [validationData, data, showPolicyStatus, channels]);

  useEffect(() => {
    if (showWeeks) {
      setEditableRows((prev) => {
        const keys = Object.keys(prev);
        for (const key of keys) {
          if (!showWeeks.includes(key)) {
            delete prev[key];
          }
        }
        return prev;
      });
      const sortOrder = Object.keys(Weeks);
      const dataToSort = [...showWeeks];
      const customComparator = (a: string, b: string) => {
        const indexA = sortOrder.indexOf(a);
        const indexB = sortOrder.indexOf(b);
        return indexA - indexB;
      };
      dataToSort.sort(customComparator);
      setWeeks([...dataToSort]);
      setActiveTab(dataToSort[0]);
    }
  }, [showWeeks]);

  useEffect(() => {
    if (!isObjectEmpty(updatedData)) {
      const keys = Object.keys(editableRows);
      let visited = false;
      for (const key of keys) {
        if (editableRows[key].length > 0) {
          visited = true;
          break;
        }
      }
      getValidData(updatedData, !visited);
    }
  }, [updatedData]);

  const handleDataPersist = (
    isSaveClicked: boolean,
    week: string,
    channel: string
  ) => {
    const updatedDataCopy = dereferenceObject(updatedData);
    const persistedDataCopy = dereferenceObject(persistedData);
    const updatedWeekData =
      updatedDataCopy[week as keyof typeof updatedDataCopy];
    let updatedChannelData =
      updatedWeekData[channel as keyof typeof updatedWeekData];
    const persistedWeekData =
      persistedDataCopy[week as keyof typeof persistedDataCopy];
    let persistedChannelData =
      persistedWeekData[channel as keyof typeof persistedWeekData];
    if (isSaveClicked) {
      if (showPolicyStatus) {
        const dataToValidate = isObjectEmpty(data) ? validationData : data;
        const validationWeekData =
          dataToValidate[week as keyof typeof dataToValidate];
        const validationChannelData =
          validationWeekData?.[channel as keyof typeof validationWeekData];
        if (validationChannelData) {
          const updatedDataDiff = updatedDiff(
            validationChannelData,
            updatedChannelData
          );
          updatedChannelData.isDefault = isObjectEmpty(updatedDataDiff);
        }
      }
      persistedChannelData = updatedChannelData;
      persistedWeekData[channel as keyof typeof persistedWeekData] =
        persistedChannelData;
    } else {
      updatedChannelData = persistedChannelData;
      updatedWeekData[channel as keyof typeof updatedWeekData] =
        updatedChannelData;
    }
    setPersistedData(persistedDataCopy);
    setUpdatedData(updatedDataCopy);
  };

  const handleEditableRowClick = (
    index: number,
    isSaveClicked: boolean,
    week: string,
    channel: string
  ) => {
    handleDataPersist(isSaveClicked, week, channel);
    setEditableRows((prevState) => {
      const editableArray = prevState[activeTab] || [];
      if (editableArray.includes(index)) {
        prevState[activeTab] = editableArray.filter((val) => val !== index);
      } else {
        prevState[activeTab] = [...editableArray, index];
      }
      if (getEditingWeeks)
        getEditingWeeks(
          Object.keys(prevState).filter((key) => prevState[key].length > 0)
        );
      return { ...prevState };
    });
  };

  const getTabs = () => {
    const children: Array<{ key: string; children: React.ReactNode }> = [];
    const tabs = weeks.map((week: string) => {
      const weekData = updatedData[week as keyof typeof updatedData];
      const validationDataForWeek =
        validationData[week as keyof typeof validationData];
      children.push({
        key: week,
        children: (
          <SettingsTableView
            hideSearch
            columns={complianceConfigColumns({
              handleChange: (
                target: string,
                value: number | string | undefined
              ) => {
                setUpdatedData((prevState) =>
                  updateNestedProperty(
                    dereferenceObject(prevState),
                    target,
                    value
                  )
                );
              },
              onEditClick: handleEditableRowClick,
              editableArray: editableRows[week] || [],
              week,
              data: weekData,
              validationData: validationDataForWeek,
              showPolicyStatus,
              channelPreferences: channels,
              hideAction,
            })}
            dataSource={modifyPolicyData(weekData)}
            rowClassName={(record) => {
              return record.isActive ? '' : 'hide-row';
            }}
          />
        ),
      });
      return {
        key: week,
        title: Weeks[week as keyof typeof Weeks],
        children: <></>,
      };
    });

    return (
      <>
        <div className="flex justify-between">
          <TabsView
            tabItems={tabs}
            activeKey={activeTab}
            onTabClick={(key: string) => {
              sendAmplitudeEvent(
                AmplitudeEvents.CREATE_CAMPAIGN_DAY_TAB_VIEW_EVENT,
                {
                  day: key,
                }
              );
              setActiveTab(key);
            }}
          />
          <div>{rightComponent || <></>}</div>
        </div>
        {children.find((child) => child.key === activeTab)?.children}
      </>
    );
  };

  return <div className="config_table">{getTabs()}</div>;
};
