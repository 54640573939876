import { TextView } from 'components';
import React from 'react';

interface IInfo {
  title: string;
  description: string;
  children?: IInfo[];
}

interface IModalInfo {
  data: IInfo[];
}

const ModalInfo = (props: IModalInfo) => {
  const { data } = props;
  const handleInfo = (data: IInfo): any => {
    const { title, description, children } = data;
    return (
      <>
        <div>
          <TextView text={title} color="#475467" size={14} lineHeight={20} />
        </div>
        <div>
          <TextView
            text={description}
            size={14}
            lineHeight={20}
            color="#667085"
          />
        </div>
        {children &&
          children.map((child: IInfo) => {
            return handleInfo(child);
          })}
      </>
    );
  };
  return (
    <div className="flex flex-col gap-6">
      {data.map((infoData) => {
        return (
          <div
            key={infoData.title}
            className="flex flex-col gap-2 p-6 rounded-lg"
            style={{ background: '#F9FAFB' }}
          >
            {handleInfo(infoData)}
          </div>
        );
      })}
    </div>
  );
};

export default ModalInfo;
