import { combineReducers } from 'redux';
import {
  IBuckerPreferenceState,
  IChannelPreferenceState,
  IProductPreferenceState,
} from 'redux/types/Preference.types';
import channelsSlice, { channelsInitialState } from './ChannelPreference.slice';
import productPreferenceSlice, {
  productInitialState,
} from './ProductPreference.slice';
import bucketPreferenceSlice, {
  bucketInitialState,
} from './BucketPreference.slice';

export interface IPreferenceState {
  channels: IChannelPreferenceState;
  product: IProductPreferenceState;
  bucket: IBuckerPreferenceState;
}

export const preferenceInitialState = {
  channels: channelsInitialState,
  product: productInitialState,
  bucket: bucketInitialState,
};

export const preferenceReducer = combineReducers({
  channels: channelsSlice,
  product: productPreferenceSlice,
  bucket: bucketPreferenceSlice,
});
