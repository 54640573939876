import { chevronDown, deleteIcon } from 'assets/icon';
import { ImageView, OverFlowMenuView, TextView } from 'components';
import React, { useRef } from 'react';
import HtmlSwitchForEmail from './HtmlSwitchForEmail';
import { modifyStyle } from '../Channels/components/defaultStyle';
import { TemplateContent } from 'redux/content/content.slice';
import { Mention, MentionsInput } from 'react-mentions';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/store';
import CreditScore from './CreditScore';
import { message } from 'antd';
import { channelsMap } from 'hooks/useChannelDetails';
import { ContentStatus } from 'constants/constant';
import { IMentionsDataResultType } from '../Content.type';

export function findVariables(content: string) {
  if (!content) return [];

  let currentIndex = 0;
  const variables = [];

  while (currentIndex < content.length) {
    const start = content.indexOf('{@', currentIndex);
    if (start === -1) break;

    const end = content.indexOf('}', start);
    if (end === -1) break;

    const variable = content.substring(start, end + 1);
    variables.push(variable);
    currentIndex = end + 1;
  }

  return variables;
}

export function findHashVariables(content: string) {
  if (!content) return [];

  const regex = /{#.*?}/g; // find all the ocurance of {#...}
  return content.match(regex) || [];
}

const getPlaceHolderText = (channel: string, contentData: TemplateContent) => {
  if (
    channel.toLowerCase() === 'ivr' &&
    contentData.status === ContentStatus.PUBLISHED &&
    !contentData.rawText
  ) {
    return '';
  }
  if (channel.toLowerCase() === 'ivr' || channel === 'conversationBot') {
    return `Add var sequence
For ex: {@Firsname} | {@lastname} | {@bank} | {@lastdigit}
type @ to see the list of available parameters`;
  }
  return 'type @ to see the list of available parameters';
};

export type ContentEditorProps = {
  onChange: (value: string) => void;
  contentData: TemplateContent;
  onDelete: () => void;
  channel: string;
  edit?: boolean;
  index: number;
};
const ContentEditor: React.FC<ContentEditorProps> = ({
  contentData,
  onChange,
  onDelete,
  channel,
  edit = false,
  index,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const isChannelSmsOrWhatsappOrEmail =
    channel.toLowerCase() === 'sms' ||
    channel.toLowerCase() === 'whatsapp' ||
    channel.toLowerCase() === 'email';

  const isChannelSms = channel.toLowerCase() === 'sms';
  const filterData = useSelector(
    (store: RootState) => store.content.config.data.Var
  );
  const isChannelIVROrConversationBot =
    channel?.toLowerCase() === 'ivr' || channel === 'conversationBot';

  const insertText = (text: string) => {
    const input = inputRef.current;
    if (!input) return;

    const startPos = input.selectionStart as number;
    const endPos = input.selectionEnd as number;

    const currentValue = input.value;
    const newValue =
      currentValue.substring(0, startPos) +
      `{${text.includes("#") ? "" : "@" }${text}}` +
      currentValue.substring(endPos, currentValue.length);

    input.value = newValue;
    input.setSelectionRange(
      startPos + text.length + 4,
      startPos + text.length + 4
    );
    onChange(input.value);
    if (findVariables(input.value).length === 3)
      message.info(
        `As per new TRAI guidelines, ${
          channelsMap[channel as keyof typeof channelsMap].channel
        } content should not have more than 2 variable. You can proceed if the DLT ID is generated before the new rules are implemented`
      );
    input.focus();
  };

  const { mentionData, hashtagData }: IMentionsDataResultType = filterData.reduce<IMentionsDataResultType>(
    (acc, item) => {
      if (item.displayName) {
        const data = { id: item.fieldName, display: item.displayName };
        if (item.fieldName.includes("#")) {
          acc.hashtagData.push(data);
        } else {
          acc.mentionData.push(data);
        }
      }
      return acc;
    },
    { mentionData: [], hashtagData: [] }
  );

  const overFlowMenuItems = filterData.map((item, key) => {
    return {
      label: item.displayName,
      key,
      onClick: () => {
        insertText(item.fieldName as string);
      },
      disabled: !edit,
    };
  });

  return (
    <div style={{ border: '1px solid #D0D5DD', borderRadius: 8 }}>
      <MentionsInput
        placeholder={getPlaceHolderText(channel, contentData)}
        style={modifyStyle(edit)}
        disabled={!edit}
        aria-multiline
        value={contentData?.message || contentData?.rawText || ''}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        inputRef={inputRef}
      >
        <Mention
          trigger="@"
          data={mentionData}
          markup="{@__id__}"
          displayTransform={(display) => `{@${display}}`}
          renderSuggestion={(suggestion) => (
            <div>{`${suggestion.display}`}</div>
          )}
          onAdd={() => {
            if (findVariables(contentData.message).length === 2)
              message.info(
                `As per new TRAI guidelines, ${
                  channelsMap[channel as keyof typeof channelsMap].channel
                } content should not have more than 2 variable. You can proceed if the DLT ID is generated before the new rules are implemented`
              );
          }}
        />
        <Mention
          trigger="#"
          data={hashtagData}
          markup="{__id__}"
          displayTransform={(display) => `{${display}}`}
          renderSuggestion={(suggestion) => (
            <div>{`${suggestion.display}`}</div>
          )}
        />
      </MentionsInput>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          gap: '2rem',
          paddingRight: '1.2rem',
          paddingBottom: '1.2rem',
          marginTop: -7,
        }}
      >
        <div>
          <TextView text="Character used: " color="#475467" />
          {isChannelSmsOrWhatsappOrEmail && (
            <TextView
              text={contentData.message.length}
              color={'#475467'}
              weight="700"
            />
          )}
          {isChannelIVROrConversationBot && (
            <TextView
              text={contentData.rawText?.length || '0'}
              color={'#475467'}
              weight="700"
            />
          )}
        </div>

        {isChannelSms && (
          <CreditScore
            language={contentData.language}
            textLength={contentData.message.length}
          />
        )}
      </div>
      <div
        style={{
          height: 40,
          backgroundColor: '#F9FAFB',
          borderEndEndRadius: 8,
          borderEndStartRadius: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        className="flex flex-row justify-between w-full"
      >
        <div className="flex flex-row justify-center items-center h-full">
          <div
            className="w-[118px] h-full flex  justify-center items-center flex-row"
            style={{ borderRight: '1px solid #D0D5DD' }}
          >
            <OverFlowMenuView
              items={overFlowMenuItems}
              icon={
                <div className="flex flex-row gap-0.5 justify-center items-top mt-1">
                  <TextView text="Var" />
                  <ImageView src={chevronDown} pointer />
                </div>
              }
            />
          </div>
          {/* <div
            style={{ borderRight: '1px solid #D0D5DD' }}
            className="w-[47px] h-full flex justify-center items-center"
          >
            <ImageView src={atSignIcon} pointer />
          </div> */}
        </div>
        <div className="h-full flex justify-center items-center gap-3">
          {edit && <HtmlSwitchForEmail index={index} />}
          {edit && (
            <div
              style={{ borderLeft: '1px solid #D0D5DD' }}
              className="w-[47px] h-full flex justify-center items-center"
            >
              <ImageView src={deleteIcon} pointer onClick={onDelete} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentEditor;
