import { useLocation } from 'react-router';
import queryString from 'query-string';
import _ from 'lodash';

type UseGetQueryFromUrlType = {
  queries: string[];
};

const useGetQueryFromUrl = ({ queries }: UseGetQueryFromUrlType) => {
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  return queries.map((query) => {
    const value = params.get(query);
    return value === null ? undefined : value;
  });
};

export const useGetQueryFromUrlUpdated = <T>() => {
  const location = useLocation();
  const queryStringUrl = location.search;
  const params = queryString.parse(queryStringUrl, {
    parseNumbers: true,
    parseBooleans: true,
  }) as unknown as Partial<T>;
  return _.mapKeys(params, (value, key) => _.camelCase(key));
};

export default useGetQueryFromUrl;
