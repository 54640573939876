import { put, takeLatest } from 'redux-saga/effects';
import { campaignPlanner } from '../../../services/api/endPoints';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { IFetchCampaignListResponse } from './Outreach.types';
import { fetchCampaignList, fetchCampaignListFailed, fetchCampaignListSucceeded } from 'redux/slice/campaign-planner/CamapignList.slice';

export function* fetchCampaignListSaga(action: any) {
    try {
        const { limit, offset, status, name } = action.payload;
        const result: IFetchCampaignListResponse = yield HTTPClient.PostRequest(campaignPlanner.GET_CAMPAIGN_LIST(name, status, { offset, limit }));

        if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.data) {
            // eslint-disable-next-line @typescript-eslint/naming-convention

            // For now we are processing only lender entities
            yield put(fetchCampaignListSucceeded({
                campaignList: result.data.data,
                totalRecords: result.data.totalRecords,
            }));
        } else {
            yield put(
                fetchCampaignListFailed({
                    error: {
                        message: 'No Token Found',
                    },
                })
            );
        }
    } catch (error) {
        // Handle API call failure or network errors
        yield put(
            fetchCampaignListFailed({
                error: {
                    message: 'An error occurred while fetching campaign list.',
                },
            })
        );
    }
}

export function* watchCampaignListSaga() {
    yield takeLatest(fetchCampaignList.type, fetchCampaignListSaga);
}
