import { put, takeLatest, delay } from 'redux-saga/effects';
import { HTTPClient } from '../../../services/api/http-client';
import { IFetchUniqueLenderResponse } from './Lenders.types';
import { fetchUniqueLenderFailed, fetchUniqueLenderSucceeded, fetchUniqueLender, fetchUniqueLenderDataUnavailable } from 'redux/slice/lenders/unqueLender.slice';
import { lender } from 'services/api/endPoints';
import { EmsMessageStatus } from 'constants/constant';
import { PayloadAction } from '@reduxjs/toolkit';



export function* fetchUniqueLenderSaga(
    action: PayloadAction<string[]>
) { 
    try {
        yield delay(1000);
        // Simulate API call with a 10-second delay using Redux Saga's `delay` effect
        const result: IFetchUniqueLenderResponse = yield HTTPClient.PostRequest(
            lender.GET_UNIQUE_LENDERS,
            action.payload
        );
        if (result.data?.data && result.data?.message === EmsMessageStatus.MY_AGENCIES_FETCHED_SUCCESSFULLY) {
            yield put(fetchUniqueLenderSucceeded(result.data?.data));
        } else if (result.data?.message === EmsMessageStatus.NO_LENDERS_ASSOCIATED || result.data?.message === EmsMessageStatus.ENTITY_NOT_ONBOARDED_IN_SPOCTOX) {
            yield put(fetchUniqueLenderDataUnavailable({message: result.data?.message}));
        }
    } catch (error) {
        // Handle API call failure or network errors
        yield put(
        fetchUniqueLenderFailed({
            error: {
            message: 'An error occurred while fetching unique lenders.',
            },
        })
    );}
}

export function* watchUniqueLenderSaga() {
  yield takeLatest(fetchUniqueLender.type, fetchUniqueLenderSaga);
}