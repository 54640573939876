import React, { ReactNode } from 'react';
import YbCoreTabs from '@yubi/yb-core-tabs';
import { useStyles } from '@yubi/yb-style-provider';
import { DimensionValue } from 'react-native';

type Variant = 'line' | 'button' | 'advanced';

declare enum ComponentState {
  Default = 'default',
  Active = 'active',
  Hover = 'hover',
  Disabled = 'disabled',
}

interface TabItem {
  key: string;
  title: ReactNode;
  width?: DimensionValue;
  children?: ReactNode;
  disabled?: boolean;
  leftIcon?: (state: ComponentState) => ReactNode;
  rightIcon?: (state: ComponentState) => ReactNode;
}

interface ITabsViewProps {
  activeKey: string;
  tabItems: TabItem[];
  onTabClick: (tab: string) => void;
  variant?: Variant;
}

const TabsView = (props: ITabsViewProps) => {
  const { activeKey, tabItems, onTabClick, variant } = props;
  const { styleConnector } = useStyles();
  return (
    <YbCoreTabs
      styleConnector={styleConnector}
      variant={variant || 'line'}
      activeKey={activeKey}
      tabItems={tabItems}
      onTabClick={onTabClick}
    />
  );
};

export default TabsView;
