import { YbCoreIcon } from '@yubi/yb-core-icon';
import { DividerView, OverFlowMenuView, TextView } from 'components';
import React, { useState } from 'react';
import WrapperCmp from './WrapperCmp';
import { InputViewV3 } from 'componentsV3';
import { IBucketData } from '../shared/contract';

interface ICreateBucketAtom {
  handleChange: (index: number, data: IBucketData) => void;
  handleAction: (actionType: 'Reset' | 'Delete', index: number) => void;
  data: IBucketData;
  index: number;
  disableOverflow?: boolean;
}

const CreateBucketAtom = (props: ICreateBucketAtom) => {
  const {
    handleChange,
    data,
    index,
    handleAction,
    disableOverflow = false,
  } = props;

  const handleDataChange = (key: string, value: string) => {
    const newData = { ...data, [key]: value };
    handleChange(index, newData);
  };

  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const isDataValid = data.bucketName;
  const isOverflowDisabled = disableOverflow && !isDataValid;

  return (
    <section className="border rounded-md border-[#F2F4F7]">
      <header className="flex justify-between px-6 py-4 ">
        <section>
          <TextView
            text={`Bucket ${index + 1}`}
            size={16}
            lineHeight={24}
            weight="700"
          />
        </section>
        <section className="flex gap-2 items-center">
          <div
            typeof="button"
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            <YbCoreIcon
              size={16}
              name={isAccordionOpen ? 'chevron-up' : 'chevron-down'}
            />
          </div>
          <div className={isOverflowDisabled ? 'cursor-not-allowed' : ''}>
            <OverFlowMenuView
              items={[
                {
                  key: 'reset',
                  label: 'Reset',
                  disabled: !isDataValid,
                  onClick: () => handleAction('Reset', index),
                },
                {
                  key: 'delete',
                  label: 'Remove',
                  disabled: !isDataValid,
                  onClick: () => {
                    handleAction('Delete', index);
                  },
                },
              ].filter((item) => {
                if (disableOverflow && item.key === 'delete') {
                  return false;
                }
                return true;
              })}
              icon={
                <YbCoreIcon
                  size={16}
                  name={'dots-vertical'}
                  color={isOverflowDisabled ? '#d5d5d5' : ''}
                />
              }
            />
          </div>
        </section>
      </header>
      <DividerView lineColor="#F2F4F7" />
      {isAccordionOpen && (
        <body className="flex flex-col">
          <article className="flex ">
            <section className="flex flex-col gap-1 px-6 py-4 w-[50%]">
              <WrapperCmp label={'Bucket Name'} isMandatory={true}>
                <InputViewV3
                  size="s"
                  value={data.bucketName}
                  onChange={(e: any) => {
                    handleDataChange('bucketName', e.target.value);
                  }}
                  placeholder="Type here..."
                />
              </WrapperCmp>
            </section>
            <section className="flex flex-1 items-end px-6 py-4">
              <div
                className="px-2 py-1 rounded-lg h-fit"
                style={{ background: '#DEEAFC', width: 'fit-content' }}
              >
                <TextView
                  text={`Delinquency ${
                    data.bucketName ? '= ' + data.bucketName : ''
                  }`}
                  size={14}
                  weight="400"
                  lineHeight={20}
                  color="#2C43AB"
                />
              </div>
            </section>
          </article>
        </body>
      )}
    </section>
  );
};

export default CreateBucketAtom;
