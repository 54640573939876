import React from 'react';
import TextView from 'components/TextView';
import { StyledFlexDiv, StyledPaddingDiv } from '../shared/styledComponents';
import DividerView from 'components/DividerView';
import { dividerColorCode } from 'constants/constant';

interface ISettingsHeader {
  heading: string;
  description: string;
  rightComponent?: React.ReactNode;
}

const SettingsHeader = (props: ISettingsHeader) => {
  const { heading, description, rightComponent } = props;
  return (
    <>
      <div className="pb-6">
        <StyledFlexDiv justifyContent="space-between">
          <StyledFlexDiv flexDirection="column" gap="8px">
            <TextView
              textType={'h1'}
              weight={'700'}
              color="#101828"
              lineHeight={36}
              size={24}
              text={heading}
            />
            <TextView
              weight={'400'}
              color="#667085"
              lineHeight={20}
              size={14}
              text={description}
            />
          </StyledFlexDiv>
          {rightComponent && rightComponent}
        </StyledFlexDiv>
      </div>
      <DividerView lineColor={dividerColorCode} />
    </>
  );
};

export default SettingsHeader;
