import React, { useState } from 'react';
import CampaignTabs from './components/CampaignTabs';
import TopBar from 'templates/TopBar';
import AuthProvider from 'templates/AuthProvider';
import { CAMPAIGN_PLANNER } from 'rbac/constants';

export const CampaignList = () => {
  const [activeKey, setActiveKey] = useState('1');

  return (
    <>
      <AuthProvider permission={CAMPAIGN_PLANNER} showFriendlyScreen>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
          <TopBar
            subTitle={`The Campaign List gives you an overview of all campaigns, their details, and the current state of each campaign.\nYou may also take some quick actions on the campaigns from here.`}
            title="Campaign list"
            isBreadCrumb
          />
          <CampaignTabs activeKey={activeKey} setActiveKey={setActiveKey} />
        </div>
      </AuthProvider>
    </>
  );
};
