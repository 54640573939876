export const sampleConstant = 'Sample content';

export enum AllocationStatus {
  SEGMENTATION_IN_PROGRESS = 'SEGMENTATION_IN_PROGRESS',
  NONE = 'NONE',
  CAMPAIGN_PLANNING_IN_PROGRESS = 'CAMPAIGN_PLANNING_IN_PROGRESS',
  EXECUTION_IN_PROGRESS = 'EXECUTION_IN_PROGRESS',
}
export const dividerColorCode = '#F2F4F7';
export const groupCollectionAgency = 'collection_agency';
export const groupCollectionLender = 'lender';

export const CAMPAIGN_PLANNER_PLAN_PARENT_NODE_ID =
  '476c5ba6-b86b-4b87-ab2d-e9570f82e087';

export const CAMPAIGN_PLANNER_PLAN_PARENT_NODE_AND_OUTREACH_ID = `${CAMPAIGN_PLANNER_PLAN_PARENT_NODE_ID}_-1`;

export const ALLOCATION_ENUM_OPTIONS: any = {
  loanType: {
    '1': 'Secured',
    '2': 'Unsecured',
    '3': 'Others',
  },
  assetClass: {
    '1': 'Credit Card',
    '2': 'Personal Loan',
    '3': 'Business Loan',
    '4': 'Education Loan',
    '5': 'Home Loan',
    '6': 'Loan Against Property',
    '7': 'Auto Loan',
    '8': 'Gold Loan',
    '9': 'Agriculture Loan',
    '10': 'Others',
  },
  subAssetClass: {
    '1': 'Two Wheeler Loan',
    '2': 'Three Wheeler Loan',
    '3': 'Truck Loan',
    '4': 'Tractor Loan',
    '5': 'Others',
  },
  repaymentFreq: {
    '1': 'daily',
    '2': 'weekly',
    '3': 'monthly',
  },
  repaymentMode: {
    '1': 'Cash',
    '2': 'Nach',
    '3': 'PDC',
    '4': 'SI',
    '5': 'ECS',
    '6': 'Online',
  },
  lastPaymentMode: {
    '1': 'Cash',
    '2': 'Nach',
    '3': 'PDC',
    '4': 'SI',
    '5': 'ECS',
    '6': 'Online',
  },
};

export enum EmsMessageStatus {
  NO_LENDERS_ASSOCIATED = 'NO_LENDERS_ASSOCIATED',
  ENTITY_NOT_ONBOARDED_IN_SPOCTOX = 'ENTITY_NOT_ONBOARDED_IN_SPOCTOX',
  MY_AGENCIES_FETCHED_SUCCESSFULLY = 'MY_LENDERS_FETCHED_SUCCESSFULLY',
  REMIND_SENT_LIMIT_EXHAUSTED = 'REMIND_SENT_LIMIT_EXHAUSTED',
}

export const pillsName = [
  'campaign.channel',
  'campaign.language',
  'contentName',
  'slot',
];
export const YYYY_MM_DD = 'YYYY-MM-DD';
export const DD_MM_YYYY = 'DD-MM-YYYY';

export const CMSTemplateActionAccess: Record<string, { edit: boolean; disable: boolean }> = {
  published: { edit: true, disable: true },
  scheduled: { edit: true, disable: false },
  used: { edit: false, disable: true },
  active: { edit: false, disable: false },
  disabled: { edit: false, disable: false },
  old: { edit: false, disable: false },
};

export const editPermissionContentStatus = ['draft', 'published'];

export enum ContentStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DISABLED = 'disabled',
  USED = 'used',
  ACTIVE = 'active',
  OLD = 'old',
}

export enum AmplitudeEvents {
  HOMEPAGE_EVENT = 'VIEW_HOME',
  NAV_TAB_LENDER_NAME_DROPDOWN_CLICK_EVENT = 'NAV_TAB_LENDER_NAME_DROPDOWN_CLICK',
  NAV_TAB_PROFILE_CLICK_EVENT = 'NAV_TAB_PROFILE_CLICK',
  NAV_TAB_SETTINGS_CLICK_EVENT = 'NAV_TAB_SETTINGS_CLICK',
  NAV_TAB_LOGOUT_CLICK_EVENT = 'NAV_TAB_LOGOUT_CLICK',
  NAV_TAB_ALLOCATION_CLICK_EVENT = 'NAV_TAB_ALLOCATION_CLICK',
  NAV_TAB_ALLOCATION_LIST_CLICK_EVENT = 'NAV_TAB_ALLOCATION_LIST_CLICK',
  NAV_TAB_ALLOCATION_CONFIG_CLICK_EVENT = 'NAV_TAB_ALLOCATION_CONFIG_CLICK',
  NAV_TAB_CREATE_ALLOCATION_CLICK_EVENT = 'NAV_TAB_CREATE_ALLOCATION_CLICK',
  NAV_TAB_CAMPAIGN_CLICK_EVENT = 'NAV_TAB_CAMPAIGN_CLICK',
  NAV_TAB_CAMPAIGN_LIST_CLICK_EVENT = 'NAV_TAB_CAMPAIGN_LIST_CLICK',
  NAV_TAB_SEGMENT_LIST_CLICK_EVENT = 'NAV_TAB_SEGMENT_LIST_CLICK',
  NAV_TAB_CAMPAIGN_CONFIG_OUTREACH_CLICK_EVENT = 'NAV_TAB_CAMPAIGN_CONFIG_OUTREACH_CLICK',
  NAV_TAB_CAMPAIGN_CONFIG_CONDITION_RULE_CLICK_EVENT = 'NAV_TAB_CAMPAIGN_CONFIG_CONDITION_RULE_CLICK',
  NAV_TAB_CREATE_CAMPAIGN_CLICK_EVENT = 'NAV_TAB_CREATE_CAMPAIGN_CLICK',
  NAV_TAB_AGENCY_CLICK_EVENT = 'NAV_TAB_AGENCY_CLICK',
  NAV_TAB_MANAGE_AGENCIES_CLICK_EVENT = 'NAV_TAB_MANAGE_AGENCIES_CLICK',
  NAV_TAB_CONTENT_CLICK_EVENT = 'NAV_TAB_CONTENT_CLICK',
  FILTER_EVENT = 'FILTER_EVENT',
  ALLOCATION_LIST_VIEW_EVENT = 'ALLOCATION_LIST_VIEW',
  ALLOCATION_LIST_CREATE_ALLOCATION_CLICK_EVENT = 'ALLOCATION_LIST_CREATE_ALLOCATION_CLICK',
  ALLOCATION_LIST_ALLOCATED_TAB_EVENT = 'ALLOCATION_LIST_ALLOCATED_TAB_',
  ALLOCATION_LIST_UNALLOCATED_TAB_EVENT = 'ALLOCATION_LIST_UNALLOCATED_TAB_',
  ALLOCATION_LIST_FILTERS_CLICK_EVENT = 'ALLOCATION_LIST_FILTERS_CLICK',
  ALLOCATION_LIST_FILTERS_RESET_EVENT = 'ALLOCATION_LIST_FILTERS_RESET',
  ALLOCATION_LIST_FILTERS_APPLY_EVENT = 'ALLOCATION_LIST_FILTERS_APPLY',
  ALLOCATION_LIST_ASSIGN_REALLOCATE_CLICK_EVENT = 'ALLOCATION_LIST_ASSIGN_REALLOCATE_CLICK',
  ALLOCATION_LIST_ASSIGN_DEALLOCATE_CLICK_EVENT = 'ALLOCATION_LIST_ASSIGN_DEALLOCATE_CLICK',
  ALLOCATION_LIST_ASSIGN_ALLOCATE_CLICK_EVENT = 'ALLOCATION_LIST_ASSIGN_ALLOCATE_CLICK',
  ALLOCATION_CONFIG_VIEW_EVENT = 'ALLOCATION_CONFIG_VIEW',
  ALLOCATION_CONFIG_DELETE_CLICK_EVENT = 'ALLOCATION_CONFIG_DELETE_CLICK',
  ALLOCATION_CONFIG_DISABLE_CLICK_EVENT = 'ALLOCATION_CONFIG_DISABLE_CLICK',
  ALLOCATION_CONFIG_CREATE_CONFIG_CLICK_EVENT = 'ALLOCATION_CONFIG_CREATE_CONFIG_CLICK',
  ALLOCATION_CONFIG_FILTERS_CLICK_EVENT = 'ALLOCATION_CONFIG_FILTERS_CLICK',
  ALLOCATION_CONFIG_FILTERS_RESET_EVENT = 'ALLOCATION_CONFIG_FILTERS_RESET',
  ALLOCATION_CONFIG_FILTERS_APPLY_EVENT = 'ALLOCATION_CONFIG_FILTERS_APPLY',
  ALLOCATION_CONFIG_SAVE_CLICK_EVENT = 'ALLOCATION_CONFIG_SAVE_CLICK',
  CAMPAIGN_LIST_CAMPAIGN_PLAN_VIEW_EVENT = 'CAMPAIGN_LIST_CAMPAIGN_PLAN_VIEW',
  ALLOCATION_RULE_SAVE_AS_DRAFT_EVENT = 'ALLOCATION_RULE_SAVE_AS_DRAFT',
  ALLOCATION_RULE_SAVE_AS_CONFIG_EVENT = 'ALLOCATION_RULE_SAVE_AS_CONFIG',
  ALLOCATION_RULE_NEXT_CLICK_EVENT = 'ALLOCATION_RULE_NEXT_CLICK',
  CREATE_ALLOCATION_VIEW_EVENT = 'CREATE_ALLOCATION_VIEW',
  CREATE_ALLOCATION_ASSIGN_TAB_SUMMARY_VIEW_EVENT = 'CREATE_ALLOCATION_ASSIGN_TAB_SUMMARY_VIEW',
  CREATE_ALLOCATION_SUBMIT_CLICK_EVENT = 'CREATE_ALLOCATION_SUBMIT_CLICK',
  CAMPAIGN_LIST_COMPLETED_TAB_VIEW_EVENT = 'CAMPAIGN_LIST_COMPLETED_TAB_VIEW',
  CAMPAIGN_LIST_SEARCH_TYPE_EVENT = 'CAMPAIGN_LIST_SEARCH_TYPE',
  CAMPAIGN_LIST_CAMPAIGN_NAME_CLICK_EVENT = 'CAMPAIGN_LIST_CAMPAIGN_NAME_CLICK',
  CAMPAIGN_LIST_EXECUTION_DUE_TAB_VIEW_EVENT = 'CAMPAIGN_LIST_EXECUTION_DUE_TAB_VIEW',
  CAMPAIGN_LIST_IN_PROGRESS_TAB_VIEW_EVENT = 'CAMPAIGN_LIST_IN_PROGRESS_TAB_VIEW',
  CAMPAIGN_LIST_MONITORING_VIEW_EVENT = 'CAMPAIGN_LIST_MONITORING_VIEW',
  CAMPAIGN_LIST_CAMPAIGN_PLAN_DAY_TAB_VIEW_EVENT = 'CAMPAIGN_LIST_CAMPAIGN_PLAN_DAY_TAB_VIEW',
  CAMPAIGN_MONITORING_CAMPAIGN_TAB_VIEW_EVENT = 'CAMPAIGN_MONITORING_CAMPAIGN_TAB_VIEW',
  CAMPAIGN_MONITORING_OUTREACH_TAB_VIEW_EVENT = 'CAMPAIGN_MONITORING_OUTREACH_TAB_VIEW',
  CAMPAIGN_MONITORING_SEGMENT_LIST_TAB_VIEW_EVENT = 'CAMPAIGN_MONITORING_SEGMENT_LIST_TAB_VIEW',
  CAMPAIGN_MONITORING_SEGMENT_CLICK_EVENT = 'CAMPAIGN_MONITORING_SEGMENT_CLICK',
  CAMPAIGN_LIST_SUMMARY_VIEW_EVENT = 'CAMPAIGN_LIST_SUMMARY_VIEW',
  CREATE_CAMPAIGN_PLANNING_IN_PROGRESS_TAB_VIEW_EVENT = 'CREATE_CAMPAIGN_PLANNING_IN_PROGRESS_TAB_VIEW',
  CREATE_CAMPAIGN_PLANNING_IN_PROGRESS_ACTION_PLAN_CLICK_EVENT = 'CREATE_CAMPAIGN_PLANNING_IN_PROGRESS_ACTION_PLAN_CLICK',
  CREATE_CAMPAIGN_PAST_ALLOCATION_TAB_VIEW_EVENT = 'CREATE_CAMPAIGN_PAST_ALLOCATION_TAB_VIEW',
  CREATE_CAMPAIGN_PLANNING_DUE_TAB_VIEW_EVENT = 'CREATE_CAMPAIGN_PLANNING_DUE_TAB_VIEW',
  CREATE_CAMPAIGN_PLANNING_DUE_TAB_SEARCH_TYPE_EVENT = 'CREATE_CAMPAIGN_PLANNING_DUE_TAB_SEARCH TYPE',
  CREATE_CAMPAIGN_SAVE_AS_DRAFT_CLICK_EVENT = 'CREATE_CAMPAIGN_SAVE_AS_DRAFT_CLICK',
  CREATE_CAMPAIGN_NEXT_CLICK_EVENT = 'CREATE_CAMPAIGN_NEXT_CLICK',
  CREATE_CAMPAIGN_DAY_TAB_VIEW_EVENT = 'CREATE_CAMPAIGN_DAY_TAB_VIEW',
  CREATE_CAMPAIGN_SEGMENT_RULE_NEXT_CLICK_EVENT = 'CREATE_CAMPAIGN_SEGMENT_RULE_NEXT_CLICK',
  CREATE_CAMPAIGN_SEGMENT_RULE_RESET_CLICK_EVENT = 'CREATE_CAMPAIGN_SEGMENT_RULE_RESET_CLICK',
  CREATE_CAMPAIGN_SEGMENT_RULE_SAVE_IN_SEGMENT_LIBRARY_CLICK_EVENT = ' CREATE_CAMPAIGN_SEGMENT_RULE_SAVE_IN_SEGMENT_LIBRARY_CLICK',
  CREATE_CAMPAIGN_SEGMENT_RULE_ADD_TO_CART_CLICK_EVENT = ' CREATE_CAMPAIGN_SEGMENT_RULE_ADD_TO_CART_CLICK',
  CREATE_CAMPAIGN_SEGMENT_RULE_SHOW_CART_CLICK_EVENT = ' CREATE_CAMPAIGN_SEGMENT_RULE_SHOW_CART_CLICK',
  CREATE_CAMPAIGN_SEGMENT_RULE_HIDE_CART_CLICK_EVENT = ' CREATE_CAMPAIGN_SEGMENT_RULE_HIDE_CART_CLICK',
  CREATE_CAMPAIGN_SEGMENT_RULE_EDIT_CLICK_EVENT = 'CREATE_CAMPAIGN_SEGMENT_RULE_EDIT_CLICK',
  CREATE_CAMPAIGN_SEGMENT_RULE_DELETE_CLICK_EVENT = 'CREATE_CAMPAIGN_SEGMENT_RULE_DELETE_CLICK',
  CREATE_CAMPAIGN_SEGMENT_LIBRARY_VIEW_EVENT = 'CREATE_CAMPAIGN_SEGMENT_LIBRARY_VIEW',
  CREATE_CAMPAIGN_SEGMENT_LIBRARY_ADD_TO_CART_CLICK_EVENT = 'CREATE_CAMPAIGN_SEGMENT_LIBRARY_ADD_TO_CART_CLICK',
  CREATE_CAMPAIGN_SEGMENT_LIBRARY_CLOSE_CLICK_EVENT = 'CREATE_CAMPAIGN_SEGMENT_LIBRARY_CLOSE_CLICK',
  CREATE_CAMPAIGN_PLANNING_SELECT_DAY_EVENT = 'CREATE_CAMPAIGN_PLANNING_SELECT_DAY',
  CREATE_CAMPAIGN_SUBMIT_YES_CLICK_EVENT = 'CREATE_CAMPAIGN_SUBMIT_YES_CLICK',
  CREATE_CAMPAIGN_SUBMIT_NO_CLICK_EVENT = 'CREATE_CAMPAIGN_SUBMIT_NO_CLICK',
  CAMPAIGN_STRATEGY_TAB_FILTERS_CLICK_EVENT = 'CAMPAIGN_STRATEGY_TAB_FILTERS_CLICK',
  OUTREACH_LIST_TAB_VIEW_EVENT = 'OUTREACH_LIST_TAB_VIEW',
  OUTREACH_LIST_TAB_FILTERS_CLICK_EVENT = 'OUTREACH_LIST_TAB_FILTERS_CLICK',
  OUTREACH_LIST_TAB_SEARCH_TYPE_EVENT = 'OUTREACH_LIST_TAB_SEARCH_TYPE',
  OUTREACH_LIST_TAB_ACTIONS_CLONE_CLICK_EVENT = 'OUTREACH_LIST_TAB_ACTIONS_CLONE_CLICK',
  OUTREACH_LIST_TAB_ACTIONS_EDIT_CLICK_EVENT = 'OUTREACH_LIST_TAB_ACTIONS_EDIT_CLICK',
  OUTREACH_LIST_TAB_ACTIONS_DELETE_CLICK_EVENT = 'OUTREACH_LIST_TAB_ACTIONS_DELETE_CLICK',
  OUTREACH_LIST_TAB_FILTERS_RESET_CLICK_EVENT = 'OUTREACH_LIST_TAB_FILTERS_RESET_CLICK',
  OUTREACH_LIST_TAB_FILTERS_APPLY_CLICK_EVENT = 'OUTREACH_LIST_TAB_FILTERS_APPLY_CLICK',
  CREATE_OUTREACH_CLONE_CLICK_EVENT = 'CREATE_OUTREACH_CLONE_CLICK',
  CREATE_OUTREACH_ADD_NEW_CLICK_EVENT = 'CREATE_OUTREACH_ADD_NEW_CLICK',
  CREATE_OUTREACH_SAVE_CLICK_EVENT = 'CREATE_OUTREACH_SAVE_CLICK',
  CREATE_OUTREACH_RESET_CLICK_EVENT = 'CREATE_OUTREACH_RESET_CLICK',
  CREATE_OUTREACH_CROSS_CLICK_EVENT = 'CREATE_OUTREACH_CROSS_CLICK',
  CAMPAIGN_STRATEGY_TAB_SEARCH_TYPE_EVENT = 'CAMPAIGN_STRATEGY_TAB_SEARCH_TYPE',
  CAMPAIGN_STRATEGY_TAB_VIEW_EVENT = 'CAMPAIGN_STRATEGY_TAB_VIEW',
  CAMPAIGN_STRATEGY_TAB_ACTIONS_DELETE_CLICK_EVENT = 'CAMPAIGN_STRATEGY_TAB_ACTIONS_DELETE_CLICK',
  CAMPAIGN_STRATEGY_TAB_ACTIONS_EDIT_CLICK_EVENT = 'CAMPAIGN_STRATEGY_TAB_ACTIONS_EDIT_CLICK',
  CAMPAIGN_STRATEGY_TAB_ACTIONS_CLONE_CLICK_EVENT = 'CAMPAIGN_STRATEGY_TAB_ACTIONS_CLONE_CLICK',
  CAMPAIGN_STRATEGY_TAB_FILTERS_RESET_CLICK_EVENT = 'CAMPAIGN_STRATEGY_TAB_FILTERS_RESET_CLICK',
  CAMPAIGN_STRATEGY_TAB_FILTERS_APPLY_CLICK_EVENT = 'CAMPAIGN_STRATEGY_TAB_FILTERS_APPLY_CLICK',
  CAMPAIGN_STRATEGY_SAVE_CLICK_EVENT = 'CAMPAIGN_STRATEGY_SAVE CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_SAVE_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_SAVE CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_CONTENT_VIEW_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_CONTENT_VIEW_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_EDIT_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_EDIT_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_DELETE_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_DELETE_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_LEFT_ADD_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_LEFT_ADD_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_LEFT_MINUS_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_LEFT_MINUS_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_RIGHT_ADD_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_RIGHT_ADD_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_SELECT_OUTREACH_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_SELECT_OUTREACH',
  CAMPAIGN_STRATEGY_OUTREACH_APPLY_OUTREACH_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_APPLY_OUTREACH',
  CAMPAIGN_STRATEGY_OUTREACH_CANCEL_OUTREACH_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_CANCEL_OUTREACH',
  CAMPAIGN_STRATEGY_OUTREACH_LEFT_ADD_VIEW_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_LEFT_ADD_VIEW_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_LEFT_ADD_SAVE_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_LEFT_ADD_SAVE_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_RIGHT_ADD_VIEW_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_RIGHT_ADD_VIEW_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_RIGHT_ADD_SAVE_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_RIGHT_ADD_SAVE_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_ADD_PLAN_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_ADD_PLAN_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_CONDITION_GROUP_ADD_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_CONDITION_GROUP_ADD_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_CONDITION_GROUP_CLOSE_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_CONDITION_GROUP_CLOSE_CLICK',
  CAMPAIGN_STRATEGY_ADD_MORE_PLANS_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_ADD_MORE_PLANS_CLICK',
  CAMPAIGN_STRATEGY_DAY_CLICK_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_DAY_CLICK',
  CAMPAIGN_STRATEGY_OUTREACH_ADD_STRATEGY_EVENT = 'CAMPAIGN_STRATEGY_OUTREACH_ADD_STRATEGY',
  CREATE_CAMPAIGN_NBA_RAISE_A_REQUEST_EVENT = 'CREATE_CAMPAIGN_NBA_RAISE_A_REQUEST',
  SEGMENT_LIST_VIEW_EVENT = 'SEGMENT_LIST_VIEW',
  SEGMENT_LIST_CREATE_SEGMENT_CLICK_EVENT = 'SEGMENT_LIST_CREATE_SEGMENT_CLICK',
  SEGMENT_LIST_ACTIONS_EDIT_CLICK_EVENT = 'SEGMENT_LIST_ACTIONS_EDIT_CLICK',
  SEGMENT_LIST_ACTIONS_CLONE_CLICK_EVENT = 'SEGMENT_LIST_ACTIONS_CLONE_CLICK',
  SEGMENT_LIST_ACTIONS_DELETE_CLICK_EVENT = 'SEGMENT_LIST_ACTIONS_DELETE_CLICK',
  SEGMENT_RULE_RESET_CLICK_EVENT = 'SEGMENT_RULE_RESET_CLICK',
  SEGMENT_RULE_SAVE_CLICK_EVENT = 'SEGMENT_RULE_SAVE_CLICK',
  OUTREACH_EVENT = 'OUTREACH',
  STRATEGY_EVENT = 'STRATEGY',
  TAB_VIEW_PLACEHOLDER = '_TAB_VIEW',
  CREATE_PLACEHOLDER = 'CREATE_',
  CLICK_PLACEHOLDER = '_CLICK',
  DELETE_PLACEHOLDER = '_DELETE',
  CAMPAIGN_STRATEGY_PLACEHOLDER = 'CAMPAIGN_STRATEGY_',
  ARROW_CLICK_PLACEHOLDER = '_ARROW_CLICK',
  OUTREACH_CONFIG_PLACEHOLDER = 'OUTREACH_CONFIG_',
  ALLOCATION_LIST_FILTER = 'ALLOCATION_LIST_FILTER',
  ALLOCATION_CONFIG_FILTER = 'ALLOCATION_CONFIG_FILTER',
  CAMPAIGN_STRATEGY_TAB_FILTER = 'CAMPAIGN_STRATEGY_TAB_FILTER',
  OUTREACH_LIST_TAB_FILTER = 'OUTREACH_LIST_TAB_FILTER',
}

export const EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND';
export const CAMPAIGN_NAME_NOT_FOUND = 'CAMPAIGN_NAME_NOT_FOUND';
