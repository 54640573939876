import React from 'react';

interface IEditPencil {
  color?: string;
}

const EditPencil = (props: IEditPencil) => {
  const { color } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon 16x16/16px/pencil" clip-path="url(#clip0_5013_9602)">
        <g id="Vector">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.5283 0.861278C11.7886 0.600928 12.2107 0.600928 12.4711 0.861278L15.1377 3.52794C15.3981 3.78829 15.3981 4.2104 15.1377 4.47075C14.8774 4.7311 14.4553 4.7311 14.1949 4.47075L11.5283 1.80409C11.2679 1.54374 11.2679 1.12163 11.5283 0.861278Z"
            fill={color || '#101828'}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.52894 2.86128C9.78928 2.60093 10.2114 2.60093 10.4717 2.86128L13.1384 5.52794C13.3988 5.78829 13.3988 6.2104 13.1384 6.47075L5.47175 14.1374C5.38971 14.2195 5.28768 14.2787 5.17575 14.3092L1.50909 15.3092C1.27828 15.3721 1.03144 15.3066 0.86227 15.1374C0.693103 14.9683 0.627551 14.7214 0.690498 14.4906L1.6905 10.8239C1.72102 10.712 1.78023 10.61 1.86227 10.5279L9.52894 2.86128ZM2.93027 11.3456L2.28382 13.7159L4.65412 13.0694L11.7242 5.99935L10.0003 4.27549L2.93027 11.3456Z"
            fill={color || '#101828'}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5013_9602">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditPencil;
