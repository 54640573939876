import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { AuthService } from '@yubi/yb-module-auth';

import {
  IConfig,
  IContentTemplatePayload,
  IContents,
  IDeleteContentPayload,
  IDisablePublishContentType,
  IGetContentPayload,
  SaveTemplate,
} from './content.type';
import { RootState } from 'redux/store/store';
import { validateEmail } from 'features/Content/Channels/ContentAccordion';
import { ContentStatus } from 'constants/constant';

export type TemplateContent = {
  created_by: any;
  id?: number;
  status: ContentStatus;
  language: string;
  message: string;
  senderId: string;
  templateId: string;
  variableMapping: string[];
  isActive: boolean;
  rawText: string;
  flowId: string;
  fromAddress: string;
  fromName: string;
  subject: string;
  templateType: string;
  createdAt: string;
  modifiedAt: string | null;
  mediaLink: string | null;
  emailBcc: string | null;
  emailCc: string | null;
};

export interface ICategories {
  product: string;
  bucket: string;
  type: string;
  tags: string[];
}

export interface IContent extends ICategories {
  name: string;
  username: string;

  details: TemplateContent[];
}

export interface ContentInitialState {
  isLoading: boolean;
  content: IContent;
  contents: IContents;
  config: IConfig;
  selectedContentIndex: number;
  languages: string[];
}

console.log(
  'AuthService.getIdToken',
  AuthService.getUserName(AuthService.getIdToken()),
  AuthService.getIdToken()
);

export const contentInitialState: ContentInitialState = {
  isLoading: false,
  contents: {
    totalCount: 0,
    contentsData: [],
  },
  content: {
    product: '',
    bucket: '',
    name: '',
    type: '',
    tags: [],
    details: [],
    username: AuthService.getUserName(AuthService.getIdToken()) || '',
  },
  selectedContentIndex: 0,
  languages: [],
  config: {
    data: {
      OutreachTypes: [],
      Bucket: [],
      Filter: [{ column: '', label: '' }],
      Var: [],
      Language: [],
      AssetClass: [],
    },
  },
};

const content = createSlice({
  name: 'content',
  initialState: contentInitialState,
  reducers: {
    toggleLoadingAction: (state) => {
      state.isLoading = true;
    },
    fetchContents: (state, action: PayloadAction<IGetContentPayload>) => {
      state.isLoading = true;
    },
    fetchContentsSuccess: (
      state,
      action: PayloadAction<{
        contents: IContents;
      }>
    ) => {
      state.isLoading = false;
      state.contents = action.payload.contents;
    },
    fetchContentsFailure: (state) => {
      state.isLoading = false;
      state.contents = { totalCount: 0, contentsData: [] };
    },
    addCategories: (
      state,
      actions: PayloadAction<{ categories: ICategories }>
    ) => {
      state.content.product = actions.payload.categories.product;
      state.content.bucket = actions.payload.categories.bucket;
      state.content.type = actions.payload.categories.type;
      state.content.tags = actions.payload.categories.tags || [];
    },
    addContentName: (
      state,
      actions: PayloadAction<{ contentName: string }>
    ) => {
      state.content.name = actions.payload.contentName;
    },
    addEmptyContentToTemplate: (state) => {
      state.content.details.push({
        created_by: '',
        language: '',
        templateId: '',
        message: '',
        status: ContentStatus.DRAFT,
        senderId: '',
        variableMapping: [],
        isActive: false,
        rawText: '',
        flowId: '',
        fromAddress: '',
        fromName: '',
        subject: '',
        templateType: 'Basic',
        createdAt: '',
        modifiedAt: null,
        mediaLink: '',
        emailBcc: '',
        emailCc: '',
      });
      state.selectedContentIndex = state.content.details.length - 1;
    },
    addLanguageToContent: (
      state,
      actions: PayloadAction<{ language: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.language = actions.payload.language;
        }
      });
    },
    addDltIdToContent: (
      state,
      actions: PayloadAction<{ dltId: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.templateId = actions.payload.dltId;
        }
      });
    },
    addSenderIdToContent: (
      state,
      actions: PayloadAction<{ senderId: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.senderId = actions.payload.senderId;
        }
      });
    },
    addMediaLinkToContent: (
      state,
      actions: PayloadAction<{ mediaLink: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.mediaLink = actions.payload.mediaLink;
        }
      });
    },
    addEmailBccToContent: (
      state,
      actions: PayloadAction<{ emailBcc: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.emailBcc = actions.payload.emailBcc;
        }
      });
    },
    addEmailCcToContent: (
      state,
      actions: PayloadAction<{ emailCc: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.emailCc = actions.payload.emailCc;
        }
      });
    },
    addContentTextToContent: (
      state,
      actions: PayloadAction<{
        contentText: string;
        contentIndex: number;
        channel: string;
      }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          if (
            actions.payload.channel.toLowerCase() === 'ivr' ||
            actions.payload.channel === 'conversationBot'
          ) {
            templateContent.rawText = actions.payload.contentText;
            return;
          }
          templateContent.message = actions.payload.contentText;
        }
      });
    },
    selectContentIndex: (
      state,
      actions: PayloadAction<{ selectedContentIndex: number }>
    ) => {
      state.selectedContentIndex = actions.payload.selectedContentIndex;
    },
    resetContent: (state) => {
      const empty: Omit<IContent, 'username'> = {
        product: '',
        bucket: '',
        name: '',
        type: '',
        tags: [],
        details: [],
      };
      state.content.bucket = empty.bucket;
      state.content.product = empty.product;
      state.content.name = empty.name;
      state.content.type = empty.type;
      state.content.tags = empty.tags;
      state.content.details = empty.details;
    },
    deleteLanguageAndContentById: () => {},
    deleteLanguageAndContentByIdFailure: () => {},
    deleteLanguageAndContentByIdSuccess: (
      state,
      actions: PayloadAction<{ contentId: number }>
    ) => {
      state.content.details = state.content.details.filter(
        (item) => item.id !== actions.payload.contentId
      );
    },
    deleteLanguageAndContentByIndex: (
      state,
      actions: PayloadAction<{ selectedContentIndex: number }>
    ) => {
      state.content.details = state.content.details.filter(
        (_, index) => index !== actions.payload.selectedContentIndex
      );
    },

    fetchContentTemplateDetails: (
      state,
      action: PayloadAction<{
        reqPayload: IContentTemplatePayload;
        channel: string;
      }>
    ) => {
      state.isLoading = true;
    },
    fetchContentTemplateDetailsFailure: (state) => {
      state.isLoading = false;
    },
    fetchContentTemplateDetailsSuccess: (
      state,
      action: PayloadAction<{
        content: IContent;
      }>
    ) => {
      state.isLoading = false;
      state.content = action.payload.content;
    },

    postCloneTemplate: (
      state,
      action: PayloadAction<{
        reqPayload: IContentTemplatePayload;
        channel: string;
        postCloneTemplateSuccessCallBack?(namequery: string): void;
      }>
    ) => {
      state.isLoading = true;
    },
    postCloneTemplateSuccess: (state) => {
      state.isLoading = false;
    },
    postCloneTemplateFailure: (state) => {
      state.isLoading = false;
    },

    resetEmailContent: (state) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === state.selectedContentIndex) {
          templateContent.message = '';
        }
      });
    },
    updateEmailContentFromBasicToHtml: (state) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === state.selectedContentIndex) {
          templateContent.message = `<div style="white-space:pre-line">${templateContent.message}</div>`;
        }
      });
    },
    postTemplateCreation: (
      state,
      actions: PayloadAction<{
        content: IContent;
        channel?: string;
        postTemplateCreationCallBack?(): void;
        status: string;
      }>
    ) => {
      state.isLoading = true;
    },
    postTemplateCreationFailure: (state) => {
      state.isLoading = false;
    },
    postTemplateCreationSuccess: (
      state,
      actions: PayloadAction<{
        templateContent: SaveTemplate[];
        status: string;
      }>
    ) => {
      state.isLoading = false;
      if (actions.payload.status === ContentStatus.DRAFT) {
        state.content.details = state.content.details.map((content) => {
          const responseContent = actions.payload.templateContent.find(
            (templateContent) => {
              if (
                templateContent.language.toLowerCase() ===
                  content.language.toLowerCase() &&
                !content.id
              ) {
                return true;
              }
              return false;
            }
          );
          if (responseContent) {
            return {
              ...content,
              id: responseContent.id,
            };
          }
          return content;
        });
      }
    },
    deleteContentTemplateById: (
      state,
      actions: PayloadAction<IDeleteContentPayload>
    ) => {
      state.isLoading = true;
    },
    deleteContentTemplateByIdFailure: (state) => {
      state.isLoading = false;
    },
    deleteContentTemplateByIdSuccess: (
      state,
      actions: PayloadAction<IDeleteContentPayload>
    ) => {
      state.isLoading = false;
      state.contents.contentsData = state.contents.contentsData.filter(
        (contentItem) => {
          if (
            contentItem.name === actions.payload.name &&
            contentItem.bucket === actions.payload.bucket
          )
            return false;
          return true;
        }
      );

      state.contents.totalCount = state.contents.totalCount - 1;
    },
    deleteContentById: (
      state,
      action: PayloadAction<{
        channel: string;
        id: number;
        deleteContentByIdCallBack: () => void;
      }>
    ) => {
      state.isLoading = true;
    },
    deleteContentByIdFailure: (state) => {
      state.isLoading = false;
    },
    deleteContentByIdSuccess: (
      state,
      action: PayloadAction<{ channel: string; id: number }>
    ) => {
      state.isLoading = false;
      state.content.details = state.content.details.filter(
        (contentData) => contentData.id !== action.payload.id
      );
    },
    disablePublishContent: (
      state,
      action: PayloadAction<IDisablePublishContentType>
    ) => {
      state.isLoading = true;
    },
    disablePublishContentSuccess: (
      state,
      action: PayloadAction<IDisablePublishContentType>
    ) => {
      state.isLoading = false;
      state.contents.contentsData = state.contents.contentsData.filter((contentItem) => !(
        contentItem.name === action.payload.name && contentItem.bucket === action.payload.bucket
      ));
    },
    disablePublishContentFailure: (state) => {
      state.isLoading = false;
    },
    fetchConfig: (state) => {
      state.isLoading = true;
    },
    fetchConfigSuccess: (
      state,
      action: PayloadAction<{
        config: IConfig;
      }>
    ) => {
      state.isLoading = false;
      state.config.data = action.payload.config.data;
    },
    fetchConfigFailure: (state) => {
      state.isLoading = false;
    },
    onToolIdChange: (
      state,
      actions: PayloadAction<{ toolId: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.flowId = actions.payload.toolId;
        }
      });
    },
    onFromNameChange: (
      state,
      actions: PayloadAction<{ fromName: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.fromName = actions.payload.fromName;
        }
      });
    },
    onFromAddressChange: (
      state,
      actions: PayloadAction<{ fromAddress: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.fromAddress = actions.payload.fromAddress;
        }
      });
    },
    onSubjectChange: (
      state,
      actions: PayloadAction<{ subject: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.subject = actions.payload.subject;
        }
      });
    },
    onTemplateTypeChange: (
      state,
      actions: PayloadAction<{ templateType: string; contentIndex: number }>
    ) => {
      state.content.details.forEach((templateContent, index) => {
        if (index === actions.payload.contentIndex) {
          templateContent.templateType = actions.payload.templateType;
        }
      });
    },
  },
});

const selectCategories = (state: RootState) => state.content.content;
export const selectShouldEnableMoveToContentButton = createSelector(
  [selectCategories],
  (categories) => {
    if (categories.type && categories.bucket && categories.product) {
      return true;
    }
    return false;
  }
);

export const selectTemplateContents = (state: RootState): TemplateContent[] =>
  state.content.content.details;

export const shouldEnableContent = (
  templateContent: TemplateContent,
  channel: string
) => {
  if (
    channel.toLowerCase() === 'sms' &&
    templateContent.message &&
    templateContent.senderId &&
    templateContent.templateId &&
    templateContent.language
  ) {
    return true;
  }
  if (
    channel.toLowerCase() === 'ivr' &&
    templateContent.language &&
    templateContent.flowId
  ) {
    return true;
  }
  if (
    channel.toLowerCase() !== 'sms' &&
    templateContent.language &&
    (templateContent.message || templateContent.rawText) &&
    (templateContent.templateId ||
      templateContent.senderId ||
      templateContent.flowId ||
      (templateContent.fromAddress &&
        templateContent.fromName &&
        templateContent.subject &&
        validateEmail(templateContent.emailBcc || '') &&
        validateEmail(templateContent.emailCc || '') &&
        validateEmail(templateContent.fromAddress)))
  ) {
    return true;
  }
  return false;
};

export const selectShouldEnableAddContentButton = (channel: string) =>
  createSelector([selectTemplateContents], (templateContents) => {
    return templateContents.every((templateContent) => {
      return shouldEnableContent(templateContent, channel);
    });
  });

export const selectSelectedContentIndex = (store: RootState) =>
  store.content.selectedContentIndex;

export const selectSelectedContent = createSelector(
  [selectSelectedContentIndex, selectTemplateContents],
  (selectedContentIndex, templateContents) => {
    return (
      templateContents[selectedContentIndex] || {
        language: '',
        dltId: '',
        contentText: '',
      }
    );
  }
);

export const selectLanguages = (state: RootState) =>
  state.content.config.data.Language;
export const selectLanguagesForDropDown = createSelector(
  [selectLanguages, selectTemplateContents],
  (languages, templateContents) => {
    const languagesInTemplateContents = templateContents?.map(
      (templateContent) => templateContent.language
    );
    return languages?.map((language) => ({
      label: language,
      value: language,
      disabled: languagesInTemplateContents.includes(language),
    }));
  }
);

export const {
  resetContent,
  toggleLoadingAction,
  fetchContents,
  fetchContentsFailure,
  fetchContentsSuccess,
  fetchConfig,
  fetchConfigFailure,
  fetchConfigSuccess,
  addCategories,
  addContentName,
  addEmptyContentToTemplate,
  addLanguageToContent,
  addDltIdToContent,
  addContentTextToContent,
  selectContentIndex,
  deleteLanguageAndContentByIndex,
  fetchContentTemplateDetails,
  fetchContentTemplateDetailsFailure,
  fetchContentTemplateDetailsSuccess,
  resetEmailContent,
  updateEmailContentFromBasicToHtml,
  postTemplateCreation,
  deleteContentTemplateById,
  deleteContentTemplateByIdFailure,
  deleteContentTemplateByIdSuccess,
  postTemplateCreationFailure,
  postTemplateCreationSuccess,
  deleteContentById,
  deleteContentByIdFailure,
  deleteContentByIdSuccess,
  postCloneTemplate,
  postCloneTemplateFailure,
  postCloneTemplateSuccess,
  onToolIdChange,
  onFromNameChange,
  onFromAddressChange,
  onSubjectChange,
  onTemplateTypeChange,
  addSenderIdToContent,
  addMediaLinkToContent,
  addEmailBccToContent,
  addEmailCcToContent,
  disablePublishContent,
  disablePublishContentSuccess,
  disablePublishContentFailure,
} = content.actions;
export default content.reducer;
