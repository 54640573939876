import { APIStatus } from 'services/api/apiStatusCode';
import { createSlice } from '@reduxjs/toolkit';
import { IOnboardInitialState } from 'redux/types/UserOnboardDetails.types';

export const onboardInitialState: IOnboardInitialState = {
  status: APIStatus.INIT,
  isLoading: false,
};

const userOnboardSlice = createSlice({
  name: 'onboard',
  initialState: onboardInitialState,
  reducers: {
    fetchUserOnboardDetailsRequested: (state) => {
      state.status = APIStatus.PENDING;
      state.isLoading = true;
    },
    fetchUserOnboardDetailsSuccess: (state, action) => {
      state.status = APIStatus.SUCCESS;
      state.onboardData = action.payload;
      state.isLoading = false;
    },
    fetchUserOnboardDetailsFailed: (state, action) => {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  fetchUserOnboardDetailsRequested,
  fetchUserOnboardDetailsSuccess,
  fetchUserOnboardDetailsFailed,
} = userOnboardSlice.actions;

export default userOnboardSlice.reducer;
