import { IOffsetPagination } from 'types';

export interface IConditionRule extends IGroup {
  ruleId?: number;
  ruleName: string;
  ruleStatus: string;
  systemGenerated: boolean;
  library: boolean;
}

export interface IConditionRules {
  [key: string]: number;
}

export interface IGroup {
  logicalOperator?: string;
  conditions?: ICondition[];
  groups?: IGroup[];
}

export enum IOperator {
  And = 'and',
  Or = 'or',
}

export interface IApiConditions extends ICondition {
  id: number;
}

export interface ICondition {
  conditionId?: number;
  name?: string;
  conditionOption: string;
  mainDisposition: string;
  dispositionType: string;
}

export enum ConditionType {
  Include = 'INCLUDE',
  Exclude = 'EXCLUDE',
}

export enum DispositionType {
  Best = 'BEST',
  Latest = 'LATEST',
}

export interface IGetConditionRulesPayload
  extends Omit<IOffsetPagination, 'totalElements' | 'onPaginationChange'> {
  status: string;
  searchCriteria?: string;
  filters?: Record<string, string | string[]>;
}

export interface IRule {
  ruleId: number;
  ruleName: string;
  userName: string | null;
  userId: string;
  createdDate: string;
  createdBy: string;
  groupsCount: number;
  conditionsCount: number;
  conditions: string;
  lastModifiedDate: string;
  mapped: boolean;
  ruleStatus: string;
  systemGenerated: boolean;
}

export interface IConditionListResponse {
  data: IRule[];
  totalRecords: number;
  message: string;
}

export interface IConditionsResponse {
  data: ICondition[];
  message: string;
}

export interface IDispositionOption {
  label: string;
  value: string;
}
