import React from 'react';
import { YbCoreStepper } from '@yubi/yb-core-stepper';
import { useStyles } from '@yubi/yb-style-provider';

type HorizontalStepperType = 'bar' | 'ellipse';

interface StepsDataType {
  stepName?: string;
  stepNameFontSize?: number;
  stepDescription?: string;
  stepDescriptionFontSize?: number;
  onClick?: () => void;
}

interface IStepperView {
  direction: 'horizontal' | 'vertical';
  totalSteps: number;
  currentStep: number;
  stepsData?: StepsDataType[];
  horizontalStepperType?: HorizontalStepperType;
  stepLabelPosition?: 'inline' | 'bottom';
}

const Stepper = (props: IStepperView) => {
  const {
    direction,
    totalSteps,
    currentStep,
    stepsData,
    horizontalStepperType,
    stepLabelPosition,
  } = props;
  const { styleConnector } = useStyles();
  return (
    <YbCoreStepper
      direction={direction}
      horizontalStepperType={horizontalStepperType || 'bar'}
      totalSteps={totalSteps}
      currentStep={currentStep}
      styleConnector={styleConnector}
      stepsData={stepsData}
      stepLabelPosition={stepLabelPosition}
    />
  );
};

export default Stepper;
