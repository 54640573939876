import { ButtonView, OverFlowMenuView, TextView } from 'components';
import React, { useEffect } from 'react';
import { YbCoreTag } from '@yubi/yb-core-tags-v3';
import { YbCoreButton } from '@yubi/yb-core-button';
import { useNavigate } from 'react-router-dom';
import ExitModal from './ExitModal';
import { atom, useAtom } from 'jotai';
import { YbCoreIcon } from '@yubi/yb-core-icon';

type HeaderPropTypes = {
  tags: string[];
  nextButton: {
    disabled: boolean;
    onClick: () => void;
    name: string;
  };
  secondaryButtons: {
    label: string;
    onClick: () => void;
  }[];
  exitModal: {
    saveInConfig: {
      onClick: () => Promise<boolean>;
      disabled?: boolean;
      isLoading?: boolean;
    };
    cancel: {
      onModalCancel: () => void;
      disabled?: boolean;
      isLoading?: boolean;
    };
  };

  heading: string;
  menuItems: {
    label: string;
    onClick: () => void;
    key: number;
    disabled?: boolean;
  }[];
  onBackClick?: () => void;
};

export const showExitModalAtom = atom(false);
const CampaignHeader: React.FC<HeaderPropTypes> = ({
  tags,
  nextButton,
  heading,
  exitModal: {
    saveInConfig,
    cancel: {
      onModalCancel,
      disabled: isExitDisabled,
      isLoading: isExitLoading,
    },
  },
  secondaryButtons,
  menuItems,
  onBackClick,
}) => {
  const [showExitModal, setShowExitModal] = useAtom(showExitModalAtom);

  useEffect(() => {
    return () => {
      setShowExitModal(false);
    };
  }, []);

  return (
    <div className="flex flex-row justify-between items-start">
      <div className="flex flex-row gap-2 justify-start items-start">
        {onBackClick && (
          <div className="hover:cursor-pointer mt-1.5" onClick={onBackClick}>
            <YbCoreIcon size={16} name="chevron-left" />
          </div>
        )}

        <div className="flex flex-col gap-2">
          <TextView
            text={heading}
            size={18}
            lineHeight={28}
            weight="600"
            color="#121926"
          />
          <div className="flex flex-row gap-2">
            {tags.map((tag) => {
              return (
                <YbCoreTag
                  label={tag}
                  size="l"
                  type="standard"
                  variant="subtle"
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-2">
        {!!menuItems.length && (
          <OverFlowMenuView
            items={menuItems}
            icon={
              <div className="flex w-full h-full  justify-center items-center">
                <div className="flex w-full h-full justify-center items-center">
                  <YbCoreButton
                    size="s"
                    leadingIcon="dots-vertical"
                    variant="tertiary"
                  />
                </div>
              </div>
            }
          />
        )}
        {secondaryButtons.map((button, index) => (
          <ButtonView
            key={`${button.label}-${index}`}
            size="s"
            variant="tertiary"
            label={button.label}
            onClick={button.onClick}
          />
        ))}
        <YbCoreButton
          size="s"
          variant="primary"
          disabled={nextButton.disabled}
          onClick={() => {
            nextButton.onClick();
          }}
          label={nextButton.name}
        />
      </div>
      <ExitModal
        primaryButton={{
          isLoading: saveInConfig.isLoading,
          onClick: async () => {
            const isSaved = await saveInConfig.onClick();
            setShowExitModal(isSaved);
          },
          disabled: saveInConfig.disabled,
          label: 'Save and Exit',
        }}
        visible={showExitModal}
        onCancel={function (): void {
          setShowExitModal(false);
        }}
        title={'Exit campaign creation'}
        subTitle={
          'Are you sure you want to leave your campaign. All progress made will be lost.'
        }
        secondaryButton={{
          disabled: isExitDisabled,
          label: 'Exit',
          onClick: onModalCancel,
          isLoading: isExitLoading,
        }}
      />
    </div>
  );
};

export default CampaignHeader;
