import { AllocationStatus } from 'constants/constant';
import { APIStatus, ApiStatusCodes } from 'services/api/apiStatusCode';

export interface ICampaignSegment {
  segmentId: number;
  segmentName: string | null;
  segmentFormula: string | null;
  segmentLogic: string | null;
  isLibrary: boolean;
  segmentDateMappings: any[]; // Replace 'any' with a more specific type if available
}

export enum NbaCampaignStatus {
  WAITING = 'WAITING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}
export interface ICampaignData {
  campaignId: number;
  campaignName: string;
  startDate: string;
  endDate: string;
  allocationId: string;
  allocationName: string;
  lob: string;
  records: string;
  risks: string;
  currentStatus: AllocationStatus;
  createdDate: string;
  lastModifiedDate: string;
  paused: boolean;
  campaignSegmentList: { segment: ICampaignSegment }[];
  edited: boolean;
  isNba: boolean;
  campaignNbaStatus?: {
    nbaStatus?: NbaCampaignStatus;
    ticketRaised?: boolean;
  } | null;
}

export interface ICampaignSegmentList {
  segment: ICampaignSegment;
  segmentDateMappings: any[];
}

interface ICampaignDates {
  [key: string]: number;
}

export interface ICampaignDetailsData {
  campaignData: ICampaignData;
  campaignDates: ICampaignDates;
}

export interface ICampaignDetailsInitialState {
  data: ICampaignDetailsData;
  isLoading: boolean;
  error: string | null;
}

export interface ICampaignDataInitialState {
  campaignData: ICampaignData;
  isLoading: boolean;
  error: { message?: string };
  status: APIStatus;
}

export interface ICampaignDetailsDataResponse {
  status: ApiStatusCodes;
  data: { data: ICampaignDetailsData };
}
