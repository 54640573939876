import { put, takeLatest } from 'redux-saga/effects';
import { campaignPlanner } from '../../../services/api/endPoints';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { ICreateCampaignResponse } from './Outreach.types';
import { message } from 'antd';
import {
  CreateCampaignActionType,
  UpdateCampaignActionType,
} from 'redux/slice/campaign-planner/types';
import {
  createCampaign,
  createCampaignFailed,
  createCampaignSucceeded,
  pauseOrResumeCampaign,
  pauseOrResumeCampaignFailed,
  pauseOrResumeCampaignSucceeded,
  updateCampaign,
} from 'redux/slice/strategy/strategy';
import {
  pauseOrResumeCampaignList,
  pauseOrResumeCampaignListFailed,
  pauseOrResumeCampaignListSucceeded,
} from 'redux/slice/campaign-planner/CamapignList.slice';
import { PayloadAction } from '@reduxjs/toolkit';

export function* createCampaignSaga(action: CreateCampaignActionType) {
  try {
    const result: ICreateCampaignResponse = yield HTTPClient.PostRequest(
      campaignPlanner.CREATE_CAMPAIGN,
      action.payload.payload
    );

    if (
      result.status === ApiStatusCodes.SUCCESS ||
      result.status === ApiStatusCodes.CREATED
    ) {
      yield put(createCampaignSucceeded(result.data.data));
      action.payload.handleSegmentationCallback({
        campaignId: result.data.data.campaignId,
        isSuccess: true,
      });
    } else {
      action.payload.handleSegmentationCallback({
        isSuccess: false,
      });
      yield put(createCampaignFailed());
    }
  } catch (error: any) {
    message.error(
      error?.response?.data?.error ||
        'An error occurred while creating campaigns'
    );
    yield put(createCampaignFailed());
    action.payload.handleSegmentationCallback({
      isSuccess: false,
    });
  }
}

export function* updateCampaignSaga(action: UpdateCampaignActionType) {
  try {
    const result: ICreateCampaignResponse = yield HTTPClient.PutRequest(
      `${campaignPlanner.UPDATE_CAMPAIGN(
        action.payload.payload.campaignId,
        action.payload.payload.lenderId,
        action.payload.submitPlan
      )}`,
      action.payload.payload
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(createCampaignSucceeded(result?.data?.data));

      action.payload.handleSegmentationCallback({
        campaignId: result?.data?.data.campaignId,
        isSuccess: true,
      });
    } else {
      action.payload.handleSegmentationCallback({
        isSuccess: false,
      });
      yield put(createCampaignFailed());
    }
  } catch (error) {
    const err = error as unknown as { response: { data: { error: string } } };
    yield put(createCampaignFailed());
    action.payload.handleSegmentationCallback({
      isSuccess: false,
    });
    message.error(
      err?.response?.data.error || 'Failed to update campaign dates'
    );
  }
}

export function* pauseOrResumeCampaignSaga(
  action: PayloadAction<{
    campaignId: number;
    status: string;
    pauseOrResumeCampaignSuccessCallback?: () => void;
  }>
) {
  try {
    const { campaignId, status } = action.payload;
    const result: ICreateCampaignResponse = yield HTTPClient.PostRequest(
      campaignPlanner.PAUSE_CAMPAIGN(campaignId, status)
    );

    if (result?.status === ApiStatusCodes.SUCCESS) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      yield put(
        pauseOrResumeCampaignSucceeded({ paused: result.data.data.paused })
      );
      yield put(
        pauseOrResumeCampaignListSucceeded({
          campaignId,
          paused: result.data.data.paused,
        })
      );
      action.payload.pauseOrResumeCampaignSuccessCallback?.();
      message.success(result?.data?.message || 'Campaign paused successfully');
    } else {
      yield put(
        pauseOrResumeCampaignListFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
      yield put(
        pauseOrResumeCampaignFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      pauseOrResumeCampaignListFailed({
        error: {
          message: 'No Token Found',
        },
      })
    );
    yield put(
      pauseOrResumeCampaignFailed({
        error: {
          message: 'An error occurred while fetching campaign list.',
        },
      })
    );
  }
}

export function* watchCreateCampaignSaga() {
  yield takeLatest(createCampaign.type, createCampaignSaga);
  yield takeLatest(updateCampaign.type, updateCampaignSaga);
  yield takeLatest(pauseOrResumeCampaign.type, pauseOrResumeCampaignSaga);
  yield takeLatest(pauseOrResumeCampaignList.type, pauseOrResumeCampaignSaga);
}
