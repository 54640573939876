import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOutreachContentProps } from '../../saga/campaign-planner/Outreach.types';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
  IActionPayload,
  IOutreachContentListInitialState,
} from '../../types/Outreach.types';

export const outreachContentListInitialState: IOutreachContentListInitialState =
  {
    outreachContentData: [],
    isLoading: false,
    status: APIStatus.INIT,
    error: {},
  };

const outreachConfigListSlice = createSlice({
  name: 'outreachConfigInformation',
  initialState: outreachContentListInitialState,
  reducers: {
    fetchContent: (
      state: IOutreachContentListInitialState,
      action: PayloadAction<
        | {
            channel: string;
            language: string;
            lenderId: string;
          }
        | undefined
      >
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchContentSucceeded: (
      state: IOutreachContentListInitialState,
      action: PayloadAction<{
        outreachContentData: IOutreachContentProps[];
      }>
    ) => {
      state.outreachContentData = action.payload.outreachContentData;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchContentFailed: (
      state: IOutreachContentListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    resetOutreachConfigListState: () => outreachContentListInitialState,
  },
});

export const {
  fetchContent,
  fetchContentSucceeded,
  fetchContentFailed,
  resetOutreachConfigListState,
} = outreachConfigListSlice.actions;

export default outreachConfigListSlice.reducer;
