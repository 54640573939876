import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUserDataProps } from '../../../redux/saga/user/User.types';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
  IActionPayload,
  IUserEntityInitialState,
} from '../../types/UserEntities.types';

export const userEntitiesSliceInitialState: IUserEntityInitialState = {
  userData: undefined,
  isLoading: false,
  status: APIStatus.INIT,
  error: {},
};

const userEntitiesSlice = createSlice({
  name: 'userInformation',
  initialState: userEntitiesSliceInitialState,
  reducers: {
    fetchUserEntities: (state: IUserEntityInitialState) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchUserEntitiesSucceeded: (
      state: IUserEntityInitialState,
      action: PayloadAction<IUserDataProps>
    ) => {
      state.userData = action.payload;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchUserEntitiesFailed: (
      state: IUserEntityInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    resetUserEntitiesState: () => userEntitiesSliceInitialState,
  },
});

export const {
  fetchUserEntities,
  fetchUserEntitiesSucceeded,
  fetchUserEntitiesFailed,
  resetUserEntitiesState,
} = userEntitiesSlice.actions;

export default userEntitiesSlice.reducer;
