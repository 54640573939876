import { put, takeLatest } from 'redux-saga/effects';
import { lender } from '../../../services/api/endPoints';
import {
  fetchLenderInvitesSucceeded,
  fetchLenderInvitesFailed,
  fetchLenderInvites,
} from '../../slice/lenders/LendersInvites.slice';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { IFetchLenderInvitesResponse } from './Lenders.types';

export function* fetchLenderInvitesSaga() {
  try {
    const result: IFetchLenderInvitesResponse = yield HTTPClient.GetRequest(
      lender.GET_LENDER_INVITES
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(fetchLenderInvitesSucceeded(result?.data?.data));
    } else {
      yield put(
        fetchLenderInvitesFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchLenderInvitesFailed({
        error: {
          message: 'An error occurred while fetching lender invites.',
        },
      })
    );
  }
}

export function* watchLenderInvitesSaga() {
  yield takeLatest(fetchLenderInvites.type, fetchLenderInvitesSaga);
}
