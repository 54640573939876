import { styled } from 'styled-components';

interface WelcomeContainerProps {
  backgroundImage?: string;
}

export const StyledWelcomeContainer = styled.div<WelcomeContainerProps>`
  display: flex;
  flex-direction: column;
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: cover;
`;

export const StyledBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 4%;
`;
