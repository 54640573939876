import React, { useEffect, useState } from 'react';
import SettingsHeader from '../components/SettingsHeader';
import { enableOrDisablePreferences, getPreferences } from '../shared/api';
import { message } from 'antd';
import {
  DividerView,
  PaginationView,
  SpinnerView,
  SwitchView,
  TextView,
} from 'components';
import { IOffsetPagination } from 'types';
import { useAppSelector } from 'utils/hooks/useAppSelector';

import { IBuckerPreference } from '../shared/contract';
import { UPDATE_PREFERENCES, VIEW_PREFERENCES } from 'rbac/constants';
import NoData from '../components/NoData';
import { getLogicDiv } from '../shared/constants';
import { isPermissionEnabled } from 'utils/helper';
import SettingsTableHeader from '../components/SettingsTableHeader';
import AuthProvider from 'templates/AuthProvider';
import { ButtonViewV3 } from 'assets/components-v3';
import CreateBucket from '../components/CreateBucket';
import NoDataBucket from 'assets/img/NoDataBucket.svg';
import UserInfo from 'templates/UserInfo';

interface IBucketPreferencesProps {
  onBucketCreateClicked?: number;
  resetBucketCreateClicked?: () => void;
  searchText: string;
}

const BucketPreferences = (props: IBucketPreferencesProps) => {
  const { onBucketCreateClicked, resetBucketCreateClicked, searchText } = props;
  const [dataSource, setDataSource] = useState<IBuckerPreference[]>();
  const [paginationData, setPaginationData] = useState<IOffsetPagination>();
  const [loading, setLoading] = useState(false);
  const { userPermission } = useAppSelector((store) => store);

  const [showCreateBucket, setShowCreateBucket] = useState(false);

  const fetchBucketPreferences = async (
    limit = 10,
    offset = 0,
    searchCriteria = ''
  ) => {
    setLoading(true);

    const { response, errorMessage } = await getPreferences(
      'buckets',
      offset,
      limit,
      searchCriteria
    ).finally(() => setLoading(false));
    const { data, count } = response || {};
    if (data) {
      setDataSource(data);
      setPaginationData({
        offset,
        limit,
        totalElements: count || 0,
        onPaginationChange: (newOffset, newLimit) =>
          fetchBucketPreferences(newLimit, newOffset, searchCriteria),
      });
    } else {
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    if (onBucketCreateClicked) {
      setShowCreateBucket(true);
    }
  }, [onBucketCreateClicked]);

  useEffect(() => {
    fetchBucketPreferences();
  }, []);

  const handlePreferenceChange = (checked: boolean, bucketId: number) => {
    setLoading(true);
    enableOrDisablePreferences('buckets', bucketId, checked).finally(() => {
      setLoading(false);
      const { offset, limit } = paginationData || {};
      fetchBucketPreferences(limit, offset);
    });
  };

  useEffect(() => {
    const { limit } = paginationData || {};
    fetchBucketPreferences(limit, 0, searchText);
  }, [searchText]);

  const editPermissionEnabled = isPermissionEnabled(
    userPermission.userPermissions,
    UPDATE_PREFERENCES
  );

  const showUserInfo =
    !loading &&
    (searchText === '' || searchText === undefined) &&
    !dataSource?.length;

  const { offset, limit, totalElements, onPaginationChange } =
    paginationData || {};

  const handlePageSizeChange = (newPageSize: number) => {
    const value = offset ? Math.floor(offset / newPageSize) : 0;
    if (onPaginationChange)
      onPaginationChange(value * newPageSize, newPageSize);
  };
  return (
    <AuthProvider permission={VIEW_PREFERENCES} showFriendlyScreen>
      <div className="flex flex-col w-full h-full">
        <SpinnerView spinning={loading}>
          <>
            {showUserInfo ? (
              <UserInfo
                headingText={'Add buckets and bucket conditions'}
                subText={[]}
                buttonComponent={
                  <div className="flex flex-col justify-center items-center ">
                    <TextView
                      text={
                        'Add buckets and their conditions that will help in\ncreating segments with an allocation.'
                      }
                      align="center"
                      color="#667085"
                    />
                    <div className="py-4">
                      <ButtonViewV3
                        label={'Add Buckets'}
                        onClick={() => {
                          setShowCreateBucket(true);
                        }}
                      />
                    </div>
                  </div>
                }
                imageUrl={NoDataBucket}
              />
            ) : (
              <div className="py-6 w-full">
                <div
                  className="flex flex-col py-4"
                  style={{ minWidth: 'fit-content' }}
                >
                  <header
                    className="flex px-6 py-2.5 gap-2 justify-between"
                    style={{ background: '#F1F5FA' }}
                  >
                    <article
                      className="w-[260px]"
                      style={{ minWidth: '260px' }}
                    >
                      <TextView
                        text="Bucket name"
                        size={12}
                        lineHeight={18}
                        weight="500"
                        color="#667085"
                      />
                    </article>
                    <article
                      className="flex justify-start w-full"
                      // style={{ minWidth: 'inherit' }}
                    >
                      <TextView
                        text="Bucket conditions"
                        size={12}
                        lineHeight={18}
                        weight="500"
                        color="#667085"
                      />
                    </article>
                    <article className="w-[260px]">
                      <TextView
                        text="Actions"
                        size={12}
                        lineHeight={18}
                        weight="500"
                        color="#667085"
                      />
                    </article>
                  </header>
                  <DividerView lineColor="#E7EAEE" />
                  <section>
                    {dataSource?.map((bucket) => {
                      const {
                        bucket_name: bucketName,
                        enable,
                        id,
                        logics,
                      } = bucket;
                      return (
                        <>
                          <div className="flex py-[16px] px-6 gap-2 flex justify-between">
                            <div
                              className="flex items-center gap-2 w-[260px]"
                              style={{ minWidth: '260px' }}
                            >
                              <div className="w-[206px] text-wrap">
                                <TextView
                                  textType={'body'}
                                  weight={'500'}
                                  fontStyle={'normal'}
                                  lineHeight={20}
                                  size={14}
                                  text={bucketName}
                                />
                              </div>
                            </div>
                            <div className="flex items-center gap-2 w-full flex-wrap justify-start">
                              {logics?.map((logic, index) => {
                                const {
                                  field_name: fieldName,
                                  operator,
                                  values,
                                } = logic;
                                return (
                                  <div className="flex items-center gap-2">
                                    {getLogicDiv(fieldName, operator, values)}
                                    {index !== logics.length - 1 && (
                                      <TextView
                                        text="&"
                                        weight="700"
                                        color="#98A2B3"
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                            <section className="w-[260px]">
                              <SwitchView
                                checked={enable}
                                onClick={(checked) => {
                                  handlePreferenceChange(checked, id);
                                }}
                                disabled={!editPermissionEnabled}
                                size="small"
                                label={''}
                              />
                            </section>
                          </div>
                          <DividerView lineColor="#E7EAEE" />
                        </>
                      );
                    })}
                  </section>
                </div>
                {paginationData && (totalElements || 0) > 10 && (
                  <div className="flex justify-end items-end flex-1">
                    <PaginationView
                      selectedPage={offset || 0}
                      totalPages={totalElements || 0}
                      title={`Rows per page`}
                      onPageSelected={(newPageSize: string) =>
                        handlePageSizeChange(parseInt(newPageSize))
                      }
                      isBorderedDropdown={true}
                      pageSize={limit || 10}
                      onPageChange={(page) =>
                        onPaginationChange &&
                        onPaginationChange(page, limit || 10)
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </>
        </SpinnerView>
      </div>
      {showCreateBucket && (
        <CreateBucket
          open={showCreateBucket}
          onClose={(isCreateSuccessful: boolean) => {
            if (isCreateSuccessful) {
              fetchBucketPreferences();
            }
            if (resetBucketCreateClicked) resetBucketCreateClicked();
            setShowCreateBucket(false);
          }}
        />
      )}
    </AuthProvider>
  );
};

export default BucketPreferences;
