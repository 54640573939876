import React, { useCallback, useEffect, useState } from 'react';
import {
  SearchView,
  SpinnerView,
  TableView,
  TextView,
} from '../../../../components';
import { inputColor, generalColor } from '../../../../assets/color';
import { createDebouncedFunction, formatDate } from '../../../../utils/helper';
import { useAppSelector } from 'utils/hooks/useAppSelector';
import {
  fetchCampaignList,
  resetOutreachConfigListState,
} from 'redux/slice/campaign-planner/CamapignList.slice';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import Grid from '@yubi/yb-core-grid';
import { useNavigate } from 'react-router-dom';
import useAmplitude from 'hooks/useAmplitude';
import { AmplitudeEvents } from 'constants/constant';
import { ICampaignListProps } from 'redux/saga/campaign-planner/Outreach.types';

const Completed = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, campaignList, totalRecords } = useAppSelector(
    (state) => state.campaignList
  );

  const { sendAmplitudeEvent } = useAmplitude(
    AmplitudeEvents.CAMPAIGN_LIST_COMPLETED_TAB_VIEW_EVENT
  );

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const [searchText, setSearchText] = useState<string>('');

  const delayedAmplitudeSearchEvent = createDebouncedFunction(
    (text: string) => {
      sendAmplitudeEvent(AmplitudeEvents.CAMPAIGN_LIST_SEARCH_TYPE_EVENT, {
        campaignName: text,
      });
    }
  );

  useEffect(() => {
    return () => {
      dispatch(resetOutreachConfigListState());
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchCampaignList({
        offset: Math.floor(pagination.offset / pagination.limit),
        limit: pagination.limit,
        status: 'completed',
        name: '',
      })
    );
  }, [pagination]);

  const handleSearchTextChange = (newText: string) => {
    setSearchText(newText);
    dispatch(
      fetchCampaignList({
        offset: Math.floor(pagination.offset / pagination.limit),
        limit: pagination.limit,
        status: 'completed',
        name: newText,
      })
    );
    if (!newText.length) return;
    delayedAmplitudeSearchEvent(newText);
  };

  const columns = [
    {
      title: 'Campaign name',
      width: 100,
      key: 'campaignName',
      render: (record: ICampaignListProps) => {
        return (
          <div>
            <TextView
              text={record?.campaignName || '-'}
              color={inputColor}
              onClick={() => {
                navigate(
                  `/campaign/monitoring?campaign-id=${record?.campaignId}`
                );
                if (!record?.campaignName) return;
                sendAmplitudeEvent(
                  AmplitudeEvents.CAMPAIGN_LIST_CAMPAIGN_NAME_CLICK_EVENT,
                  {
                    campaignName: record.campaignName,
                  }
                );
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'LOB',
      width: 100,
      key: 'lob',
      render: (record: ICampaignListProps) => {
        return (
          <TextView text={record?.lob || '-'} color={generalColor}></TextView>
        );
      },
    },
    {
      title: 'Risk',
      width: 100,
      key: 'risks',
      render: (record: ICampaignListProps) => {
        return (
          <TextView
            text={record?.risk?.join(', ') || '-'}
            color={generalColor}
          ></TextView>
        );
      },
    },
    {
      title: 'Records',
      width: 100,
      key: 'records',
      render: (record: ICampaignListProps) => {
        return (
          <TextView
            text={record?.records || '-'}
            color={generalColor}
          ></TextView>
        );
      },
    },
    {
      title: 'Duration',
      width: 100,
      key: 'createdDate',
      render: (record: ICampaignListProps) => {
        return (
          <TextView
            text={`${formatDate(record?.startDate, true) || '-'} - ${
              formatDate(record?.endDate, true) || '-'
            }`}
            color={generalColor}
          ></TextView>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginTop: '23px' }}>
        <div
          className="float-right absolute top-[-30px] right-0 flex gap-4"
          style={{ zIndex: 2 }}
        >
          <Grid container columns={12} rowGap={16}>
            <Grid style={{ alignItems: 'center' }}>
              <SearchView
                value={searchText}
                onSubmitEditing={(newText) => handleSearchTextChange(newText)}
                onChange={(newText) => handleSearchTextChange(newText)}
                placeholder={'Search'}
              />
            </Grid>
          </Grid>
        </div>
        <TableView
          isLoading={isLoading}
          columns={columns}
          dataSource={campaignList}
          pagination={{
            offset: pagination.offset,
            limit: pagination.limit,
            totalElements: totalRecords,
            onPaginationChange: (offset: number, limit: number) => {
              setPagination({ offset, limit });
            },
          }}
        />
      </div>
    </>
  );
};

export default Completed;
