import { YbCoreDropdown } from '@yubi/yb-core-dropdown-v3';
import { ILenderPoliciesState } from 'features/Campaign/Campaign';
import { ComplianceTable } from 'features/Settings/Compliance/ComplianceTable';
import { modifyPolicyStructure } from 'features/Settings/shared/helper';
import React, { useEffect, useMemo, useState } from 'react';
import { IPolicy } from 'redux/types/Compliance.types';
import { useAppSelector } from 'utils/hooks/useAppSelector';

interface ICompliance {
  policies: { [key: string]: IPolicy };
  defaultPolicy: IPolicy;
  risks: Array<string>;
  setSelectedPolicy: React.Dispatch<React.SetStateAction<string>>;
  allPolicies?: ILenderPoliciesState;
  selectedPolicy: string;
}

const Compliance = (props: ICompliance) => {
  const {
    policies,
    defaultPolicy,
    risks,
    setSelectedPolicy,
    allPolicies,
    selectedPolicy,
  } = props;
  const { data: channels } = useAppSelector(
    (state) => state.settings.preference.channels
  );

  const [selectedRisk, setSelectedRisk] = useState<string>('');
  const getDefaultData = useMemo(() => {
    const selectedPolicy = Object.values(policies || {})?.find(
      (policy) => policy.riskCategory === selectedRisk
    );
    return modifyPolicyStructure(selectedPolicy || defaultPolicy);
  }, [policies, selectedRisk, defaultPolicy]);

  useEffect(() => {
    if (risks.length) {
      setSelectedRisk(risks[0]);
    }
  }, [risks]);

  return (
    <div className="py-2">
      <ComplianceTable
        validationData={getDefaultData}
        data={getDefaultData}
        getValidData={() => {}}
        channels={channels || []}
        resetClicked={0}
        handleReset={() => {}}
        hideAction
        rightComponent={
          <div className="flex flex-row gap-2">
            <YbCoreDropdown
              size="s"
              triggerWidth={290}
              placeholder="Select"
              listWidth={204}
              type="standard"
              loading={false}
              listVariant="single"
              onChange={(e: { currentItem: { value: string } }) => {
                setSelectedPolicy(e.currentItem.value);
              }}
              defaultSelectedItems={[
                { id: 1, label: selectedPolicy, value: selectedPolicy },
              ]}
              options={Object.entries(allPolicies || {}).map(([key]) => ({
                label: key,
                value: key,
              }))}
            />

            <YbCoreDropdown
              size="s"
              triggerWidth={100}
              placeholder="Select Risk"
              type="standard"
              loading={false}
              listVariant="single"
              onChange={(e: { currentItem: { value: string } }) => {
                setSelectedRisk(e.currentItem.value);
              }}
              defaultSelectedItems={[
                { id: 1, label: selectedRisk, value: selectedRisk },
              ]}
              options={risks.map((r) => ({ label: r, value: r }))}
            />
          </div>
        }
      />
    </div>
  );
};

export default Compliance;
