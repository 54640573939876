import { put, takeLatest } from 'redux-saga/effects';
import { campaignPlanner } from '../../../services/api/endPoints';
import {
  createOutreachSucceeded,
  createOutreachFailed,
  createOutreach,
  updateOutreachSucceeded,
  updateOutreachFailed,
  updateOutreach,
  fetchOutreachList,
  getOutreachFilters,
} from '../../slice/campaign-planner/OutreachList.slice';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { ICreateOutreachResponse } from './Outreach.types';
import { message } from 'antd';
import axios from 'axios';
import { parseAxiosError } from 'utils/helper';

export function* createOutreachSaga(action: any) {
  try {
    const result: ICreateOutreachResponse = yield HTTPClient.PostRequest(
      campaignPlanner.CREATE_BULK_OUTREACH,
      action.payload.outreach
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(createOutreachSucceeded(result?.data?.data));
      action.payload.apiSuccessCallback?.({ isStatus: true });
      message.success(result?.data?.message || 'Outreach created successfully');
    } else {
      yield put(
        createOutreachFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      createOutreachFailed({
        error: {
          message: 'An error occurred while creating outreaches.',
        },
      })
    );
    if (axios.isAxiosError(error)) {
      // Ensure the error is an AxiosError
      const parsedError = parseAxiosError(error);
      message.error(
        parsedError?.response.data.error || 'Failed to create outreaches'
      );
    }
  }
}

export function* updateOutreachSaga(payload: any) {
  try {
    const result: ICreateOutreachResponse = yield HTTPClient.PutRequest(
      `${campaignPlanner.OUTREACH()}/${payload.payload.outreach.outreachId}`,
      payload.payload.outreach
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(updateOutreachSucceeded(result?.data?.data));
      payload.payload.apiSuccessCallback?.({ isStatus: true });
      message.success(result?.data?.message || 'Outreach updated successfully');
    } else {
      yield put(
        updateOutreachFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      updateOutreachFailed({
        error: {
          message: 'An error occurred while updating outreaches.',
        },
      })
    );
    if (axios.isAxiosError(error)) {
      // Ensure the error is an AxiosError
      const parsedError = parseAxiosError(error);
      message.error(
        parsedError?.response.data.error || 'Failed to update outreaches'
      );
    }
  }
}

export function* onCreateOutreachSuccess(
  action: ReturnType<typeof createOutreachSucceeded>
) {
  // Call fetchOutreachListSaga with the necessary payload
  yield put(
    fetchOutreachList({
      offset: 0, // Update with your desired offset
      limit: 10, // Update with your desired limit
      searchText: '',
      appliedFilterState: {},
    })
  );
  yield put(getOutreachFilters());
}

export function* watchCreateOutreachSuccess() {
  yield takeLatest(createOutreachSucceeded.type, onCreateOutreachSuccess);
}

export function* watchCreateOutreachSaga() {
  yield takeLatest(createOutreach.type, createOutreachSaga);
  yield takeLatest(updateOutreach.type, updateOutreachSaga);
}
