import { YbCoreTypography } from '@yubi/yb-core-typography';
import { TextView } from 'components';
import { AmplitudeEvents } from 'constants/constant';
import useAmplitude from 'hooks/useAmplitude';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlanDay } from 'redux/slice/strategy/strategy';
import { RootState } from 'redux/store/store';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  cursor: pointer;
`;
export enum State {
  PAST,
  PRESENT,
  FUTURE,
}
type StyledViewProps = {
  state: State;
};
const getBackgroundColor = ({ state }: StyledViewProps) => {
  if (state === State.PAST) return '#667085';
  if (state === State.FUTURE) return '#FFFFFF';

  return '#F79009';
};

export const getTextColor = (state: State) => {
  if (state === State.FUTURE) return '#98A2B3';
  return '#FFFFFF';
};

export const StyledView = styled.div<StyledViewProps>`
  background-color: ${getBackgroundColor};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;

export interface DayTabProps {
  state: State;
  date: number;
  subTitle: string;
}

export default function DayTab({ state, date, subTitle }: DayTabProps) {
  const dispatch = useDispatch();
  const strategyName = useSelector(
    (store: RootState) => store.strategy.createStrategy.strategyName
  );

  const { sendAmplitudeEvent } = useAmplitude();
  return (
    <Wrapper
      onClick={() => {
        sendAmplitudeEvent(AmplitudeEvents.CAMPAIGN_STRATEGY_DAY_CLICK_EVENT, {
          strategyName,
          day: date,
        });
        dispatch(selectPlanDay({ selectedPlanDay: date }));
      }}
    >
      <YbCoreTypography color="#475467" size={12} lineHeight={18}>
        {subTitle}
      </YbCoreTypography>
      <StyledView state={state}>
        <TextView color={getTextColor(state)} text={date?.toString()} />
      </StyledView>
    </Wrapper>
  );
}
