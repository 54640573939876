import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILocationConfiguration } from 'features/Settings/UserManagment/EmployeeProfile/EmployeeProfile.types';
import { ISagaDesignationParam } from 'redux/saga/hierarchy-management/DesignationManagement.types';
import { ISagaHierarchySetupParam } from 'redux/saga/hierarchy-management/HierarchySetup.types';
import { APIStatus } from 'services/api/apiStatusCode';

interface IUser {
  userId: string;
  name: string;
  email: string;
  phoneNumber: string;
  employeeId: string;
}

interface IProduct {
  id: number;
  entityId: string;
  lineOfBusiness: string;
  assetClassCategory: string;
  assetClassSubCategory: string;
  enabled: boolean;
  productName: string;
}

interface IUserConfiguration {
  id: number;
  entityId: string;
  user: IUser;
  product: IProduct;
  bucketIds: number[];
}

export interface ITeamMemberData {
  designation: string;
  user: IUser;
  parentUser: IUser | null;
  parentDesignation: string | null;
  entityId: string;
  lenderEntities?: { lenderId: string; lenderName: string }[];
  userConfigurations: IUserConfiguration[];
  locationConfigurations?: ILocationConfiguration[];
}
interface IHierarchySetupPayloadAction {
  data: ITeamMemberData[];
  count: number;
  message: string;
}
export interface IHierarchySetupInitialState
  extends IHierarchySetupPayloadAction {
  isLoading: boolean;
  status: APIStatus;
  error: { message?: string };
}

export const hierarchySetupInitialState: IHierarchySetupInitialState = {
  data: [],
  count: 0,
  message: '',
  status: APIStatus.INIT,
  isLoading: false,
  error: {},
};

const hierarchySetupSlice = createSlice({
  name: 'hierarchySetup',
  initialState: hierarchySetupInitialState,
  reducers: {
    fetchHierarchySetupData: (
      state,
      action: PayloadAction<ISagaHierarchySetupParam>
    ) => {
      state.isLoading = true;
    },
    fetchHierarchySetupDataSucceeded: (
      state,
      action: PayloadAction<IHierarchySetupPayloadAction>
    ) => {
      const { data, count, message } = action.payload;
      state.data = data;
      state.count = count;
      state.message = message;
      state.status = APIStatus.SUCCESS;
      state.isLoading = false;
    },
    fetchHierarchySetupDataFailed: (
      state,
      action: PayloadAction<{ message: string }>
    ) => {
      state.isLoading = false;
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
    resetHierarchySetupState: () => hierarchySetupInitialState,
  },
});

export const {
  fetchHierarchySetupData,
  fetchHierarchySetupDataSucceeded,
  fetchHierarchySetupDataFailed,
  resetHierarchySetupState,
} = hierarchySetupSlice.actions;

export default hierarchySetupSlice.reducer;
