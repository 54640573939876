import React from 'react';
import TextView from 'components/TextView';
import { StyledFlexDiv } from './styledComponents';
import SwitchView from 'components/SwitchView';
import {
  DerivedFormulas,
  ICampaignTime,
  IChannelComplianceData,
  IChannelPreferenceData,
  IComplianceConfigColumns,
  IContentPreference,
  IEditFlow,
  IInfoComp,
  ILMSField,
  ILobData,
  INumberInput,
  IProductPreferencesData,
  IRange,
} from './contract';
import { DividerView, ImageView, InputView, TimePickerView } from 'components';
import EditIcon from 'assets/icon/pencil.svg';
import EyeIcon from 'assets/icon/eye.svg';
import GreenCheckIcon from 'assets/icon/green-check.svg';
import CloseIcon from 'assets/icon/close.svg';
import { ColumnProps } from 'antd/es/table';
import Trash from 'assets/svgComponents/Trash';
import './styles.css';
import { ILenderPolicy } from 'redux/types/Compliance.types';
import {
  convertTimeTo12hrs,
  getLogicText,
  getMaxMessage,
  getMinMessage,
} from './helper';
import { Dropdown, Tooltip } from 'antd';
import FullyPaid from 'assets/icon/fully-paid.svg';
import AuthProvider from 'templates/AuthProvider';
import { EDIT_COMPLIANCE, VIEW_COMPLIANCE } from 'rbac/constants';
import verticalDotsIcon from 'assets/img/verticalDots.svg';
import { CirclePlus } from 'assets/svgComponents/CircularPlus';
import { YbCoreIcon } from '@yubi/yb-core-icon';

export const locationPreferencesColumns = [
  {
    title: 'Zone',
    dataIndex: 'zone',
    key: 'zone',
    render: (zone: string) => {
      return (
        <StyledFlexDiv>
          <TextView weight={'500'} lineHeight={20} size={14} text={zone} />
        </StyledFlexDiv>
      );
    },
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    render: (state: string) => {
      return (
        <StyledFlexDiv>
          <TextView weight={'500'} lineHeight={20} size={14} text={state} />
        </StyledFlexDiv>
      );
    },
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
    render: (region: string) => {
      return (
        <StyledFlexDiv>
          <TextView weight={'500'} lineHeight={20} size={14} text={region} />
        </StyledFlexDiv>
      );
    },
  },
];

export const productPreferencesColumns = (
  handlePreferenceChange: (isChecked: boolean, id: number) => void,
  editPermissionEnabled: boolean,
  handleDelete: (id: number) => void
) => [
  {
    title: 'Category',
    dataIndex: 'assetClassCategory',
    key: 'assetClassCategory',
    render: (assetClassCategory: string) => {
      return (
        <TextView
          weight={'500'}
          fontStyle={'normal'}
          lineHeight={20}
          size={14}
          text={assetClassCategory}
        />
      );
    },
  },
  {
    title: 'Sub category',
    dataIndex: 'assetClassSubCategory',
    key: 'assetClassSubCategory',
    render: (assetClassSubCategory: string) => (
      <TextView
        weight={'500'}
        fontStyle={'normal'}
        lineHeight={20}
        size={14}
        text={assetClassSubCategory}
      />
    ),
  },
  {
    title: 'Product name',
    dataIndex: 'productName',
    key: 'productName',
    render: (productName?: string) => (
      <TextView
        weight={'500'}
        fontStyle={'normal'}
        lineHeight={20}
        size={14}
        text={productName || ''}
      />
    ),
  },
  {
    title: 'Actions',
    dataIndex: '',
    key: 'actions',
    width: '150px',
    render: (_: unknown, obj: IProductPreferencesData) => (
      <section
        className={`${
          editPermissionEnabled ? 'cursor-pointer' : 'cursor-not-allowed'
        }`}
        onClick={() => {
          if (editPermissionEnabled) {
            handleDelete(obj.productId);
          }
        }}
      >
        {/* <YbCoreIcon size={16} name={'trash-01'} /> */}
        <SwitchView
          checked={obj.enabled}
          onClick={(checked) => {
            handlePreferenceChange(checked, obj.productId);
          }}
          disabled={!editPermissionEnabled}
          size="small"
          label=""
        />
      </section>
    ),
  },
];

export const getChannelTableColumns = (
  handlePreferenceChange: (isChecked: boolean, id: number) => void,
  editPermissionEnabled: boolean
) => [
  {
    key: 'channel_toggle',
    width: '16px',
    render: (_: unknown, obj: IChannelPreferenceData) => {
      return (
        <>
          <SwitchView
            checked={obj.enabled}
            onClick={(checked) => {
              handlePreferenceChange(checked, obj.channelPreferenceId);
            }}
            disabled={!editPermissionEnabled}
            size="small"
            label=""
          />
        </>
      );
    },
  },
  {
    title: 'Name',
    dataIndex: 'displayName',
    key: 'displayName',
    render: (name: string) => {
      return (
        <TextView
          textType={'body'}
          weight={'500'}
          fontStyle={'normal'}
          lineHeight={20}
          size={14}
          text={name}
        />
      );
    },
  },
];

export const NumberInput = (props: INumberInput) => {
  const {
    value,
    onChange,
    maxInput,
    minInput,
    size = 'm',
    placeHolderText,
    borderColor,
  } = props;

  return (
    <input
      style={{
        borderColor: borderColor || '#9aa5b8',
      }}
      className="outline-none px-4 w-full rounded-lg h-8 border"
      type="number"
      max={maxInput}
      min={minInput}
      value={value}
      onChange={(e) => {
        const newValue = parseInt(e.target.value);
        onChange(newValue);
      }}
      placeholder={placeHolderText}
      onKeyDown={(e) => {
        if (e.key === '-' || e.key === '+') {
          e.preventDefault();
        }
      }}
    />
  );
};

const EditFlow = (props: IEditFlow) => {
  const { isEditable, validConditionRender, inValidConditionRender } = props;
  return <>{isEditable ? validConditionRender : inValidConditionRender}</>;
};

const getChannelDisplayName = (
  channelPreferences: IChannelPreferenceData[],
  channel: string
) => {
  const channelObj = channelPreferences.find(
    (channelPreference) => channelPreference.channelProductName === channel
  );
  return channelObj?.displayName || '-';
};

export const complianceConfigColumns = (args: IComplianceConfigColumns) => {
  const {
    handleChange,
    onEditClick,
    editableArray,
    week,
    validationData,
    showPolicyStatus,
    channelPreferences,
    hideAction,
  } = args;

  const columnsArray: ColumnProps<any>[] = [
    {
      title: 'Channel',
      dataIndex: 'channel',
      fixed: 'left' as const,
      render: (channel: string, channelCompliance: IChannelComplianceData) => {
        return (
          <div className={`flex items-center `}>
            <TextView
              text={getChannelDisplayName(channelPreferences, channel)}
            />
          </div>
        );
      },
    },
    {
      title: 'Start time',
      dataIndex: 'campaignTime',
      width: 200,
      align: 'left' as const,
      render: (
        campaignTime: ICampaignTime,
        channelCompliance: IChannelComplianceData,
        index: number
      ) => {
        const campaignTimeToValidate =
          validationData?.[
            `${channelCompliance.channel}` as keyof typeof validationData
          ]?.campaignTime;
        return (
          <div className={`flex items-center `}>
            <EditFlow
              isEditable={editableArray.includes(index)}
              validConditionRender={
                <TimePickerView
                  selectedTime={campaignTime.start}
                  minTime={campaignTimeToValidate?.start}
                  maxTime={campaignTime?.end || campaignTimeToValidate?.end}
                  onSelectTime={(time) => {
                    handleChange(
                      `${week}.${channelCompliance.channel}.campaignTime.start`,
                      time
                    );
                  }}
                />
              }
              inValidConditionRender={
                <TextView
                  text={convertTimeTo12hrs(campaignTime.start)}
                  color="#667085"
                />
              }
            />
          </div>
        );
      },
    },
    {
      title: 'End time',
      dataIndex: 'campaignTime',
      align: 'left' as const,
      width: 200,
      render: (
        campaignTime: ICampaignTime,
        channelCompliance: IChannelComplianceData,
        index: number
      ) => {
        const campaignTimeToValidate =
          validationData?.[
            `${channelCompliance.channel}` as keyof typeof validationData
          ]?.campaignTime;
        return (
          <div className={`flex items-center `}>
            <EditFlow
              isEditable={editableArray.includes(index)}
              validConditionRender={
                <TimePickerView
                  minTime={campaignTime.start}
                  maxTime={campaignTimeToValidate?.end}
                  selectedTime={campaignTime.end}
                  onSelectTime={(time) => {
                    handleChange(
                      `${week}.${channelCompliance.channel}.campaignTime.end`,
                      time
                    );
                  }}
                />
              }
              inValidConditionRender={
                <TextView
                  text={convertTimeTo12hrs(campaignTime.end)}
                  color="#667085"
                />
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Intensity',
      dataIndex: 'campaignMaxIntensity',
      align: 'left' as const,
      width: 120,
      render: (
        intensity: number,
        channelCompliance: IChannelComplianceData,
        index: number
      ) => {
        const maxIntensity =
          validationData?.[
            `${channelCompliance.channel}` as keyof typeof validationData
          ]?.campaignMaxIntensity || 0;
        return (
          <div className={`flex justify-start`}>
            <EditFlow
              isEditable={editableArray.includes(index)}
              validConditionRender={
                <NumberInput
                  minInput={0}
                  maxInput={maxIntensity}
                  value={intensity}
                  onChange={(e) => {
                    if (e != null && !(e > maxIntensity || e < 0)) {
                      handleChange(
                        `${week}.${channelCompliance.channel}.campaignMaxIntensity`,
                        e
                      );
                    }
                  }}
                />
              }
              inValidConditionRender={
                <TextView
                  text={`${intensity != null ? intensity : '-'}`}
                  color="#667085"
                />
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Campaign timegap (minutes)',
      dataIndex: 'campaignTimeGapMinutes',
      align: 'left' as const,
      width: 200,
      render: (
        timegap: number,
        channelCompliance: IChannelComplianceData,
        index: number
      ) => {
        const timeGap =
          validationData?.[
            `${channelCompliance.channel}` as keyof typeof validationData
          ]?.campaignTimeGapMinutes || 0;

        return (
          <div className={`flex items-center justify-start `}>
            <EditFlow
              isEditable={editableArray.includes(index)}
              validConditionRender={
                <NumberInput
                  minInput={timeGap}
                  maxInput={7500}
                  value={timegap}
                  onChange={(e) => {
                    if (e != null && !(e < timeGap || e > 7500)) {
                      handleChange(
                        `${week}.${channelCompliance.channel}.campaignTimeGapMinutes`,
                        e
                      );
                    }
                  }}
                />
              }
              inValidConditionRender={
                <TextView
                  text={`${timegap != null ? timegap : '-'}`}
                  color="#667085"
                />
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Action',
      align: 'right' as const,
      fixed: 'right' as const,
      width: '80px' as const,
      render: (
        _: unknown,
        channelCompliance: IChannelComplianceData,
        index: number
      ) => (
        <div className={`flex justify-end`}>
          <EditFlow
            isEditable={editableArray.includes(index)}
            validConditionRender={
              <div className="flex justify-end  gap-2">
                <div
                  className="p-1 rounded-full"
                  style={{
                    background: '#DEF7EC',
                  }}
                >
                  <ImageView
                    src={GreenCheckIcon}
                    onClick={() =>
                      onEditClick(
                        index,
                        true,
                        week,
                        channelCompliance.channel as string
                      )
                    }
                  />
                </div>
                <div
                  className="p-1 rounded-full"
                  style={{
                    background: '#FDE8E8',
                  }}
                >
                  <ImageView
                    src={CloseIcon}
                    onClick={() =>
                      onEditClick(
                        index,
                        false,
                        week,
                        channelCompliance.channel as string
                      )
                    }
                  />
                </div>
              </div>
            }
            inValidConditionRender={
              <Tooltip placement="top" title={'Edit'}>
                <div
                  className={`${
                    !channelCompliance?.isActive ? 'pointer-events-none' : ''
                  }`}
                >
                  <ImageView
                    src={EditIcon}
                    onClick={() =>
                      onEditClick(
                        index,
                        false,
                        week,
                        channelCompliance.channel as string
                      )
                    }
                  />
                </div>
              </Tooltip>
            }
          />
        </div>
      ),
    },
  ];
  if (hideAction) {
    columnsArray.pop();
  }
  if (showPolicyStatus) {
    columnsArray.splice(5, 0, {
      title: 'Policy Status',
      dataIndex: 'isDefault',
      width: '120px',
      align: 'center' as const,
      render: (isDefault: boolean) => (
        <div className="flex justify-center">
          <div
            className={`rounded-full px-2 py-1 w-fit ${
              isDefault ? 'default' : 'modified'
            }`}
          >
            <TextView
              text={isDefault ? 'Default' : 'Modified'}
              size={12}
              lineHeight={18}
              weight="700"
              color={isDefault ? '#222836' : '#C27803'}
            />
          </div>
        </div>
      ),
    });
  }
  return columnsArray;
};

interface IFieldsValue {
  type: string;
  label: string;
  path: string;
  description?: string;
  validatorMessage: string;
  minValue?: number;
  maxValue?: number;
}

export interface IDerivedFieldOption {
  title: string;
  key: string;
  description: string;
  type: Array<string>;
  fields: Array<IFieldsValue>;
}

export const fieldOptions: { [key: string]: IDerivedFieldOption } = {
  ratio: {
    title: 'Ratio',
    key: 'ratio',
    description: 'Divide 2 LMS fields to create a ratio',
    type: ['integer', 'float'],
    fields: [
      {
        type: 'field',
        label: 'Numerator',
        path: '[fields,0]',
        description: 'Select the field to use on the top',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'field',
        label: 'Denominator',
        path: '[fields,1]',
        description: 'Select the field to use on the bottom',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'input',
        label: 'New field name',
        path: '[newFields,0]',
        validatorMessage: 'Please enter a field name',
      },
    ],
  },
  range: {
    title: 'Range',
    key: 'range',
    description: 'Create categories using range values',
    type: ['integer', 'float'],
    fields: [
      {
        type: 'field',
        label: 'Parent field',
        path: '[fields,0]',
        description: 'Select the field to create ranges',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'input',
        label: 'New field name',
        path: '[newFields,0]',
        validatorMessage: 'Please enter a field name',
      },
    ],
  },
  merge: {
    title: 'Merge',
    key: 'merge',
    description: 'Merge 2 LMS fields to into a single field',
    type: ['integer', 'float', 'string', 'enum', 'date'],
    fields: [
      {
        type: 'field',
        label: 'Field 1',
        path: '[fields,0]',
        description: 'Select the first field to merge',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'operator',
        label: 'Separator',
        path: 'separator',
        description: 'Select the separator ',
        validatorMessage: 'Please select a separator',
      },
      {
        type: 'field',
        label: 'Field 2',
        path: '[fields,1]',
        description: 'Select the second field to merge',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'input',
        label: 'New field name',
        path: '[newFields,0]',
        validatorMessage: 'Please enter a field name',
      },
    ],
  },
  // split: {
  //   title: 'Split',
  //   key: 'split',
  //   description: 'Split an LMS field into multiple fields',
  //   type: ['string', 'enum', 'date'],
  //   fields: [
  //     {
  //       type: 'field',
  //       label: 'Parent field',
  //       path: '[fields,0]',
  //       description: 'Select the field to split',
  //       validatorMessage: 'Please select a field',
  //     },
  //     {
  //       type: 'operator',
  //       label: 'Split by',
  //       path: 'separator',
  //       description: 'Select a separator to split field',
  //       validatorMessage: 'Please select a separator',
  //     },
  //     {
  //       type: 'input',
  //       label: 'Split field 1 name',
  //       path: '[newFields,0]',
  //       description: 'Name for the first field ',
  //       validatorMessage: 'Please enter a field name',
  //     },
  //     {
  //       type: 'input',
  //       label: 'Split field 2 name',
  //       path: '[newFields,1]',
  //       description: 'Name for the second field ',
  //       validatorMessage: 'Please enter a field name',
  //     },
  //   ],
  // },
  round_off: {
    title: 'Round off',
    key: 'round_off',
    description: 'Round number to nearest integer value',
    type: ['integer', 'float'],
    fields: [
      {
        type: 'field',
        label: 'Parent field',
        path: '[fields,0]',
        description: 'Select the field to round off',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'input',
        label: 'New field name',
        path: '[newFields,0]',
        validatorMessage: 'Please enter a field name',
      },
    ],
  },
  round_up: {
    title: 'Round up',
    key: 'round_up',
    description: 'Round number to next integer value',
    type: ['integer', 'float'],
    fields: [
      {
        type: 'field',
        label: 'Parent field',
        path: '[fields,0]',
        description: 'Select the field to round up',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'input',
        label: 'New field name',
        path: '[newFields,0]',
        validatorMessage: 'Please enter a field name',
      },
    ],
  },
  round_down: {
    title: 'Round down',
    key: 'round_down',
    description: 'Round number to lower integer value',
    type: ['integer', 'float'],
    fields: [
      {
        type: 'field',
        label: 'Parent field',
        path: '[fields,0]',
        description: 'Select the field to round down',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'input',
        label: 'New field name',
        path: '[newFields,0]',
        validatorMessage: 'Please enter a field name',
      },
    ],
  },
  discount: {
    title: 'Discount',
    key: 'discount',
    description: 'Discount to be given',
    type: ['integer', 'float'],
    fields: [
      {
        type: 'field',
        label: 'Parent field',
        path: '[fields,0]',
        description: 'Select the field to discount',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'input',
        label: 'New field name',
        path: '[newFields,0]',
        validatorMessage: 'Please enter a field name',
      },
      {
        type: 'inputNumeric',
        label: 'Discount (%)',
        minValue: 0,
        maxValue: 100,
        path: '[values,0]',
        description: 'Discount to be given',
        validatorMessage: 'Please enter a number',
      },
    ],
  },
  rename: {
    title: 'Rename',
    key: 'rename',
    description: 'Rename an existing field',
    type: ['integer', 'float', 'string', 'enum', 'date'],
    fields: [
      {
        type: 'field',
        label: 'Parent field',
        path: '[fields,0]',
        description: 'Select the field to discount',
        validatorMessage: 'Please select a field',
      },
      {
        type: 'input',
        label: 'New field name',
        path: '[newFields,0]',
        validatorMessage: 'Please enter a field name',
      },
    ],
  },
};

export const operatorOptions = [
  {
    label: '_(Underscore)',
    value: '_underscore_',
  },
  {
    label: '-(Hyphen)',
    value: '_hyphen_',
  },
  {
    label: ',(Comma)',
    value: '_comma_',
  },
  {
    label: ':(Colon)',
    value: '_colon_',
  },
  {
    label: ';(Semi-Colon)',
    value: '_semi_',
  },
  {
    label: '&(Ampersand)',
    value: '_amp_',
  },
  {
    label: '(Space)',
    value: '_space_',
  },
];

const getFieldName = (lmsFields: ILMSField[], fieldName: string) => {
  return lmsFields.find((field) => field.completeJsonPath === fieldName)
    ?.displayName;
};

const getParentFieldText = (data: Array<string>, lmsFields: ILMSField[]) => {
  let text = '';
  data.forEach((item, index) => {
    text += getFieldName(lmsFields, item) || '-';
    if (index !== data.length - 1) {
      text += ', ';
    }
  });
  return text;
};

export const derivedFieldsColumns = (
  lmsFields: ILMSField[],
  handleDelete: (id: number) => void,
  showActions: boolean
) => {
  const columns = [
    {
      title: 'New Derived Fields',
      dataIndex: 'newFields',
      key: 'fieldName',
      render: (data: Array<string>) => {
        return (
          <div className="flex flex-wrap gap-2">
            {data.map((item, index) => {
              return (
                <div>
                  <TextView
                    textType={'body'}
                    weight={'500'}
                    fontStyle={'normal'}
                    lineHeight={20}
                    size={14}
                    text={item}
                  />
                  {index !== data.length - 1 && <>,</>}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: 'Parent Fields',
      dataIndex: 'fields',
      key: 'fieldName',
      render: (data: Array<string>) => {
        return (
          <div className="flex flex-wrap">
            <TextView
              textType={'body'}
              weight={'500'}
              fontStyle={'normal'}
              lineHeight={20}
              size={14}
              text={getParentFieldText(data, lmsFields)}
            />
          </div>
        );
      },
    },
    {
      title: 'Formula',
      dataIndex: 'operation',
      key: 'formula',
      render: (operation: string) => {
        return (
          <TextView
            textType={'body'}
            weight={'500'}
            fontStyle={'normal'}
            lineHeight={20}
            size={14}
            text={DerivedFormulas[operation as keyof typeof DerivedFormulas]}
          />
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      align: 'right' as const,
      render: (id: number) => {
        return (
          <div className="flex justify-end gap-6">
            <div className="cursor-pointer" onClick={() => handleDelete(id)}>
              <Trash />
            </div>
          </div>
        );
      },
    },
  ];
  if (!showActions) {
    columns.pop();
  }
  return columns;
};

export const getDerivedFieldValidationRules = (operator: string) => {
  const rules = {
    ratio: {
      fields: {
        required: true,
        min: 2,
      },
      newFields: {
        required: true,
        min: 1,
      },
      operation: {
        required: true,
      },
    },
    range: {
      fields: {
        required: true,
        min: 1,
      },
      newFields: {
        required: true,
        min: 1,
      },
      operation: {
        required: true,
      },
    },
    merge: {
      fields: {
        required: true,
        min: 2,
      },
      newFields: {
        required: true,
        min: 1,
      },
      operation: {
        required: true,
      },
      separator: {
        required: true,
      },
    },
    split: {
      fields: {
        required: true,
        min: 1,
      },
      newFields: {
        required: true,
        min: 2,
      },
      operation: {
        required: true,
      },
      separator: {
        required: true,
      },
    },
    round_up: {
      fields: {
        required: true,
        min: 1,
      },
      newFields: {
        required: true,
        min: 1,
      },
      operation: {
        required: true,
      },
    },
    round_off: {
      fields: {
        required: true,
        min: 1,
      },
      newFields: {
        required: true,
        min: 1,
      },
      operation: {
        required: true,
      },
    },
    round_down: {
      fields: {
        required: true,
        min: 1,
      },
      newFields: {
        required: true,
        min: 1,
      },
      operation: {
        required: true,
      },
    },
    discount: {
      fields: {
        required: true,
        min: 1,
      },
      newFields: {
        required: true,
        min: 1,
      },
      operation: {
        required: true,
      },
      values: {
        required: true,
        min: 1,
      },
    },
  };
  return rules[operator as keyof typeof rules];
};

export const rangeTableColumns = (
  editableRow: number | undefined,
  onChange: (
    index: number,
    property: string,
    value: string | number | undefined
  ) => void,
  onSaveDelete: (
    index: number,
    key: 'save' | 'discard' | 'delete' | 'edit' | 'addNew'
  ) => void,
  data: IRange[],
  showError: number
) => [
  {
    title: 'Label',
    dataIndex: 'label',
    render: (label: string, _: unknown, index: number) => {
      if (editableRow === index) {
        return (
          <div className="w-32 range-table-cell">
            <InputView
              touched={!!showError}
              error={
                showError && !(label || '').trim() ? 'Please enter a label' : ''
              }
              size="s"
              initialValue={label}
              onChangeText={(e) =>
                onChange(index, 'label', ((e as string) || '').trimStart())
              }
              maxLength={50}
            />
          </div>
        );
      }
      return (
        <TextView
          text={label}
          size={14}
          lineHeight={20}
          weight="400"
          color="#344054"
        />
      );
    },
  },
  {
    title: 'Min value',
    dataIndex: 'minValue',
    render: (minValue: number | undefined, _: unknown, index: number) => {
      if (editableRow === index) {
        return (
          <div className="w-32 range-table-cell">
            <InputView
              touched={!!showError}
              error={showError ? getMinMessage(data, index) : ''}
              isNumeric
              size="s"
              initialValue={minValue ? `${minValue}` : ''}
              onChangeText={(e) => onChange(index, 'minValue', Number(e))}
            />
          </div>
        );
      }
      return (
        <TextView
          text={`${minValue}`}
          size={14}
          lineHeight={20}
          weight="400"
          color="#344054"
        />
      );
    },
  },
  {
    title: 'Max value',
    dataIndex: 'maxValue',
    render: (maxValue: number | undefined, _: unknown, index: number) => {
      if (editableRow === index) {
        return (
          <div className="w-32 range-table-cell">
            <InputView
              touched={!!showError}
              error={showError ? getMaxMessage(data, index) : ''}
              size="s"
              isNumeric
              initialValue={maxValue ? `${maxValue}` : ''}
              onChangeText={(e) => onChange(index, 'maxValue', Number(e))}
            />
          </div>
        );
      }
      return (
        <TextView
          text={`${maxValue}`}
          size={14}
          lineHeight={20}
          weight="400"
          color="#344054"
        />
      );
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'right' as const,
    render: (_: unknown, __: unknown, index: number) => (
      <div className="flex justify-end gap-4">
        {editableRow === index ? (
          <>
            <div
              className="p-1 rounded-full cursor-pointer"
              style={{ backgroundColor: '#DEF7EC' }}
              onClick={() => onSaveDelete(index, 'save')}
            >
              <img alt="icons" src={GreenCheckIcon} />
            </div>
            {index !== 0 && (
              <div
                className="p-1 rounded-full cursor-pointer"
                style={{ backgroundColor: '#FDE8E8' }}
                onClick={() => onSaveDelete(index, 'discard')}
              >
                <img alt="icons" src={CloseIcon} />
              </div>
            )}
          </>
        ) : (
          <div
            className={`flex gap-4 ${editableRow ? 'cursor-not-allowed' : ''}`}
          >
            <div
              className={editableRow ? 'pointer-events-none' : 'cursor-pointer'}
              onClick={() => onSaveDelete(index, 'edit')}
            >
              <img alt="icons" src={EditIcon} />
            </div>
            {data.length > 1 && (
              <div
                className={
                  editableRow ? 'pointer-events-none' : 'cursor-pointer'
                }
                onClick={() => onSaveDelete(index, 'delete')}
              >
                <Trash />
              </div>
            )}
            <div
              className={editableRow ? 'pointer-events-none' : 'cursor-pointer'}
              onClick={() => onSaveDelete(index, 'addNew')}
            >
              <CirclePlus />
            </div>
          </div>
        )}
      </div>
    ),
  },
];

export const derivedFieldsSteps = [
  {
    stepName: 'Formula',
  },
  {
    stepName: 'Fields',
  },
];

export const initialDeriveFieldData = {
  fields: [],
  newFields: [],
  operation: '',
  separator: '',
  values: [],
  dataType: '',
};

export enum Weeks {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  holiday = 'Holiday',
}

const getModifiedChannels = (
  weeks: Array<string>,
  data: ILenderPolicy
): string[] => {
  const modifiedChannels: Array<string> = [];
  weeks.forEach((week) => {
    const channels = data[week as keyof typeof Weeks];
    if (channels) {
      Object.keys(channels).forEach((channel) => {
        if (
          channels[channel as keyof typeof Weeks]?.isDefault === false &&
          modifiedChannels.indexOf(channel) === -1
        ) {
          modifiedChannels.push(channel);
        }
      });
    }
  });
  return modifiedChannels;
};

interface IGetPoliciesColumns {
  onEditClick: (id?: string) => void;
  onViewClick: (id: string) => void;
  onStatusUpdate: (id: string, isActive: boolean) => void;
  channels: IChannelPreferenceData[];
  showActions: boolean;
}

const textStyle = {
  width: '28ch',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  lineHeight: '18px',
  color: '#667085',
  fontWeight: 400,
};

export const policiesColumns = ({
  onEditClick,
  onViewClick,
  onStatusUpdate,
  channels,
  showActions,
}: IGetPoliciesColumns) => {
  const columns = [
    {
      title: 'Name',
      key: 'name',
      fixed: 'left' as const,
      dataIndex: 'policyName',
      render: (name: string, data: ILenderPolicy) => {
        const { id, isActive } = data;
        return (
          <div className="flex gap-2">
            <SwitchView
              checked={isActive}
              onClick={(checked) => {
                onStatusUpdate(id, checked);
              }}
              size="small"
              label=""
              disabled={!showActions}
            />
            <TextView
              textType="h1"
              text={name}
              weight="500"
              size={14}
              lineHeight={20}
              color="#101828"
            />
          </div>
        );
      },
    },
    {
      title: 'Product',
      key: 'productName',
      dataIndex: 'productName',
      align: 'left' as const,
      render: (productName: string) => (
        <TextView
          text={productName}
          weight="400"
          size={12}
          lineHeight={18}
          color="#667085"
        />
      ),
    },
    {
      title: 'Bucket',
      key: 'bucketName',
      dataIndex: 'bucketName',
      align: 'left' as const,
      render: (bucketName: string) => (
        <TextView
          text={bucketName}
          weight="400"
          size={12}
          lineHeight={18}
          color="#667085"
        />
      ),
    },
    {
      title: 'Risk',
      key: 'riskCategory',
      dataIndex: 'riskCategory',
      align: 'left' as const,
      render: (riskCategory: string) => (
        <TextView
          text={riskCategory}
          weight="400"
          size={12}
          lineHeight={18}
          color="#667085"
        />
      ),
    },
    {
      title: 'Modified Channels',
      key: 'activeDays',
      dataIndex: 'activeDays',
      align: 'left' as const,
      render: (activeDays: Array<string>, policyData: ILenderPolicy) => {
        const modifiedChannels = getModifiedChannels(activeDays, policyData);
        const totalChannels = modifiedChannels.length;
        let text = '-';
        if (totalChannels) {
          let combinedText = '';
          modifiedChannels.forEach(
            (channel, index) =>
              (combinedText += `${getChannelDisplayName(channels, channel)}${
                index !== totalChannels - 1 ? ', ' : ''
              }`)
          );
          text = combinedText;
        }
        return (
          <Tooltip title={text.length > 28 ? text : ''}>
            <div style={textStyle}>
              <TextView text={text} color="#667085" />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Modified Days',
      key: 'weeks',
      dataIndex: 'activeDays',
      align: 'left' as const,
      render: (activeDays: Array<string>, policyData: ILenderPolicy) => {
        let text = '-';
        if (activeDays.length > 0) {
          const modifiedWeeks: string[] = activeDays.filter((week) => {
            const weekData = policyData[week as keyof typeof Weeks];
            return (
              !!weekData &&
              Object.values(weekData).some((channel) => !channel.isDefault)
            );
          });

          if (modifiedWeeks.length > 0) {
            const sortOrder = Object.keys(Weeks);
            modifiedWeeks.sort(
              (a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)
            );
            text = modifiedWeeks
              .map((week, index) => {
                const separator =
                  index !== modifiedWeeks.length - 1 ? ', ' : '';
                return `${Weeks[week as keyof typeof Weeks]}${separator}`;
              })
              .join('');
          }
        }

        return (
          <Tooltip title={text.length > 28 ? text : ''}>
            <div style={textStyle}>
              <TextView text={text} color="#667085" />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      align: 'right' as const,
      fixed: 'right' as const,
      width: '80px' as const,
      render: (id: string) => {
        return (
          <div className="flex justify-end gap-4">
            <AuthProvider permission={VIEW_COMPLIANCE}>
              <Tooltip placement="top" title={'View'}>
                <div className="cursor-pointer">
                  <ImageView src={EyeIcon} onClick={() => onViewClick(id)} />
                </div>
              </Tooltip>
            </AuthProvider>
            <AuthProvider permission={EDIT_COMPLIANCE}>
              <Tooltip placement="top" title={'Edit'}>
                <div className="cursor-pointer">
                  <ImageView src={EditIcon} onClick={() => onEditClick(id)} />
                </div>
              </Tooltip>
            </AuthProvider>
          </div>
        );
      },
    },
  ];
  return columns;
};
export const getLogicDiv = (
  fieldName: string,
  comparison: string,
  values: Array<string | number>
) => {
  return (
    <div
      className="px-2 py-1 rounded-lg"
      style={{ background: '#DEEAFC', width: 'fit-content' }}
    >
      <TextView
        text={`${fieldName} ${getLogicText(comparison, values)}`}
        size={14}
        weight="400"
        lineHeight={20}
        color="#2C43AB"
      />
    </div>
  );
};

export const paymentUpdateConfig = [
  {
    title: 'Paid',
    key: 'Paid',
    description:
      'Set the conditions to tag accounts as "paid" when campaigns are running.',
    icon: FullyPaid,
  },
];

export const paymentOperators = [
  {
    key: 'greaterThan',
    operator: '>',
    label: 'Is greater than(>)',
  },
  {
    key: 'lesserThan',
    operator: '<',
    label: 'Is lesser than(<)',
  },
  {
    key: 'equalTo',
    operator: '=',
    label: 'Is equal to(=)',
  },
  {
    key: 'greaterThanEqualTo',
    operator: '>=',
    label: 'is greater than & equal to (>=)',
  },
  {
    key: 'lesserThanEqualTo',
    operator: '<=',
    label: 'is lesser than & equal to (<=)',
  },
  {
    key: 'notEqualTo',
    operator: '!=',
    label: 'Is not equal to(!=)',
  },
  {
    key: 'range',
    operator: 'range',
    label: 'Is in between(Range)',
  },
];

export const getFormulaPill = (
  fieldName: string,
  comparison: string,
  values: Array<string | number>,
  isDefaultSegment: boolean
) => {
  return (
    <div
      className="px-2 py-1 rounded-lg"
      style={{ background: '#F2F4F7', width: 'fit-content' }}
    >
      {isDefaultSegment && (
        <TextView
          text={fieldName}
          size={12}
          weight="200"
          lineHeight={18}
          color="#667085"
        />
      )}
      {!isDefaultSegment && (
        <TextView
          text={`${fieldName} ${getLogicText(comparison, values)}`}
          size={12}
          weight="200"
          lineHeight={18}
          color="#667085"
        />
      )}
    </div>
  );
};

export const InfoComp = (props: IInfoComp) => {
  const { leftComp, rightComp } = props;
  return (
    <div className="w-full">
      <div className="py-6 flex">
        <div style={{ width: '40%', minWidth: '40%' }}>{leftComp}</div>
        <div className="flex flex-1 items-center flex-wrap">{rightComp}</div>
      </div>
      <DividerView lineColor="#E7EAEE" />
    </div>
  );
};

export const riskCategories = [
  {
    key: 'all',
    displayText: 'All',
  },
  {
    key: 'high',
    displayText: 'High Risk',
  },
  {
    key: 'medium',
    displayText: 'Medium Risk',
  },
  {
    key: 'low',
    displayText: 'Low Risk',
  },
];
interface IContentColumns {
  onEditClick: (id: number) => void;
  onDeleteClick: (id: number) => void;
}

export const contentPreferencesColumns = ({
  onEditClick,
  onDeleteClick,
}: IContentColumns) => {
  const items = (index: number) => [
    {
      key: 'edit',
      label: (
        <div style={{ width: '120px' }} onClick={() => onEditClick(index)}>
          Edit
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div style={{ width: '120px' }} onClick={() => onDeleteClick(index)}>
          Delete
        </div>
      ),
    },
  ];
  return [
    {
      title: 'Name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Contact number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Email ID',
      dataIndex: 'emailId',
      key: 'emailId',
    },
    {
      title: 'Actions',
      dataIndex: '',
      width: '80px',
      align: 'right' as const,
      key: 'actions',
      render: (data: IContentPreference) => {
        return (
          <div className="w-full flex justify-end">
            <Dropdown menu={{ items: items(data.id) }} placement="bottomLeft">
              <img src={verticalDotsIcon} />
            </Dropdown>
          </div>
        );
      },
    },
  ];
};

export const monthsShortHandText = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const lobDataConstant: ILobData = {
  productCategory: '',
  productSubCategory: undefined,
  bucket: [],
  pauseDurationType: '',
  enabled: false,
};

export const campaignPauseOptions = [
  { label: 'D', value: 'D' },
  { label: 'W', value: 'W' },
  { label: 'M', value: 'M' },
];

export const paymentFileColumns = (
  handleUserActionClick: (actionType: string, index: number) => void
) => {
  const getPasDurationTypeText = (type: string, value: number) => {
    let text = '';
    if (type === 'D') {
      text = 'Day';
    } else if (type === 'W') {
      text = 'Week';
    } else {
      text = 'Month';
    }
    if (value > 1) return text + 's';
    return text;
  };
  return [
    {
      title: 'Product category',
      dataIndex: 'productCategory',
      key: 'productCategory',
      width: '180px',
      render: (productCategory: string) => {
        return <TextView text={productCategory} color="#475467" />;
      },
    },
    {
      title: 'Bucket',
      dataIndex: 'bucket',
      key: 'bucket',
      render: (bucket: string[]) => {
        return (
          <div className="flex gap-1">
            {bucket.map((item, index) => (
              <TextView
                key={index}
                text={item + (bucket.length - 1 === index ? '' : ',')}
                color="#475467"
              />
            ))}
          </div>
        );
      },
    },
    {
      title: (
        <div className="min-w-fit">
          <TextView text="Duration for campaign pause" color="#667085" />
        </div>
      ),
      dataIndex: 'pauseDurationType',
      key: 'pauseDurationType',
      width: '27ch' as const,
      render: (pauseDurationType: string, record: ILobData) => {
        return (
          <TextView
            text={`${record.pauseDurationValue} ${getPasDurationTypeText(
              pauseDurationType,
              record.pauseDurationValue || 0
            )}`}
            color="#475467"
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      align: 'right' as const,
      width: '15%',
      render: (_: unknown, lobData: ILobData, index: number) => {
        const isEnabled = lobData.enabled || false;
        return (
          <section className="flex w-full justify-end gap-2 items-center">
            <SwitchView
              checked={isEnabled}
              onClick={(checked) => handleUserActionClick('enabled', index)}
              size="small"
              label=""
            />
            <Tooltip placement="top" title={'Edit'}>
              <div
                // className={isEnabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                onClick={() => handleUserActionClick('edit', index)}
              >
                <YbCoreIcon
                  name={'pencil-02'}
                  size={16}
                  // color={isEnabled ? '#D0D5DD' : ''}
                />
              </div>
            </Tooltip>
          </section>
        );
      },
    },
  ];
};

export const productTypeOptions = [
  { label: 'Credit card', value: 'credit card' },
  { label: 'Unsecured loan', value: 'un secured' },
  { label: 'Secured loan', value: 'secured' },
];
