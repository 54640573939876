import { put, takeLatest } from 'redux-saga/effects';
import { lender } from '../../../services/api/endPoints';
import {
    rejectLenderInvitesSucceeded,
    rejectLenderInvitesFailed,
    rejectLenderInvites,
} from '../../slice/lenders/LendersInvites.slice';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { IFetchLenderInvitesResponse } from './Lenders.types';

export function* rejectLenderSaga(payload: any): any {
    try {
        const result: IFetchLenderInvitesResponse = yield HTTPClient.PostRequest(lender.REJECT_INVITES, payload.payload);

        if (result?.status === ApiStatusCodes.SUCCESS && result?.data) {
            // eslint-disable-next-line @typescript-eslint/naming-convention

            // For now we are processing only lender entities
            yield put(rejectLenderInvitesSucceeded(result?.data?.data));
        } else {
            throw new Error("No Token Found");
        }
    } catch (error) {
        // Handle API call failure or network errors
        yield put(
            rejectLenderInvitesFailed({
                error: {
                    message: 'An error occurred while rejecting lenders.',
                },
            })
        );
    }
}

export function* watchRejectLenderInvitesSaga() {
    yield takeLatest(rejectLenderInvites.type, rejectLenderSaga);
}
