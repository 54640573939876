import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IProductPreferencesData } from 'features/Settings/shared/contract';
import { IError } from 'redux/types/Compliance.types';
import {
  IGetPreferencePayload,
  IProductPreferenceState,
} from 'redux/types/Preference.types';

import { APIStatus } from 'services/api/apiStatusCode';

export const productInitialState: IProductPreferenceState = {
  status: APIStatus.INIT,
};

const slice = createSlice({
  name: 'product',
  initialState: productInitialState,
  reducers: {
    getProductPreferencesRequested: (
      state: IProductPreferenceState,
      _action: PayloadAction<IGetPreferencePayload>
    ) => {
      state.status = APIStatus.PENDING;
    },
    getProductPreferencesRequestSucceeded: (
      state: IProductPreferenceState,
      action: PayloadAction<IProductPreferencesData[]>
    ) => {
      state.status = APIStatus.SUCCESS;
      state.data = action.payload;
    },
    getProductPreferencesRequestFailed: (
      state: IProductPreferenceState,
      action: PayloadAction<IError>
    ) => {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
  },
});

export const {
  getProductPreferencesRequested,
  getProductPreferencesRequestSucceeded,
  getProductPreferencesRequestFailed,
} = slice.actions;

export default slice.reducer;
