export const primaryColor = '#20c77c';
export const tableColor = '#101828';
export const generalColor = '#667085';
export const labelColor = '#4A5567';
export const displayColor = '#D0D5DD';
export const inputColor = '#101828';
export const backgroundColor = '#F2F4F7';
export const subTextColor = '#6B7280';
export const dividerColor = '#F2F4F7';
export const headingColor = '#98A2B3';
export const buttonColor = '#FD7149';