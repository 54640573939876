import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BreadCrumbsProps } from 'features/Allocation/AllocationList/AllocationList';
import {
  AllocatedData,
  AllocationList,
  AllocationType,
} from 'testData/allocationLIst';
import {
  AllocationOverview,
  IUploadAlloacationSftpPayload,
  TSFTPAllocationStatus,
} from './types';
import { FetchAllocationRequestType } from './allocation.saga';
import { FiltersType2 } from 'features/Allocation/AllocationListTable/AllocatedListTable';
import { message } from 'antd';
import {
  blankDiv,
  toastMessagee,
} from 'components/antd/AntdToastMessage/DynamicMessage';

type BreadCrumbs = {
  name: string;
  id: number;
};

export type UnAllocatedNumber = {
  Never_Allocated: number;
  Hold: number;
  Deallocated: number;
};
export type GroupedAllocationNumbers = {
  unallocated: UnAllocatedNumber;
  allocated: {
    Parallel_Allocated: number;
    Agency: number;
    Self: number;
  };
  draft: {
    Never_Allocated: number;
  };
};

export interface AllocationInitialState {
  isLoading: boolean;
  breadCrumbs: Array<BreadCrumbs>;
  breadCrumbUrls: { [key: string]: string };
  breadCrumbActiveState: string;
  allocationList: AllocationList;
  allocationOverview: AllocationOverview;
  groupedAllocationNumbers: GroupedAllocationNumbers;
  allocationTableDetails: AllocationType[];
  allocationTypeActiveTab: string;
  allocatedAssignmentActiveTab: string;
  unAllocatedAssignmentActiveTab: string;
}
export const allocationInitialState: AllocationInitialState = {
  isLoading: false,
  breadCrumbs: [],
  breadCrumbUrls: {
    'allocation details': '/allocation/allocation-list/allocation-details',
    'allocation list': '/allocation/allocation-list',
    'allocation summary': '/allocation/allocation-summary',
    home: '/report',
  },
  breadCrumbActiveState: '',
  allocationList: {
    allocated: {
      allocatedToAgency: [],
      allocatedToSelf: [],
      parallelAllocated: [],
    },
    unallocated: {
      neverAllocated: [],
      deaalocated: [],
      oHold: [],
    },
  },
  allocationOverview: {
    allocated: {
      all: {
        allocatedToSelf: '',
        totalOutstanding: '',
        principleOutstanding: '',
        recoveredAmount: '',
        totalAllocationAssigned: '',
        noOfAccounts: '',
      },
      allocatedToAgency: {
        allocatedToSelf: '',
        totalOutstanding: '',
        principleOutstanding: '',
        recoveredAmount: '',
        totalAllocationAssigned: '',
        noOfAccounts: '',
      },
      allocatedToSelf: {
        allocatedToSelf: '',
        totalOutstanding: '',
        principleOutstanding: '',
        recoveredAmount: '',
        totalAllocationAssigned: '',
        noOfAccounts: '',
      },
      parallelAllocated: {
        allocatedToSelf: '',
        totalOutstanding: '',
        principleOutstanding: '',
        recoveredAmount: '',
        totalAllocationAssigned: '',
        noOfAccounts: '',
      },
    },
    unallocated: {
      all: {
        allocatedToSelf: '',
        totalOutstanding: '',
        principleOutstanding: '',
        recoveredAmount: '',
        totalAllocationAssigned: '',
        noOfAccounts: '',
      },
      neverAllocated: {
        allocatedToSelf: '',
        totalOutstanding: '',
        principleOutstanding: '',
        recoveredAmount: '',
        totalAllocationAssigned: '',
        noOfAccounts: '',
      },
      deaalocated: {
        allocatedToSelf: '',
        totalOutstanding: '',
        principleOutstanding: '',
        recoveredAmount: '',
        totalAllocationAssigned: '',
        noOfAccounts: '',
      },
      oHold: {
        allocatedToSelf: '',
        totalOutstanding: '',
        principleOutstanding: '',
        recoveredAmount: '',
        totalAllocationAssigned: '',
        noOfAccounts: '',
      },
    },
  },
  groupedAllocationNumbers: {
    unallocated: {
      Never_Allocated: 0,
      Hold: 0,
      Deallocated: 0,
    },
    allocated: {
      Parallel_Allocated: 0,
      Agency: 0,
      Self: 0,
    },
    draft: {
      Never_Allocated: 0,
    },
  },
  allocationTableDetails: [],
  allocationTypeActiveTab: 'allocated',
  allocatedAssignmentActiveTab: 'All',
  unAllocatedAssignmentActiveTab: 'All',
};

export type UpdateBreadCrumbStateAction = {
  breadCrumbs: Array<BreadCrumbsProps>;
  breadCrumbActiveState: string;
};

export type UpdateBreadActiveStateAction = {
  breadCrumbActiveState: string;
};
const allocation = createSlice({
  name: 'allocaton',
  initialState: allocationInitialState,
  reducers: {
    fetchAllocation: (
      state: AllocationInitialState,
      action: PayloadAction<FetchAllocationRequestType>
    ) => {
      state.isLoading = true;
    },
    fetchAllocationSuccess: (
      state: AllocationInitialState,
      action: PayloadAction<{ allocatedData: AllocatedData }>
    ) => {
      state.isLoading = false;
      state.allocationTableDetails = action.payload.allocatedData || [];
    },
    fetchAllocationFailure: (state) => {
      state.allocationTableDetails = [];
      state.isLoading = false;
    },
    fetchSftpAllocation: (
      state: AllocationInitialState,
      action: PayloadAction<FetchAllocationRequestType>
    ) => {
      state.isLoading = true;
    },
    updateBreadCrumbsAction: (
      state: AllocationInitialState,
      action: PayloadAction<UpdateBreadCrumbStateAction>
    ) => {
      state.breadCrumbs = action.payload.breadCrumbs;
      state.breadCrumbActiveState = action.payload.breadCrumbActiveState;
    },
    updateBreadActiveStateAction: (
      state: AllocationInitialState,
      action: PayloadAction<UpdateBreadActiveStateAction>
    ) => {
      state.breadCrumbActiveState = action.payload.breadCrumbActiveState;
    },
    updateBreadCrumbUrlsAction: (
      state: AllocationInitialState,
      action: PayloadAction<{
        updatedBreadcrumbUrls: { [key: string]: string };
      }>
    ) => {
      state.breadCrumbUrls = action.payload.updatedBreadcrumbUrls;
    },
    fetchAllocationOverviewAction: (state: AllocationInitialState) => {
      state.isLoading = true;
    },
    fetchAllocationOverviewSuccessAction: (
      state: AllocationInitialState,
      action: PayloadAction<{ allocationOverview: AllocationOverview }>
    ) => {
      state.isLoading = false;
      state.allocationOverview = action.payload.allocationOverview;
    },
    fetchAllocationOverviewFailureAction: (state: AllocationInitialState) => {
      state.isLoading = false;
    },
    setLoadingTrue: (state: AllocationInitialState) => {
      state.isLoading = true;
    },
    setLoadingFalse: (state: AllocationInitialState) => {
      state.isLoading = false;
    },
    fetchGroupedAllocationNumbers: (
      state: AllocationInitialState,
      action: PayloadAction<{
        lenderId: string;
        filterRequestPayload: FiltersType2[];
      }>
    ) => {
      state.isLoading = true;
    },
    fetchGroupedAllocationNumbersSuccess: (
      state: AllocationInitialState,
      action: PayloadAction<{
        groupedAllocationNumbers: GroupedAllocationNumbers;
      }>
    ) => {
      state.isLoading = false;
      state.groupedAllocationNumbers = action.payload.groupedAllocationNumbers;
    },
    fetchGroupedAllocationNumbersFailure: (state: AllocationInitialState) => {
      state.isLoading = false;
    },
    updateAllocationTypeActiveTab: (
      state: AllocationInitialState,
      action: PayloadAction<{
        allocationTyep: string;
      }>
    ) => {
      state.allocationTypeActiveTab = action.payload.allocationTyep;
    },
    updateUnAllocatedAssignmentActiveTab: (
      state: AllocationInitialState,
      action: PayloadAction<{
        assignmentTyep: string;
      }>
    ) => {
      state.unAllocatedAssignmentActiveTab = action.payload.assignmentTyep;
    },
    updateAllocatedAssignmentActiveTab: (
      state: AllocationInitialState,
      action: PayloadAction<{
        assignmentTyep: string;
      }>
    ) => {
      state.allocatedAssignmentActiveTab = action.payload.assignmentTyep;
    },
    uploadAllocationFileOnSFTP: (
      state: AllocationInitialState,
      action: PayloadAction<IUploadAlloacationSftpPayload>
    ) => {
      state.isLoading = true;
    },
    updateSftpAllocationStatus: (
      state: AllocationInitialState,
      action: PayloadAction<{
        sftpStatus: TSFTPAllocationStatus;
        allocationId: number;
      }>
    ) => {
      const foundAllocation = state.allocationTableDetails.find(
        (allocation) => allocation.allocationId === action.payload.allocationId
      );
      if (foundAllocation) {
        const { sftpStatus } = action.payload;
        foundAllocation.allocationSftpStatus = sftpStatus;
        if (sftpStatus === 'SUCCESS') {
          message.success({
            key: 2,
            icon: blankDiv(),
            content: toastMessagee({
              type: 'success',
              color: '#2D671F',
              background: '#2D671F',
              text: 'Success! Mass Allocation was submitted to SFTP successfully.',
              key: 2,
            }),
          });
        } else if (sftpStatus === 'FAILED') {
          message.error({
            key: 1,
            icon: blankDiv(),
            content: toastMessagee({
              type: 'error',
              color: '#CD3546',
              background: '#FBE8F1',
              text: 'Alert! Failed to submit Mass Allocation to the SFTP server.',
              key: 1,
              styleClass: {
                filter:
                  'invert(33%) sepia(86%) saturate(393%) hue-rotate(290deg) brightness(92%) contrast(101%)',
              },
            }),
          });
        }
      }
      state.isLoading = false;
    },
    resetAllocationTypeActiveTab: (state: AllocationInitialState) => {
      state.allocatedAssignmentActiveTab = 'All';
      state.unAllocatedAssignmentActiveTab = 'All';
      state.allocationTableDetails = [];
    },
  },
});
// groupedAllocationNumbers: GroupedAllocationNumbers

export const {
  fetchAllocation,
  fetchAllocationSuccess,
  updateBreadCrumbsAction,
  updateBreadActiveStateAction,
  updateBreadCrumbUrlsAction,
  fetchAllocationOverviewAction,
  fetchAllocationOverviewSuccessAction,
  setLoadingTrue,
  setLoadingFalse,
  fetchAllocationOverviewFailureAction,
  fetchGroupedAllocationNumbers,
  fetchGroupedAllocationNumbersSuccess,
  fetchGroupedAllocationNumbersFailure,
  updateAllocationTypeActiveTab,
  updateUnAllocatedAssignmentActiveTab,
  updateAllocatedAssignmentActiveTab,
  fetchAllocationFailure,
  resetAllocationTypeActiveTab,
  fetchSftpAllocation,
  uploadAllocationFileOnSFTP,
  updateSftpAllocationStatus,
} = allocation.actions;

export default allocation.reducer;
