import { put, takeLatest } from 'redux-saga/effects';
import {
  IContent,
  deleteContentById,
  deleteContentByIdFailure,
  deleteContentByIdSuccess,
  deleteContentTemplateById,
  deleteContentTemplateByIdSuccess,
  disablePublishContent,
  disablePublishContentFailure,
  disablePublishContentSuccess,
  fetchConfig,
  fetchConfigSuccess,
  fetchContentTemplateDetails,
  fetchContentTemplateDetailsFailure,
  fetchContentTemplateDetailsSuccess,
  fetchContents,
  fetchContentsFailure,
  fetchContentsSuccess,
  postCloneTemplate,
  postCloneTemplateFailure,
  postCloneTemplateSuccess,
  postTemplateCreation,
  postTemplateCreationFailure,
  postTemplateCreationSuccess,
} from './content.slice';
import { HTTPClient } from 'services/api/http-client';
import { message } from 'antd';
import { ApiStatusCodes } from 'services/api/apiStatusCode';
import { contentManagement } from 'services/api/endPoints';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  IConfig,
  IContentResponse,
  IContentTemplatePayload,
  IDeleteContentPayload,
  IDisablePublishContentType,
  IGetContentPayload,
  SaveTemplateResponse,
} from './content.type';
import { ContentStatus } from 'constants/constant';

export type TableContent = {
  id: number;
  template: string;
  LOB: string;
  language: string;
  content: string;
  createOn: string;
  lastUsedOn: string;
};
export type Content2 = {
  id: number;
  bucket: string;
  createdAt: string;
  createdBy: string;
  isActive: boolean;
  language: string;
  lenderId: string;
  message: string;
  modifiedAt: string;
  modifiedBy: string;
  name: string;
  product: string;
  risk: string;
  senderId: string;
  status: string;
  tags: string;
  templateId: string;
  type: string;
  variableMapping: string;
};
export interface FetchContentsSagaResponse {
  data: IContentResponse;
  status: ApiStatusCodes;
}

interface Data {
  result: IContent;
  totalCount: number;
}

export interface ContentTemplateDetailsResponse {
  status: null;
  statusCode: number;
  timestamp: string;
  message: null;
  data: Data;
  error: null;
}

interface ResultItem {
  language: string;
  id?: number; // id is optional for some items
  error?: string; // error is optional for some items
  message: string;
  status: 'success' | 'failed';
}

interface ApiResponse {
  status: null;
  statusCode: number;
  timestamp: string;
  message: null;
  data: {
    totalCount: number;
    result: ResultItem[];
  };
  error: null;
}
interface ICloneTemplateResponse {
  status: string | null;
  statusCode: number;
  timestamp: string;
  message: string | null;
  data: {
    totalCount: number;
    result: CloneTemplate[];
  };
  error: string | null;
}

interface CloneTemplate {
  templateName: string;
  contentId: number;
  language: string;
}

export interface PostTemplateCloneResponse {
  data: ICloneTemplateResponse;
  status: ApiStatusCodes;
}

export interface PostTemplateCreationResponse {
  data: SaveTemplateResponse;
  status: ApiStatusCodes;
}

export interface FetchConfigResponse {
  data: IConfig;
  status: ApiStatusCodes;
}
export interface FetchContentTemplateDetailsResponse {
  data: ContentTemplateDetailsResponse;
  status: ApiStatusCodes;
}

export interface DeleteContentByIdResponse {
  data: {
    status: string;
    statusCode: string;
    timestamp: string;
    message: string;
    data: null;
    error: null;
  };
  status: ApiStatusCodes;
}

function* fetchContentsSaga({ payload }: PayloadAction<IGetContentPayload>) {
  try {
    const result: FetchContentsSagaResponse = yield HTTPClient.PostRequest(
      contentManagement.POST_CONTENT_LIST(
        payload.contentType,
        payload.pageNo,
        payload.pageSize
      ),
      { filters: payload.filters.filters, search: payload.filters.search }
    );
    yield put(
      fetchContentsSuccess({
        contents: {
          contentsData: result.data.data.result,
          totalCount: result.data.data.totalCount,
        },
      })
    );
  } catch (e) {
    message.error('Error while fetching contents');
    yield put(fetchContentsFailure());
  }
}

function* deleteContentTemplateByIdSaga({
  payload,
}: PayloadAction<IDeleteContentPayload>) {
  try {
    yield HTTPClient.PostRequest(
      contentManagement.DELETE_CONTENT(payload.channel),
      {
        name: payload.name,
        bucket: payload.bucket,
        product: payload.product,
      }
    );
    message.success('Content deleted successfully');
    yield put(deleteContentTemplateByIdSuccess(payload));
    payload.deleteContentTemplateByIdSuccessCallBack?.();
  } catch (e) {
    message.error('Error while deleting content');
  }
}

function* fetchContentTemplateDetailsSaga({
  payload,
}: PayloadAction<{ reqPayload: IContentTemplatePayload; channel: string }>) {
  try {
    const result: FetchContentTemplateDetailsResponse =
      yield HTTPClient.PostRequest(
        contentManagement.FETCH_CONTENT_TEMPLATE_DETAILS(payload.channel),
        payload.reqPayload
      );
    yield put(
      fetchContentTemplateDetailsSuccess({ content: result.data.data.result })
    );
  } catch (e) {
    message.error('Error while fetching content');
    yield put(fetchContentTemplateDetailsFailure());
  }
}

function* postCloneTemplateSaga({
  payload,
}: PayloadAction<{
  reqPayload: IContentTemplatePayload;
  channel: string;
  postCloneTemplateSuccessCallBack?(namequery: string): void;
}>) {
  try {
    const result: PostTemplateCloneResponse = yield HTTPClient.PostRequest(
      contentManagement.POST_CLONE_TEMPLATE(payload.channel),
      payload.reqPayload
    );
    payload.postCloneTemplateSuccessCallBack?.(
      result.data.data.result[0].templateName
    );
    yield put(postCloneTemplateSuccess());
    message.success('Content cloned successfully');
  } catch (e) {
    yield put(postCloneTemplateFailure());
    message.error('Error while cloning content');
  }
}

function* postTemplateCreationSaga(
  action: PayloadAction<{
    content: IContent;
    channel: string;
    postTemplateCreationCallBack?(): void;
    status: string;
  }>
) {
  try {
    const result: PostTemplateCreationResponse = yield HTTPClient.PostRequest(
      contentManagement.POST_TEMPLATE_CREATION(action.payload.channel),
      action.payload.content
    );

    yield put(
      postTemplateCreationSuccess({
        templateContent: result.data.data.result,
        status: action.payload.status,
      })
    );
    if (
      action.payload.content.details.some(
        (item) => item.status === ContentStatus.PUBLISHED
      )
    ) {
      message.success('Template Published successfully');
    } else {
      message.success('Template Saved as Draft');
    }
    action.payload.postTemplateCreationCallBack?.();
  } catch (e) {
    message.error('Error while creating template');
    yield put(postTemplateCreationFailure());
  }
}

function* deleteContentByIdSaga(
  action: PayloadAction<{
    channel: string;
    id: number;
    deleteContentByIdCallBack: () => void;
  }>
) {
  try {
    const result: DeleteContentByIdResponse = yield HTTPClient.DeleteRequest(
      contentManagement.DELETE_CONTENT_BY_ID(
        action.payload.channel,
        action.payload.id
      )
    );
    message.success(result.data.message);
    yield put(deleteContentByIdSuccess(action.payload));
    action.payload.deleteContentByIdCallBack();
  } catch (e) {
    message.error('Error while creating template');
    yield put(deleteContentByIdFailure());
  }
}

function* disablePublishContentSaga(
  action: PayloadAction<IDisablePublishContentType>
) { 
  try {
    const result: DeleteContentByIdResponse = yield HTTPClient.PostRequest(
      contentManagement.DISABLE_PUBLISH_CONTENT(
        action.payload.channel,
      ), action.payload
    );
    message.success(result.data.message);
    yield put(disablePublishContentSuccess(action.payload));
  } catch (e) {
    message.error('Error while disabling template');
    yield put(disablePublishContentFailure());
  }
}

function* fetchConfigSaga() {
  try {
    const result: FetchConfigResponse = yield HTTPClient.GetRequest(
      contentManagement.FETCH_CONFIG
    );

    yield put(fetchConfigSuccess({ config: result.data }));
  } catch (e) {
    message.error('Error while fetching content');
  }
}
export function* watchContentSaga() {
  yield takeLatest(fetchContents.type, fetchContentsSaga);
  yield takeLatest(
    fetchContentTemplateDetails.type,
    fetchContentTemplateDetailsSaga
  );
  yield takeLatest(
    deleteContentTemplateById.type,
    deleteContentTemplateByIdSaga
  );
  yield takeLatest(postCloneTemplate.type, postCloneTemplateSaga);
  yield takeLatest(postTemplateCreation.type, postTemplateCreationSaga);
  yield takeLatest(deleteContentById.type, deleteContentByIdSaga);
  yield takeLatest(fetchConfig.type, fetchConfigSaga);
  yield takeLatest(disablePublishContent.type, disablePublishContentSaga);
}
