import { message } from 'antd';
import {successTick, errorInfo, closeBlackIcon } from 'assets/icon';
import ImageView from 'components/ImageView';
import TextView from 'components/TextView';
import React from 'react';


interface ItoastMessageeProps{
  color: string;
  type: 'success' | 'info' | 'error';
  text: string;
  background: string;
  key: number;
  styleClass?: any;
}

export const toastMessagee = (props: ItoastMessageeProps) => {
  return (
    <div className={`bg-[${props.background}] rounded-lg flex gap-10 p-2`}>
      <div className="flex gap-3">
        <ImageView
          src={props.type === "success" ? successTick : errorInfo}
          style={props.styleClass}
        />
        <TextView
          text={`${props.text}`}
          weight="300"
          size={14}
          lineHeight={20}
          color={`${props.color}`}
        />
      </div>
      <ImageView
        src={closeBlackIcon}
        onClick={() => {
          message.destroy(props.key);
        }}
        style={{width:"1rem"}}
      />
    </div>
  );
};

export const blankDiv = () => {
  return (<></>);
};