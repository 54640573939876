import React, { useState } from 'react';
import { YbCoreIcon } from '@yubi/yb-core-icon';
import { DividerView, OverFlowMenuView, TextView } from 'components';
import DropDownViewV3 from 'componentsV3/DropDownViewV3';
import WrapperCmp from './WrapperCmp';
import { InputViewV3 } from 'componentsV3';
import { IProductData } from '../shared/contract';
import { productTypeOptions } from '../shared/constants';

interface ICreateProductAtomProps {
  handleChange: (index: number, data: IProductData) => void;
  handleAction: (actionType: 'Reset' | 'Delete', index: number) => void;
  data: IProductData;
  index: number;
  disableOverflow?: boolean;
}

const CreateProductAtom = (props: ICreateProductAtomProps) => {
  const {
    handleChange,
    data,
    index,
    handleAction,
    disableOverflow = false,
  } = props;

  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const handleDataChange = (key: string, value: string) => {
    const newData = { ...data, [key]: value };
    handleChange(index, newData);
  };

  const validator = () => {
    let isValid = true;
    if (
      data.productName === '' ||
      data.lineOfBusiness === '' ||
      data.assetClassCategory === '' ||
      data.assetClassSubCategory === ''
    ) {
      isValid = false;
    }
    return isValid;
  };

  const isDataValid = validator();
  const isOverflowDisabled = disableOverflow && !isDataValid;

  return (
    <section className="border rounded-md border-[#F2F4F7]">
      <header className="flex justify-between px-6 py-4 ">
        <section>
          <TextView
            text={`Product ${index + 1}`}
            size={16}
            lineHeight={24}
            weight="700"
          />
        </section>
        <section className="flex gap-2 items-center">
          <div
            typeof="button"
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            <YbCoreIcon
              size={16}
              name={isAccordionOpen ? 'chevron-up' : 'chevron-down'}
            />
          </div>
          <div className={isOverflowDisabled ? 'cursor-not-allowed' : ''}>
            <OverFlowMenuView
              disabled={isOverflowDisabled}
              items={[
                {
                  key: 'reset',
                  label: 'Reset',
                  disabled: !isDataValid,
                  onClick: () => handleAction('Reset', index),
                },
                {
                  key: 'delete',
                  label: 'Remove',
                  disabled: !isDataValid,
                  onClick: () => {
                    handleAction('Delete', index);
                  },
                },
              ].filter((item) => {
                if (disableOverflow && item.key === 'delete') {
                  return false;
                }
                return true;
              })}
              icon={
                <YbCoreIcon
                  size={16}
                  name={'dots-vertical'}
                  color={isOverflowDisabled ? '#d5d5d5' : ''}
                />
              }
            />
          </div>
        </section>
      </header>
      <DividerView lineColor="#F2F4F7" />
      {isAccordionOpen && (
        <body className="flex flex-col">
          <section className="flex flex-col gap-1 px-6 py-4">
            <WrapperCmp label={'Asset Type'} isMandatory={true}>
              <DropDownViewV3
                options={productTypeOptions}
                size="s"
                type={'standard'}
                value={data.lineOfBusiness}
                onChange={(value: string) => {
                  handleDataChange('lineOfBusiness', value);
                }}
                onClear={() => {
                  handleDataChange('lineOfBusiness', '');
                }}
              />
            </WrapperCmp>
          </section>
          <DividerView lineColor="#F2F4F7" />
          <section className="flex px-6 py-4 gap-4">
            <section className="flex flex-col w-[50%] gap-1">
              <WrapperCmp label={'Product Category'} isMandatory={true}>
                <InputViewV3
                  size="s"
                  value={data.assetClassCategory}
                  onChange={(e: any) => {
                    handleDataChange('assetClassCategory', e.target.value);
                  }}
                  placeholder="Type here..."
                />
              </WrapperCmp>
            </section>
            <div className="flex flex-col w-[50%] gap-1">
              <WrapperCmp label={'Product Sub- Category'} isMandatory={true}>
                <InputViewV3
                  size="s"
                  value={data.assetClassSubCategory}
                  onChange={(e: any) => {
                    handleDataChange('assetClassSubCategory', e.target.value);
                  }}
                  placeholder="Type here..."
                />
              </WrapperCmp>
            </div>
          </section>
          <DividerView lineColor="#F2F4F7" />
          <section className="flex flex-col gap-1 pl-6 pr-2 py-4 w-[50%]">
            <WrapperCmp label={'Product Name'} isMandatory={true}>
              <InputViewV3
                size="s"
                value={data.productName}
                onChange={(e: any) => {
                  handleDataChange('productName', e.target.value);
                }}
                placeholder="Type here..."
              />
            </WrapperCmp>
          </section>
        </body>
      )}
    </section>
  );
};

export default CreateProductAtom;
