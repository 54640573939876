/* eslint-disable @typescript-eslint/no-unused-expressions */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CampaignSegment,
  NewCampaignResponseData,
  SegmentDateMappings,
} from 'features/StrategyConfig/CreatePlan/Flow/strategyTestData';
import {
  ConditionOutreachType,
  IStrategyData,
  OutreachType,
} from 'features/StrategyConfig/CreatePlan/Flow/types';
import {
  CreateCampaignWithAllocationResponseData,
  SegmentsListType,
  StrategyListType,
} from './strategy.saga';
import { PostOutreachSaveType } from 'features/StrategyConfig/CreatePlan/CreatePlanSaveWrapper';
import { State } from 'features/CampaignPlanning/Tab';
import {
  checkPlansConflictWithStrategy,
  checkStrategyConflictWithStrategyAndPlanNewRule,
  dateDifferenceInDays,
  getFieldsForFirstOutreachFirstDay,
} from './helper';
import {
  AllocationStatus,
  CAMPAIGN_PLANNER_PLAN_PARENT_NODE_AND_OUTREACH_ID,
} from 'constants/constant';
import { PayloadSaveDraft } from 'features/CampaignPlanning/types';
import { getDatesBetween } from 'utils/date';
import '../../../styles/index.css';
import {
  CampaignExecutionStatus,
  convertISOToLocaleDateString,
} from 'features/Campaign/Campaign';
import { message } from 'antd';
import { SegmentPayload } from './types';
import { ISliderData } from 'components/FilterViewAdvanceV2/filterTyes';
import { IActionPayload } from 'redux/types/Outreach.types';
import {
  ICampaignData,
  NbaCampaignStatus,
} from '../campaign-planner/CampaignDetails.types';
import {
  CreateCampaignActionType,
  UpdateCampaignActionType,
} from '../campaign-planner/types';
export type DateList = {
  date: string;
  state: State;
  dayCount: number;
};

export type ChannelDropdown = {
  displayName: string;
  productName: string;
};
export type Channel = 'ivr' | 'sms' | 'whatsapp' | 'conversation_bot' | 'email';
export interface Content {
  id: string;
  name: string;
  message: string;
}
export interface OutreachConfig {
  channel: ChannelDropdown[];
  languages: {
    [key in Channel]: string[];
  };
  contents: Content[];
}

export type ActiveStrategySwitch = {
  segmentId: number;
  date: string;
  enableStrategyLibrary: boolean;
};
export type StrategyStateInitialStateTypes = {
  isLoading: boolean;
  isCampaignPlanningLoading: boolean;
  isSegmentLoading: boolean;
  strategyData: Map<string, Array<ConditionOutreachType>>;
  selectedPlanDay: number;
  createStrategy: IStrategyData;
  conditionRules: ConditionRule[];
  outreachesDropdownList: OutreachType[];
  isStrategyCreated: boolean;
  strategiesList: StrategyListType[];
  totalRecords: number;
  campaignPlanSelectedDay: DateList;
  campaignPlanner: NewCampaignResponseData;
  campaignDates: Record<string, string>;
  segments: { segmentLogic: string; segmentName: string }[];
  campaignEndStartDates: {
    startDate: string;
    endDate: string;
    daysCount: number;
  };
  dateList: DateList[];
  activeAccordions: string[];
  outreachConfig: OutreachConfig;
  activeStrategySwitch: ActiveStrategySwitch[];
  tos: string;
  selectedChannel: string;
  segmentEditedList: Map<number, boolean>;
};
export type DeleteConditionType = {
  deleteConditions: string[];
};

export type AddConditionOutreachType = {
  conditionOutreaches: ConditionOutreachType;
  selectedOutreachDayCount: number;
  accumulatedTimeSlotsInMinutes: number;
};
export type DeleteOutreachType = {
  nodeId: string;
  outreachId: number;
};

export type AddOutreachType = {
  currentNode: string;
  selectedOutreaches: OutreachType[];
  editOutreach?: {
    outreachId: number;
  };
};

export type ConditionRule = {
  ruleId: number;
  ruleName: string;
  userId: string;
  createdDate: string; // ISO 8601 format date as string
  createdBy: string;
  groupsCount: number;
  conditionsCount: number;
  lastUsed: string;
  mapped: boolean;
  systemGenerated: boolean;
};
export const strategyInitialState: StrategyStateInitialStateTypes = {
  isLoading: false,
  strategyData: new Map<string, Array<ConditionOutreachType>>(),
  selectedPlanDay: 1,
  createStrategy: {
    strategyName: '',
    daysCount: 0,
    isMapped: false,
    isLibrary: true,
    createdDate: '',
  },
  conditionRules: [],
  outreachesDropdownList: [],
  isStrategyCreated: false,
  strategiesList: [],
  campaignPlanSelectedDay: {
    date: '',
    state: State.FUTURE,
    dayCount: 0,
  },
  campaignPlanner: {
    editAllowed: true,
    campaignName: '',
    startDate: '',
    allocationId: '',
    endDate: '',
    allocationName: '',
    currentStatus: AllocationStatus.NONE,
    createdAt: '',
    campaignId: 0,
    campaignSegmentList: [],
    records: '',
    risks: '',
    lenderId: '',
    clientId: '',
    tos: '',
    paused: false,
    campaignExecutionStatus: CampaignExecutionStatus.NONE,
    currentDateAndTime: -1,
    edited: false,
    isNba: undefined,
    campaignNbaStatus: {
      nbaStatus: NbaCampaignStatus.WAITING,
      ticketRaised: false,
    },
    lob: '',
    allocationStartDate: '',
    allocationEndDate: '',
  },
  activeAccordions: [],
  campaignEndStartDates: {
    startDate: '',
    endDate: '',
    daysCount: 0,
  },
  dateList: [],
  segments: [],
  campaignDates: {},
  totalRecords: 0,
  outreachConfig: {
    channel: [],
    languages: {
      ivr: [],
      sms: [],
      whatsapp: [],
      conversation_bot: [],
      email: [],
    },
    contents: [],
  },
  activeStrategySwitch: [],
  tos: '',
  selectedChannel: '',
  isCampaignPlanningLoading: false,
  segmentEditedList: new Map<number, boolean>(),
  isSegmentLoading: false,
};

const strategy = createSlice({
  name: 'strategy',
  initialState: strategyInitialState,
  reducers: {
    addNewOutreach: (
      state,
      action: PayloadAction<{
        planIndex: number;
        outreachPayload: AddOutreachType & { selectedPlanDay: number };
      }>
    ) => {
      state.createStrategy.days?.forEach((value) => {
        if (
          value.currentDay === action.payload.outreachPayload.selectedPlanDay
        ) {
          value.plans.forEach((plan, index) => {
            if (index === action.payload.planIndex) {
              plan.planConditionoutreachList.some((condition) => {
                if (
                  condition.conditionOutreach.currentNode ===
                  action.payload.outreachPayload.currentNode
                ) {
                  condition.conditionOutreach.outreach.push(
                    ...action.payload.outreachPayload.selectedOutreaches
                  );
                  return true;
                }
                return false;
              });
            }
          });
        }
      });
    },
    addConditionOutreachAction: (
      state,
      action: PayloadAction<{
        planIndex: number;
        payload: AddConditionOutreachType;
        selectedPlanDay: number;
      }>
    ) => {
      state.createStrategy.days?.forEach((value) => {
        if (value.currentDay === action.payload.selectedPlanDay) {
          value.plans.forEach((plan, index) => {
            if (index === action.payload.planIndex) {
              plan.planConditionoutreachList.push(
                action.payload.payload.conditionOutreaches
              );
            }
          });
        }
      });
    },
    selectPlanDays: (state, action: PayloadAction<{ daysCount: number }>) => {
      state.createStrategy.daysCount = action.payload.daysCount;
    },
    selectPlanDay: (
      state,
      action: PayloadAction<{ selectedPlanDay: number }>
    ) => {
      state.selectedPlanDay = action.payload.selectedPlanDay;
    },
    createFirstOutreachPlan: (
      state,
      action: PayloadAction<PostOutreachSaveType>
    ) => {
      const selectedDay = state.createStrategy.days?.find((value) => {
        if (value.currentDay === action.payload.current_day) {
          return true;
        }
        return false;
      });

      if (selectedDay) {
        selectedDay.plans.push(
          getFieldsForFirstOutreachFirstDay(action.payload).plans[0]
        );
        return;
      }

      state.createStrategy.days?.push(
        getFieldsForFirstOutreachFirstDay(action.payload)
      );
    },
    fetchStrategyById: (
      state,
      action: PayloadAction<{ strategyId: string }>
    ) => {
      state.isLoading = true;
    },
    fetchStrategyByIdSuccess: (
      state,
      action: PayloadAction<{ strategy: IStrategyData; isMapped: boolean }>
    ) => {
      state.isLoading = false;
      state.createStrategy = action.payload.strategy;
      state.createStrategy.isMapped = action.payload.isMapped;
    },
    setLoadingStrategyState: (
      state,
      action: PayloadAction<{ isLoading: boolean }>
    ) => {
      state.isLoading = action.payload.isLoading;
    },
    fetchConditionRules: (state, action: PayloadAction<{ status: string }>) => {
      state.isLoading = true;
    },
    fetchConditionRulesSuccess: (
      state,
      action: PayloadAction<{ conditionRules: ConditionRule[] }>
    ) => {
      state.conditionRules = action.payload.conditionRules;
      state.isLoading = false;
    },
    fetchOutreachDropdownList: (state) => {
      state.isLoading = true;
    },
    fetchOutreachDropdownListSuccess: (
      state,
      action: PayloadAction<{ outreaches: OutreachType[] }>
    ) => {
      state.isLoading = false;
      state.outreachesDropdownList = action.payload.outreaches;
    },
    deleteConditionOutreaches: (
      state,
      action: PayloadAction<{
        planIndex: number;
        conditionPayload: DeleteConditionType;
        selectedPlanDay: number;
      }>
    ) => {
      state.createStrategy.days?.forEach((value) => {
        if (value.currentDay === action.payload.selectedPlanDay) {
          value.plans.forEach((plan, index) => {
            if (index === action.payload.planIndex) {
              plan.planConditionoutreachList =
                plan.planConditionoutreachList.filter((condition) => {
                  if (
                    action.payload.conditionPayload.deleteConditions.includes(
                      condition.conditionOutreach.currentNode
                    )
                  ) {
                    return false;
                  }
                  return true;
                });
            }
          });
        }
      });
    },
    deleteOutreach: (
      state,
      action: PayloadAction<{
        planIndex: number;
        outReachPayload: DeleteOutreachType;
        selectedPlanDay: number;
      }>
    ) => {
      state.createStrategy.days?.forEach((value) => {
        if (value.currentDay === action.payload.selectedPlanDay) {
          value.plans.forEach((plan, index) => {
            if (index === action.payload.planIndex) {
              plan.planConditionoutreachList.some((condition) => {
                if (
                  condition.conditionOutreach.currentNode ===
                  action.payload.outReachPayload.nodeId
                ) {
                  condition.conditionOutreach.outreach =
                    condition.conditionOutreach.outreach.filter((outreach) => {
                      if (
                        outreach.outreachId ===
                        action.payload.outReachPayload.outreachId
                      ) {
                        return false;
                      }
                      return true;
                    });
                  return true;
                }
                return false;
              });
            }
          });
        }
      });
    },
    fetchStrategies: (
      state,
      action: PayloadAction<
        | {
            offset: number;
            limit: number;
            appliedFilterState: Record<string, string[] | ISliderData>;
            searchText: string;
          }
        | undefined
      >
    ) => {
      state.isLoading = true;
    },
    fetchStrategiesSuccess: (
      state,
      action: PayloadAction<{
        strategiesList: StrategyListType[];
        totalRecords: number;
      }>
    ) => {
      state.isLoading = false;
      state.strategiesList = action.payload.strategiesList;
      state.totalRecords = action.payload.totalRecords;
    },
    fetchStrategiesFail: (state) => {
      state.isLoading = false;
    },
    resetStrategiesList: (state) => {
      state.strategiesList = [];
    },
    selectCampaignPlanDate: (
      state,
      action: PayloadAction<{ campaignPlanSelectedDay: DateList }>
    ) => {
      state.campaignPlanSelectedDay = action.payload.campaignPlanSelectedDay;
    },
    fetchCampaignPlan: (
      state,
      action: PayloadAction<{
        allocationId: number;
      }>
    ) => {
      state.isLoading = true;
    },
    fetchCampaignPlanSuccess: (
      state,
      action: PayloadAction<{
        campaignPlanner: NewCampaignResponseData;
        campaignDates: Record<string, string>;
      }>
    ) => {
      state.campaignPlanner = action.payload.campaignPlanner;
      state.campaignPlanner.campaignName =
        action.payload.campaignPlanner.campaignName;
      state.campaignEndStartDates.endDate =
        action.payload.campaignPlanner.endDate;
      state.campaignEndStartDates.startDate =
        action.payload.campaignPlanner.startDate;
      state.campaignPlanner = action.payload.campaignPlanner;
      state.campaignPlanner.edited = action.payload.campaignPlanner.edited;
      state.campaignPlanner.isNba = action.payload.campaignPlanner.isNba;
      state.activeAccordions = [
        action.payload.campaignPlanner?.campaignSegmentList?.[0]?.segment.segmentId.toString() ||
          '-1',
      ];
      const dateList = getDatesBetween(
        new Date(action.payload.campaignPlanner.startDate),
        new Date(action.payload.campaignPlanner.endDate)
      );

      state.dateList = dateList;
      state.campaignPlanSelectedDay = dateList[0];
      state.campaignEndStartDates.daysCount =
        dateDifferenceInDays(
          action.payload.campaignPlanner.startDate,
          action.payload.campaignPlanner.endDate
        ) + 1;
      state.campaignDates = action.payload.campaignDates;
      state.isLoading = false;
      state.isCampaignPlanningLoading = false;
      state.campaignPlanner.editAllowed =
        action.payload.campaignPlanner.editAllowed;
    },
    createParentOutreachPlanSegment: (
      state,
      action: PayloadAction<{
        payload: PostOutreachSaveType;
        segmentId: number;
        campaignPlanSelectedDay: string;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          state.campaignPlanner.campaignSegmentList?.forEach(
            (campaignSegment) => {
              if (
                campaignSegment.segment.segmentId === action.payload.segmentId
              ) {
                const selectedSegmentDateMappings =
                  campaignSegment.segmentDateMappings?.find(
                    (segmentDateMapping) => {
                      if (
                        segmentDateMapping.campaignDate.currentDate ===
                        action.payload.campaignPlanSelectedDay
                      ) {
                        return true;
                      }
                      return false;
                    }
                  );

                if (!selectedSegmentDateMappings) {
                  const newSegmentStrategyMapping: SegmentDateMappings = {
                    campaignDate: {
                      currentDate: convertISOToLocaleDateString(
                        action.payload.campaignPlanSelectedDay
                      ),
                    },
                    strategy: null,
                    plans: [
                      getFieldsForFirstOutreachFirstDay(action.payload.payload)
                        .plans[0],
                    ],
                  };
                  campaignSegment.segmentDateMappings?.push(
                    newSegmentStrategyMapping
                  );
                } else {
                  campaignSegment.segmentDateMappings?.forEach(
                    (segmentDateMapping) => {
                      if (
                        segmentDateMapping.campaignDate.currentDate ===
                        action.payload.campaignPlanSelectedDay
                      ) {
                        segmentDateMapping.plans.push(
                          getFieldsForFirstOutreachFirstDay(
                            action.payload.payload
                          ).plans[0]
                        );
                      }
                    }
                  );
                }
              }
            }
          );
        }
      });
      state.segmentEditedList.set(action.payload.segmentId, true);
    },
    setCampaignNbaStatusFailed: (state) => {
      state.campaignPlanner.campaignNbaStatus = {
        ...(state.campaignPlanner.campaignNbaStatus || {}),
        nbaStatus: NbaCampaignStatus.FAILED,
      };
    },
    updateCampaignNbaTicketRaised: (
      state,
      action: PayloadAction<{ isTicketRaised: boolean }>
    ) => {
      state.campaignPlanner.campaignNbaStatus = {
        ...(state.campaignPlanner.campaignNbaStatus || {}),
        ticketRaised: action.payload.isTicketRaised,
      };
    },
    addConditionOutreachForSegmentAction: (
      state,
      action: PayloadAction<{
        planIndex: number;
        strategyPayload: AddConditionOutreachType;
        segementPayload: { segmentId: number; campaignPlanSelectedDay: string };
        campaignEndDate: string;
      }>
    ) => {
      // 1. check conflict with strategy
      // 2. check outreach is beyond end time
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (
          campaignSegment.segment.segmentId ===
          action.payload.segementPayload.segmentId
        ) {
          const shouldAddOutreachesToPlan = checkPlansConflictWithStrategy(
            campaignSegment,
            action.payload.segementPayload.campaignPlanSelectedDay,
            action.payload.strategyPayload.selectedOutreachDayCount,
            action.payload.strategyPayload.conditionOutreaches.conditionOutreach
              .outreach,
            action.payload.campaignEndDate
          );

          if (!shouldAddOutreachesToPlan) {
            state.campaignPlanner.campaignSegmentList?.forEach(
              (campaignSegment) => {
                if (
                  campaignSegment.segment.segmentId ===
                  action.payload.segementPayload.segmentId
                ) {
                  campaignSegment.segmentDateMappings?.forEach(
                    (segmentDateMapping) => {
                      if (
                        segmentDateMapping.campaignDate.currentDate ===
                        action.payload.segementPayload.campaignPlanSelectedDay
                      ) {
                        segmentDateMapping.plans.forEach((plan, index) => {
                          if (index === action.payload.planIndex) {
                            plan.planConditionoutreachList.push(
                              action.payload.strategyPayload.conditionOutreaches
                            );
                          }
                        });
                      }
                    }
                  );
                }
              }
            );
          }
        }
      });
      state.segmentEditedList.set(
        action.payload.segementPayload.segmentId,
        true
      );
    },
    fetchSegmentsList: (
      state,
      action: PayloadAction<{ allocationId: number | string }>
    ) => {
      state.isLoading = false;
    },
    fetchSegmentsListSuccess: (
      state,
      action: PayloadAction<{
        segmentsList: SegmentsListType[];
        allocationId: number;
      }>
    ) => {
      state.isLoading = false;
    },
    updateStrategyName: (
      state,
      action: PayloadAction<{ strategyName: string }>
    ) => {
      state.createStrategy.strategyName = action.payload.strategyName;
    },
    campaignPlanSaveDraft: (
      state,
      action: PayloadAction<{
        payloadSaveDraft: PayloadSaveDraft;
        campaignId: number;
        campaignPlanSaveDraftSuccessCallback?: (val: {
          isSuccess: boolean;
        }) => void;
        showToast: boolean;
      }>
    ) => {
      state.isLoading = true;
    },
    campaignPlanSaveDraftSuccess: (state) => {
      state.isLoading = false;
    },
    updateDateListCampaignPlanner: (
      state,
      action: PayloadAction<{
        dateList: DateList[];
      }>
    ) => {
      state.dateList.forEach((date1) => {
        action.payload.dateList.forEach((updatedDate) => {
          if (updatedDate.date === date1.date) {
            date1.state = updatedDate.state;
          }
        });
      });
    },
    deleteStrategyById: (
      state,
      action: PayloadAction<{
        strategyId: number;
        deleteStrategyByIdSuccessCallback: () => void;
      }>
    ) => {
      state.isLoading = true;
    },
    deleteStrategyByIdSuccess: (
      state,
      action: PayloadAction<{
        strategyId: number;
      }>
    ) => {
      state.strategiesList = state.strategiesList.filter((newStrategy) => {
        if (newStrategy.strategyId === action.payload.strategyId) {
          return false;
        }
        return true;
      });
      state.totalRecords = state.totalRecords - 1;
      state.isLoading = false;
    },
    addStrategyFromLibraryToSegmentInCampaignPlan: (
      state,
      action: PayloadAction<{
        campaignPlanSelectedDay: string;
        segmentId: number;
        strategyFromLibrary: StrategyListType;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          const { isBlocked, messageInfo } =
            checkStrategyConflictWithStrategyAndPlanNewRule(
              action.payload.strategyFromLibrary,
              action.payload.campaignPlanSelectedDay,
              state.campaignPlanner.endDate
            );

          if (isBlocked) {
            message.open({
              content: messageInfo,
              type: 'error',
            });
            return;
          }
          const segmentDateMapping = campaignSegment.segmentDateMappings?.find(
            (sm) =>
              sm.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
          );

          if (segmentDateMapping) {
            segmentDateMapping.strategy = {
              strategyId: action.payload.strategyFromLibrary.strategyId,
              strategyName: action.payload.strategyFromLibrary.strategyName,
              daysCount: action.payload.strategyFromLibrary.daysCount,
              totalNoOfOutreaches:
                action.payload.strategyFromLibrary.totalNoOfOutreaches,
            };
            state.segmentEditedList.set(action.payload.segmentId, true);
            return;
          }

          const newStrategy = {
            strategyId: action.payload.strategyFromLibrary.strategyId,
            strategyName: action.payload.strategyFromLibrary.strategyName,
            daysCount: action.payload.strategyFromLibrary.daysCount,
            totalNoOfOutreaches:
              action.payload.strategyFromLibrary.totalNoOfOutreaches,
          };

          const newSegmentStrategyMapping: SegmentDateMappings = {
            campaignDate: {
              campaignDateId: 1,
              currentDate: action.payload.campaignPlanSelectedDay,
            },
            strategy: newStrategy,
            plans: [],
          };
          campaignSegment.segmentDateMappings?.push(newSegmentStrategyMapping);
          state.segmentEditedList.set(action.payload.segmentId, true);
        }
      });
    },

    createCampaignWithAllocation: (
      state,
      action: PayloadAction<{
        allocationId: number;
        allocationName: string;
        createCampaignWithAllocationCallback: (campaignId: number) => void;
      }>
    ) => {
      state.isLoading = true;
    },
    createCampaignWithAllocationSuccess: (
      state,
      action: PayloadAction<CreateCampaignWithAllocationResponseData>
    ) => {
      state.isLoading = false;
      state.campaignPlanner.allocationId = action.payload.allocationId;
      state.campaignPlanner.allocationName = action.payload.allocationName;
      state.campaignPlanner.campaignId = action.payload.campaignId;
    },
    pauseOrResumeCampaign: (
      state,
      action: PayloadAction<{
        campaignId: number;
        status: string;
        pauseOrResumeCampaignSuccessCallback?: () => void;
      }>
    ) => {
      state.isLoading = true;
    },
    pauseOrResumeCampaignSucceeded: (
      state,
      action: PayloadAction<{ paused: boolean }>
    ) => {
      state.campaignPlanner.paused = action.payload.paused;
      state.isLoading = false;
    },
    pauseOrResumeCampaignFailed: (
      state,
      action: PayloadAction<IActionPayload>
    ) => {
      state.isLoading = false;
    },
    updatesDaysInStrategy: (state) => {
      state.createStrategy.days = [];
    },
    removeStrategyFromLibraryFromSegmentInCampaignPlan: (
      state,
      action: PayloadAction<{
        campaignPlanSelectedDay: string;
        segmentId: number;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          campaignSegment.segmentDateMappings =
            campaignSegment.segmentDateMappings?.map((segmentDateMapping) => {
              if (
                segmentDateMapping.campaignDate.currentDate ===
                action.payload.campaignPlanSelectedDay
              ) {
                state.segmentEditedList.set(action.payload.segmentId, true);
                return { ...segmentDateMapping, strategy: null };
              }
              return segmentDateMapping;
            });
        }
      });

      state.activeStrategySwitch = state.activeStrategySwitch.map(
        (activeStrategySwitch) => {
          if (
            activeStrategySwitch.segmentId === action.payload.segmentId &&
            activeStrategySwitch.date === action.payload.campaignPlanSelectedDay
          ) {
            return {
              ...activeStrategySwitch,
              enableStrategyLibrary: true,
            };
          }
          return activeStrategySwitch;
        }
      );
    },
    updateActiveAccordionAccordions: (
      state,
      action: PayloadAction<{
        activeAccordions: string[];
      }>
    ) => {
      state.activeAccordions = action.payload.activeAccordions;
    },
    resetStrategyAction: (state) => {
      state.createStrategy = {
        strategyName: '',
        daysCount: 0,
        isMapped: false,
        isLibrary: true,
        createdDate: '',
      };

      state.selectedPlanDay = 1;
      state.isStrategyCreated = false;
    },
    deleteParentOutreachNode: (
      state,
      action: PayloadAction<{
        planIndex: number;
        selectedPlanDay: number;
      }>
    ) => {
      state.createStrategy.days?.forEach((value) => {
        if (value.currentDay === action.payload.selectedPlanDay) {
          value.plans = value.plans.filter((plan, index) => {
            return index !== action.payload.planIndex;
          });
        }
      });
    },
    fetchOutreachConfig: (
      state,
      action: PayloadAction<{ lenderId: string }>
    ) => {
      state.isLoading = true;
    },
    fetchOutreachConfigSuccess: (
      state,
      action: PayloadAction<OutreachConfig>
    ) => {
      state.isLoading = false;
      state.outreachConfig.channel = action.payload.channel;
      state.outreachConfig.languages = action.payload.languages;
    },
    cloneStrategyById: (
      state,
      action: PayloadAction<{
        strategyId: number;
        limit: number;
        cloneStrategyByIdSuccessCallback: (clonedStrategyId: number) => void;
      }>
    ) => {
      state.isLoading = true;
    },
    cloneStrategyByIdSuccess: (
      state,
      action: PayloadAction<{ strategy: IStrategyData; limit: number }>
    ) => {
      state.isLoading = false;
      const strategyTableData: StrategyListType = {
        strategyName: action.payload.strategy.strategyName,
        daysCount: action.payload.strategy.daysCount,
        isMapped: false,
        createdDate: action.payload.strategy.createdDate || '',
        lastModified: '',
        lastUsed: '',
        strategyId: action.payload.strategy.strategyId || 0,
        createdBy: action.payload.strategy.createdBy || '',
        lastModifiedBy: action.payload.strategy.lastModifiedDate || '',
        totalNoOfOutreaches: action.payload.strategy.totalNoOfOutreaches || 0,
      };
      state.strategiesList.unshift(strategyTableData);
      if (state.strategiesList.length > action.payload.limit) {
        state.strategiesList.pop();
      }
      state.totalRecords = state.totalRecords + 1;
    },
    getContentConfig: (
      state,
      action: PayloadAction<{
        language: string;
        lenderId: string;
      }>
    ) => {
      console.log('redux getContentConfig ', state.selectedChannel);
      state.isLoading = true;
    },
    getContentConfigSuccess: (
      state,
      action: PayloadAction<{ contents: Content[] }>
    ) => {
      state.isLoading = false;
      state.outreachConfig.contents = action.payload.contents;
    },
    deleteConditionOutreachesInCampaignPlan: (
      state,
      action: PayloadAction<{
        strategyPayload: DeleteConditionType;
        segmentPayload: SegmentPayload;
      }>
    ) => {
      state.isLoading = true;
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (
          campaignSegment.segment.segmentId ===
          action.payload.segmentPayload.segmentId
        ) {
          campaignSegment.segmentDateMappings?.forEach((segmentDateMapping) => {
            if (
              segmentDateMapping.campaignDate.currentDate ===
              action.payload.segmentPayload.campaignPlanSelectedDay
            ) {
              segmentDateMapping.plans.forEach((plan, index) => {
                if (index === action.payload.segmentPayload.planIndex) {
                  plan.planConditionoutreachList =
                    plan.planConditionoutreachList.filter((condition) => {
                      if (
                        action.payload.strategyPayload.deleteConditions.includes(
                          condition.conditionOutreach.currentNode
                        )
                      ) {
                        state.segmentEditedList.set(
                          action.payload.segmentPayload.segmentId,
                          true
                        );

                        return false;
                      }
                      return true;
                    });
                }
              });
            }
          });
        }
      });
      state.isLoading = false;
    },
    addOutreachFromConditionCampaignPlan: (
      state,
      action: PayloadAction<{
        outreaches: AddOutreachType;
        campaignPlanSelectedDay: string;
        index: number;
        segmentId: number;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          campaignSegment.segmentDateMappings?.forEach((segmentDateMapping) => {
            if (
              segmentDateMapping.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
            ) {
              segmentDateMapping.plans.forEach((plan, index) => {
                if (index === action.payload.index) {
                  plan.planConditionoutreachList.forEach((condition) => {
                    if (
                      condition.conditionOutreach.currentNode ===
                      action.payload.outreaches.currentNode
                    ) {
                      if (action.payload.outreaches.editOutreach?.outreachId) {
                        condition.conditionOutreach.outreach =
                          condition.conditionOutreach.outreach.map(
                            (outreach) => {
                              if (
                                outreach.outreachId ===
                                action.payload.outreaches.editOutreach
                                  ?.outreachId
                              ) {
                                return action.payload.outreaches
                                  .selectedOutreaches[0];
                              }
                              return outreach;
                            }
                          );
                      } else {
                        condition.conditionOutreach.outreach.push(
                          ...action.payload.outreaches.selectedOutreaches
                        );
                      }

                      state.segmentEditedList.set(
                        action.payload.segmentId,
                        true
                      );
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    deleteFromOutreachCampaignPlan: (
      state,
      action: PayloadAction<{
        conditionPayload: DeleteConditionType;
        segmentId: number;
        campaignPlanSelectedDay: string;
        planIndex: number;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          campaignSegment.segmentDateMappings?.forEach((segmentDateMapping) => {
            if (
              segmentDateMapping.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
            ) {
              segmentDateMapping.plans.forEach((plan, index) => {
                if (index === action.payload.planIndex) {
                  plan.planConditionoutreachList =
                    plan.planConditionoutreachList.filter((condition) => {
                      if (
                        action.payload.conditionPayload.deleteConditions.includes(
                          condition.conditionOutreach.currentNode
                        )
                      ) {
                        state.segmentEditedList.set(
                          action.payload.segmentId,
                          true
                        );

                        return false;
                      }
                      return true;
                    });
                }
              });
            }
          });
        }
      });
    },
    deleteCurrentOutreachCampaignPlan: (
      state,
      action: PayloadAction<{
        outReachPayload: DeleteOutreachType;
        planIndex: number;
        segmentId: number;
        campaignPlanSelectedDay: string;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          campaignSegment.segmentDateMappings?.forEach((segmentDateMapping) => {
            if (
              segmentDateMapping.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
            ) {
              segmentDateMapping.plans.forEach((plan, index) => {
                if (index === action.payload.planIndex) {
                  plan.planConditionoutreachList.forEach((condition) => {
                    if (
                      condition.conditionOutreach.currentNode ===
                      action.payload.outReachPayload.nodeId
                    ) {
                      condition.conditionOutreach.outreach =
                        condition.conditionOutreach.outreach.filter(
                          (outreach) => {
                            if (
                              outreach.outreachId ===
                              action.payload.outReachPayload.outreachId
                            ) {
                              state.segmentEditedList.set(
                                action.payload.segmentId,
                                true
                              );

                              return false;
                            }
                            return true;
                          }
                        );
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    deleteParentOutreachCampaignPlan: (
      state,
      action: PayloadAction<{
        planIndex: number;
        segmentId: number;
        campaignPlanSelectedDay: string;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          campaignSegment.segmentDateMappings?.forEach((segmentDateMapping) => {
            if (
              segmentDateMapping.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
            ) {
              segmentDateMapping.plans = segmentDateMapping.plans.filter(
                (_, index) => {
                  state.segmentEditedList.set(action.payload.segmentId, true);
                  return index !== action.payload.planIndex;
                }
              );
            }
          });
        }
      });
    },
    planCampaignPlan: (
      state,
      action: PayloadAction<{
        campaignId: number;
        lenderId: string;
        planCampaignPlanCallback: (val: { isSuccess: boolean }) => void;
      }>
    ) => {
      state.isLoading = true;
    },
    updateEnableStrategyLibraryInCampaignPlan: (
      state,
      action: PayloadAction<{
        segmentId: number;
        enableStrategyLibrary: boolean;
        date: string;
      }>
    ) => {
      const activeStrategySwitchObj = state.activeStrategySwitch.find(
        (item) =>
          item.segmentId === action.payload.segmentId &&
          item.date === action.payload.date
      );

      if (activeStrategySwitchObj) {
        state.activeStrategySwitch = state.activeStrategySwitch.map((item) => {
          if (item.segmentId === action.payload.segmentId) {
            return {
              ...item,
              enableStrategyLibrary: action.payload.enableStrategyLibrary,
            };
          }
          return item;
        });

        return;
      }

      state.activeStrategySwitch.push({
        segmentId: action.payload.segmentId,
        date: action.payload.date,
        enableStrategyLibrary: action.payload.enableStrategyLibrary,
      });
    },
    resetCampaignPlan: () => {
      return strategyInitialState;
    },

    setEditStatus: (
      state,
      action: PayloadAction<{
        isEdited: boolean;
      }>
    ) => {
      state.campaignPlanner.edited = action.payload.isEdited;
    },
    setSelectedChannel: (
      state,
      action: PayloadAction<{
        selectedChannel: string;
      }>
    ) => {
      state.selectedChannel = action.payload.selectedChannel;
    },
    createCampaign: (state, action: CreateCampaignActionType) => {
      state.isLoading = true;
    },
    createCampaignSucceeded: (state, action: PayloadAction<ICampaignData>) => {
      state.isLoading = false;
      state.campaignPlanner.allocationId = action.payload.allocationId || '';
      state.campaignPlanner.allocationName = action.payload.allocationName;
      state.campaignPlanner.currentStatus = action.payload.currentStatus;
      state.campaignPlanner.startDate = action.payload.startDate;
      state.campaignPlanner.endDate = action.payload.endDate;
      state.campaignPlanner.campaignId = action.payload.campaignId;
      state.campaignPlanner.campaignName = action.payload.campaignName;
      state.campaignPlanner.lob = action.payload.lob;
      state.campaignPlanner.records = action.payload.records;
    },
    createCampaignFailed: (state) => {
      state.isLoading = false;
    },
    updateCampaign: (state, action: UpdateCampaignActionType) => {
      state.isLoading = true;
    },
    setCampaignPlanningLoading: (
      state,
      action: PayloadAction<{ isCampaignPlanningLoading: boolean }>
    ) => {
      state.isCampaignPlanningLoading =
        action.payload.isCampaignPlanningLoading;
    },

    updateSegments: (
      state,
      action: PayloadAction<{
        segments: CampaignSegment[];
        action: 'ADD' | 'REMOVE';
      }>
    ) => {
      if (action.payload.action === 'ADD') {
        state.campaignPlanner.campaignSegmentList?.push(
          ...action.payload.segments
        );
      } else {
        state.campaignPlanner.campaignSegmentList =
          state.campaignPlanner.campaignSegmentList?.filter(
            (segment) =>
              !action.payload.segments.some(
                (s) => s.segment.segmentId === segment.segment.segmentId
              )
          );
      }
    },
    addConditionToParentOutreachInCampaignAction: (
      state,
      action: PayloadAction<{
        conditionRule: ConditionRule;
        segmentId: number;
        campaignPlanSelectedDay: string;
        planIndex: number;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          const segmentDateMapping = campaignSegment.segmentDateMappings?.find(
            (sm) =>
              sm.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
          );

          if (segmentDateMapping) {
            segmentDateMapping.plans.forEach((plan, index) => {
              if (index === action.payload.planIndex) {
                plan.planConditionoutreachList.forEach((condition) => {
                  if (
                    `${condition.conditionOutreach.parentNode}_${condition.parentOutreachId}` ===
                    CAMPAIGN_PLANNER_PLAN_PARENT_NODE_AND_OUTREACH_ID
                  ) {
                    condition.conditionOutreach = {
                      ...condition.conditionOutreach,
                      conditionRule: { ...action.payload.conditionRule },
                    };
                    state.segmentEditedList.set(action.payload.segmentId, true);
                  }
                });
              }
            });
          }
        }
      });
    },
    addConditionToParentOutreachInStrategyAction: (
      state,
      action: PayloadAction<{
        conditionRule: ConditionRule;
        selectedPlanDay: number;
        planIndex: number;
      }>
    ) => {
      state.createStrategy.days?.forEach((value) => {
        if (value.currentDay === action.payload.selectedPlanDay) {
          value.plans.forEach((plan, index) => {
            if (index === action.payload.planIndex) {
              plan.planConditionoutreachList.forEach((condition) => {
                if (
                  `${condition.conditionOutreach.parentNode}_${condition.parentOutreachId}` ===
                  CAMPAIGN_PLANNER_PLAN_PARENT_NODE_AND_OUTREACH_ID
                ) {
                  condition.conditionOutreach = {
                    ...condition.conditionOutreach,
                    conditionRule: { ...action.payload.conditionRule },
                  };
                }
              });
            }
          });
        }
      });
    },
    onEditOutreachSuccessCampaignAction: (
      state,
      action: PayloadAction<{
        outReach: OutreachType;
        nodeId: string;
        planIndex: number;
        campaignPlanSelectedDay: string;
        segmentId: number;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          campaignSegment.segmentDateMappings?.forEach((segmentDateMapping) => {
            if (
              segmentDateMapping.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
            ) {
              segmentDateMapping.plans.forEach((plan, index) => {
                if (index === action.payload.planIndex) {
                  plan.planConditionoutreachList.forEach((condition) => {
                    if (
                      condition.conditionOutreach.currentNode ===
                      action.payload.nodeId
                    ) {
                      condition.conditionOutreach.outreach =
                        condition.conditionOutreach.outreach.map((outreach) => {
                          if (
                            outreach.outreachId ===
                            action.payload.outReach.outreachId
                          ) {
                            state.segmentEditedList.set(
                              action.payload.segmentId,
                              true
                            );
                            return { ...outreach, ...action.payload.outReach };
                          }
                          return outreach;
                        });
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    onEditOutreachSuccessStrategyAction: (
      state,
      action: PayloadAction<{
        outreach: OutreachType;
        nodeId: string;
        planIndex: number;
        selectedPlanDay: number;
      }>
    ) => {
      state.createStrategy.days?.forEach((value) => {
        if (value.currentDay === action.payload.selectedPlanDay) {
          value.plans.forEach((plan, index) => {
            if (index === action.payload.planIndex) {
              plan.planConditionoutreachList.forEach((condition) => {
                if (
                  condition.conditionOutreach.currentNode ===
                  action.payload.nodeId
                ) {
                  condition.conditionOutreach.outreach =
                    condition.conditionOutreach.outreach.map((outreach) => {
                      if (
                        outreach.outreachId ===
                        action.payload.outreach.outreachId
                      ) {
                        return { ...outreach, ...action.payload.outreach };
                      }
                      return outreach;
                    });
                }
              });
            }
          });
        }
      });
    },
    deleteParentConditionInCampaignAction: (
      state,
      action: PayloadAction<{
        nodeId: string;
        segmentId: number;
        campaignPlanSelectedDay: string;
        planIndex: number;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          campaignSegment.segmentDateMappings?.forEach((segmentDateMapping) => {
            if (
              segmentDateMapping.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
            ) {
              segmentDateMapping.plans.forEach((plan, index) => {
                if (index === action.payload.planIndex) {
                  plan.planConditionoutreachList.forEach((condition) => {
                    if (
                      condition.conditionOutreach.currentNode ===
                      action.payload.nodeId
                    ) {
                      state.segmentEditedList.set(
                        action.payload.segmentId,
                        true
                      );
                      condition.conditionOutreach = {
                        ...condition.conditionOutreach,
                        conditionRule: undefined,
                      };
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    deleteParentConditionInStrategyAction: () => {},
    getSegmentById: (
      state,
      action: PayloadAction<{
        campaignId: string;
        segmentId: number;
      }>
    ) => {
      state.isSegmentLoading = true;
    },
    getSegmentByIdSuccess: (
      state,
      action: PayloadAction<{
        campaignSegment: CampaignSegment;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.some((segment) => {
        if (
          segment.segment.segmentId ===
          action.payload.campaignSegment.segment.segmentId
        ) {
          segment.isSegmentFetched = true;
          segment.segmentDateMappings =
            action.payload.campaignSegment.segmentDateMappings;
          return true;
        }
        return false;
      });
      state.isSegmentLoading = false;
    },
    getSegmentByIdFailure: (state) => {
      state.isSegmentLoading = false;
    },
    resetCampaignSegmentEditedList: (state) => {
      state.segmentEditedList = new Map<number, boolean>();
    },
    removePlanInCampaignPlanAction: (
      state,
      action: PayloadAction<{
        segmentId: number;
        campaignPlanSelectedDay: string;
        planIndex: number;
      }>
    ) => {
      state.campaignPlanner.campaignSegmentList?.forEach((campaignSegment) => {
        if (campaignSegment.segment.segmentId === action.payload.segmentId) {
          campaignSegment.segmentDateMappings?.forEach((segmentDateMapping) => {
            if (
              segmentDateMapping.campaignDate.currentDate ===
              action.payload.campaignPlanSelectedDay
            ) {
              segmentDateMapping.plans = segmentDateMapping.plans.filter(
                (_, index) => index !== action.payload.planIndex
              );
              state.segmentEditedList.set(action.payload.segmentId, true);
            }
          });
        }
      });
    },
  },
});

export const {
  createCampaign,
  createCampaignSucceeded,
  createCampaignFailed,
  updateCampaign,
  addNewOutreach,
  addConditionOutreachAction,
  selectPlanDay,
  selectPlanDays,
  createFirstOutreachPlan,
  fetchStrategyById,
  fetchStrategyByIdSuccess,
  fetchConditionRules,
  fetchConditionRulesSuccess,
  fetchOutreachDropdownList,
  fetchOutreachDropdownListSuccess,
  deleteConditionOutreaches,
  deleteOutreach,
  fetchStrategies,
  fetchStrategiesSuccess,
  resetStrategiesList,
  selectCampaignPlanDate,
  createParentOutreachPlanSegment,
  fetchCampaignPlan,
  fetchCampaignPlanSuccess,
  addConditionOutreachForSegmentAction,
  fetchSegmentsList,
  fetchSegmentsListSuccess,
  updateStrategyName,
  campaignPlanSaveDraft,
  campaignPlanSaveDraftSuccess,
  updateDateListCampaignPlanner,
  deleteStrategyById,
  deleteStrategyByIdSuccess,
  addStrategyFromLibraryToSegmentInCampaignPlan,
  createCampaignWithAllocation,
  createCampaignWithAllocationSuccess,
  pauseOrResumeCampaign,
  pauseOrResumeCampaignSucceeded,
  pauseOrResumeCampaignFailed,
  updatesDaysInStrategy,
  updateActiveAccordionAccordions,
  removeStrategyFromLibraryFromSegmentInCampaignPlan,
  resetStrategyAction,
  fetchStrategiesFail,
  deleteParentOutreachNode,
  fetchOutreachConfig,
  fetchOutreachConfigSuccess,
  cloneStrategyById,
  cloneStrategyByIdSuccess,
  getContentConfig,
  getContentConfigSuccess,
  deleteConditionOutreachesInCampaignPlan,
  addOutreachFromConditionCampaignPlan,
  deleteFromOutreachCampaignPlan,
  deleteCurrentOutreachCampaignPlan,
  deleteParentOutreachCampaignPlan,
  planCampaignPlan,
  updateEnableStrategyLibraryInCampaignPlan,
  resetCampaignPlan,
  setEditStatus,
  setSelectedChannel,
  setCampaignNbaStatusFailed,
  updateCampaignNbaTicketRaised,
  setLoadingStrategyState,
  setCampaignPlanningLoading,
  updateSegments,
  addConditionToParentOutreachInCampaignAction,
  addConditionToParentOutreachInStrategyAction,
  onEditOutreachSuccessCampaignAction,
  onEditOutreachSuccessStrategyAction,
  deleteParentConditionInCampaignAction,
  deleteParentConditionInStrategyAction,
  getSegmentById,
  getSegmentByIdSuccess,
  resetCampaignSegmentEditedList,
  removePlanInCampaignPlanAction,
  getSegmentByIdFailure,
} = strategy.actions;
export default strategy.reducer;
