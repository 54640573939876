import { all, fork } from 'redux-saga/effects';
import { watchFetchReportTokenSaga } from './ReportPage.saga';
import { watchUserEntitiesSaga } from './user/UserEntities.saga';
import { watchUserPermissionSaga } from './user/UserPermission.saga';
import { watchAllocationSagas } from 'redux/allocation/allocation.saga';
import { watchCreateConfigSaga } from './allocation-config/CreateConfig.saga';
import { watchGetAllocationConfigListSaga } from './allocation-config/GetAllocationList.saga';
import { watchAgencySagas } from './manage-agency/agency.saga';
import { watchInviteAgencySagas } from './manage-agency/inviteAgency.saga';
import { watchMyLenderSaga } from './lenders/MyLenders.saga';
import { watchLenderInvitesSaga } from './lenders/LendersInvites.saga';
import { watchRejectLenderInvitesSaga } from './lenders/RejectLenders.saga';
import { watchAcceptLenderInvitesSaga } from './lenders/AcceptLenders.saga';
import { watchStrategySagas } from 'redux/slice/strategy/strategy.saga';
import { watchOutreachListSaga } from './campaign-planner/OutreachList.saga';
import {
  watchCreateOutreachSaga,
  watchCreateOutreachSuccess,
} from './campaign-planner/CreateOutreach.saga';
import { watchDeleteOutreachSaga } from './campaign-planner/DeleteOutreach.saga';
import { watchConfigSaga } from './campaign-planner/ConfigList.saga';
import { watchCreateCampaignSaga } from './campaign-planner/CreateCampaign.saga';
import { watchGetOnboardDetailsSaga } from './user/UserOnboard.saga';
import { watchSendAssociationSagas } from './manage-agency/sendAssociation.saga';
import { watchGetGlobalPolicySaga } from './settings/compliance/getGlobalPolicy.saga';
import { watchGetDefaultPolicySaga } from './settings/compliance/getDefaultPolicy.saga';
import { watchCampaignListSaga } from './campaign-planner/CampaignList.saga';
import { watchAllocationListSaga } from './campaign-planner/AllocationList.saga';
import { watchGetDerivedFieldSaga } from './derived-field/GetDerivedField.saga';
import { watchDesignationListSaga } from './hierarchy-management/DesignationManagement.saga';
import { watchHierarchySetupDataSaga } from './hierarchy-management/HierarchySetup.saga';
import {
  watchEmployeeProfileDataSaga,
  watchSavedProductBucketSaga,
} from './hierarchy-management/EmployeeProfile.saga';
import { watchGetChannelPreferenceSaga } from './settings/preferences/channelPreference.saga';
import { watchGetLenderPoliciesSaga } from './settings/compliance/getLenderPolicies.saga';
import { watchGetProductPreferenceSaga } from './settings/preferences/productPreference.saga';
import { watchGetBucketPreferenceSaga } from './settings/preferences/bucketPreference.saga';
import { watchContentSaga } from 'redux/content/content.saga';
import { watchGetLMSFieldsSaga } from './settings/loanbook/getLMSFields.saga';
import { watchUniqueLenderSaga } from './lenders/MyUniqueLenders.saga';
import { watchGetDerivedFieldsSaga } from './settings/loanbook/getDerivedFields.saga';
import { watchSyncHierarchySaga } from './hierarchy-management/syncHierarchy.saga';

function* rootSaga() {
  yield all([
    fork(watchFetchReportTokenSaga),
    fork(watchUserEntitiesSaga),
    fork(watchUserPermissionSaga),
    fork(watchMyLenderSaga),
    fork(watchLenderInvitesSaga),
    fork(watchCreateConfigSaga),
    fork(watchGetAllocationConfigListSaga),
    fork(watchAllocationSagas),
    fork(watchAgencySagas),
    fork(watchInviteAgencySagas),
    fork(watchSendAssociationSagas),
    fork(watchRejectLenderInvitesSaga),
    fork(watchAcceptLenderInvitesSaga),
    fork(watchStrategySagas),
    fork(watchOutreachListSaga),
    fork(watchCreateOutreachSaga),
    fork(watchDeleteOutreachSaga),
    fork(watchConfigSaga),
    fork(watchCreateCampaignSaga),
    fork(watchGetOnboardDetailsSaga),
    fork(watchGetGlobalPolicySaga),
    fork(watchGetDefaultPolicySaga),
    fork(watchCampaignListSaga),
    fork(watchAllocationListSaga),
    fork(watchGetDerivedFieldSaga),
    fork(watchDesignationListSaga),
    fork(watchHierarchySetupDataSaga),
    fork(watchEmployeeProfileDataSaga),
    fork(watchSavedProductBucketSaga),
    fork(watchGetChannelPreferenceSaga),
    fork(watchGetLenderPoliciesSaga),
    fork(watchGetProductPreferenceSaga),
    fork(watchGetBucketPreferenceSaga),
    fork(watchCreateOutreachSuccess),
    fork(watchContentSaga),
    fork(watchGetLMSFieldsSaga),
    fork(watchUniqueLenderSaga),
    fork(watchGetDerivedFieldsSaga),
    fork(watchSyncHierarchySaga),
  ]);
}
export default rootSaga;
