import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
  IDesignation,
  ISagaDesignationParam,
} from 'redux/saga/hierarchy-management/DesignationManagement.types';

export interface IDesignationManagementInitialState {
  designation: IDesignation[] | [];
  count: number;
  isLoading: boolean;
  status: APIStatus;
  error: { message?: string };
}
export const designationManagementInitialState: IDesignationManagementInitialState =
  {
    designation: [],
    isLoading: false,
    count: 0,
    status: APIStatus.INIT,
    error: {},
  };

const designationManagementSlice = createSlice({
  name: 'designationManagement',
  initialState: designationManagementInitialState,
  reducers: {
    fetchDesignationList: (
      state,
      action: PayloadAction<ISagaDesignationParam>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchDesignationListSucceeded: (state, action) => {
      const { data, count } = action.payload;
      state.designation = [...state.designation, ...data];
      state.count = count;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchDesignationListFailed: (state, action) => {
      state.isLoading = false;
      state.status = APIStatus.ERROR;
      state.error = action.payload.error;
    },
    resetDesignationListState: () => designationManagementInitialState,
  },
});

export const {
  fetchDesignationList,
  fetchDesignationListSucceeded,
  fetchDesignationListFailed,
  resetDesignationListState,
} = designationManagementSlice.actions;

export default designationManagementSlice.reducer;
