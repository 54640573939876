import React from 'react';
import { IDataProps } from '../../../components/DropDownView/DropDownView.type';
import Plus from 'assets/svgComponents/Plus';
import Minus from 'assets/svgComponents/Minus';
import './Condition.css';
import {
  ICondition,
  IConditionRules,
  IDispositionOption,
} from '../shared/contract';
import DropDownView from 'components/antd/DropDownView';
import { TextView } from 'components';
import { getConditionPill, RoundedPoint } from '../shared/constants';
import DropDownViewV3 from 'componentsV3/DropDownViewV3';

interface IConditionProps {
  path: string;
  condition: ICondition;
  onChange: (changedCondition: ICondition) => void;
  addORRemoveConditionAndGroup: (
    path: string,
    isAdd: boolean,
    isCondition: boolean
  ) => void;
  dispositionOptions: IDispositionOption[];
  conditionRule: IConditionRules;
  isView: boolean;
}

const Condition = (props: IConditionProps) => {
  const {
    path,
    condition,
    onChange,
    addORRemoveConditionAndGroup,
    dispositionOptions,
    conditionRule,
    isView,
  } = props;
  const handleChange = (key: string, value: string) => {
    const newCondition: ICondition = {
      ...condition,
      [key as keyof typeof condition]: value,
    };
    const { mainDisposition, conditionOption, dispositionType } = newCondition;
    if (mainDisposition && conditionOption && dispositionType) {
      const conditionName = [
        conditionOption,
        mainDisposition,
        dispositionType,
      ].join('-');
      const id = conditionRule[conditionName];
      newCondition.conditionId = id;
      newCondition.name = conditionName;
    }
    onChange(newCondition);
  };
  const { mainDisposition, conditionOption, dispositionType } = condition;
  const substring = 'groups';
  const regex = new RegExp(substring, 'g');
  const matchCount = (path.match(regex) || []).length;

  return (
    <div className={`flex gap-2 ${matchCount === 0 ? '' : 'pl-4'} test`}>
      {matchCount === 0 && (
        <div className="flex">
          <div className={`flex items-center w-[30px]`}>
            <div
              className="h-[2px] w-full"
              style={{
                borderBottom: '2px solid #EEF2F6',
                borderLeft: '2px solid #EEF2F6',
              }}
            ></div>
          </div>
          <div className="flex items-center" style={{ marginRight: '8px' }}>
            <RoundedPoint />
          </div>
        </div>
      )}
      {isView ? (
        getConditionPill(mainDisposition, 'mainDisposition')
      ) : (
        <div>
          <DropDownViewV3
            size="s"
            triggerWidth={204}
            value={mainDisposition || ''}
            placeholder="Select disposition"
            options={dispositionOptions}
            onChange={(value: string) => handleChange('mainDisposition', value)}
            label={''}
            type={'standard'}
          />
        </div>
      )}
      {isView ? (
        getConditionPill(conditionOption, 'conditionOption')
      ) : (
        <div>
          <DropDownViewV3
            size="s"
            triggerWidth={204}
            value={conditionOption}
            placeholder="Include/Exclude"
            options={[
              {
                label: 'Include',
                value: 'INCLUDE',
              },
              {
                label: 'Exclude',
                value: 'EXCLUDE',
              },
            ]}
            onChange={(value: string) => handleChange('conditionOption', value)}
            label={''}
            type={'standard'}
          />
        </div>
      )}
      {isView ? (
        getConditionPill(dispositionType, 'dispositionType')
      ) : (
        <div className="w-[200px]">
          <DropDownViewV3
            size="s"
            triggerWidth={204}
            value={dispositionType}
            placeholder="Latest/Best"
            options={[
              {
                label: 'Best',
                value: 'BEST',
              },
              {
                label: 'Latest',
                value: 'LATEST',
              },
            ]}
            onChange={(value: string) => handleChange('dispositionType', value)}
            label={''}
            type={'standard'}
          />
        </div>
      )}
      {!isView && (
        <div
          onClick={() => {
            addORRemoveConditionAndGroup(path, false, true);
          }}
          className="flex h-[32px] px-2 items-center action action-button cursor-pointer"
        >
          <Minus />
        </div>
      )}
    </div>
  );
};

export default Condition;
