import { put, takeLatest } from 'redux-saga/effects';
import {
  IGetGlobalPolicyPayload,
  getGlobalPolicyRequestFailed,
  getGlobalPolicyRequestSucceeded,
  getGlobalPolicyRequested,
} from 'redux/slice/settings/Compliance/GetGlobalPolicy.slice';
import { settingsEndpoints } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AxiosResponse } from 'axios';
import { IComplianceResponse, IPolicy } from 'redux/types/Compliance.types';

function* getGlobalPolicySaga({
  payload,
}: PayloadAction<IGetGlobalPolicyPayload>) {
  const { region, entityId } = payload;
  const requestId = uuidv4();
  try {
    const response: AxiosResponse<IComplianceResponse<IPolicy>> =
      yield HTTPClient.PostRequest(
        settingsEndpoints.Get_GLOBAL_POLICIES,
        { region, lenderId: entityId },
        { headers: { 'request-id': requestId } }
      );
    yield put(getGlobalPolicyRequestSucceeded(response.data.data));
  } catch (err) {
    yield put(
      getGlobalPolicyRequestFailed({ error: { message: 'Error' } } as any)
    );
  }
}

export function* watchGetGlobalPolicySaga() {
  yield takeLatest(getGlobalPolicyRequested.type, getGlobalPolicySaga);
}
