import React from 'react';
import YbCoreSideMenu, {
  YbSideMenuContentItem,
  YbSideMenuSectionItem,
  YbSideMenuSubItem,
} from '@yubi/yb-core-side-menu';
import { useStyles } from '@yubi/yb-style-provider';

interface ISideMenu
  extends Omit<YbSideMenuSectionItem, 'type'>,
    YbSideMenuContentItem {}

interface ISideMenuViewProps {
  items: ISideMenu[];
  collapsed: boolean;
  hideAllLeftIcons?: boolean;
  onChangeSelection?: (
    selectedItem: YbSideMenuContentItem | YbSideMenuSubItem,
    index: number,
    isChild: boolean
  ) => void;
}

const SideMenuView = (props: ISideMenuViewProps) => {
  const { items, collapsed, hideAllLeftIcons, onChangeSelection } = props;
  const { styleConnector } = useStyles();
  return (
    <YbCoreSideMenu
      componentType="Web"
      collapsed={collapsed || false}
      items={items}
      styleConnector={styleConnector}
      hideAllLeftIcons={hideAllLeftIcons}
      onChangeSelection={onChangeSelection}
    />
  );
};

export default SideMenuView;
