import React from 'react';
import YbCoreDropdown from '@yubi/yb-core-dropdown';
import { useStyles } from '@yubi/yb-style-provider';
import { IDropDownViewProps } from './DropDownView.type';

const DropDownView = ({
  value,
  dropdownData,
  handleClick,
  placeholder,
  dropdownContainerCustomStyle = { zIndex: 1000 },
  title,
  testIDObject,
  onChangeText,
  onFocus,
  disableSearch = true,
  AdvancedDropdownProps,
  key,
  disable,
}: IDropDownViewProps) => {
  const { styleConnector } = useStyles();
  return (
    <div style={{ width: '100%', zIndex: 10000 }}>
      <YbCoreDropdown
        key={key}
        AdvancedDropdownProps={AdvancedDropdownProps}
        disableSearch={disableSearch}
        isDisabled={disable}
        input={value}
        onChangeText={onChangeText}
        onFocus={onFocus}
        dropdownContainerCustomStyle={dropdownContainerCustomStyle}
        dropdownData={dropdownData}
        handleClick={handleClick}
        title={title}
        placeholder={placeholder}
        showCancelSelectionButton={false}
        styleConnector={styleConnector}
        testIDObject={testIDObject}
        type="dropdown-advanced"
      />
    </div>
  );
};

export default DropDownView;
