import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IActionPayload,
  InviteAgencyFormParam,
  RemindReinviteActionFormParam,
} from '../../types/ManageAgency.type';
import { APIStatus } from 'services/api/apiStatusCode';
import { IErrorParam } from 'redux/types/types';

export interface InviteAgencyInitialState {
  inviteAgencyFormStatus: APIStatus;
  remindReinviteAgencyFormStatus: APIStatus;
  sendAssociateFormStatus: APIStatus;
  error: IErrorParam;
  successMsg: string;
}

export const inviteAgencyInitialState: InviteAgencyInitialState = {
  inviteAgencyFormStatus: APIStatus.INIT,
  remindReinviteAgencyFormStatus: APIStatus.INIT,
  sendAssociateFormStatus: APIStatus.INIT,
  error: {},
  successMsg: '',
};

const inviteAgency = createSlice({
  name: 'inviteLenderAgency',
  initialState: inviteAgencyInitialState,
  reducers: {
    inviteAgencyForm: (
      state: InviteAgencyInitialState,
      action: PayloadAction<InviteAgencyFormParam>
    ) => {
      state.inviteAgencyFormStatus = APIStatus.PENDING;
    },
    inviteAgencyFormSuccess: (
      state: InviteAgencyInitialState,
      action: PayloadAction<string>
    ) => {
      state.inviteAgencyFormStatus = APIStatus.SUCCESS;
      state.successMsg = action.payload;
    },
    remindReinviteAgencyForm: (
      state: InviteAgencyInitialState,
      action: PayloadAction<RemindReinviteActionFormParam>
    ) => {
      state.remindReinviteAgencyFormStatus = APIStatus.PENDING;
    },
    remindReinviteAgencyFormSuccess: (
      state: InviteAgencyInitialState,
      action: PayloadAction<string>
    ) => {
      state.remindReinviteAgencyFormStatus = APIStatus.SUCCESS;
      state.successMsg = action.payload;
    },
    inviteAgencyFailed: (
      state: InviteAgencyInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      if (action.payload?.type === 'REMIND_SENT_LIMIT_EXHAUSTED') {
        state.inviteAgencyFormStatus = APIStatus.NOT_ACCEPTABLE;
      } else {
        state.inviteAgencyFormStatus = APIStatus.ERROR;
      }
    },
    resetInviteState: () => inviteAgencyInitialState,
  },
});

export const {
  inviteAgencyForm,
  inviteAgencyFormSuccess,
  remindReinviteAgencyForm,
  remindReinviteAgencyFormSuccess,
  inviteAgencyFailed,
  resetInviteState,
} = inviteAgency.actions;

export default inviteAgency.reducer;
