import { useStyles } from '@yubi/yb-style-provider';
import YbCoreInput from '@yubi/yb-core-input';
import React from 'react';
import {
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native/types';

interface InputViewProps {
  id?: string;
  label?: string;
  initialValue: string;
  size?: 's' | 'm' | 'l';
  onBlur?: (
    e: NativeSyntheticEvent<TextInputFocusEventData>,
    value: string | undefined
  ) => void;
  onKeyPress?: (e: KeyboardEvent) => void;
  onChangeText: (text: string | number | undefined) => void;
  placeholder?: string;
  isNumeric?: boolean;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

const InputView: React.FC<InputViewProps> = ({
  id = 'input',
  initialValue = '',
  size = 'm',
  label,
  onChangeText,
  onKeyPress,
  onBlur,
  disabled,
  placeholder,
  isNumeric,
  error,
  touched,
  maxLength,
}) => {
  const { styleConnector } = useStyles();

  return (
    <YbCoreInput
      id={id}
      label={label}
      value={initialValue}
      onChangeText={onChangeText}
      styleConnector={styleConnector}
      size={size}
      onKeyPress={onKeyPress}
      onBlur={onBlur}
      placeholder={placeholder}
      isNumeric={isNumeric}
      error={error}
      touched={touched}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};

export default InputView;
