import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserPermissionParam } from '../../../redux/saga/user/User.types';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
  IActionPayload,
  IUserPermissionInitialState,
} from '../../types/UserPermission.types';

export const userPermissionSliceInitialState: IUserPermissionInitialState = {
  userId: '',
  status: APIStatus.INIT,
  isLoading: false,
  error: {},
};

const userPermissionSlice = createSlice({
  name: 'userPermission',
  initialState: userPermissionSliceInitialState,
  reducers: {
    fetchUserPermission: (state: IUserPermissionInitialState) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchUserPermissionSucceeded: (
      state: IUserPermissionInitialState,
      action: PayloadAction<IUserPermissionParam>
    ) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { ca_user_id, permissions } = action.payload;
      state.userId = ca_user_id;
      state.userPermissions = permissions;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchUserPermissionFailed: (
      state: IUserPermissionInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;

      state.status = APIStatus.ERROR;
    },
    resetUserPermissionState: () => userPermissionSliceInitialState,
  },
});

export const {
  fetchUserPermission,
  fetchUserPermissionSucceeded,
  fetchUserPermissionFailed,
  resetUserPermissionState,
} = userPermissionSlice.actions;

export default userPermissionSlice.reducer;
