import { Weeks, getDerivedFieldValidationRules } from './constants';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import {
  IChannelPreferenceData,
  IChannelsCompliance,
  IDateData,
  IDerivedField,
  IDerivedNewField,
  ILMSField,
  IRange,
  IStrategyData,
  IWeekCompliance,
} from './contract';
import { SegmentDateMappings } from 'features/StrategyConfig/CreatePlan/Flow/strategyTestData';
import { Plan } from 'features/StrategyConfig/CreatePlan/Flow/types';
import { debug } from 'console';
import { IStrategyRequest } from 'redux/slice/strategy/types';
import moment from 'moment-timezone';

interface IChannelPreferenceApiData {
  channelPreferenceId: number;
  channelProduct: {
    channelID: number;
    channelProductId: number;
    channelProductName: string;
    displayName: string;
  };
  enabled: boolean;
}

export const getChannelPreferenceData = (data: IChannelPreferenceApiData[]) => {
  return data.map((channel) => ({
    channelPreferenceId: channel.channelPreferenceId,
    channelProductName: channel.channelProduct.channelProductName,
    enabled: channel.enabled,
    displayName:
      channel.channelProduct.displayName ||
      channel.channelProduct.channelProductName,
  }));
};

export const containsSearchText = <T>(data: T, searchText: string): boolean => {
  if (Array.isArray(data)) {
    return data.some((item) => containsSearchText(item, searchText));
  } else if (typeof data === 'object') {
    return Object.values(data as object).some(
      (item) => item && containsSearchText(item, searchText)
    );
  }

  return data && typeof data !== 'boolean'
    ? data.toString().toLowerCase().includes(searchText)
    : false;
};

export const getCurrentPage = (
  totalNoOfRecords: number,
  sizeOfPage: number,
  currentPage: number
) => {
  let totalAvailablePages = Math.floor(totalNoOfRecords / sizeOfPage);
  return totalAvailablePages > currentPage
    ? currentPage
    : totalNoOfRecords % sizeOfPage === 0
    ? --totalAvailablePages
    : totalAvailablePages;
};

export const checkForSearch = <T>(data: Array<T>, text: string): T[] => {
  const searchText = text.toLowerCase();
  return data.filter((preference) => {
    return containsSearchText(preference, searchText);
  });
};

export const downloadTemplate = (csvData: string, fileName: string) => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  window.URL.revokeObjectURL(url);
};

export const downloadExcelTemplate = (data: string, fileName: string) => {
  const dataArray = data.split('\n').map((line) => line.split(',')); // Split data into rows and columns

  const worksheet = XLSX.utils.aoa_to_sheet(dataArray);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); // You can change the sheet name

  XLSX.writeFile(workbook, fileName);
};

export const isDataValid = <T>(data: T) => {
  if (data && Array.isArray(data)) {
    for (const val of data) {
      const result = isDataValid(val);
      if (!result) return false;
    }
  } else if (data && typeof data === 'object') {
    const keys = Object.keys(data);
    for (const key of keys) {
      const result = isDataValid(data[key as keyof typeof data]);
      if (!result) return false;
    }
    return true;
  }
  return !!data;
};

export const isObjectEmpty = (object: object | undefined | null) => {
  if (!object) return true;
  return Object.keys(object).length === 0;
};

export const dereferenceObject = (object: object) =>
  JSON.parse(JSON.stringify(object));

export const getModifiedObjects = (data: object, policies: IWeekCompliance) => {
  const policiesModifiedByWeek: { [key: string]: Array<string> } = {};
  const modifiedWeeks = Object.keys(data);
  modifiedWeeks.forEach((key) => {
    if (data[key as keyof typeof data]) {
      const modifiedChannels = Object.keys(data[key as keyof typeof data]);
      policiesModifiedByWeek[key as keyof typeof policiesModifiedByWeek] =
        modifiedChannels;
    }
  });
  const newApiData: { [key: string]: IWeekCompliance } = {};
  Object.keys(policiesModifiedByWeek).forEach((key) => {
    const weeksData = policies[key as keyof typeof policies];
    const changedWeeks = policiesModifiedByWeek[key];
    const channelsData: IChannelsCompliance = {};
    changedWeeks.forEach((channel) => {
      if (weeksData?.[channel as keyof typeof weeksData])
        channelsData[channel as keyof typeof channelsData] =
          weeksData?.[channel as keyof typeof weeksData];
    });
    if (!isObjectEmpty(channelsData)) {
      newApiData[key] = channelsData;
    }
  });
  return newApiData;
};

export const updateNestedProperty = (
  obj: object | number | string,
  key: string,
  value: number | string | undefined
) => {
  const keys = key.split('.');
  let currentObject = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const currentKey = keys[i];
    const key = currentKey as keyof typeof currentObject;
    if (!currentObject[key] || typeof currentObject[key] !== 'object') {
      currentObject[key] = {} as never;
    }

    currentObject = currentObject[key];
  }
  currentObject[keys[keys.length - 1] as keyof typeof currentObject] =
    value as never;

  return typeof obj === 'object' ? dereferenceObject(obj) : obj;
};

export const modifyPolicyStructure = <T>(data: T) => {
  if (!data) return {};
  const newData = dereferenceObject(data);
  const weeksArray: Array<string> = Object.keys(Weeks);
  Object.keys(newData).forEach((key: string) => {
    if (!weeksArray.includes(key)) {
      delete newData[key as keyof typeof newData];
    }
  });
  return newData;
};
export const convertTimeTo12hrs = (time: string) => {
  if (!time) return '-';
  return dayjs(time, 'HH:mm:ss').format('hh:mm A');
};

export const getMinMessage = (data: IRange[], index: number) => {
  const currentData = data[index];
  const prevData = data[index - 1];
  if (!currentData.minValue) {
    return 'Please enter min value';
  }
  if (prevData && (currentData.minValue || 0) <= (prevData.maxValue || 0)) {
    return `Min should be greater than ${prevData.maxValue}`;
  }
  if (
    currentData?.maxValue &&
    (currentData.minValue || 0) >= (currentData.maxValue || 0)
  ) {
    return `Min should be less than ${currentData.maxValue}`;
  }
  return '';
};

export const getMaxMessage = (data: IRange[], index: number) => {
  const currentData = data[index];
  const prevData = data[index - 1];
  const nextData = data[index + 1];
  if (!currentData.maxValue) {
    return 'Please enter max value';
  }
  if (nextData && (currentData.maxValue || 0) >= (nextData.minValue || 0)) {
    return `Max should be less than ${nextData.minValue}`;
  }
  if (
    prevData?.maxValue &&
    (currentData.maxValue || 0) <= (currentData.minValue || 0)
  ) {
    return `Max should be greater than ${prevData?.maxValue}`;
  }
  return '';
};

export const checkIsRangeDataValid = (data: IRange[], index: number) => {
  const isValid = data.every(
    (item) => item.label && item.label?.trim() && item.minValue && item.maxValue
  );
  if (isValid) {
    const currentData = data[index];
    const prevData = data[index - 1];
    const nextData = data[index + 1];
    if (prevData && (currentData.minValue || 0) <= (prevData.maxValue || 0)) {
      return false;
    }
    if (nextData && (currentData.maxValue || 0) >= (nextData.minValue || 0)) {
      return false;
    }
    if ((currentData.minValue || 0) >= (currentData.maxValue || 0)) {
      return false;
    }
  }
  return isValid;
};

export const derivedFieldValidator = (
  operator: string,
  dataToValidate: IDerivedNewField
) => {
  if (!operator) return false;
  const validationRules = getDerivedFieldValidationRules(operator);
  for (const key in validationRules) {
    const rule = validationRules[key as keyof typeof validationRules];
    const data = dataToValidate[key as keyof typeof dataToValidate];
    if (rule.required) {
      if (data === '') {
        return false;
      }
    }
    if ('min' in rule) {
      if (Array.isArray(data)) {
        if (data.length < rule.min) return false;
        if (!data.every((item) => (item as string).trim())) return false;
      }
    }
  }
  if (operator === 'range') {
    const rangeData = dataToValidate.values as IRange[];
    if (rangeData.length === 0) return false;
    if (
      !rangeData.every(
        (item: IRange) =>
          item.label && item.label?.trim() && item.minValue && item.maxValue
      )
    )
      return false;
  }
  return true;
};

export const getDerivedFieldData = (data: IDerivedNewField, path: string) => {
  const dataToFetch = dereferenceObject(data);
  if (path.startsWith('[')) {
    const validPath = path.replace('[', '').replace(']', '').split(',');
    const dataArray = dataToFetch[validPath[0] as keyof typeof dataToFetch];
    return dataArray[validPath[1] as keyof typeof dataArray];
  }
  return dataToFetch[path as keyof typeof dataToFetch];
};

export const updateDerivedFieldData = (
  data: IDerivedNewField,
  path: string,
  value: string
) => {
  const dataToModify = dereferenceObject(data);
  if (path.startsWith('[')) {
    const validPath = path.replace('[', '').replace(']', '').split(',');
    const dataArray = dataToModify[validPath[0] as keyof typeof dataToModify];
    dataArray[Number(validPath[1])] = value;
    dataToModify[validPath[0] as keyof typeof dataToModify] =
      dataArray as string[];
  } else dataToModify[path as keyof typeof dataToModify] = value as string;
  return dataToModify;
};

export const modifyPolicyData = (data: IChannelsCompliance | undefined) => {
  if (!data) return [];
  const keys = Object.keys(data);
  return keys.map((key) => {
    return {
      channel: key,
      ...data[key as keyof typeof data],
    };
  });
};

export const addIsActiveAndIsDefault = (
  data: IWeekCompliance,
  channels: IChannelPreferenceData[],
  isDataEmpty: boolean,
  showPolicyStatus: boolean
) => {
  const updatedData = dereferenceObject(data);
  const channelsData: Array<string> = [];
  channels.forEach((channel) => {
    if (channel.enabled) {
      channelsData.push(channel.channelProductName);
    }
  });
  Object.keys(updatedData).forEach((week) => {
    const weekData = updatedData[week as keyof typeof updatedData];
    Object.keys(weekData).forEach((channel) => {
      weekData[channel as keyof typeof weekData].isActive =
        channelsData.includes(channel);
      if (isDataEmpty && showPolicyStatus) {
        weekData[channel as keyof typeof weekData].isDefault = true;
      }
    });
  });
  return updatedData;
};
export const getDerivedFieldDataType = (
  data: IDerivedNewField,
  loadBookFieldsData: ILMSField[]
) => {
  if (
    ['ratio', 'round_off', 'round_up', 'round_down', 'range'].includes(
      data.operation
    )
  ) {
    return 'number';
  }
  const dataTypes: string[] = [];
  loadBookFieldsData.forEach((field) => {
    if (data.fields.includes(field.completeJsonPath)) {
      dataTypes.push(field.dataType);
    }
  });
  let isDataTypeSame = true;
  for (let i = 0; i < dataTypes.length; i++) {
    if (dataTypes[i] !== dataTypes[0]) {
      isDataTypeSame = false;
      break;
    }
  }
  if (isDataTypeSame) {
    return dataTypes[0];
  } else {
    return 'string';
  }
};

export const modifyDerivedFieldsToLmsData = (
  derivedFields: IDerivedField[]
) => {
  if (derivedFields.length === 0) return [];
  return derivedFields.map((field: IDerivedField) => {
    const { newFields, id, dataType, enabled } = field;
    const name = newFields.reduce((acc, curr) => {
      return acc + curr;
    }, '');
    return {
      id,
      displayName: name,
      completeJsonPath: name,
      dataType,
      isDerived: true,
      enabled,
    };
  });
};

export const getLogicText = (logic: string, values: Array<string | number>) => {
  if (logic === 'range') {
    return `in between [${values[0]} to ${values[1]}]`;
  }
  return `${logic} ${values.join(', ')}`;
};

export const getMinMaxTime = (data: IDateData) => {
  const dates = Object.keys(data);
  let min = Infinity;
  let max = -Infinity;
  for (const date of dates) {
    const dateData = data[date];
    const timeSlots = Object.keys(dateData);
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    timeSlots.forEach((time) => {
      const slot = parseInt(time);
      if (slot < min) {
        min = slot;
      }
      if (slot > max) {
        max = slot;
      }
    });
  }
  if (min === Infinity) {
    min = 9;
  }
  if (max === -Infinity) {
    max = 17;
  }
  if (max - min < 6) {
    while (max - min < 6) {
      if (max < 23) {
        max++;
        continue;
      }
      if (min > 0) {
        min--;
      }
    }
  }
  return {
    min: min.toString().padStart(2, '0'),
    max: max.toString().padStart(2, '0'),
  };
};

const flattenOutReachMap = (outReachMap: any) => {
  const calenderData: IDateData = {};
  let earlySlot = `08`;
  Object.values(outReachMap).forEach((outReach: any) => {
    const date: Date = outReach.dateOfExecution;
    const slot = `${String(date.getHours()).padStart(2, '0')}:${String(
      date.getMinutes()
    ).padStart(2, '0')}`;
    const splitSlot = slot.split(':');
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const dateData = dereferenceObject(calenderData[formattedDate] || {});
    if (parseInt(splitSlot[0]) < parseInt(earlySlot)) {
      earlySlot = splitSlot[0];
    }
    if (isObjectEmpty(dateData)) {
      dateData[splitSlot[0]] = [
        {
          slot,
          contentName: outReach.content,
          conditionRule: outReach.outReachCondition,
          isStrategy: outReach.isStrategy,
          campaign: {
            channel: outReach.channel,
            language: outReach.language,
          },
        },
      ];
    } else {
      const slotData = dateData[splitSlot[0]] || [];
      slotData.push({
        slot,
        contentName: outReach.content,
        conditionRule: outReach.outReachCondition,
        isStrategy: outReach.isStrategy,
        campaign: {
          channel: outReach.channel,
          language: outReach.language,
        },
      });
      dateData[splitSlot[0]] = slotData;
    }
    calenderData[formattedDate] = dateData;
  });
  return { calenderData, earlySlot };
};

const addParentToOutreaches = (
  outReachMap: any,
  parentOutReaches: Array<string>
) => {
  while (Object.keys(outReachMap).length !== parentOutReaches.length) {
    Object.keys(outReachMap).forEach((outReachId) => {
      if (parentOutReaches.indexOf(outReachId) === -1) {
        const outReachData = outReachMap[outReachId];
        const parentData = outReachMap[outReachData.parentOutReachId];
        if (parentData) {
          const date: Date = new Date(parentData.dateOfExecution);
          const slot = outReachData.slot.split(':');
          const newDate = new Date(
            date.setDate(date.getDate() + parseInt(outReachData.day || 0))
          );
          newDate.setHours(parseInt(slot[0]));
          newDate.setMinutes(parseInt(slot[1]));

          outReachMap[outReachId] = {
            ...outReachData,
            dateOfExecution: newDate,
          };
          parentOutReaches.push(outReachId);
        }
      }
    });
  }
  return outReachMap;
};

interface IOutReact {
  conditionRule: {
    ruleName: string;
    conditionsCount: number;
    groupsCount: number;
  };
}

const processPlans = (
  plans: Plan[],
  outReachMap: any,
  parentOutReaches: Array<string>,
  currentDate: string,
  strategyId?: string
) => {
  plans.forEach((plan) => {
    plan.planConditionoutreachList.forEach((planCondition) => {
      const currentNode = planCondition.conditionOutreach.currentNode;
      const parentOutReachId = planCondition.parentOutreachId;
      const isParent = parentOutReachId === -1;
      const outReachCondition = (
        planCondition.conditionOutreach as unknown as IOutReact
      ).conditionRule;

      const ruleData = !isObjectEmpty(outReachCondition)
        ? {
            ruleName: outReachCondition?.ruleName,
            conditionsCount: outReachCondition?.conditionsCount,
            groupsCount: outReachCondition?.groupsCount,
          }
        : null;

      const parentHash = planCondition.conditionOutreach.parentNode;
      const newParentOutReachId = !isParent
        ? strategyId
          ? `${parentHash}-${parentOutReachId}-${strategyId}`
          : `${parentHash}-${parentOutReachId}`
        : '-1';
      planCondition.conditionOutreach.outreach.forEach((outreach) => {
        let outreachId = `${currentNode}-${outreach.outreachId}`;
        if (strategyId) {
          outreachId = `${outreachId}-${strategyId}`;
        }
        outReachMap[`${outreachId}`] = {
          ...outreach,
          outreachId,
          isStrategy: !!strategyId,
          dateOfExecution: isParent
            ? new Date(`${currentDate} ${outreach.slot}`)
            : null,
          outReachCondition: ruleData || undefined,
          parentOutReachId: newParentOutReachId,
        };
        if (isParent) {
          parentOutReaches.push(outreachId);
        }
      });
    });
  });
  return { outReachMap, parentOutReaches };
};

export const convertCampaignIntoCalenderData = (
  data: SegmentDateMappings[],
  strategyList?: IStrategyRequest[]
): { data: IDateData; strategyData: any } => {
  let outReachMap: any = {};
  let parentOutReaches: Array<string> = [];
  const strategies: any = {};
  const strategyData: IStrategyData[] = [];
  data.forEach((campaign) => {
    const {
      plans,
      strategy,
      campaignDate: { currentDate },
    } = campaign;

    const strategyId = strategy?.strategyId
      ? `${strategy.strategyId}-${currentDate}`
      : undefined;

    if (!isObjectEmpty(strategy) && strategyId && strategy?.strategyId) {
      if (isObjectEmpty(strategies[strategyId])) {
        strategies[`${strategyId}`] = {
          ...strategy,
          dateOfExecution: new Date(`${currentDate} 00:00`),
        };
      } else {
        const currentDateOfExecution = strategies[strategyId].dateOfExecution;
        const newDateOfExecution = new Date(`${currentDate} 00:00`);
        strategies[strategyId].dateOfExecution =
          currentDateOfExecution > newDateOfExecution
            ? newDateOfExecution
            : currentDateOfExecution;
      }
    }
    const {
      outReachMap: newOutReachMap,
      parentOutReaches: newParentOutReaches,
    } = processPlans(plans, outReachMap, parentOutReaches, currentDate);
    outReachMap = newOutReachMap;
    parentOutReaches = newParentOutReaches;
  });

  Object.keys(strategies).forEach((strategyId) => {
    let strategy = strategies[strategyId];
    const { dateOfExecution } = strategy;
    const currentStrategyData: IStrategyData = {
      name: strategy.strategyName,
      noOfOutReaches: strategy.totalNoOfOutreaches,
      noOfDays: strategy.daysCount,
      startDate: dateOfExecution,
      endDate: new Date(
        new Date(dateOfExecution).setDate(
          dateOfExecution.getDate() + parseInt(strategy.daysCount) - 1
        )
      ),
    };
    strategyData.push(currentStrategyData);
    if ((strategy.days?.length || 0) === 0) {
      const strategyIdToSearch = strategyId.split('-')[0];
      const strategyDataFromList = strategyList?.find(
        (strategyData) => strategyData.strategyId === strategyIdToSearch
      );
      if (strategyDataFromList) {
        strategy = {
          ...strategy,
          days: strategyDataFromList.strategyData.days,
        };
      }
    }
    strategy.days?.forEach((dayData: any) => {
      const { current, plans } = dayData;
      const newDate = new Date(
        dateOfExecution.setDate(
          dateOfExecution.getDate() + parseInt(current || 0)
        )
      );

      const formattedDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')}`;

      const {
        outReachMap: newOutReachMap,
        parentOutReaches: newParentOutReaches,
      } = processPlans(
        plans,
        outReachMap,
        parentOutReaches,
        formattedDate,
        strategyId
      );
      outReachMap = newOutReachMap;
      parentOutReaches = newParentOutReaches;
    });
  });

  outReachMap = addParentToOutreaches(outReachMap, parentOutReaches);
  const { calenderData, earlySlot } = flattenOutReachMap(outReachMap);
  return { data: calenderData, strategyData };
};

function formatDateToYYYYMMDD(date: Date): string {
  const year: number = date.getFullYear();
  const month: string = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
  const day: string = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const handleStrategyData = (strategyData: any, startDate: Date) => {
  let outReachMap: any = {};
  let parentOutReaches: Array<string> = [];
  strategyData.days.map((dayData: any) => {
    const { plans, currentDay } = dayData;
    const newDate = new Date(
      startDate.setDate(startDate.getDate() + currentDay - 1)
    );
    const formattedDate = formatDateToYYYYMMDD(newDate);
    const {
      outReachMap: newOutReachMap,
      parentOutReaches: newParentOutreaches,
    } = processPlans(plans, outReachMap, parentOutReaches, formattedDate);
    outReachMap = newOutReachMap;
    parentOutReaches = newParentOutreaches;
    return '';
  });
  outReachMap = addParentToOutreaches(outReachMap, parentOutReaches);
  const { calenderData, earlySlot } = flattenOutReachMap(outReachMap);
  return calenderData;
};

export const getColorCodeForChannel = (channel: string) => {
  const key = channel.toLowerCase();
  const channelColorCodes = {
    sms: '#FD7149',
    ivr: '#4065C5',
    conversation_bot: '#163A6B',
    whatsApp: '#70CD59',
    'field+': '#ACAC2A',
    'call+': '#CD3546',
  };
  return channelColorCodes[key as keyof typeof channelColorCodes] || '#4065C5';
};

export const getTimeText = (time: number) => {
  if (time < 12) return `${time} AM`;
  else if (time === 12) return `${time} PM`;
  return `${time - 12} PM`;
};

export const getDateDifferenceInDays = (
  startDate: Date,
  endDate: Date
): number => {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds
  const diffInTime = endDate.getTime() - startDate.getTime();

  // Convert the difference from milliseconds to days
  const diffInDays = diffInTime / (1000 * 3600 * 24);

  return diffInDays;
};

export const isTimeInThePastAsOfTimeZone = (
  date: string,
  hours: string,
  minutes: string,
  timeZone: string
) => {
  const selectedTime1 = moment
    .tz(`${date} ${hours}:${minutes}`, 'YYYY-MM-DD HH:mm', timeZone)
    .startOf('minute');
  const currentTime = moment.tz(timeZone).startOf('minute');

  return selectedTime1.isBefore(currentTime);
};
