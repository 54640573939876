import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getDerivedFieldsRequested,
  getDerivedFieldsRequestFailed,
  getDerivedFieldsRequestSucceeded,
} from 'redux/slice/settings/LoanBook/GetDerivedFileds.slice';
import { settingsEndpoints } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';

function* getDerivedFieldsSaga() {
  try {
    const response: AxiosResponse<any> = yield HTTPClient.GetRequest(
      settingsEndpoints.GET_DERIVED_FIELDS
    );
    if (response.data.success) {
      yield put(getDerivedFieldsRequestSucceeded(response.data.data));
    } else {
      throw new Error('Error');
    }
  } catch (err) {
    yield put(
      getDerivedFieldsRequestFailed({ error: { message: 'Error' } } as any)
    );
  }
}

export function* watchGetDerivedFieldsSaga() {
  yield takeLatest(getDerivedFieldsRequested.type, getDerivedFieldsSaga);
}
