import { put, takeLatest } from 'redux-saga/effects';
import { userEndpoints } from '../../../services/api/endPoints';
import {
  fetchUserEntitiesSucceeded,
  fetchUserEntitiesFailed,
  fetchUserEntities,
} from '../../../redux/slice/user/UserEntities.slice';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { IFetchUserEntitiesResponse, IUserDataProps } from './User.types';
import { groupCollectionAgency } from 'constants/constant';

// ... Other imports ...

function getUserEntitiesWithLenderGroup(userData: IUserDataProps) {
  const userEntitiesWithLenderGroup = userData?.user_entities?.filter(
    (entity) => {
      return entity?.groups?.some((group) =>
        ['lender', groupCollectionAgency].includes(group?.group_code)
      );
    }
  );

  return userEntitiesWithLenderGroup;
}
export function* fetchUserEntitiesSaga() {
  try {
    const result: IFetchUserEntitiesResponse = yield HTTPClient.GetRequest(
      userEndpoints.GET_USER_ENTITIES
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.user) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { name, email, ca_user_id, default_entity } = result?.data?.user;

      // For now we are processing only lender entities
      yield put(
        fetchUserEntitiesSucceeded({
          name,
          email,
          ca_user_id,
          default_entity,
          user_entities: getUserEntitiesWithLenderGroup(result?.data?.user),
          timeZone: result?.data?.timeZone,
        })
      );
    } else {
      yield put(
        fetchUserEntitiesFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchUserEntitiesFailed({
        error: {
          message: 'An error occurred while fetching user entities.',
        },
      })
    );
  }
}

export function* watchUserEntitiesSaga() {
  yield takeLatest(fetchUserEntities.type, fetchUserEntitiesSaga);
}
