import { TextDirection } from '@yubi/phoenix-provider';
import { Input } from '@yubi/yb-core-input-v3';
import * as React from 'react';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';

type InputSize = 's' | 'm' | 'l';
interface PrefixSuffixList {
  label: string;
  value: string;
}
interface PrefixSuffix {
  type: 'text' | 'list' | 'icon';
  width?: number;
  iconName?: string;
  text?: string;
  selectedList?: PrefixSuffixList;
  onListChange?: (listItem: PrefixSuffixList) => void;
  list?: PrefixSuffixList[];
}
interface InputProps {
  testId?: string;
  value: string | undefined;
  label?: string;
  caption?: string;
  placeholder?: string;
  required?: boolean;
  leftIcon?: string;
  onLeftIconClick?: () => void;
  rightIcon?: string;
  showClearIcon?: boolean;
  onRightIconClick?: () => void;
  disabled?: boolean;
  size?: InputSize;
  prefix?: PrefixSuffix;
  suffix?: PrefixSuffix;
  order?: number;
  error?: string;
  readonly?: boolean;
  isPasswordText?: boolean;
  isNumeric?: boolean;
  decimals?: number;
  direction?: TextDirection;
  onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  onChangeText?: (text: string, formattedText: string | number) => void;
}

const InputViewV3 = (props: InputProps) => {
  return <Input {...props} />;
};

export default InputViewV3;
