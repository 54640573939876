interface CacheEntry<T> {
  data: T;
  expirationTime?: number;
}

const SESSION_STORAGE_KEY_PREFIX = 'my_app_session_cache_';

const sessionStorageService = {
  setItem<T>(key: string, value: T, expirationMS?: number): void {
    const entry: CacheEntry<T> = { data: value };

    if (expirationMS) {
      entry.expirationTime = Date.now() + expirationMS;
    }

    sessionStorage.setItem(
      SESSION_STORAGE_KEY_PREFIX + key,
      JSON.stringify(entry)
    );
  },

  getItem<T>(key: string): T | null {
    const item = sessionStorage.getItem(SESSION_STORAGE_KEY_PREFIX + key);

    if (item) {
      const entry: CacheEntry<T> = JSON.parse(item);

      if (!entry.expirationTime || entry.expirationTime > Date.now()) {
        return entry.data;
      } else {
        this.removeItem(key); // Remove expired data from session storage
      }
    }

    return null;
  },

  removeItem(key: string): void {
    sessionStorage.removeItem(SESSION_STORAGE_KEY_PREFIX + key);
  },

  clear(): void {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.startsWith(SESSION_STORAGE_KEY_PREFIX)) {
        sessionStorage.removeItem(key);
      }
    }
  },
  clearSessionStorage(): void {
    sessionStorage.clear();
  },
};

export default sessionStorageService;
