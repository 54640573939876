import { ConditionType, DispositionType, ICondition } from './contract';

export const verifyCondition = (condition: ICondition): boolean => {
  const { conditionOption, mainDisposition, dispositionType } = condition;

  if (
    !(
      conditionOption === ConditionType.Include ||
      conditionOption === ConditionType.Exclude
    )
  ) {
    return false;
  }
  if (!mainDisposition) {
    return false;
  }

  if (
    !(
      dispositionType === DispositionType.Best ||
      dispositionType === DispositionType.Latest
    )
  ) {
    return false;
  }
  return true;
};

export const conditionOptions = [
  {
    label: 'And',
    value: 'AND',
  },
  {
    label: 'Or',
    value: 'OR',
  },
];

export const getSelectedConditionOption = (value?: string) => {
  if (!value) return [];
  const selectedOption = conditionOptions.find((item) => item.value === value);
  if (!selectedOption) return [];
  return [selectedOption];
};

export const convertFirstLetterToCapital = (str: string) => {
  return str
    .split(' ') // Split the sentence into words
    .map((word) => {
      // Capitalize the first letter and make the rest of the word lowercase
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' '); // Join the words back into a sentence
};
