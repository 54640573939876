import { createSlice } from '@reduxjs/toolkit';
import { IDerivedFieldsState } from 'redux/types/LoanBook.types';
import { APIStatus } from 'services/api/apiStatusCode';

export const derivedFieldsInitialState: IDerivedFieldsState = {
  status: APIStatus.INIT,
};

const getDerivedFieldsSlice = createSlice({
  name: 'derivedFields',
  initialState: derivedFieldsInitialState,
  reducers: {
    getDerivedFieldsRequested(state) {
      state.status = APIStatus.PENDING;
    },
    getDerivedFieldsRequestSucceeded(state, action) {
      state.status = APIStatus.SUCCESS;
      state.data = action.payload;
    },
    getDerivedFieldsRequestFailed(state, action) {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
  },
});

export const {
  getDerivedFieldsRequested,
  getDerivedFieldsRequestSucceeded,
  getDerivedFieldsRequestFailed,
} = getDerivedFieldsSlice.actions;

export default getDerivedFieldsSlice.reducer;
