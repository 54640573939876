import { AuthService, MFAService } from '@yubi/yb-module-auth';
import { IDerivedField } from 'features/CreateConfig/CreateConfig.types';
import { IConditionsLogic } from 'redux/types/GetAllocationConfigList.type';
import { Area, Action } from 'rbac/permissions';
import { IOffsetPagination, IPagination } from 'types';
import {
  FilterListType,
  ISliderData,
} from 'components/FilterViewAdvanceV2/filterTyes';
import { FiltersType2 } from 'features/Allocation/AllocationListTable/AllocatedListTable';
import _ from 'lodash';

export const getTokens = () => {
  const accessToken = AuthService.getAccessToken();
  const mfaToken = MFAService.getMFAToken();

  if (mfaToken && accessToken) {
    return { accessToken, mfaToken };
  }

  throw new Error('Error while fetching error');
};

export const isObjectEmpty = (obj: object | undefined): boolean => {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
};

export const getSelectedValues = (
  dataCategories: IDerivedField[]
): IConditionsLogic[] => {
  return dataCategories
    ?.filter((category) => !!category?.selectedValue)
    .map((category) => category?.selectedValue as IConditionsLogic);
};

export const createAllocationFormula1 = (dataCategories: IDerivedField[]) => {
  // Handle allocation formula
  const result: any = [];

  getSelectedValues(dataCategories)?.forEach((selectedValue) => {
    let { comparison, completeJsonPath, values, displayName } =
      selectedValue || {};
    if (comparison && completeJsonPath && values && values?.length > 0) {
      if (comparison === 'in' && values.length < 2) {
        comparison = '=';
      }

      result.push({ fieldName: displayName, comparison, values });
    }
  });

  return result;
};

export function capitalizeEachWord(str: string) {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
export const convertDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
};
export const createAllocationFormulaForConfig = (
  conditionLogic: IConditionsLogic[]
) => {
  const formula: string[] = [];
  conditionLogic?.forEach((condition) => {
    let { values, comparison, displayName } = condition;
    if (comparison === 'in' && values && values.length < 2) {
      comparison = '=';
    }
    const value = values?.join(',');
    formula.push(`${displayName} ${comparison} ${value}`);
  });
  return formula.join('\n');
};

export const createAllocationFormulaForConfig1 = (
  conditionLogic: IConditionsLogic[]
) => {
  const result: any = [];
  conditionLogic?.forEach((condition) => {
    let {
      values,
      comparison,
      completeJsonPath,
      displayName,
      isDefaultSegment,
    } = condition;
    if (comparison && completeJsonPath && values && values?.length > 0) {
      if (comparison === 'in' && values && values.length < 2) {
        comparison = '=';
      }
      result.push({
        fieldName: displayName,
        comparison,
        values,
        isDefaultSegment,
      });
    }
  });

  return result;
};

export const calculatePagination = (
  pageData: IOffsetPagination
): IPagination => {
  const { offset, limit, onPaginationChange, totalElements } = pageData;
  const totalPages = Math.ceil(totalElements / limit);
  const pageNumber = Math.floor(offset / limit);
  return {
    pageNumber,
    pageSize: limit,
    totalElements,
    totalPages,
    onPaginationChange,
  };
};

export function formatDate(
  inputDate: string | number,
  hideTime?: boolean
): string {
  // Parse input date string into a Date object
  if (!inputDate) {
    return '-';
  }

  const dateObj = new Date(inputDate);

  // Check if the parsed date is valid
  if (isNaN(dateObj.getTime())) {
    return '-';
  }

  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.toLocaleString('default', { month: 'short' }));
  const year = dateObj.getFullYear();
  if (hideTime) {
    return `${day}/${month}/${year}`;
  } else {
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  }
}

export function filterFieldsByDisplayName(
  fields: IDerivedField[],
  regexPattern: string
): IDerivedField[] {
  const regex = new RegExp(regexPattern, 'i');
  return fields.filter((field) => regex.test(field.displayName));
}
export const isPermissionEnabled = (
  userPermissions: { [k in Area]: Action[] } | undefined,
  permission: string | string[]
) => {
  if (!userPermissions) return false;

  const permissionsToCheck = Array.isArray(permission)
    ? permission
    : [permission];

  const keys = Object.keys(userPermissions);

  return permissionsToCheck.some((perm) =>
    keys.some((key) =>
      userPermissions[key as keyof typeof userPermissions].includes(perm)
    )
  );
};

export const getMenu = <T>(
  userPermissions: { [k in Area]: Action[] } | undefined,
  menu: T[],
  isUserOnboard = true
) => {
  const newMenu: T[] = [];
  menu.forEach((menuItem: T) => {
    let modifiedMenuItem = menuItem;
    let containsValidPermissions = isUserOnboard || false;
    if (menuItem['permissions' as keyof typeof menuItem] && isUserOnboard) {
      const permissions = menuItem[
        'permissions' as keyof typeof menuItem
      ] as Array<string>;
      containsValidPermissions = permissions.some((permission: string) =>
        isPermissionEnabled(userPermissions, permission)
      );
    }
    if (
      menuItem['children' as keyof typeof menuItem] &&
      Array.isArray(menuItem['children' as keyof typeof menuItem]) &&
      isUserOnboard
    ) {
      const children = getMenu(
        userPermissions,
        menuItem['children' as keyof typeof menuItem] as T[],
        isUserOnboard
      );
      if (children.length > 0) {
        modifiedMenuItem = { ...modifiedMenuItem, children };
      } else {
        containsValidPermissions = false;
      }
    }
    if (containsValidPermissions) newMenu.push(modifiedMenuItem);
  });
  return newMenu;
};

export const getFieldText = (operator: string, value: Array<string>) => {
  if (operator === '=') {
    return value[0];
  }
  if (operator === '>' || operator === '<') {
    return `${operator} ${value[0]}`;
  }
  return value.join(' - ');
};

export function toTitleCase(input: string): string {
  return input
    .split(' ') // Split the string by space to work on individual words
    .map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of each word
    )
    .join(' '); // Rejoin the words back into a single string
}

export function deepEqual(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!deepEqual(val1, val2)) {
        return false;
      }
    } else {
      if (val1 !== val2) {
        return false;
      }
    }
  }

  return true;
}

export function convertEpochToHumanReadable(epoch: number) {
  const date = new Date(epoch * 1000); // Convert seconds to milliseconds
  return date.toLocaleString(); // Convert date to local time string
}

export function capitalizeFirstLetter(str: string) {
  // Ensure str is not empty
  if (!str) return str;

  // Capitalize the first letter and convert the rest to lowercase
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// export function getEnumValuesFromKeys(
//   fieldName: keyof typeof ALLOCATION_ENUM_OPTIONS,
//   keys: string[]
// ): string[] {
//   const data = ALLOCATION_ENUM_OPTIONS[fieldName];
//   if (!data) return keys;

//   const matchedValues = keys.map((key) => {
//     const matchedValue = data[key];
//     return matchedValue;
//   });

//   return matchedValues.filter((keys) => !!keys) as string[];
// }

// export function getEnumKeysFromData(
//   fieldName: keyof typeof ALLOCATION_ENUM_OPTIONS,
//   values: string[]
// ) {
//   const data = ALLOCATION_ENUM_OPTIONS[fieldName];
//   if (!data) return values;

//   const matchedKeys = values.map((value) => {
//     const matchedKey = Object.keys(data).find((key) => data[key] === value);
//     return matchedKey;
//   });

//   return matchedKeys.filter((keys) => !!keys) as string[];
// }

export const getSelectedValuesForSubmit = (
  dataCategories: IDerivedField[]
): IConditionsLogic[] => {
  const result = dataCategories
    ?.filter(
      (category) =>
        !!category?.selectedValue && category?.selectedValue?.values?.length > 0
    )
    .map((category) => category?.selectedValue as IConditionsLogic);

  return result.map((category) => {
    return {
      ...category,
      values: (category.values || []).map((value) => value.toLowerCase()),
    };
  });
};

export const createAllocationFormula = (dataCategories: IDerivedField[]) => {
  // Handle allocation formula
  const result: string[] = [];

  getSelectedValues(dataCategories)?.forEach((selectedValue) => {
    let { comparison, values, displayName, completeJsonPath } =
      selectedValue || {};

    if (comparison && completeJsonPath && values && values?.length > 0) {
      const value = values;

      if (comparison === 'in' && value.length > 2) {
        comparison = '=';
      }

      result.push(`${displayName} ${comparison} ${value?.join(',')}`);
    }
  });

  return result.join(' | ');
};

export function toSentenceCase(input: string): string {
  if (!input) return '';

  // Convert the first character to uppercase
  const firstChar = input.charAt(0).toUpperCase();

  // Convert the rest of the characters to lowercase
  const restOfString = input.slice(1).toLowerCase();

  // Combine the first character with the rest of the string
  return firstChar + restOfString;
}

export function roundToTwoDecimalPlaces(num: string | number) {
  return parseFloat(Number(num).toFixed(2));
}

export function parseAxiosError(axiosError: any) {
  const parsedError = {
    message: axiosError.message,
    name: axiosError.name,
    code: axiosError.code,
    requestConfig: {
      url: axiosError.config?.url,
      method: axiosError.config?.method,
      headers: axiosError.config?.headers,
      data: axiosError.config?.data,
    },
    response: {
      data: axiosError.response?.data,
      status: axiosError.response?.status,
      statusText: axiosError.response?.statusText,
      headers: axiosError.response?.headers,
    },
    stack: axiosError.stack,
  };

  return parsedError;
}

export function pluralize(count: number, word: string) {
  if (count === 1) return `${count} ${word}`;

  return `${count} ${word}s`;
}

export const generateTime = () => {
  const responseArray: Array<string> = [];
  Array(24)
    .fill(0)
    .map((_, index) => {
      const prefix = index <= 9 ? `0${index}` : `${index}`;
      if (index !== 0) responseArray.push(prefix + ':00');
      responseArray.push(prefix + ':15');
      responseArray.push(prefix + ':30');
      responseArray.push(prefix + ':45');
      return '';
    });
  return responseArray;
};
export function convertDateRange(startDateStr: string, endDateStr: string) {
  // Parse the dates
  if (!startDateStr || !endDateStr) return '';
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Extract day and month parts
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const month = startDate.toLocaleString('en-us', { month: 'short' }); // 'Mar'
  const year = startDate.getFullYear();

  // Format the new date range string
  return `${startDay}-${endDay} ${month} ${year}`;
}

export function truncateString(
  str: string | undefined,
  maxLength: number
): string {
  if (!str) {
    return '';
  }
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...';
  } else {
    return str;
  }
}

export const getFilterRequestPayload = (
  filterState: Record<string, ISliderData | string[]>,
  fetchedFilterData: FiltersType2[]
) => {
  const filterKeys = Object.keys(filterState);
  const filterRequestPayload = fetchedFilterData
    .filter((filter) => {
      if (filterKeys.some((key) => key === filter.name)) {
        return true;
      }
      return false;
    })
    .map((filter) => {
      const filterName = filterKeys.find((key) => key === filter.name);
      return {
        ...filter,
        data: filterState[filterName as keyof typeof filterState],
      };
    });
  return filterRequestPayload;
};

export const generateFilterList = (
  data: string[] | ISliderData | number[]
): FilterListType | ISliderData => {
  if (Array.isArray(data)) {
    return data.reduce((acc, val, index) => ({ ...acc, [index + 1]: val }), {});
  } else {
    return data;
  }
};

export const isDateInPast = (inputDate: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const givenDate = new Date(inputDate);
  givenDate.setHours(0, 0, 0, 0);

  return givenDate < today;
};

export const formatIndianCurrency = (amount: number) => {
  return amount.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createDebouncedFunction = (callback: (...args: any[]) => void) => {
  return _.debounce(callback, 1000);
};
