import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Avatar from '@yubi/yb-core-avatar';
import { useStyles } from '@yubi/yb-style-provider';

declare type Type =
  | 'initial'
  | 'initial-title-caption'
  | 'image'
  | 'image-title-caption';
declare type Size = 'tiny' | 'xs' | 's' | 'm' | 'l' | 'xl';
interface IAvatarViewProps {
  testID?: string;
  avatarType?: Type;
  title?: string;
  caption?: string;
}

const AvatarView = ({
  avatarType = 'image',
  testID = 'avatar-view-testid',
  title = '',
  caption = '',
}: IAvatarViewProps) => {
  const { styleConnector } = useStyles();

  return (
    <Avatar
      styleConnector={styleConnector}
      avatarType={avatarType}
      testID={testID}
      title={title}
      caption={caption}
    ></Avatar>
  );
};

export default AvatarView;
