import { ButtonView, ModalView, TabsView, TextView } from 'components';
import useChannelDetails from 'hooks/useChannelDetails';
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  onTemplateTypeChange,
  resetEmailContent,
  updateEmailContentFromBasicToHtml,
} from 'redux/content/content.slice';

const tableTabs: React.ComponentProps<typeof TabsView>['tabItems'] = [
  {
    key: 'Basic',
    title: 'Basic',
    children: '',
  },
  {
    key: 'HTML',
    title: 'HTML',
    children: '',
  },
];
const HtmlSwitchForEmail = ({ index }: { index: number }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const { channel } = useChannelDetails();
  const [activeTab, setActiveTab] = React.useState('Basic');
  const dispatch = useDispatch();

  if (channel?.toLowerCase() !== 'email') return null;

  return (
    <>
      <div>
        <TabsView
          activeKey={activeTab}
          tabItems={tableTabs}
          onTabClick={(tab) => {
            if (tab === 'Basic') {
              return setIsModalVisible(true);
            }
            dispatch(
              onTemplateTypeChange({
                templateType: 'HTML',
                contentIndex: index,
              })
            );
            dispatch(updateEmailContentFromBasicToHtml());
            setActiveTab(tab);
          }}
        />
      </div>
      <ModalView
        modalContent={
          <div className="flex flex-col items-center pt-[36px] gap-4">
            <div className=" flex flex-col items-center gap-2">
              <TextView
                color="#101828"
                weight="700"
                size={16}
                fontStyle="normal"
                lineHeight={24}
                text={'Switch to Basic?'}
              />
              <TextView
                color="#475467"
                weight="400"
                size={14}
                fontStyle="normal"
                lineHeight={20}
                text="Your current updates will not be saved if you choose Basic. Switch anyway?"
              />
            </div>
            <div className="flex gap-2 px-6 items-center">
              <div className="w-full">
                <ButtonView
                  variant="tertiary"
                  label={`Don't switch`}
                  onClick={() => {
                    setActiveTab('HTML');
                    setIsModalVisible(false);
                  }}
                />
              </div>
              <div className="w-[280px]">
                <ButtonView
                  label={'Switch to Basic'}
                  onClick={() => {
                    setIsModalVisible(false);
                    setActiveTab('Basic');
                    dispatch(resetEmailContent());
                    dispatch(
                      onTemplateTypeChange({
                        templateType: 'Basic',
                        contentIndex: index,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default HtmlSwitchForEmail;
