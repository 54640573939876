import { combineReducers } from 'redux';
import globalPolicySlice, {
  globalPolicyInitialState,
} from './GetGlobalPolicy.slice';
import {
  IDefaultPolicyState,
  IGetGlobalPolicyState,
  ILenderPoliciesState,
} from 'redux/types/Compliance.types';
import defaultPolicySlice, {
  defaultPolicyInitialState,
} from './GetDefaultPolicy.slice';
import lenderPoliciesSlice, {
  lenderPoliciesInitialState,
} from './GetLenderPolicies.slice';

export interface IComplianceState {
  globalPolicy: IGetGlobalPolicyState;
  defaultPolicy: IDefaultPolicyState;
  lenderPolicies: ILenderPoliciesState;
}

export const complianceInitialState = {
  globalPolicy: globalPolicyInitialState,
  defaultPolicy: defaultPolicyInitialState,
  lenderPolicies: lenderPoliciesInitialState,
};

export const complianceReducer = combineReducers({
  globalPolicy: globalPolicySlice,
  defaultPolicy: defaultPolicySlice,
  lenderPolicies: lenderPoliciesSlice,
});
