import React from 'react';
import YbCoreRadioButton from '@yubi/yb-core-radio-button';
import { useStyles } from '@yubi/yb-style-provider';

type RadioButtonSize = 's' | 'm' | 'l';

interface IRadio {
  checked: boolean;
  value?: string;
  label?: string;
  disabled?: boolean;
  size?: RadioButtonSize;
  onChange?: (value: boolean) => void;
}

const Radio = (props: IRadio) => {
  const { checked, value, label, disabled, size, onChange } = props;
  const { styleConnector } = useStyles();
  return (
    <YbCoreRadioButton
      value={value}
      label={label}
      disabled={disabled}
      size={size}
      onChange={(e) => onChange && onChange(true)}
      styleConnector={styleConnector}
      checked={checked}
    />
  );
};

export default Radio;
