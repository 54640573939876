import React from 'react';
import FriendlyScreen from 'components/FriendlyScreen/FriendlyScreen';
import useUserPermissions from 'hooks/useUserPermissions';
import { useAppSelector } from 'utils/hooks/useAppSelector';
import Spinner from 'components/SpinnerView/SpinnerView';

interface IAuthProvider {
  permission: string | string[];
  children: React.ReactNode;
  showFriendlyScreen?: boolean;
}

const AuthProvider: React.FC<IAuthProvider> = ({
  children,
  permission,
  showFriendlyScreen,
}) => {
  const loadingOnboard = useAppSelector(
    (state) => state.userOnboardDetails.isLoading
  );

  const isLoadingUserPermissions = useAppSelector(
    (state) => state.userPermission.isLoading
  );

  const hasPermission = useUserPermissions({ permission });

  if (loadingOnboard || isLoadingUserPermissions)
    return (
      <Spinner spinning={true}>
        <div className="w-full h-full"> </div>
      </Spinner>
    );
  if (!hasPermission && showFriendlyScreen) return <FriendlyScreen />;

  if (hasPermission) return children;
  return <></>;
};

export default AuthProvider;
