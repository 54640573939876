import YbCoreDivider from '@yubi/yb-core-divider';
import { useStyles } from '@yubi/yb-style-provider';
import React from 'react';
import { Text } from 'react-native';
import { YbCoreDividerProps } from './DividerView.types';

const DividerView = ({
  type = 'horizontal',
  orientation = 'right',
  orientationMargin = 30,
  text = '',
  lineWidth = 1,
  lineColor,
  testId = 'test_divider_child',
}: YbCoreDividerProps) => {
  const { styleConnector } = useStyles();
  return (
    <YbCoreDivider
      type={type}
      orientation={orientation}
      orientationMargin={orientationMargin}
      lineColor={lineColor}
      lineWidth={lineWidth}
      testIDObj={{
        parentId: `parent_${testId}`,
        children: testId,
      }}
      styleConnector={styleConnector}
    >
      {text}
    </YbCoreDivider>
  );
};

export default DividerView;
