import React, { useState } from 'react';
import YbCoreDatePicker from '@yubi/yb-core-date-picker';
import { useStyles } from '@yubi/yb-style-provider';
import TextView from '../../components/TextView';
import dayjs from 'dayjs';
import {
  YbCoreDatePickerDualProps,
  YbCoreDatePickerSingleNonRangeProps,
  YbCoreDatePickerSingleRangeProps,
} from './types';

type DatePickerViewProps =
  | YbCoreDatePickerSingleNonRangeProps
  | YbCoreDatePickerSingleRangeProps
  | YbCoreDatePickerDualProps;

const DatePickerView: React.FC<DatePickerViewProps> = (props) => {
  const { styleConnector } = useStyles();
  const { customOverlayPosition = { top: 0, left: -130 }, label } = props;
  const [hasError, setHasError] = useState(false);
  return (
    <>
      {label && (
        <div className="mb-1">
          <TextView
            text={label}
            color="#4A5567"
            weight="400"
            size={14}
            lineHeight={24}
          />
        </div>
      )}
      <YbCoreDatePicker {...props} styleConnector={styleConnector} />
    </>
  );
};

export default DatePickerView;
