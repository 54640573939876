import { hierarchyManagementEndPoint } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';
import {
  IFetchHierarchySetupResponse,
  ISagaHierarchySetupParam,
} from './HierarchySetup.types';
import { ApiStatusCodes } from 'services/api/apiStatusCode';

import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  fetchHierarchySetupData,
  fetchHierarchySetupDataFailed,
  fetchHierarchySetupDataSucceeded,
} from 'redux/slice/hierarchy-management/hierarchySetup.slice';
import { IFilterListState } from 'features/Settings/UserManagment/HierarchySetup/HierarchySetup.types';

const { hierarchySetupEndpoint } = hierarchyManagementEndPoint;
export function* fetchHierarchySetupDataSaga({
  payload,
}: PayloadAction<ISagaHierarchySetupParam>) {
  const { offset, limit, filter, searchKeyword } = payload;
  try {
    const filterQueryString = Object.keys(filter)
      .filter((key) => filter[key as keyof IFilterListState].length > 0)
      .map((key) => {
        const values = filter[key as keyof IFilterListState];
        const encodedValues = values.map((value) => encodeURIComponent(value));
        return `${key}=${encodedValues.join(',')}`;
      })
      .join('&');

    const result: IFetchHierarchySetupResponse = yield HTTPClient.GetRequest(
      hierarchySetupEndpoint.GET_TEAM_MEMBERS(
        filterQueryString,
        offset,
        limit,
        searchKeyword
      )
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.data) {
      yield put(fetchHierarchySetupDataSucceeded(result?.data));
    } else {
      yield put(
        fetchHierarchySetupDataFailed({
          message: 'Failed to load hierarchy setup data',
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchHierarchySetupDataFailed({
        message: 'An error occurred while fetching hierarchy setup data.',
      })
    );
  }
}

export function* watchHierarchySetupDataSaga() {
  yield takeLatest(fetchHierarchySetupData.type, fetchHierarchySetupDataSaga);
}
