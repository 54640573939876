import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ILenderPoliciesState,
  ILenderPolicy,
} from 'redux/types/Compliance.types';
import { APIStatus } from 'services/api/apiStatusCode';
import { capitalizeFirstLetter } from 'utils/helper';

export const lenderPoliciesInitialState = {
  status: APIStatus.INIT,
};

export interface IGetLenderPoliciesPayload {
  region: string;
  entityId: string;
  offset: number;
  limit: number;
}

const slice = createSlice({
  name: 'lenderPolices',
  initialState: lenderPoliciesInitialState,
  reducers: {
    getLenderPoliciesRequested(
      state: ILenderPoliciesState,
      _action: PayloadAction<IGetLenderPoliciesPayload>
    ) {
      state.status = APIStatus.PENDING;
    },
    getLenderPoliciesRequestSucceeded(state: ILenderPoliciesState, action) {
      state.status = APIStatus.SUCCESS;
      state.data = action.payload;
      if (state.data?.policies?.length) {
        state.data.policies = state.data.policies.map(
          (policy: ILenderPolicy) => {
            const modifiedPolicy: ILenderPolicy = {
              ...policy,
              riskCategory: policy.riskCategory
                ? capitalizeFirstLetter(policy.riskCategory)
                : '',
            };
            return modifiedPolicy;
          }
        );
      }
    },
    getLenderPoliciesRequestFailed(state: ILenderPoliciesState, action) {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
    resetLenderPolicies(state: ILenderPoliciesState) {
      state.status = APIStatus.INIT;
      state.data = undefined;
    },
  },
});

export const {
  getLenderPoliciesRequested,
  getLenderPoliciesRequestSucceeded,
  getLenderPoliciesRequestFailed,
  resetLenderPolicies,
} = slice.actions;

export default slice.reducer;
