import React, { lazy, Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { fetchUserEntities } from 'redux/slice/user/UserEntities.slice';
import { CampaignList } from 'features/CampaignList/CampaignList';
import {
  CAMPAIGN_PLANNER,
  CREATE_CONTENT,
  DELETE_CONTENT,
  EDIT_CONTENT,
  PUBLISH_CONTENT,
  TEST_CONTENT,
  VIEW_CONTENT,
  VIEW_DESIGNATION,
  VIEW_HIERARCHY,
} from 'rbac/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/store';
import ConditionRule from 'features/ConditionRule';
import ProductAndBucketPreferences from 'features/Settings/Preferences/ProductAndBucketPreferences';
const Settings = lazy(() => import('features/Settings'));
const AllocationList = lazy(
  () => import('features/Allocation/AllocationList/AllocationList')
);
const ChannelPreferences = lazy(
  () => import('features/Settings/Preferences/ChannelPreferenceView')
);
const BucketPreferences = lazy(
  () => import('features/Settings/Preferences/BucketPreferencesView')
);
const ProductPreferences = lazy(
  () => import('features/Settings/Preferences/ProductPreferencesView')
);
const LocationPreferences = lazy(
  () => import('features/Settings/Preferences/LocationPreferencesView')
);
const Profile = lazy(() => import('features/Settings/Profile'));
const AssignAllocation = lazy(() => import('features/AssignAllocation'));
const StrategyConfig = lazy(
  () => import('features/StrategyConfig/StrategyConfig')
);
const CampaignPlanning = lazy(
  () => import('features/CampaignPlanning/CampaignPlanning')
);
const CreateCampaign = lazy(
  () => import('features/CreateCampaign/CreateCampaign')
);
const CreateAllocation = lazy(() => import('features/CreateAllocation'));
const ExtractionSetup = lazy(
  () => import('features/Settings/Loanbook/ExtractionSetup')
);
const HierarchySetup = lazy(
  () => import('features/Settings/UserManagment/HierarchySetup')
);
const DesignationSetup = lazy(
  () => import('features/Settings/UserManagment/DesignationSetup')
);
const AllocationSummary = lazy(
  () => import('features/Allocation/AllocationSummary/AllocationSummary')
);
const AllocationConfig = lazy(() => import('features/AllocationConfig'));
const NavBar = lazy(() => import('../templates/NavBar'));
const SpinnerView = lazy(() => import('../components/SpinnerView'));
const Lenders = lazy(() => import('../features/Lenders'));
const CreateConfig = lazy(() => import('features/CreateConfig'));
const LenderAgency = lazy(() => import('features/LenderAgency'));
const OutreachConfig = lazy(() => import('features/OutreachConfig'));
const Campaign = lazy(() => import('features/Campaign'));
const EmployeeProfile = lazy(
  () => import('features/Settings/UserManagment/EmployeeProfile')
);
const Compliance = lazy(() => import('features/Settings/Compliance'));
const Overview = lazy(() => import('features/Content/Channels/Overview'));
const ContentTemplate = lazy(
  () => import('features/Content/Channels/ContentTemplate')
);

const EditedEmployeeProfile = lazy(
  () =>
    import(
      'features/Settings/UserManagment/EmployeeProfile/components/EditedEmployeeProfile'
    )
);
const PaymentPreferences = lazy(
  () => import('features/Settings/Preferences/PaymentPreferences')
);
const SegmentationList = lazy(
  () => import('features/Segmentation/SegmentationList')
);
const CreateSegmentation = lazy(
  () => import('features/Segmentation/CreateSegmentation')
);
const TemplateDetails = lazy(
  () => import('features/Content/Channels/TemplateDetails')
);
const CampaignPlan = lazy(
  () => import('features/CampaignList/components/CampaignPlanDetails')
);
const ContentPreferences = lazy(
  () => import('features/Settings/Preferences/ContentPreferences')
);
const FriendlyScreen = lazy(
  () => import('components/FriendlyScreen/FriendlyScreen')
);
const ManualSegmentation = lazy(
  () => import('features/Segmentation/ManualSegmentation/ManualSegmentation')
);
const CampaignMonitoring = lazy(
  () =>
    import('features/CampaignPlanning/CampaignMonitoring/CampaignMonitoring')
);
const SegmentRule = lazy(
  () => import('features/Campaign/SegmentRule/SegmentRule')
);
const AllocationRule = lazy(
  () => import('features/AllocationRule/AllocationRule')
);
const Segmentation = lazy(() => import('features/Segmentation/Segmentation'));
const Content = lazy(() => import('antd/es/layout/layout'));
const AuthProvider = lazy(() => import('templates/AuthProvider'));
const LazyLogin = lazy(() => import('../features/Login'));
const LazyDashboard = lazy(() => import('../features/Dashboard'));
const LazyAgencyHome = lazy(() => import('../features/AgencyHome'));
const LMSDerivedFields = lazy(
  () => import('features/Settings/Loanbook/LmsAndDerivedFields')
);

const LazyHCHome = lazy(() => import('../features/HelpCentre'));
const LazyHCFaqs = lazy(() => import('../features/HelpCentre/faqs'));
const LazyHCGlossaries = lazy(
  () => import('../features/HelpCentre/glossaries')
);
const LazyHCReleaseNotes = lazy(
  () => import('../features/HelpCentre/release-notes')
);
const LazyHCVideoGuides = lazy(
  () => import('../features/HelpCentre/video-guides')
);

const MainLayout = () => {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
};

const EntityCallWrapper = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchUserEntities());
  }, []);
  return <Outlet />;
};

const AllocationLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === '/allocation')
      navigate('/allocation/create-allocation?hide-nav=true');
  }, [location.pathname]);
  return (
    <div className="mx-8 flex flex-col flex-1 ">
      <Outlet />
    </div>
  );
};

const OutreachConfigWrapper = () => {
  return (
    <Routes>
      <Route index element={<OutreachConfig />} />
      <Route
        path="strategy/:create/:strategyId?"
        element={<StrategyConfig />}
      />
    </Routes>
  );
};

const CampaignLayout = () => {
  return (
    <div className="mx-8 h-full flex">
      <AuthProvider permission={[CAMPAIGN_PLANNER]} showFriendlyScreen>
        <Outlet />
      </AuthProvider>
    </div>
  );
};
const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        errorElement={
          <FriendlyScreen headingText={`Whoops! Something went wrong`} />
        }
        path="/"
        element={<Outlet />}
      >
        <Route path="/" element={<EntityCallWrapper />}>
          <Route path="/" element={<LazyLogin />} />
          <Route path="/" element={<MainLayout />}>
            <Route path="/report" element={<LazyDashboard />} />
            <Route path="/agency/manageAgency" element={<LenderAgency />} />
            <Route path="/lender/manageLender" element={<Lenders />} />
            <Route path="campaign" element={<CampaignLayout />}>
              <Route
                path="create-campaign/campaign/segmentation"
                element={
                  <ManualSegmentation>
                    <SegmentRule />
                  </ManualSegmentation>
                }
              />

              <Route
                path="outreach-list/*"
                element={<OutreachConfigWrapper />}
              />
              <Route
                path="/campaign/create-campaign/campaign/segmentation/planning"
                element={<CampaignPlanning />}
              />
              <Route
                path="/campaign/outreach-config"
                element={<OutreachConfig />}
              />
              <Route
                path="/campaign/campaign-list"
                element={<CampaignList />}
              />
              <Route
                path="/campaign/campaign-list/campaign-plan"
                element={<CampaignPlan />}
              />
              <Route
                path="/campaign/create-campaign/campaign"
                element={<Campaign />}
              />
              <Route
                path="/campaign/create-campaign"
                element={<CreateCampaign />}
              />
              <Route
                path="create-campaign/campaign/segmentation-old"
                element={
                  <ManualSegmentation>
                    <Segmentation />
                  </ManualSegmentation>
                }
              />

              {/* <Route
                path="/campaign/create-campaign/campaign/manual"
                element={<ManualSegmentation />}
              /> */}
              <Route
                path="/campaign/segment-list"
                element={<SegmentationList />}
              />
              <Route
                path="/campaign/segment-list/create-segment"
                element={<CreateSegmentation />}
              />
              <Route
                path="/campaign/segment-list/edit-segment/:segmentId"
                element={<CreateSegmentation />}
              />

              <Route
                path="/campaign/condition_rule"
                element={<ConditionRule />}
              />
              <Route
                path="/campaign/monitoring"
                element={<CampaignMonitoring />}
              />
            </Route>
            <Route path="/agency/manage-agencies" element={<LenderAgency />} />
            <Route path="/lender/manage-lender" element={<Lenders />} />
            <Route path="/allocation" element={<AllocationLayout />}>
              <Route path="allocation-rule" element={<AllocationRule />} />
              <Route
                index
                path="create-allocation"
                element={<AllocationRule />}
              />
              {/* 
              Route handles edit draft allocation.
               */}
              <Route
                path="drafted-allocation/:allocationId"
                element={<AllocationRule isDraftEdit={true} />}
              />

              <Route
                index
                path="create-allocation-old"
                element={<CreateAllocation />}
              />

              <Route path="allocation-config" element={<AllocationConfig />} />
              <Route path="create-config" element={<CreateConfig />} />
              <Route path="edit-config/:configId" element={<CreateConfig />} />
              <Route
                path="allocation-summary"
                element={<AllocationSummary />}
              />
              <Route path="allocation-list/*" element={<AllocationList />} />
              <Route
                path="assign-allocation/:allocationId"
                element={<AssignAllocation />}
              />
            </Route>
            <Route path="/settings" element={<Settings />}>
              <Route path="profile" element={<Profile />} />
              <Route path="" element={<Profile />} />
              <Route
                path="location_preferences"
                element={<LocationPreferences />}
              />
              <Route
                path="product_preferences"
                element={<ProductAndBucketPreferences />}
              />
              <Route
                path="bucket_preferences"
                element={<ProductAndBucketPreferences />}
              />
              <Route
                path="product_bucket_preferences"
                element={<ProductAndBucketPreferences />}
              />
              <Route
                path="channel_preferences"
                element={<ChannelPreferences />}
              />
              <Route
                path="payment_preferences"
                element={<PaymentPreferences />}
              />
              <Route
                path="content_preferences"
                element={<ContentPreferences />}
              />
              <Route path="hierarchy_setup/:id" element={<EmployeeProfile />} />
              <Route
                path="hierarchy_setup/:id/edit"
                element={<EditedEmployeeProfile />}
              />
              <Route path="extraction_setup" element={<ExtractionSetup />} />
              <Route path="lms_derived_fields" element={<LMSDerivedFields />} />
              <Route
                path="designation_setup"
                element={
                  <AuthProvider
                    permission={VIEW_DESIGNATION}
                    showFriendlyScreen
                  >
                    <DesignationSetup />
                  </AuthProvider>
                }
              />
              <Route
                path="hierarchy_setup"
                element={
                  <AuthProvider permission={VIEW_HIERARCHY} showFriendlyScreen>
                    <HierarchySetup />
                  </AuthProvider>
                }
              />
              <Route path="compliance_setup" element={<Compliance />} />
            </Route>
            <Route
              path="/content/dashboard"
              element={
                <AuthProvider
                  permission={[
                    EDIT_CONTENT,
                    VIEW_CONTENT,
                    CREATE_CONTENT,
                    DELETE_CONTENT,
                    TEST_CONTENT,
                    PUBLISH_CONTENT,
                  ]}
                  showFriendlyScreen
                >
                  <Content />
                </AuthProvider>
              }
            ></Route>
            <Route
              path="/content/channels/:channel/overview"
              element={
                <AuthProvider
                  permission={[
                    EDIT_CONTENT,
                    VIEW_CONTENT,
                    CREATE_CONTENT,
                    DELETE_CONTENT,
                  ]}
                  showFriendlyScreen
                >
                  <Overview />
                </AuthProvider>
              }
            ></Route>
            <Route
              path="/content/channels/:channel/categories-template/:id?"
              element={
                <AuthProvider
                  permission={[
                    EDIT_CONTENT,
                    CREATE_CONTENT,
                    TEST_CONTENT,
                    PUBLISH_CONTENT,
                  ]}
                  showFriendlyScreen
                >
                  <ContentTemplate />
                </AuthProvider>
              }
            ></Route>
            <Route
              path="/content/channels/:channel/template-details"
              element={
                <AuthProvider
                  permission={[
                    EDIT_CONTENT,
                    VIEW_CONTENT,
                    CREATE_CONTENT,
                    DELETE_CONTENT,
                  ]}
                  showFriendlyScreen
                >
                  <TemplateDetails />
                </AuthProvider>
              }
            ></Route>
            <Route path="/agency-home" element={<LazyAgencyHome />} />
            <Route path="/help" element={<LazyHCHome />} />
            <Route path="/help/faq" element={<LazyHCFaqs />} />
            <Route path="/help/glossary" element={<LazyHCGlossaries />} />
            <Route path="/help/video-guides" element={<LazyHCVideoGuides />} />
            <Route
              path="/help/release-notes"
              element={<LazyHCReleaseNotes />}
            />
            <Route path="*" element={<>NOT FOUND</>} />
            <Route path="/error" element={<FriendlyScreen />} />
          </Route>
        </Route>
      </Route>
    )
  );
  return (
    <Suspense fallback={<SpinnerView label="Loading..." />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
