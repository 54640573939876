import { put, takeLatest } from 'redux-saga/effects';
import { lender } from '../../../services/api/endPoints';
import {
  acceptLenderInvitesSucceeded,
  acceptLenderInvitesFailed,
  acceptLenderInvites,
} from '../../slice/lenders/LendersInvites.slice';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { IFetchLenderInvitesResponse } from './Lenders.types';

export const postDataRequest = (data: any) => ({
  type: 'POST_DATA_REQUEST',
  payload: data,
});
export const postDataSuccess = (response: any) => ({
  type: 'POST_DATA_SUCCESS',
  payload: response,
});
export const postDataFailure = (error: any) => ({
  type: 'POST_DATA_FAILURE',
  payload: error,
});

export function* acceptLenderSaga(payload: any) {
  try {
    const result: IFetchLenderInvitesResponse = yield HTTPClient.PostRequest(
      lender.ACCEPT_INVITES,
      payload.payload
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(acceptLenderInvitesSucceeded(result?.data?.data));
    } else {
      yield put(
        acceptLenderInvitesFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      acceptLenderInvitesFailed({
        error: {
          message: 'An error occurred while accepting lenders.',
        },
      })
    );
  }
}

export function* watchAcceptLenderInvitesSaga() {
  yield takeLatest(acceptLenderInvites.type, acceptLenderSaga);
}
