import React, { useEffect, useRef, useState } from 'react';
import {
  ICondition,
  IConditionRules,
  IDispositionOption,
  IGroup,
} from '../shared/contract';
import Condition from './Condition';
import AddGroup from './AddGroup';
import Group from './Group';

interface IGroupProps {
  conditionGroup: IGroup;
  path: string;
  dispositionOptions: IDispositionOption[];
  addORRemoveConditionAndGroup: (
    pathToChange: string,
    isAdd: boolean,
    isCondition: boolean
  ) => void;
  onChange: (newGroup: IGroup) => void;
  conditionRule: IConditionRules;
  isView: boolean;
}

const ConditionalGroup = (props: IGroupProps) => {
  const {
    conditionGroup,
    path,
    addORRemoveConditionAndGroup,
    onChange,
    dispositionOptions,
    conditionRule,
    isView,
  } = props;
  const { conditions, groups, logicalOperator } = conditionGroup;
  const [divHeight, setDivHeight] = useState<number | null>(null);
  const substring = 'groups';
  const regex = new RegExp(substring, 'g');
  const matchCount = (path.match(regex) || []).length;

  const handleCondition = (
    newConditions: ICondition[],
    newGroups: IGroup[]
  ) => {
    onChange({
      conditions: newConditions,
      groups: newGroups,
      logicalOperator,
    });
  };

  const getBorderStyle = () => {
    if (matchCount === 1) {
      return { border: '2px solid #EEF2F6' };
    }
    if (!path) {
      if (
        !groups?.length &&
        isView &&
        (conditions?.length || logicalOperator)
      ) {
        return {
          borderLeft: '2px solid #EEF2F6',
          borderImage: `linear-gradient(to bottom, #EEF2F6 calc(100% - 32px), transparent 0) 1 100%`,
        };
      }
      return {
        borderLeft: '2px solid #EEF2F6',
        borderImage:
          divHeight && isView && (conditions?.length || logicalOperator)
            ? `linear-gradient(to bottom, #EEF2F6 calc(100% - ${
                divHeight - 2
              }px), transparent 0) 1 100%`
            : '',
      };
    }
    if (groups?.length) {
      return {
        borderLeft: '2px solid #EEF2F6',
        borderImage: divHeight
          ? `linear-gradient(to bottom, #EEF2F6 calc(100% - ${
              divHeight - 2
            }px), transparent 0) 1 100%`
          : '',
      };
    }
    return {};
  };

  return (
    <div
      className={`flex flex-col gap-2 py-2  ${
        path ? 'rounded-bl-md' : ''
      } rounded-br-md`}
      style={{
        ...getBorderStyle(),
      }}
    >
      {conditions?.map((condition, index) => {
        return (
          <Condition
            dispositionOptions={dispositionOptions}
            path={`${path ? path + '.' : path}conditions.[${index}]`}
            condition={condition}
            onChange={(newCondition: ICondition) => {
              const newConditions = [...conditions];
              newConditions[index] = newCondition;
              handleCondition(newConditions, groups || []);
            }}
            addORRemoveConditionAndGroup={addORRemoveConditionAndGroup}
            conditionRule={conditionRule}
            isView={isView}
          />
        );
      })}
      {!isView && (
        <div className="pl-4">
          {!!path && logicalOperator && (
            <AddGroup
              onAddClicked={(isCondition: boolean) => {
                addORRemoveConditionAndGroup(
                  isCondition ? `${path}.conditions.[0]` : `${path}.groups.[0]`,
                  true,
                  isCondition
                );
              }}
            />
          )}
        </div>
      )}
      <div>
        {groups?.map((group, index) => {
          const handleGroupChange = (newGroup: IGroup) => {
            const newGroups = [...groups];
            newGroups[index] = newGroup;
            handleCondition(conditions || [], newGroups);
          };
          const isLastGroup = groups.length === index + 1;
          return (
            <Group
              group={group}
              path={`${path ? path + '.' : path}groups.[${index}]`}
              index={index}
              addORRemoveConditionAndGroup={addORRemoveConditionAndGroup}
              onChange={handleGroupChange}
              isLastGroup={isLastGroup}
              dispositionOptions={dispositionOptions}
              conditionRule={conditionRule}
              isView={isView}
              updateLastDivHeight={(height) => {
                if (isLastGroup) {
                  setDivHeight(height);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ConditionalGroup;
