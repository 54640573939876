import { YbCoreIcon } from '@yubi/yb-core-icon';
import { TextView } from 'components';
import React from 'react';

interface IBreadCrumbData {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

interface IBreadCrumbProps {
  data: IBreadCrumbData[];
}

const BreadCrumb = (props: IBreadCrumbProps) => {
  const { data } = props;
  const handleBackClicked = () => {
    const activeIndex = data.findIndex((item) => item.isActive);
    if (activeIndex > 0) {
      data[activeIndex - 1].onClick();
    }
  };
  return (
    <article className="flex items-center">
      <div className="cursor-pointer" onClick={handleBackClicked}>
        <YbCoreIcon size={16} name={'chevron-left'} color="#344054" />
      </div>
      {data.map((item, index) => {
        const { name, isActive, onClick } = item;
        return (
          <>
            <section className="p-2 cursor-pointer" onClick={onClick}>
              <TextView
                text={name}
                weight={isActive ? '700' : '400'}
                color={isActive ? '#252B4D' : '#344054'}
              />
            </section>
            {index !== data.length - 1 && (
              <section>
                <YbCoreIcon size={16} name={'slash-divider'} />
              </section>
            )}
          </>
        );
      })}
    </article>
  );
};

export default BreadCrumb;
