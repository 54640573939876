import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getProductPreferencesRequestFailed,
  getProductPreferencesRequestSucceeded,
  getProductPreferencesRequested,
} from 'redux/slice/settings/Preferences/ProductPreference.slice';
import { IGetPreferencePayload } from 'redux/types/Preference.types';
import { IError } from 'redux/types/UserOnboardDetails.types';
import { settingsEndpoints } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';

function* getProductPreferenceSaga({
  payload,
}: PayloadAction<IGetPreferencePayload>) {
  try {
    const { offset, limit, searchCriteria } = payload;
    const response: AxiosResponse<any> = yield HTTPClient.GetRequest(
      settingsEndpoints.GET_PREFERENCES(
        'products',
        offset,
        limit,
        searchCriteria
      )
    );

    yield put(getProductPreferencesRequestSucceeded(response.data.data));
  } catch (err) {
    yield put(
      getProductPreferencesRequestFailed({
        error: 'Error while fetching product preferences',
      } as IError)
    );
  }
}

export function* watchGetProductPreferenceSaga() {
  yield takeLatest(
    getProductPreferencesRequested.type,
    getProductPreferenceSaga
  );
}
