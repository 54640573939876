import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISendAssociationRequest } from '../../types/ManageAgency.type';
import { APIStatus } from 'services/api/apiStatusCode';
import { IErrorParam } from 'redux/types/types';

export interface SendAssociationInitialState {
  isLoading: boolean;
  sendAssociateFormStatus: APIStatus;
  error: IErrorParam;
  successMsg: string;
}

export const sendAssociationInitialState: SendAssociationInitialState = {
  isLoading: false,
  sendAssociateFormStatus: APIStatus.INIT,
  error: {},
  successMsg: '',
};

const sendAssociation = createSlice({
  name: 'sendAssociation',
  initialState: sendAssociationInitialState,
  reducers: {
    sendAssociationForm: (
      state: SendAssociationInitialState,
      action: PayloadAction<ISendAssociationRequest>
    ) => {
      state.sendAssociateFormStatus = APIStatus.PENDING;
    },
    sendAssociationFormSuccess: (
      state: SendAssociationInitialState,
      action: PayloadAction<string>
    ) => {
      state.sendAssociateFormStatus = APIStatus.SUCCESS;
      state.successMsg = action.payload;
    },
  },
});

export const { sendAssociationForm, sendAssociationFormSuccess } =
  sendAssociation.actions;

export default sendAssociation.reducer;
