import React from 'react';
import { TextView } from 'components';

interface ICreateConditionInfo {
  isEdit?: boolean;
}

const CreateConditionInfo = (props: ICreateConditionInfo) => {
  const { isEdit = false } = props;
  const title = isEdit ? 'Editing condition rules' : 'Creating condition rules';
  const description = isEdit
    ? 'You can edit your rule, add or delete\nconditions and group, rename or\ndelete it.'
    : 'Name your condition rule for quick\ndiscovery and use';
  return (
    <div className="flex flex-col gap-6 min-w-max">
      <div
        className="p-6 flex flex-col gap-2 rounded-md"
        style={{ background: '#F9FAFB' }}
      >
        <TextView
          text={title}
          size={14}
          lineHeight={20}
          color="#101828"
          weight="400"
        />
        <TextView
          text={description}
          size={14}
          lineHeight={20}
          color="#667085"
        />
      </div>
      <div
        className="p-6 flex flex-col gap-4 rounded-md"
        style={{ background: '#F9FAFB' }}
      >
        <div className="flex flex-col gap-2">
          <TextView
            text="And"
            size={14}
            lineHeight={20}
            color="#101828"
            weight="400"
          />
          <TextView
            text={`"AND" as a connector ensures all values\nhave to be true to get an accurate\nresult`}
            size={14}
            lineHeight={20}
            color="#667085"
          />
        </div>
        <div className="flex flex-col gap-2">
          <TextView
            text="Or"
            size={14}
            lineHeight={20}
            color="#101828"
            weight="400"
          />
          <TextView
            text={`"OR" as a connector connects set\nconditions in a manner where the\nlegitimacy of the result would be true if\none or all conditions are true`}
            size={14}
            lineHeight={20}
            color="#667085"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateConditionInfo;
