import React, { useEffect, useState } from 'react';
import { YbFileupload } from '@yubi/yb-core-file-upload';
import { useStyles } from '@yubi/yb-style-provider';

type TYPES =
  | 'avif'
  | 'bmp'
  | 'gif'
  | 'ico'
  | 'jpeg'
  | 'jpg'
  | 'png'
  | 'svg'
  | 'webp'
  | 'tif'
  | 'tiff'
  | 'doc'
  | 'docx'
  | 'pdf'
  | 'csv'
  | 'xls'
  | 'xlsx';

type Upload = 'button' | 'dropzone';

type Size = 'm' | 'l';

interface IFileUploadViewProps {
  types: Array<TYPES>;
  size: Size;
  uploadType?: Upload;
  buttonPlaceHolderText?: string;
  showSupportedFilesText?: boolean;
  dropzonePaddingLeft?: number;
  dropzonePaddingRight?: number;
  maxSize?: number;
  maxNoOfFiles?: number;
  onFileUpload?: (formData?: FormData) => void;
}

export interface FileWithPath extends File {
  readonly path?: string;
}

const FileUploadView = (props: IFileUploadViewProps) => {
  const {
    types,
    size,
    uploadType,
    buttonPlaceHolderText,
    showSupportedFilesText,
    dropzonePaddingLeft,
    dropzonePaddingRight,
    maxSize,
    maxNoOfFiles,
    onFileUpload,
  } = props;
  const { styleConnector } = useStyles();
  const [files, setFiles] = useState<FileWithPath[]>([]);

  useEffect(() => {
    return () => {
      setFiles([]);
    };
  }, []);

  useEffect(() => {
    if (onFileUpload) {
      const formData = new FormData();
      if (files.length > 0) {
        formData.append('file', files[0]);
        onFileUpload(formData);
        return;
      }
      onFileUpload(undefined);
    }
  }, [files]);
  const webUploadData = (
    file: FileWithPath,
    setDownloaded: React.Dispatch<React.SetStateAction<number>>,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
    setIsUploadComplete: React.Dispatch<React.SetStateAction<boolean>>,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    let tmpProgress = 0;
    const interval = setInterval(() => {
      setProgress((prev) => prev + 10);
      setDownloaded((prev) => prev + file?.size / 10);
      tmpProgress += 10;
      if (tmpProgress === 100) {
        setIsUploadComplete(true);
        setFiles((prev) => [...prev, file]);
        clearInterval(interval);
      }
    }, 100);
    return () => {};
  };

  const webDeleteData = (
    file: FileWithPath,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>,
    removePath: (name: string, path: string) => void
  ) => {
    const abortController = new AbortController();

    const fileArray = files.filter((item) => !(item === file));
    setFiles(fileArray);

    removePath(file.name, file.path ?? '');

    return () => abortController.abort();
  };

  return (
    <YbFileupload
      types={types}
      size={size}
      uploadType={uploadType}
      buttonPlaceHolderText={buttonPlaceHolderText}
      showSupportedFilesText={showSupportedFilesText}
      dropzonePaddingLeft={dropzonePaddingLeft}
      dropzonePaddingRight={dropzonePaddingRight}
      maxSize={maxSize}
      maxNoOfFiles={maxNoOfFiles}
      styleConnector={styleConnector}
      webUploadData={webUploadData}
      webDeleteData={webDeleteData}
    />
  );
};

export default FileUploadView;
