import { ButtonView, TextView } from 'components';
import CustomModalView from 'components/CustomModalView/CustomModalView';
import React from 'react';

interface IDeleteComponentProps {
  showDelete: boolean;
  headingText: string;
  subHeadingText: string;
  onDelete: (confirmDelete: boolean) => void;
  cancelText?: string;
  confirmText?: string;
}

const DeleteComponent = (props: IDeleteComponentProps) => {
  const {
    showDelete,
    headingText,
    subHeadingText,
    onDelete,
    cancelText,
    confirmText,
  } = props;
  return (
    <>
      <CustomModalView
        open={showDelete}
        modalContent={
          <div>
            <div className="py-4 flex flex-col justify-center">
              <TextView
                lineHeight={24}
                size={16}
                weight="700"
                align="center"
                text={headingText}
                color="#101828"
              />
              <TextView
                lineHeight={20}
                size={14}
                weight="400"
                text={subHeadingText}
                align="center"
                color="#475467"
              />
            </div>

            <div className="flex justify-center px-6 py-4  gap-2">
              <span>
                <ButtonView
                  size="m"
                  variant={'tertiary'}
                  onClick={() => onDelete(false)}
                  label={cancelText || 'No, Cancel'}
                />
              </span>
              <span>
                <ButtonView
                  size="m"
                  variant="primary"
                  onClick={() => onDelete(true)}
                  label={confirmText || 'Yes, Delete'}
                />
              </span>
            </div>
          </div>
        }
        onModalClose={() => onDelete(false)}
      />
    </>
  );
};

export default DeleteComponent;
