import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ExpandableConfig, TableRowSelection } from 'antd/es/table/interface';
import { StyledFlexDiv } from 'features/Settings/shared/styledComponents';
import SpinnerView from '../SpinnerView/SpinnerView';
import './index.css';
import PaginationView from 'components/PaginationView';
import { IOffsetPagination } from 'types';
import EmptyDataView from '../EmptyDataView/index';
import { styled } from 'styled-components';
import { AlignType } from 'rc-table/lib/interface';
import YbCoreSpinner from '@yubi/yb-core-spinner';
import { useStyles } from '@yubi/yb-style-provider';

interface CustomTableProps<T> {
  columns: ColumnsType<any>;
  dataSource: T[];
  rowSelection?: TableRowSelection<T>;
  isLoading?: boolean;
  tablePagination?: object;
  onRowClick?: (record: T, rowIndex?: number) => void;
  scroll?: object;
  pagination?: IOffsetPagination;
  noDataText?: string;
  showHeader?: boolean;
  bordered?: boolean;
  rowClassName?: (record: T, index: number) => string;
  headerOffSet?: number;
  bodyColumnPosition?: AlignType;
  expandable?: ExpandableConfig<T>;
}
const CustomTable = styled(Table)<{ bodyColumnPosition: AlignType }>`
  .ant-table-row > .ant-table-cell {
    text-align: ${(prop) => prop.bodyColumnPosition} !important;
  }
  .ant-table-row > .ant-table-cell:first-child {
    text-align: left !important;
  }
`;

const TableView = ({
  columns,
  dataSource,
  bordered,
  rowSelection,
  isLoading,
  showHeader = true,
  pagination,
  onRowClick,
  noDataText,
  rowClassName,
  headerOffSet,
  bodyColumnPosition = 'start',
  expandable,
  ...tableProps
}: CustomTableProps<any>) => {
  const { offset, limit, totalElements, onPaginationChange } = pagination || {};

  const handlePageSizeChange = (newPageSize: number) => {
    const value = offset ? Math.floor(offset / newPageSize) : 0;
    if (onPaginationChange)
      onPaginationChange(value * newPageSize, newPageSize);
  };
  const { styleConnector } = useStyles();

  return (
    <div className="flex flex-1 flex-col  gap-3">
      <div>
        <CustomTable
          bodyColumnPosition={bodyColumnPosition}
          scroll={tableProps.scroll || { x: 'max-content' }}
          locale={{
            emptyText() {
              return <EmptyDataView emptyLabel={noDataText} />;
            },
          }}
          showHeader={showHeader}
          columns={columns}
          bordered={bordered}
          dataSource={dataSource}
          rowSelection={rowSelection}
          pagination={false}
          onChange={() => {}}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (onRowClick) onRowClick(record, rowIndex);
              }, // click row
            };
          }}
          rowClassName={rowClassName}
          loading={{
            spinning: !!isLoading,
            indicator: (
              <StyledFlexDiv>
                <YbCoreSpinner
                  size="small"
                  thickness={5}
                  durationInMills={1500}
                  styleConnector={styleConnector}
                />
              </StyledFlexDiv>
            ),
          }}
          sticky={{ offsetHeader: headerOffSet }}
          expandable={expandable}
        />
      </div>
      {pagination && (totalElements || 0) > 10 && (
        <div className="flex justify-end items-end flex-1 pr-3 z-50">
          <PaginationView
            selectedPage={offset || 0}
            totalPages={totalElements || 0}
            title={`Rows per page`}
            onPageSelected={(newPageSize: string) =>
              handlePageSizeChange(parseInt(newPageSize))
            }
            isBorderedDropdown={true}
            pageSize={totalElements ? limit : 1}
            onPageChange={(page) =>
              onPaginationChange && onPaginationChange(page, limit || 10)
            }
          />
        </div>
      )}
    </div>
  );
};

// write a code to make it reusable
export default TableView;
