import { put, takeLatest } from 'redux-saga/effects';
import { settingsEndpoints } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AxiosResponse } from 'axios';
import {
  IComplianceResponse,
  IDefaultPolicy,
} from 'redux/types/Compliance.types';
import {
  IGetDefaultPolicyPayload,
  getDefaultPolicyRequestFailed,
  getDefaultPolicyRequestSucceeded,
  getDefaultPolicyRequested,
} from 'redux/slice/settings/Compliance/GetDefaultPolicy.slice';

function* getDefaultPolicySaga({
  payload,
}: PayloadAction<IGetDefaultPolicyPayload>) {
  const { region, entityId } = payload;
  const requestId = uuidv4();
  try {
    const response: AxiosResponse<IComplianceResponse<IDefaultPolicy>> =
      yield HTTPClient.PostRequest(
        settingsEndpoints.GET_DEFAULT_POLICIES,
        { region, lenderId: entityId },
        { headers: { 'request-id': requestId } }
      );
    yield put(getDefaultPolicyRequestSucceeded(response.data.data));
  } catch (err) {
    yield put(
      getDefaultPolicyRequestFailed({ error: { message: 'Error' } } as any)
    );
  }
}

export function* watchGetDefaultPolicySaga() {
  yield takeLatest(getDefaultPolicyRequested.type, getDefaultPolicySaga);
}
