import { HTTPClient } from 'services/api/http-client';

import { ApiStatusCodes } from 'services/api/apiStatusCode';

import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  IGetReportingHierarchyApiResponse,
  IProductBucketLocationApiResponse,
} from './types';
import { hierarchyManagementEndPoint } from 'services/api/endPoints';
import {
  fetchEmployeeProfileData,
  fetchEmployeeProfileDataFailed,
  fetchEmployeeProfileDataSucceeded,
  fetchSavedProductBucketData,
  fetchSavedProductBucketDataFailed,
  fetchSavedProductBucketDataSucceeded,
} from 'redux/slice/hierarchy-management/EmployeeProfile.slice';
import { store } from 'redux/store/store';
import { groupCollectionAgency } from 'constants/constant';
// import { IProductBucketLocationApiResponse } from 'features/Settings/UserManagment/EmployeeProfile/components/SavedEmployeeProfile/SavedEmployeeProfile.types';

const { hierarchySetupEndpoint } = hierarchyManagementEndPoint;
export function* fetchEmployeeProfileDataSaga({ payload }: PayloadAction<any>) {
  const { userId } = payload; // Assuming you have an employeeId in the payload
  try {
    const result: IGetReportingHierarchyApiResponse =
      yield HTTPClient.GetRequest(
        hierarchySetupEndpoint.GET_REPORTING_HIERARCHY(userId)
      );

    if (
      result?.status === ApiStatusCodes.SUCCESS &&
      result?.data // Adjust this condition based on your API response structure
    ) {
      yield put(fetchEmployeeProfileDataSucceeded(result?.data));
    } else {
      yield put(
        fetchEmployeeProfileDataFailed({
          message: 'Failed to load employee profile data',
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchEmployeeProfileDataFailed({
        message: 'An error occurred while fetching employee profile data.',
      })
    );
  }
}

export function* fetchSavedProductBucketSaga({ payload }: PayloadAction<any>) {
  const { userId } = payload; // Assuming you have an employeeId in the payload

  try {
    const result: IProductBucketLocationApiResponse =
      yield HTTPClient.GetRequest(
        hierarchyManagementEndPoint.GET_PRODUCT_BUCKET_LOCATION(userId)
      );

    if (
      result?.status === ApiStatusCodes.SUCCESS &&
      result?.data // Adjust this condition based on your API response structure
    ) {
      const uniqueLOBs = result.data?.data?.reduce((prev: any, curr: any) => {
        if (curr.lineOfBusiness) {
          if (curr.lineOfBusiness in prev) {
            prev[curr.lineOfBusiness].push({
              product: { id: curr.productId, name: curr.productName },
              bucket: curr.bucketPreferences,
              enabled: curr.enabled,
            });

            return prev;
          }

          prev[curr.lineOfBusiness] = [
            {
              product: { id: curr.productId, name: curr.productName },
              bucket: curr.bucketPreferences,
              enabled: curr.enabled,
            },
          ];
        }

        return prev;
      }, {});

      const locationConfigurations =
        result.data?.data[0]?.locationConfigurations?.[0];

      if (
        store.getState()?.userConfiguration?.userGroup === groupCollectionAgency
      ) {
        const lenderEntities = result.data?.data[0]?.lenderEntities;
        yield put(
          fetchSavedProductBucketDataSucceeded({
            lenderEntities,
            locationConfigurations,
          })
        );
        return;
      }

      yield put(
        fetchSavedProductBucketDataSucceeded({
          uniqueLOBs,
          locationConfigurations,
        })
      );
    } else {
      yield put(
        fetchSavedProductBucketDataFailed({
          message: 'Failed to load employee profile data',
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchSavedProductBucketDataFailed({
        message: 'An error occurred while fetching employee profile data.',
      })
    );
  }
}

export function* watchEmployeeProfileDataSaga() {
  yield takeLatest(fetchEmployeeProfileData.type, fetchEmployeeProfileDataSaga);
}

export function* watchSavedProductBucketSaga() {
  yield takeLatest(
    fetchSavedProductBucketData.type,
    fetchSavedProductBucketSaga
  );
}
