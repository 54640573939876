import { put, takeLatest } from 'redux-saga/effects';
import { campaignPlanner } from '../../../services/api/endPoints';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import {
  IFetchConfigResponse,
  IFetchContentListResponse,
} from './Outreach.types';
import {
  fetchConfig,
  fetchConfigFailed,
  fetchConfigSucceeded,
} from '../../slice/campaign-planner/ConfigList.slice';
import {
  fetchContent,
  fetchContentFailed,
  fetchContentSucceeded,
} from '../../slice/campaign-planner/ContentList.slice';

export function* fetchConfigSaga(action: any) {
  try {
    const { lenderId } = action.payload;
    const result: IFetchConfigResponse = yield HTTPClient.GetRequest(
      campaignPlanner.GET_CONFIG(lenderId)
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(fetchConfigSucceeded(result?.data?.data));
    } else {
      yield put(
        fetchConfigFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchConfigFailed({
        error: {
          message: 'An error occurred while fetching config list.',
        },
      })
    );
  }
}

export function* fetchContentSaga(action: any) {
  try {
    const { channel, language, lenderId } = action.payload;
    const result: IFetchContentListResponse = yield HTTPClient.GetRequest(
      campaignPlanner.GET_CONTENT(channel, language, lenderId)
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(
        fetchContentSucceeded({
          outreachContentData: result.data.data,
        })
      );
    } else {
      yield put(
        fetchContentFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchContentFailed({
        error: {
          message: 'An error occurred while fetching content list.',
        },
      })
    );
  }
}

export function* watchConfigSaga() {
  yield takeLatest(fetchConfig.type, fetchConfigSaga);
  yield takeLatest(fetchContent.type, fetchContentSaga);
}
