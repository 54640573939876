import {
  GroupedListItem,
  ListItem,
  MultiSelectListItem,
  YbCoreDropdown,
  YbCoreDropdownProps,
} from '@yubi/yb-core-dropdown-v3';
import React, { useEffect } from 'react';
import { IOption } from './DropDownViewV3.types';

interface IDropDownV3 extends YbCoreDropdownProps {
  value?: string | Array<string>;
  // onChange: (value: string | Array<string>) => void;
}

const DropDownViewV3 = (props: IDropDownV3) => {
  const { value, options, type, onChange } = props;
  const [defaultSelectedItems, setDefaultSelectedItems] = React.useState<any>(
    []
  );

  const findIsOptionInValue = (
    option: ListItem | MultiSelectListItem | GroupedListItem
  ) => {
    if (type === 'standard') {
      return 'value' in option && value === option.value;
    } else {
      return 'value' in option && value?.includes(option.value);
    }
  };

  useEffect(() => {
    if (value) {
      const selectedItems = options.filter((option) =>
        findIsOptionInValue(option)
      );

      setDefaultSelectedItems(selectedItems);
    } else {
      setDefaultSelectedItems([]);
    }
  }, [value, options]);

  return (
    <YbCoreDropdown
      {...props}
      defaultSelectedItems={defaultSelectedItems}
      onChange={(e: { selectedItems: IOption | IOption[] }) => {
        const selectedItems = e.selectedItems;
        if (Array.isArray(selectedItems)) {
          const selectedValues = selectedItems.map((item) => item.value);
          onChange(selectedValues);
        } else {
          onChange(selectedItems.value);
        }
      }}
    />
  );
};

export default DropDownViewV3;
