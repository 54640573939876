import { CampaignExecutionStatus } from 'features/Campaign/Campaign';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'redux/slice/reducer';

const useShouldEditCampaign = () => {
  const campaignExecutionStatus = useSelector(
    (state: RootReducerState) =>
      state.strategy.campaignPlanner.campaignExecutionStatus
  );

  return campaignExecutionStatus !== CampaignExecutionStatus.COMPLETED;
};

export default useShouldEditCampaign;
