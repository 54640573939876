import React, { useEffect, useState } from 'react';
import { SettingsTableView } from '../components/SettingsTableView';
import { productPreferencesColumns } from '../shared/constants';
import {
  deleteProductPreference,
  enableOrDisablePreferences,
  getPreferences,
} from '../shared/api';
import { message } from 'antd';
import { IProductPreferencesData, ITabData } from '../shared/contract';
import { checkForSearch } from '../shared/helper';
import { SpinnerView, TextView } from 'components';
import { useAppSelector } from 'utils/hooks/useAppSelector';
import { isPermissionEnabled } from 'utils/helper';
import { UPDATE_PREFERENCES, VIEW_PREFERENCES } from 'rbac/constants';
import AuthProvider from 'templates/AuthProvider';
import CreateProduct from '../components/CreateProduct';
import './ProductPreferences.css';
import { YbCoreIcon } from '@yubi/yb-core-icon';
import UserInfo from 'templates/UserInfo';
import { ButtonViewV3 } from 'assets/components-v3';
import NoData from 'assets/img/NoData.svg';

const getLobData = (
  data: IProductPreferencesData[],
  lineOfBusiness: string
) => {
  return data.filter((e) => e.lineOfBusiness === lineOfBusiness);
};

interface IProductPreferencesProps {
  onProductCreateClicked?: number;
  resetProductCreateClicked?: () => void;
  searchText: string;
}

const ProductPreferences = (props: IProductPreferencesProps) => {
  const { onProductCreateClicked, resetProductCreateClicked, searchText } =
    props;
  const [preferencesData, setPreferencesData] =
    useState<IProductPreferencesData[]>();
  const [showCreateProduct, setShowCreateProduct] = useState(false);

  const [loading, setLoading] = useState(false);
  const { userPermission } = useAppSelector((store) => store);
  const editPermissionEnabled = isPermissionEnabled(
    userPermission.userPermissions,
    UPDATE_PREFERENCES
  );

  const [tableToggledOff, setTableToggledOff] = useState({
    'credit card': false,
    'un secured': false,
    secured: false,
  });

  const getProductPreferences = async () => {
    setLoading(true);
    const { response, errorMessage } = await getPreferences(
      'products',
      0,
      200
    ).finally(() => setLoading(false));
    if (response?.data) {
      setPreferencesData(response.data);
    } else {
      message.error(errorMessage || '');
    }
  };

  useEffect(() => {
    getProductPreferences();
  }, []);

  const handlePreferenceChange = async (
    checked: boolean,
    productId: number
  ) => {
    setLoading(true);
    enableOrDisablePreferences('products', productId, checked).finally(() => {
      setLoading(false);
      getProductPreferences();
    });
  };

  useEffect(() => {
    if (onProductCreateClicked) {
      setShowCreateProduct(true);
    }
  }, [onProductCreateClicked]);

  useEffect(() => {
    if (searchText) {
      const tableToggleKeys = Object.keys(tableToggledOff);
      tableToggleKeys.forEach((key) => {
        const lobData = getLobData(preferencesData || [], key);
        const dataAfterSearch = checkForSearch<IProductPreferencesData>(
          lobData,
          searchText
        );
        setTableToggledOff((prev) => ({
          ...prev,
          [key]:
            dataAfterSearch.length === 0
              ? true
              : prev[key as keyof typeof prev],
        }));
      });
    } else {
      setTableToggledOff({
        'credit card': false,
        'un secured': false,
        secured: false,
      });
    }
  }, [searchText]);

  const handleDelete = async (productId: number) => {
    try {
      setLoading(true);
      const response = await deleteProductPreference(productId);
      if (response.success) {
        message.success('Product deleted successfully');
        setPreferencesData(
          (prev) => prev?.filter((e) => e.productId !== productId)
        );
      } else {
        message.error(response.errorMessage || 'Failed to delete product');
      }
    } catch (e) {
      message.error('Failed to delete product');
    } finally {
      setLoading(false);
    }
  };

  const getTables = (lobKey: string, title: string) => {
    const lobData = getLobData(preferencesData || [], lobKey);
    const dataAfterSearch = checkForSearch<IProductPreferencesData>(
      lobData,
      searchText
    );
    const isToggleOn = tableToggledOff[lobKey as keyof typeof tableToggledOff];

    return (
      <article className="product_container">
        <SettingsTableView
          caption={title}
          noOfRecords={dataAfterSearch.length || undefined}
          hideSearch={true}
          hideTable={isToggleOn}
          columns={productPreferencesColumns(
            handlePreferenceChange,
            editPermissionEnabled,
            handleDelete
          )}
          dataSource={dataAfterSearch}
          tableHeaderRightComponent={
            <section
              className="flex items-center cursor-pointer"
              onClick={() => {
                setTableToggledOff((prev) => ({
                  ...prev,
                  [lobKey as keyof typeof tableToggledOff]:
                    !prev[lobKey as keyof typeof tableToggledOff],
                }));
              }}
            >
              <YbCoreIcon
                size={16}
                name={isToggleOn ? 'chevron-down' : 'chevron-up'}
              />
            </section>
          }
          isPaginationEnabled={false}
          paddingHorizontal="0px"
        />
      </article>
    );
  };

  const showUserInfo = !preferencesData?.length && !loading;

  return (
    <AuthProvider permission={VIEW_PREFERENCES} showFriendlyScreen>
      <div className="flex flex-col w-full h-full">
        <SpinnerView spinning={loading}>
          {showUserInfo ? (
            <article className="flex w-full h-full justify-center items-center">
              <UserInfo
                headingText={'Add product preferences'}
                subText={[]}
                buttonComponent={
                  <div className="flex flex-col justify-center items-center ">
                    <TextView
                      text={`Add the asset classes and sub classes you support`}
                      align="center"
                      color="#667085"
                    />
                    <div className="py-4">
                      <ButtonViewV3
                        label={'Add Products'}
                        onClick={() => {
                          setShowCreateProduct(true);
                        }}
                      />
                    </div>
                  </div>
                }
                imageUrl={NoData}
              />
            </article>
          ) : (
            <div className="py-6 flex flex-col gap-6">
              {getTables('credit card', 'Credit card')}
              {getTables('un secured', 'Unsecured loans')}
              {getTables('secured', 'Secured loans')}
            </div>
          )}
        </SpinnerView>
      </div>
      {showCreateProduct && (
        <CreateProduct
          open={showCreateProduct}
          onClose={(isCreateSuccessful: boolean) => {
            if (isCreateSuccessful) {
              getProductPreferences();
            }
            if (resetProductCreateClicked) {
              resetProductCreateClicked();
            }
            setShowCreateProduct(false);
          }}
        />
      )}
    </AuthProvider>
  );
};

export default ProductPreferences;
