import {
  ButtonView,
  DividerView,
  SearchView,
  SpinnerView,
  TableView,
  TabsView,
  TextView,
} from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import CreateConditionRule from './CreateConditionRule';
import {
  cloneCondition,
  deleteCondition,
  fetchCondition,
  getConditionRules,
  getConditionsList,
} from './shared/api';
import { conditionRulesColumns } from './shared/constants';
import { IOffsetPagination } from 'types';
import {
  IConditionRule,
  IConditionRules,
  IDispositionOption,
  IGetConditionRulesPayload,
  IRule,
} from './shared/contract';
import { message } from 'antd';
import { conditionRuleEndpoint } from 'services/api/endPoints';
import useFilters from 'hooks/useFilters';
import DeleteComponent from 'features/Settings/components/DeleteComponent';
import { convertFirstLetterToCapital } from './shared/helper';
import BreadCrumb from './components/BreadCrumb';
import { useNavigate } from 'react-router';

const ConditionRule = () => {
  const [activeStep, setActiveStep] = useState(() => 'activated');
  const [onCreateClicked, setOnCreateClicked] = useState(false);
  const [paginationData, setPaginationData] = useState<IOffsetPagination>();
  const [loading, setLoading] = useState(false);
  const [dispositionList, setDispositionList] = useState<
    Array<IDispositionOption>
  >([]);
  const [conditionRule, setConditionRule] = useState<IRule[]>([]);
  const [conditionRules, setConditionRules] = useState<IConditionRules>({});
  const [editRule, setEditRule] = useState<IConditionRule>();
  const [searchText, setSearchText] = useState('');
  const {
    appliedFilterState,
    filterComponent,
    handleResetFilters,
    fetchFilterData,
  } = useFilters(conditionRuleEndpoint.GET_FILTERS(activeStep), true);
  const [showDelete, setShowDelete] = useState<number>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const fetchConditionRules = async (
    stepSelected: string,
    limit = 10,
    offset = 0,
    searchCriteria = '',
    filters = {}
  ) => {
    setLoading(true);
    try {
      const payload: IGetConditionRulesPayload = {
        status: stepSelected,
        offset,
        limit,
        searchCriteria,
        filters,
      };
      const { response, errorMessage } = await getConditionRules(payload);
      if (errorMessage) {
        message.error(errorMessage);
      } else {
        setConditionRule(response?.data ?? []);
        setPaginationData({
          offset,
          limit,
          totalElements: response?.totalRecords ?? 0,
          onPaginationChange: (offset, limit) => {
            fetchConditionRules(
              stepSelected,
              limit,
              offset,
              searchCriteria,
              filters
            );
          },
        });
      }
    } catch (error) {
      message.error('Failed to fetch condition rules');
    } finally {
      setLoading(false);
    }
  };

  const getConditions = async () => {
    const { response, errorMessage } = await getConditionsList();
    if (response?.data && response.data.length > 0) {
      const conditionsRule: IConditionRules = {};
      const dispositions: string[] = response.data.reduce(
        (uniqueDispositions: string[], currDisposition) => {
          const { mainDisposition, conditionOption, dispositionType } =
            currDisposition;
          if (!uniqueDispositions.includes(mainDisposition)) {
            uniqueDispositions.push(mainDisposition);
          }
          const string = [
            conditionOption,
            mainDisposition,
            dispositionType,
          ].join('-');
          conditionsRule[`${string}`] = currDisposition.conditionId || 0;
          return uniqueDispositions;
        },
        []
      );
      setConditionRules(conditionsRule);
      setDispositionList(
        dispositions.map((disposition) => ({
          label: convertFirstLetterToCapital(disposition),
          value: disposition,
        }))
      );
    }
    if (errorMessage) {
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    getConditions();
  }, []);

  const handleResponse = (
    response: { errorMessage?: string },
    successMessage: string
  ) => {
    if (response.errorMessage) {
      message.error(response.errorMessage);
    } else {
      message.success(successMessage);
      const { limit } = paginationData || {};
      fetchConditionRules(activeStep, limit, 0, searchText, appliedFilterState);
    }
  };

  const handelUserAction = (action: string, ruleId: number) => {
    if (action === 'clone') {
      setLoading(true);
      cloneCondition(ruleId)
        .then((response) => {
          handleResponse(response, 'Condition rule cloned successfully');
          const { limit, offset } = paginationData || {};
          fetchConditionRules(
            activeStep,
            limit,
            offset,
            searchText,
            appliedFilterState
          );
        })
        .catch(() => {
          message.error('Failed to clone condition rule');
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (action === 'edit') {
      setLoading(true);
      fetchCondition(ruleId)
        .then((response) => {
          if (response.errorMessage) {
            message.error(response.errorMessage);
          } else {
            setEditRule(response.response);
            setOnCreateClicked(true);
            setIsEdit(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (action === 'delete') {
      setShowDelete(ruleId);
    } else {
      setLoading(true);
      fetchCondition(ruleId)
        .then((response) => {
          if (response.errorMessage) {
            message.error(response.errorMessage);
          } else {
            setEditRule(response.response);
            setOnCreateClicked(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSearchTextChange = (newText: string) => {
    setSearchText(newText);
    const { limit } = paginationData || {};
    fetchConditionRules(activeStep, limit, 0, newText, appliedFilterState);
  };

  useEffect(() => {
    const { limit } = paginationData || {};
    fetchConditionRules(activeStep, limit, 0, searchText, appliedFilterState);
  }, [appliedFilterState]);

  useEffect(() => {
    fetchFilterData();
  }, [activeStep]);

  return (
    <SpinnerView spinning={loading}>
      <div className="py-6 flex flex-col">
        <div>
          <BreadCrumb
            data={[
              {
                name: 'Home',
                isActive: false,
                onClick: () => navigate('/report'),
              },
              {
                name: 'Condition List',
                isActive: true,
                onClick: () => navigate('/campaign/condition_rule'),
              },
            ]}
          />
        </div>
        <div className="flex justify-between items-center">
          <TextView
            text={'Condition rules'}
            size={28}
            lineHeight={42}
            color="#101828"
            weight="900"
          />
          <div>
            <ButtonView
              label={'Create Condition Rule'}
              onClick={() => setOnCreateClicked(true)}
            />
          </div>
        </div>
        <div className="pt-5">
          <DividerView lineColor="#F2F4F7" />
        </div>
        <div className="pt-4 flex justify-between">
          <TabsView
            activeKey={activeStep}
            tabItems={[
              { key: 'activated', title: 'Active rules' },
              { key: 'deactivated', title: 'Paused rules' },
              { key: 'drafted', title: 'Drafts' },
            ]}
            onTabClick={(key: string) => {
              setActiveStep(key);
              setSearchText('');
              handleResetFilters();
              // fetchConditionRules(key);
            }}
          ></TabsView>
          <div className="flex gap-3">
            <div>
              <SearchView
                value={searchText}
                onChange={handleSearchTextChange}
                placeholder="search by name"
              />
            </div>
            <div style={{ zIndex: 11 }}>{filterComponent}</div>
          </div>
        </div>
        <div className="pt-4">
          <TableView
            columns={conditionRulesColumns(handelUserAction)}
            dataSource={conditionRule}
            pagination={paginationData}
          />
        </div>
        {onCreateClicked && (
          <CreateConditionRule
            handelUserAction={handelUserAction}
            editRule={editRule}
            conditionRule={conditionRules}
            open={onCreateClicked}
            handleCreateCondition={(value) => {
              if (value) {
                fetchConditionRules(activeStep);
                fetchFilterData();
              }
              setEditRule(undefined);
              setOnCreateClicked(false);
              setIsEdit(false);
            }}
            dispositionOptions={dispositionList}
            isEdit={isEdit}
          />
        )}
      </div>
      <DeleteComponent
        showDelete={!!showDelete}
        headingText={'Are you sure you want to exit.'}
        subHeadingText={
          'Any unsaved progress will be lost. Are you sure you want to exit?'
        }
        onDelete={(confirmDelete: boolean) => {
          if (confirmDelete && showDelete) {
            setLoading(true);
            deleteCondition(showDelete)
              .then((response) => {
                handleResponse(response, 'Condition rule deleted successfully');
              })
              .catch(() => {
                message.error('Failed to delete condition rule');
              })
              .finally(() => {
                setLoading(false);
                setShowDelete(undefined);
              });
          } else {
            setShowDelete(undefined);
          }
        }}
        cancelText="No, Cancel"
        confirmText="Yes, Delete"
      />
    </SpinnerView>
  );
};

export default ConditionRule;
