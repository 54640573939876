import dayjs from 'dayjs';
import { convertISOToLocaleDateString } from 'features/Campaign/Campaign';
import { State } from 'features/CampaignPlanning/Tab';
import { DateList } from 'redux/slice/strategy/strategy';

export function getDatesBetween(currentDate: Date, endDate: Date): DateList[] {
  const dates: DateList[] = [];
  if (currentDate > endDate)
    throw new Error('Start date must be before end date');
  let count = 1;
  while (currentDate <= endDate) {
    dates.push({
      date: convertISOToLocaleDateString(new Date(currentDate).toISOString()),
      state: State.FUTURE,
      dayCount: count++,
    });
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }

  return dates;
}

export function formatDuration(startDate?: string, endDate?: string) {
  if (!startDate || !endDate) return '';

  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (!(start.year() === end.year())) {
    return `Duration: ${start.format('DD MMM YYYY')}-${end.format(
      'DD MMM YYYY'
    )}`;
  }

  if (!(start.month() === end.month())) {
    return `Duration: ${start.format('DD MMM')}-${end.format('DD MMM YYYY')}`;
  }
  return `Duration: ${start.format('DD')}-${end.format('DD MMM YYYY')}`;
}
