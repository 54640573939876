import React from 'react';
import UserInfo from 'templates/UserInfo';
import Group from 'assets/img/group.svg';
import { TextView } from 'components';

type FriendlyScreenProps = {
  headingText?: string;
};

const restrictText = `Whoops! It seems you've stumbled upon a restricted page.`;
const FriendlyScreen: React.FC<FriendlyScreenProps> = ({
  headingText = restrictText,
}) => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <UserInfo
        imageUrl={Group}
        headingText={headingText}
        subText={[]}
        buttonComponent={
          <div className="flex flex-col justify-center items-center ">
            <TextView
              text={`Reach out to your POC from SpoctoX to get access`}
              color="#667085"
            />
            <div>
              <TextView text={` or  mail us at `} color="#667085" />
              <a className="mail_link" href="mailto:help@spocto.com">
                help@spocto.com
              </a>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default FriendlyScreen;
