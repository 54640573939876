import './i18/I18';
import React from 'react';
import ReactDOM from 'react-dom/client';
import PhoenixProvider from '@yubi/phoenix-provider';
import StyleProvider from '@yubi/yb-style-provider';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { enableMapSet } from 'immer';
import { styled } from 'styled-components';
enableMapSet();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const tokensBaseUrl = process.env.REACT_APP_PHOENIX_TOKENS_API_BASE_URL_V3;

const StyledWrapper = styled.div`
  height: 100%;
  & > * {
    height: 100%;
  }
`;
root.render(
  <StyledWrapper>
    <PhoenixProvider platform="web" baseUrl={tokensBaseUrl || ''}>
      <StyleProvider
        baseUrl={process.env.REACT_APP_PHOENIX_TOKENS_API_BASE_URL || ''}
      >
        <App />
      </StyleProvider>
    </PhoenixProvider>
  </StyledWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
