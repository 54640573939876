import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getChannelPreferenceData } from 'features/Settings/shared/helper';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getChannelPreferencesRequestFailed,
  getChannelPreferencesRequestSucceeded,
  getChannelPreferencesRequested,
} from 'redux/slice/settings/Preferences/ChannelPreference.slice';
import { IGetPreferencePayload } from 'redux/types/Preference.types';
import { IError } from 'redux/types/UserOnboardDetails.types';
import { settingsEndpoints } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';

function* getChannelPreferenceSaga({
  payload,
}: PayloadAction<IGetPreferencePayload>) {
  try {
    const { offset, limit, searchCriteria } = payload;
    const response: AxiosResponse<any> = yield HTTPClient.GetRequest(
      settingsEndpoints.GET_PREFERENCES(
        'channels',
        offset,
        limit,
        searchCriteria
      )
    );

    yield put(
      getChannelPreferencesRequestSucceeded(
        getChannelPreferenceData(response.data.data)
      )
    );
  } catch (err) {
    yield put(
      getChannelPreferencesRequestFailed({
        error: 'Error while fetching channel preferences',
      } as IError)
    );
  }
}

export function* watchGetChannelPreferenceSaga() {
  yield takeLatest(
    getChannelPreferencesRequested.type,
    getChannelPreferenceSaga
  );
}
