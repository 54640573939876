import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IOutreachFilters,
  IOutreachListProps,
} from '../../saga/campaign-planner/Outreach.types';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
  IActionPayload,
  IOutreachListInitialState,
} from '../../types/Outreach.types';
import { ISliderData } from 'components/FilterViewAdvanceV2/filterTyes';

export const outreachListInitialState: IOutreachListInitialState = {
  outreachData: [],
  singleOutreach: {
    channel: '',
    content: '',
    day: 0,
    isLibrary: true,
    isMapped: false,
    language: '',
    name: '',
    contentId: '',
    outreachId: 0,
    placeholderMsg: '',
    slot: '',
    lastModifiedDate: '',
    createdDate: '',
  },
  totalRecords: 0,
  outreachFilters: [],
  isLoading: false,
  createAndUpdateStatus: APIStatus.INIT,
  status: APIStatus.INIT,
  error: {},
};

const updatedOutreach = (
  newOutreach: IOutreachListProps,
  outreachList: IOutreachListProps[]
) => {
  const index = outreachList.findIndex(
    (newObj: IOutreachListProps) => newObj.outreachId === newOutreach.outreachId
  );

  // If the object with the given id is found, replace it; otherwise, do nothing
  if (index !== -1) {
    outreachList[index] = newOutreach;
  }
  return outreachList;
};

const outreachListSlice = createSlice({
  name: 'outreachInformation',
  initialState: outreachListInitialState,
  reducers: {
    fetchOutreachList: (
      state: IOutreachListInitialState,
      action: PayloadAction<
        | {
            offset: number;
            limit: number;
            appliedFilterState: Record<string, string[] | ISliderData>;
            searchText: string;
          }
        | undefined
      >
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchOutreachListSucceeded: (
      state: IOutreachListInitialState,
      action: PayloadAction<{
        outreachData: IOutreachListProps[];
        totalRecords: number;
      }>
    ) => {
      state.outreachData = action.payload.outreachData;
      state.totalRecords = action.payload.totalRecords;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchOutreachListFailed: (
      state: IOutreachListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    fetchOutreachById: (
      state: IOutreachListInitialState,
      action: PayloadAction<{ outreachId: number }>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchOutreachByIdSucceeded: (
      state: IOutreachListInitialState,
      action: PayloadAction<IOutreachListProps>
    ) => {
      state.singleOutreach = action.payload;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchOutreachByIdFailed: (
      state: IOutreachListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    createOutreach: (
      state: IOutreachListInitialState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
      state.createAndUpdateStatus = APIStatus.PENDING;
    },

    createOutreachSucceeded: (
      state: IOutreachListInitialState,
      action: PayloadAction<IOutreachListProps>
    ) => {
      // state.outreachData = [...state.outreachData, action.payload];
      // state.outreachData.push(action.payload);
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
      state.totalRecords = state.totalRecords + 1;
      state.createAndUpdateStatus = APIStatus.SUCCESS;
    },
    createOutreachFailed: (
      state: IOutreachListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
      state.createAndUpdateStatus = APIStatus.ERROR;
    },
    updateOutreach: (
      state: IOutreachListInitialState,
      action: PayloadAction<any>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
      state.createAndUpdateStatus = APIStatus.PENDING;
    },
    updateOutreachSucceeded: (
      state: IOutreachListInitialState,
      action: PayloadAction<IOutreachListProps>
    ) => {
      state.outreachData = updatedOutreach(action.payload, state.outreachData);
      // state.outreachData = state.outreachData.findIndex((updatedOutreach)=> updatedOutreach.outreachId === action.payload.outreachId);
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
      state.createAndUpdateStatus = APIStatus.SUCCESS;
    },
    updateOutreachFailed: (
      state: IOutreachListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.createAndUpdateStatus = APIStatus.ERROR;
      state.status = APIStatus.ERROR;
    },
    deleteOutreach: (
      state: IOutreachListInitialState,
      action: PayloadAction<{
        outreachId: number;
      }>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    deleteOutreachSucceeded: (
      state: IOutreachListInitialState,
      action: PayloadAction<{ outreachId: number }>
    ) => {
      // state.outreachData = [...state.outreachData, action.payload];
      state.outreachData = state.outreachData.filter((newOutreach) => {
        if (newOutreach.outreachId === action.payload.outreachId) {
          return false;
        }
        return true;
      });
      state.totalRecords = state.totalRecords - 1;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    deleteOutreachFailed: (
      state: IOutreachListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    getOutreachFilters: (state: IOutreachListInitialState) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    getOutreachFiltersSucceeded: (
      state: IOutreachListInitialState,
      action: PayloadAction<IOutreachFilters[]>
    ) => {
      state.outreachFilters = action.payload;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    getOutreachFiltersFailed: (
      state: IOutreachListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    resetOutreachListState: () => outreachListInitialState,
    resetCreateUpdateStatus: (state: IOutreachListInitialState) => {
      state.createAndUpdateStatus = APIStatus.INIT;
    },
  },
});

export const {
  fetchOutreachList,
  fetchOutreachListSucceeded,
  fetchOutreachListFailed,
  fetchOutreachById,
  fetchOutreachByIdSucceeded,
  fetchOutreachByIdFailed,
  createOutreach,
  createOutreachSucceeded,
  createOutreachFailed,
  updateOutreach,
  updateOutreachSucceeded,
  updateOutreachFailed,
  deleteOutreach,
  deleteOutreachFailed,
  deleteOutreachSucceeded,
  getOutreachFilters,
  getOutreachFiltersSucceeded,
  getOutreachFiltersFailed,
  resetOutreachListState,
  resetCreateUpdateStatus,
} = outreachListSlice.actions;

export default outreachListSlice.reducer;
