import React from 'react';
import YbCoreRangeSlider from '@yubi/yb-core-range-slider-custom';
import { useStyles } from '@yubi/yb-style-provider';
import { ISliderData } from 'components/FilterViewAdvanceV2/filterTyes';

interface SliderViewProps {
  min: number;
  max: number;
  dataLow: number;
  dataHigh: number;
  setSelectedFilter: (value: string[] | ISliderData) => void;
}

const SliderView = ({
  min,
  max,
  dataLow,
  dataHigh,
  setSelectedFilter,
}: SliderViewProps) => {
  const { styleConnector } = useStyles();
  return (
    <YbCoreRangeSlider
      min={min}
      max={max}
      low={dataLow}
      high={dataHigh}
      disableRange={false}
      allowLabelOverflow
      renderLabel
      disabled={false}
      headerProps={{
        inputFieldProps: {
          width: 100,
        },
      }}
      onValueChange={(low, high) => {
        setSelectedFilter({ min: low, max: high });
      }}
      styleConnector={styleConnector}
    />
  );
};

export default SliderView;
