import React from 'react';
import styled, { css } from 'styled-components';

interface IImageViewProps {
  src: string;
  testID?: string;
  className?: string;
  onClick?: (event?: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  pointer?: boolean;
  disabled?: boolean;
}

const hoverAndClickStyles = css`
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }
`;

const StyledImage = styled.img<IImageViewProps>`
  &:hover {
    cursor: ${({ disabled, onClick }) => {
      if (disabled) {
        return 'not-allowed';
      }
      return onClick ? 'pointer' : 'default';
    }};
  }
  ${(props) => !props.disabled && props.onClick && hoverAndClickStyles}
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`;

const ImageView: React.FC<IImageViewProps> = ({
  src,
  onClick,
  style,
  className,
  testID,
  disabled,
}) => {
  return (
    <StyledImage
      disabled={disabled}
      data-testid={testID}
      onClick={disabled ? undefined : onClick}
      src={src}
      style={style}
      className={className}
    />
  );
};

ImageView.defaultProps = {
  pointer: false,
  disabled: false,
};
export default ImageView;
