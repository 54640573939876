import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'redux/types/UserOnboardDetails.types';
import { APIStatus } from 'services/api/apiStatusCode';
import {
  IDefaultPolicy,
  IDefaultPolicyState,
} from '../../../types/Compliance.types';

export const defaultPolicyInitialState: IDefaultPolicyState = {
  status: APIStatus.INIT,
};

export interface IGetDefaultPolicyPayload {
  region: string;
  entityId: string;
  entityName: string;
}

const defaultPolicySlice = createSlice({
  name: 'defaultPolicy',
  initialState: defaultPolicyInitialState,
  reducers: {
    getDefaultPolicyRequested(
      state: IDefaultPolicyState,
      _action: PayloadAction<IGetDefaultPolicyPayload>
    ) {
      state.status = APIStatus.PENDING;
    },
    getDefaultPolicyRequestSucceeded(
      state: IDefaultPolicyState,
      action: PayloadAction<IDefaultPolicy>
    ) {
      state.status = APIStatus.SUCCESS;
      state.data = action.payload;
    },
    getDefaultPolicyRequestFailed(
      state: IDefaultPolicyState,
      action: PayloadAction<IError>
    ) {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
  },
});

export const {
  getDefaultPolicyRequested,
  getDefaultPolicyRequestSucceeded,
  getDefaultPolicyRequestFailed,
} = defaultPolicySlice.actions;

export default defaultPolicySlice.reducer;
