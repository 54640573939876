import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/store';

const useDisablePlan = () => {
  const currentStatus = useSelector(
    (store: RootState) => store.strategy.campaignPlanner.currentStatus
  );

  return ['CAMPAIGN_PLAN_SUBMITTED', 'UPDATED_PLAN_SUBMITTED'].includes(
    currentStatus
  );
};

export default useDisablePlan;
