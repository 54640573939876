import * as React from 'react';

const FolderPlus = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clip-path="url(#clip0_1004_38158)">
        <g id="Vector">
          <mask id="path-1-inside-1_1004_38158" fill="white">
            <path d="M8 12.668H3.33333C2.97971 12.668 2.64057 12.5275 2.39052 12.2774C2.14048 12.0274 2 11.6883 2 11.3346V4.0013C2 3.64768 2.14048 3.30854 2.39052 3.05849C2.64057 2.80844 2.97971 2.66797 3.33333 2.66797H6L8 4.66797H12.6667C13.0203 4.66797 13.3594 4.80844 13.6095 5.05849C13.8595 5.30854 14 5.64768 14 6.0013V8.33464" />
          </mask>
          <path
            d="M8 13.918C8.69036 13.918 9.25 13.3583 9.25 12.668C9.25 11.9776 8.69036 11.418 8 11.418V13.918ZM2 11.3346H0.75H2ZM3.33333 2.66797V1.41797V2.66797ZM6 2.66797L6.88388 1.78409C6.64946 1.54966 6.33152 1.41797 6 1.41797V2.66797ZM8 4.66797L7.11612 5.55185C7.35054 5.78627 7.66848 5.91797 8 5.91797V4.66797ZM12.6667 4.66797V3.41797V4.66797ZM12.75 8.33464C12.75 9.02499 13.3096 9.58464 14 9.58464C14.6904 9.58464 15.25 9.02499 15.25 8.33464H12.75ZM8 11.418H3.33333V13.918H8V11.418ZM3.33333 11.418C3.31123 11.418 3.29004 11.4092 3.27441 11.3936L1.50664 13.1613C1.99111 13.6458 2.64819 13.918 3.33333 13.918V11.418ZM3.27441 11.3936C3.25878 11.3779 3.25 11.3567 3.25 11.3346H0.75C0.75 12.0198 1.02217 12.6769 1.50664 13.1613L3.27441 11.3936ZM3.25 11.3346V4.0013H0.75V11.3346H3.25ZM3.25 4.0013C3.25 3.9792 3.25878 3.958 3.27441 3.94238L1.50664 2.17461C1.02217 2.65908 0.75 3.31616 0.75 4.0013H3.25ZM3.27441 3.94238C3.29004 3.92675 3.31123 3.91797 3.33333 3.91797V1.41797C2.64819 1.41797 1.99111 1.69014 1.50664 2.17461L3.27441 3.94238ZM3.33333 3.91797H6V1.41797H3.33333V3.91797ZM5.11612 3.55185L7.11612 5.55185L8.88388 3.78409L6.88388 1.78409L5.11612 3.55185ZM8 5.91797H12.6667V3.41797H8V5.91797ZM12.6667 5.91797C12.6888 5.91797 12.71 5.92675 12.7256 5.94238L14.4934 4.17461C14.0089 3.69014 13.3518 3.41797 12.6667 3.41797V5.91797ZM12.7256 5.94238C12.7412 5.958 12.75 5.9792 12.75 6.0013H15.25C15.25 5.31616 14.9778 4.65908 14.4934 4.17461L12.7256 5.94238ZM12.75 6.0013V8.33464H15.25V6.0013H12.75Z"
            fill="#FD7149"
            mask="url(#path-1-inside-1_1004_38158)"
          />
        </g>
        <path
          id="Vector_2"
          d="M10.667 12.668H14.667"
          stroke="#FD7149"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M12.667 10.668V14.668"
          stroke="#FD7149"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1004_38158">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FolderPlus;
