export interface ILocationPreferenceData {
  country?: string;
  region?: string;
  zone?: string;
  state: string;
  circle?: string;
  network?: string;
  locationId: number;
  enabled: boolean;
}

export interface IProductPreferencesData {
  assetClassCategory: string;
  assetClassSubCategory: string;
  enabled: boolean;
  lineOfBusiness: string;
  productId: number;
  productName: string;
}

export interface IChannelPreferenceData {
  channelPreferenceId: number;
  channelProductName: string;
  enabled: boolean;
  displayName: string;
}

export interface IPreferenceCondition {
  field_name: string;
  operator: string;
  values: Array<string>;
}

export interface IBuckerPreference {
  bucket_name: string;
  id: number;
  logics: Array<IPreferenceCondition>;
  enable: boolean;
}

export interface ICampaignTime {
  start: string;
  end: string;
}

export interface IWeekCompliance {
  sunday?: IChannelsCompliance;
  monday?: IChannelsCompliance;
  tuesday?: IChannelsCompliance;
  wednesday?: IChannelsCompliance;
  thursday?: IChannelsCompliance;
  friday?: IChannelsCompliance;
  saturday?: IChannelsCompliance;
  holiday?: IChannelsCompliance;
}

export interface IChannelsCompliance {
  [key: string]: IChannelConfig;
}

export interface IChannelConfig {
  campaignTime: ICampaignTime;
  campaignMaxIntensity: number;
  campaignTimeGapMinutes: number;
  isActive: boolean;
  isDefault?: boolean;
}

export enum DerivedFormulas {
  'ratio' = 'Ratio',
  'merge' = 'Merge',
  'split' = 'Split',
  'round_off' = 'Round Off',
  'round_up' = 'Round Up',
  'round_down' = 'Round Down',
  'range' = 'Range',
  'rename' = 'Rename',
  'discount' = 'Discount',
}

export interface IRange {
  label: string;
  minValue?: number;
  maxValue?: number;
}

export interface IDerivedField {
  id: number;
  fields: Array<string>;
  newFields: Array<string>;
  operation: string;
  separator: string;
  values: Array<object | string | number>;
  dataType: string;
  enabled: boolean;
}

export interface IDerivedNewField {
  fields: Array<string>;
  newFields: Array<string>;
  operation: string;
  separator: string;
  values: Array<object | string | number>;
  dataType?: string;
}

export interface ILMSField {
  id: number;
  displayName: string;
  completeJsonPath: string;
  fieldName?: string;
  dataType: string;
  isDerived: boolean;
  enabled: boolean;
}

export interface INumberInput {
  value: number;
  onChange: (value: number) => void;
  maxInput?: number;
  minInput?: number;
  size?: 's' | 'm';
  placeHolderText?: string;
  borderColor?: string;
}

export interface IComplianceConfigColumns {
  handleChange: (target: string, value: number | string | undefined) => void;
  onEditClick: (
    index: number,
    isSaveClicked: boolean,
    week: string,
    channel: string
  ) => void;
  editableArray: number[];
  week: string;
  data?: IChannelsCompliance;
  validationData?: IChannelsCompliance;
  showPolicyStatus?: boolean;
  channelPreferences: Array<IChannelPreferenceData>;
  hideAction?: boolean;
}

export interface IChannelComplianceData {
  [key: string]: IChannelConfig | string;
}

export interface IEditFlow {
  isEditable: boolean;
  validConditionRender: any;
  inValidConditionRender: any;
}

export interface IUpdateData {
  id: number;
  enabled: boolean;
}

export interface IExtractionCondition {
  comparison: string;
  fieldName: string;
  displayName: string;
  values: Array<string>;
}

export interface IExtraction {
  conditions: Array<IExtractionCondition>;
  enable: boolean;
  id: number;
  logicName: string;
  operator: string;
}

export interface IPolicyDetails {
  policyName: string;
  productName: string;
  bucketName: string;
  riskCategory: string;
  activeDays: string[];
}

export interface IPaymentCondition {
  completeJsonPath: string;
  displayName: string;
  fieldName: string;
  operator: string;
  values: Array<string>;
}

export interface ILinkValidity {
  product: string;
  days: number;
  hours: number;
}

export interface IPaymentGatewayConfig {
  id: number;
  paymentGatewayId: number;
  paymentGateway: string;
  partialPayment: boolean;
  linkValidityList: Array<ILinkValidity>;
  linkValidityLevel: string;
  paymentMethods: Array<{
    paymentMethod: string;
    enabled: boolean;
  }>;
}

export interface IInfoComp {
  leftComp: React.ReactNode;
  rightComp: React.ReactNode;
}

export interface IOutreach {
  slot: string;
  contentName: string;
  conditionRule?: {
    ruleName: string;
    conditionsCount: number;
    groupsCount: number;
  };
  isStrategy: boolean;
  campaign?: {
    channel: string;
    language: string;
  };
  strategyData?: {
    id: number;
    endDate: Date;
    startDate: Date;
    daysCount: number;
    totalNoOfOutreaches: number;
  };
}

export interface IDateData {
  [key: string]: ITimeData;
}
export interface ITimeData {
  [key: string]: IOutreach[];
}

export interface IContentPreference {
  id: number;
  userName: string;
  mobileNumber: string;
  emailId: string;
}

export interface IStrategyData {
  name: string;
  noOfOutReaches: number;
  noOfDays: string;
  startDate: Date;
  endDate: Date;
}

export interface ITabData {
  key: string;
  title: string;
  prop: string;
  message: string;
}

export interface ICampaignPause extends IPauseDuration {
  lobPreferences: Array<ILobData>;
}

export interface ILobData extends IPauseDuration {
  lobPreferencesId?: number;
  productCategory: string;
  productSubCategory?: string;
  bucket: Array<string>;
  enabled?: boolean;
}

export interface IPauseDuration {
  pauseDurationValue?: number;
  pauseDurationType: string;
}

export type IProductData = Omit<IProductPreferencesData, 'productId'>;

export type IBucketPreferenceData = Omit<IBuckerPreference, 'id'>;

export interface IBucketData {
  bucketName: string;
}
