import { put, takeLatest } from 'redux-saga/effects';
import { settingsEndpoints } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AxiosResponse } from 'axios';
import {
  IComplianceResponse,
  ILenderPolicy,
  IPolicy,
} from 'redux/types/Compliance.types';
import {
  IGetLenderPoliciesPayload,
  getLenderPoliciesRequestFailed,
  getLenderPoliciesRequestSucceeded,
  getLenderPoliciesRequested,
} from 'redux/slice/settings/Compliance/GetLenderPolicies.slice';

function* getLenderPoliciesSaga({
  payload,
}: PayloadAction<IGetLenderPoliciesPayload>) {
  const { region, entityId, offset, limit } = payload;
  const requestId = uuidv4();
  try {
    const response: AxiosResponse<IComplianceResponse<ILenderPolicy>> =
      yield HTTPClient.PostRequest(
        settingsEndpoints.GET_ALL_POLICIES,
        { region, lenderId: entityId, pageSize: limit, pageOffset: offset },
        { headers: { 'request-id': requestId } }
      );
    const responseData = { ...response.data.data, limit, offset };
    yield put(getLenderPoliciesRequestSucceeded(responseData));
  } catch (err) {
    yield put(
      getLenderPoliciesRequestFailed({ error: { message: 'Error' } } as any)
    );
  }
}

export function* watchGetLenderPoliciesSaga() {
  yield takeLatest(getLenderPoliciesRequested.type, getLenderPoliciesSaga);
}
