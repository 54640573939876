import { track } from '@amplitude/analytics-browser';
import { EMAIL_NOT_FOUND } from 'constants/constant';
import { useEffect } from 'react';
import { useAppSelector } from 'utils/hooks/useAppSelector';

const useAmplitude = (
  eventName?: string,
  requestObject?: Record<string, string | number | Record<string, unknown>[]>,
  shouldFetch = true
) => {
  const entityId = useAppSelector((state) => state.userConfiguration.entityId);

  const userEmail = useAppSelector(
    (state) => state.userEntities.userData?.email || EMAIL_NOT_FOUND
  );

  const trackEvent = (
    eventName: string,
    requestObject?: Record<string, unknown>
  ) => {
    track(eventName, {
      email: userEmail,
      entity: entityId,
      ...(requestObject || {}),
    });
  };

  useEffect(() => {
    if (!shouldFetch || !eventName?.length) return;
    trackEvent(eventName, requestObject);
  }, [userEmail, entityId]);

  const sendAmplitudeEvent = (
    eventName: string,
    requestObject?: Record<string, unknown>
  ) => {
    trackEvent(eventName, requestObject);
  };

  return {
    sendAmplitudeEvent,
  };
};

export default useAmplitude;
