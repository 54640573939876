import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserConfigurationInitialState {
  entityId?: string;
  entityName?: string;
  entityUrl?: string;
  lenderId: string;
  lenderName?: string;
  userGroup?: string;
  caUserId: string;
  isLoading: boolean;
  error: { message?: string };
}

export const userConfigurationInitialState: IUserConfigurationInitialState = {
  entityId: '',
  entityName: '',
  caUserId: '',
  entityUrl: '',
  lenderId: '',
  lenderName: '',
  userGroup: '',
  isLoading: false,
  error: {},
};

const userConfigurationSlice = createSlice({
  name: 'userInformation',
  initialState: userConfigurationInitialState,
  reducers: {
    setUserConfiguration: (
      state,
      {
        payload,
      }: PayloadAction<{
        entityId?: string;
        entityName?: string;
        entityUrl?: string;
        caUserId?: string;
        lenderId: string;
        lenderName?: string;
        userGroup?: string;
      }>
    ) => {
      state.entityId = payload.entityId;
      state.entityName = payload.entityName;
      state.entityUrl = payload?.entityUrl;
      state.userGroup = payload?.userGroup;
      state.lenderId = payload.lenderId;
      state.lenderName = payload?.lenderName;
      if (payload?.caUserId) state.caUserId = payload?.caUserId;
    },
    updateLenderIdInUserConfiguration: (state, { payload }: PayloadAction<{ lenderId: string; lenderName:string; }>) => {
      state.lenderId = payload.lenderId;
      state.lenderName = payload.lenderName;
    },
    updateEntityIdInUserConfiguration: (state, { payload }: PayloadAction<{ entityId: string; }>) => {
      state.entityId = payload.entityId;
    },
    resetUserConfigurationState: () => userConfigurationInitialState,
  },
});

export const { setUserConfiguration, resetUserConfigurationState, updateLenderIdInUserConfiguration, updateEntityIdInUserConfiguration } =
  userConfigurationSlice.actions;

export default userConfigurationSlice.reducer;