// ... Other imports ...

import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  fetchGetDerivedFieldSucceeded,
  fetchGetDerivedFieldFailed,
  fetchGetDerivedField,
} from 'redux/slice/derived-field/GetDerivedField.slice';
import { ApiStatusCodes } from 'services/api/apiStatusCode';
import { HTTPClient } from 'services/api/http-client';
import {
  IGetDerivedFieldResponse,
  ISagaGetDerivedFieldParam,
} from 'redux/slice/derived-field/types';
import { allocationEngineEndpoints } from 'services/api/endPoints';

// const { getDerivedFieldEndpoint } = AllocationEngineEndpoints; // Adjust according to your API endpoint structure

export function* fetchGetDerivedFieldSaga({
  payload,
}: PayloadAction<ISagaGetDerivedFieldParam>) {
  // Extract parameters from payload if needed

  try {
    const result: IGetDerivedFieldResponse = yield HTTPClient.GetRequest(
      allocationEngineEndpoints.GET_DERIVED_FIELDS(payload.lenderId, payload.allocationId)
    );

    if (
      result?.status === ApiStatusCodes.SUCCESS &&
      result?.data?.data?.data?.length > 0
    ) {
      const finalFilteredObject = result?.data?.data?.data?.reduce(
        (previous: any, current: any) => {
          if (current.distinctValues) {
            previous.push({
              fieldName: current.completeJsonPath,
              displayName: current.displayName,
              type: current.dataType,
              values: current.distinctValues,
              id: current.id,
              completeJsonPath: current.completeJsonPath,
            });
          }
          return previous;
        },
        []
      );
      yield put(fetchGetDerivedFieldSucceeded(finalFilteredObject));
    } else {
      yield put(
        fetchGetDerivedFieldFailed({
          error: 'Failed to load derived field data',
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchGetDerivedFieldFailed({
        error: 'An error occurred while fetching derived field data.',
      })
    );
  }
}

export function* watchGetDerivedFieldSaga() {
  yield takeLatest(fetchGetDerivedField.type, fetchGetDerivedFieldSaga);
}
