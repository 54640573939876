export type ScrollbarWidth = 'auto' | 'none' | 'thin';

const defaultStyle = {
  control: {
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 300,
    },
    highlighter: {
      padding: 9,
    },
    input: {
      padding: 9,
      border: '1px solid white',
      outline: 'none',
    },
  },

  suggestions: {
    marginTop: 30,

    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      borderRadius: 10,
      fontSize: 14,
      maxHeight: 250,
      overflow: 'auto',
      scrollbarWidth: 'thin' as ScrollbarWidth,
      scrollbarColor: '#8D8D8F #E9E9EB',
    },
    item: {
      padding: '5px 15px',
      overflow: 'auto',
      '&focused': {
        backgroundColor: 'rgb(233, 233, 235, 0.4)',
      },
    },
  },
};

export const modifyStyle = (parameter: boolean) => {
  const textColor = parameter ? '#101828' : '#475467';

  const modifiedStyle = {
    ...defaultStyle,
    '&multiLine': {
      ...defaultStyle['&multiLine'],
      control: {
        ...defaultStyle['&multiLine'].control,
        color: textColor,
      },
    },
  };

  return modifiedStyle;
};
