import React from 'react';
import { emptyIcon } from 'assets/icon';
import ImageView from 'components/ImageView/ImageView';
import styled from 'styled-components';
import TextView from 'components/TextView';

const EmptyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 50px;
`;
const EmptyImageContainer = styled.div``;
const EmptyLabelContainer = styled.div`
  color: grey;
`;

interface IEmptyDataViewProps {
  emptyLabel?: string;
  className?: string;
}

function EmptyDataView(props: IEmptyDataViewProps) {
  const { emptyLabel, className } = props;
  return (
    <EmptyDataContainer className={className}>
      <EmptyImageContainer>
        <ImageView src={emptyIcon} />
      </EmptyImageContainer>
      <EmptyLabelContainer>
        <TextView
          text={emptyLabel || 'No data'}
          color="#98A2B3"
          lineHeight={20}
        />
      </EmptyLabelContainer>
    </EmptyDataContainer>
  );
}

export default EmptyDataView;
