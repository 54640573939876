import { useCallback, useEffect, useRef, useState } from 'react';
import { useBlocker } from 'react-router-dom';

export function usePrompt(
  onLocationChange: () => boolean,
  hasUnsavedChanges: boolean
) {
  const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}

type UsePromptWithMessageProps = {
  showPopUp?: boolean;
  messageText: string;
  callback: () => Promise<void>;
};
const usePromptWithMessage = ({
  showPopUp = false,
  messageText,
  callback,
}: UsePromptWithMessageProps) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(showPopUp);
  }, [showPopUp]);

  const onLocationChange = useCallback(() => {
    if (isEditing) {
      if (window.confirm(messageText)) {
        callback();
        return false;
      } else {
        return true;
      }
    }
    return false;
  }, [isEditing]);

  usePrompt(onLocationChange, isEditing);

  const setIsEditingState = (state: boolean) => {
    setIsEditing(state);
  };
  return { setIsEditingState, isEditing };
};

export default usePromptWithMessage;
