import React from 'react';
import DividerView from '../../components/DividerView';
import TextView from '../../components/TextView';
import { StyledWelcomeContainer } from '../../styles/commonStyledComponent';
import { BreadCrumbView } from 'components';

interface ITopBarProps {
  title: string;
  subTitle?: string;
  isBreadCrumb?: boolean;
  rightComponent?: React.ReactNode;
  leftComponent?: React.ReactNode;
  topMargin?: number;
  subText?: React.ReactNode;
  subText2?: React.ReactNode;
}
const TopBar = ({
  title,
  subTitle,
  rightComponent,
  isBreadCrumb,
  leftComponent,
  topMargin = 6,
  subText,
  subText2,
}: ITopBarProps) => {
  return (
    <StyledWelcomeContainer>
      <div>
        <div className={`mt-${topMargin} flex justify-between items-center`}>
          <div>
            {isBreadCrumb && (
              <div className="">
                <BreadCrumbView />
              </div>
            )}
            <div className="flex flex-col gap-2 mb-6">
              <TextView size={24} weight={'700'} text={title} lineHeight={36} />
              {subTitle && (
                <TextView
                  size={14}
                  weight={'400'}
                  text={subTitle}
                  color="#667085"
                  lineHeight={20}
                />
              )}
              {subText && subText}
              {subText2 && subText2}
            </div>
            <div>{leftComponent}</div>
          </div>
          <div>{rightComponent} </div>
        </div>
        <DividerView lineColor={'#E7EAEE'} />
      </div>
    </StyledWelcomeContainer>
  );
};

export default TopBar;
