// Assuming your action creator fetchReportToken is correctly imported from the appropriate path and has the right type.

import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { userEndpoints } from '../../services/api/endPoints';
import { HTTPClient } from '../../services/api/http-client';
import {
  fetchReportToken,
  fetchReportTokenFailed,
  fetchReportTokenSucceeded,
} from '../slice/ReportPage.slice';
import {
  IFetchReportSagaParam,
  IReportPageResponse,
} from '../types/ReportPage.types';

export function* fetchReportPageSaga({
  payload,
}: PayloadAction<IFetchReportSagaParam>) {
  try {
    const result: IReportPageResponse = yield HTTPClient.GetRequest(
      userEndpoints.GET_SUPERSET_TOKEN(payload.dashboardType)
    );

    if (result?.data) {
      yield put(fetchReportTokenSucceeded(result?.data));
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(
      fetchReportTokenFailed({
        error: {
          message: 'Error occurred while fetching report token',
        },
      })
    );
  }
}

export function* watchFetchReportTokenSaga() {
  yield takeLatest(fetchReportToken.type, fetchReportPageSaga);
}
