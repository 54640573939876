import React from 'react';
import { StyledFlexDiv } from '../shared/styledComponents';

import { ColumnsType } from 'antd/es/table/interface';
import TableView from 'components/TableView';
import { IOffsetPagination } from 'types';
import TableHeader from 'components/TableHeader/TableHeader';

interface ISettingsTableProps {
  caption?: string;
  noOfRecords?: number;
  columns: ColumnsType<any>;
  isPaginationEnabled?: boolean;
  dataSource: Array<{
    [key: string]: any;
  }>;
  showNoOfSearchRecords?: boolean;
  onSearchTextChange?: (searchText: string) => void;
  paginationData?: IOffsetPagination;
  onNoOfRecordsPerPageChanged?: (noOfPagesPerPage: string) => void;
  onPageChange?: (pageNumber: number) => void;
  isLoading?: boolean;
  hideSearch?: boolean;
  searchPlaceHolder?: string;
  noDataText?: string;
  searchText?: string;
  rowClassName?: (record: any, index: number) => string;
  tableHeaderRightComponent?: React.ReactNode;
  hideTable?: boolean;
  paddingHorizontal?: string;
}

export const SettingsTableView = (props: ISettingsTableProps) => {
  const {
    caption,
    noOfRecords,
    columns,
    dataSource,
    onSearchTextChange,
    showNoOfSearchRecords,
    paginationData,
    isLoading,
    hideSearch,
    searchPlaceHolder,
    noDataText,
    searchText,
    rowClassName,
    tableHeaderRightComponent,
    hideTable,
    paddingHorizontal,
  } = props;

  return (
    <div className={paddingHorizontal || 'py-4'}>
      <StyledFlexDiv flexDirection="column" gap={'16px'}>
        {caption && (
          <TableHeader
            caption={caption}
            noOfRecords={noOfRecords}
            onSearchTextChange={onSearchTextChange}
            noOfRecordsAfterSearch={
              showNoOfSearchRecords ? dataSource.length : undefined
            }
            hideSearch={hideSearch}
            placeHolder={searchPlaceHolder}
            searchText={searchText}
            rightComponent={tableHeaderRightComponent}
          />
        )}
        {!hideTable && (
          <TableView
            dataSource={dataSource}
            columns={columns}
            isLoading={isLoading}
            pagination={paginationData}
            noDataText={noDataText}
            rowClassName={rowClassName}
          />
        )}
      </StyledFlexDiv>
    </div>
  );
};
