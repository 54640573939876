import React, { ComponentState, ReactElement } from 'react';
import { YbCoreButton } from '@yubi/yb-core-button';
import { useStyles } from '@yubi/yb-style-provider';
import { DimensionValue, ViewStyle } from 'react-native';

interface IButtonViewProps {
  size?: 's' | 'm' | 'l';
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'delete'
    | 'special';
  label: string;
  testID?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  leadingIcon?: ((state?: ComponentState) => ReactElement) | ReactElement;
  height?: DimensionValue | undefined;
  trailingIcon?: ((state?: ComponentState) => ReactElement) | ReactElement;
  loading?: boolean;
}

const ButtonView = ({
  size = 's',
  variant = 'primary',
  label,
  onClick,
  disabled,
  height,
  testID,
  leadingIcon,
  trailingIcon,
  loading = false,
}: IButtonViewProps) => {
  const { styleConnector } = useStyles();

  return (
    <YbCoreButton
      loading={loading}
      testID={testID}
      size={size}
      styleConnector={styleConnector}
      customStyles={
        height
          ? {
              containerStyle: (): ViewStyle => ({
                height,
              }),
            }
          : undefined
      }
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      leadingIcon={leadingIcon}
      trailingIcon={trailingIcon}
      label={label}
    />
  );
};

export default ButtonView;
