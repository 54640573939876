import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface ICustomModalProps {
  open: boolean;
  children?: React.ReactNode;
}

const CustomModal: React.FC<ICustomModalProps> = ({ open, children }) => {
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (open) {
      const siblingDiv = document.createElement('div');
      siblingDiv.id = 'sibling';
      siblingDiv.style.position = 'fixed';
      siblingDiv.style.top = '0';
      siblingDiv.style.left = '0';
      siblingDiv.style.width = '100vw';
      siblingDiv.style.height = '100dvh';
      siblingDiv.style.backgroundColor = '#fff';
      siblingDiv.style.zIndex = '9999';
      siblingDiv.style.overflowY = 'auto';
      siblingDiv.style.overflowX = 'auto';

      document.body.appendChild(siblingDiv);
      setModalRoot(siblingDiv);
    } else {
      const sibling = document.getElementById('sibling');
      if (sibling) {
        document.body.removeChild(sibling);
        setModalRoot(null);
      }
    }

    return () => {
      const sibling = document.getElementById('sibling');
      if (sibling) {
        document.body.removeChild(sibling);
      }
    };
  }, [open]);

  if (!open || !modalRoot) {
    return null;
  }

  return ReactDOM.createPortal(children, modalRoot);
};

export default CustomModal;
