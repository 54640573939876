import { useStyles } from '@yubi/yb-style-provider';
import TextView from 'components/TextView';
import React from 'react';
import styled from 'styled-components';
import YbCoreSpinner from '@yubi/yb-core-spinner';

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${Number.MAX_SAFE_INTEGER} !important;
`;
const SpinnerContainer = styled.div<{ blurBackground?: boolean }>`
  position: fixed !important;
  /* display: inline-block; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${Number.MAX_SAFE_INTEGER - 1000} !important;
  bottom: 0;
  right: 0;
  background-color: white !important;
  opacity: ${(props) => (props.blurBackground ? 0.4 : 1)} !important;
`;
interface YbCoreSpinnerProps {
  size?: 'large' | 'medium' | 'small';
  radius?: number;
  thickness?: number;
  label?: string;
  children?: React.ReactNode;
  spinning?: boolean;
  className?: string;
  testID?: string;
  labelPosition?: 'left' | 'right' | 'top' | 'bottom';
}

function Spinner({
  size = 'small',
  radius,
  thickness = 5,
  label,
  children,
  spinning,
  className,
  testID,
}: YbCoreSpinnerProps) {
  const { styleConnector } = useStyles();
  return (
    <>
      {spinning && (
        <SpinnerContainer className={className} blurBackground={spinning}>
          <SpinnerWrapper
            className={`flex flex-col items-center justify-center ${className}`}
          >
            <YbCoreSpinner
              size={size}
              testID={testID}
              durationInMills={1500}
              radius={radius}
              thickness={thickness}
              styleConnector={styleConnector}
            />
            {label && (
              <div className="my-1">
                <TextView text={label} />
              </div>
            )}
          </SpinnerWrapper>
        </SpinnerContainer>
      )}
      <>{children}</>
    </>
  );
}

export default Spinner;
