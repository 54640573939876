import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBuckerPreference } from 'features/Settings/shared/contract';
import { IError } from 'redux/types/Compliance.types';
import {
  IGetPreferencePayload,
  IBuckerPreferenceState,
} from 'redux/types/Preference.types';

import { APIStatus } from 'services/api/apiStatusCode';

export const bucketInitialState: IBuckerPreferenceState = {
  status: APIStatus.INIT,
};

const slice = createSlice({
  name: 'bucket',
  initialState: bucketInitialState,
  reducers: {
    getBucketPreferencesRequested: (
      state: IBuckerPreferenceState,
      _action: PayloadAction<IGetPreferencePayload>
    ) => {
      state.status = APIStatus.PENDING;
    },
    getBucketPreferencesRequestSucceeded: (
      state: IBuckerPreferenceState,
      action: PayloadAction<IBuckerPreference[]>
    ) => {
      state.status = APIStatus.SUCCESS;
      state.data = action.payload;
    },
    getBucketPreferencesRequestFailed: (
      state: IBuckerPreferenceState,
      action: PayloadAction<IError>
    ) => {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
  },
});

export const {
  getBucketPreferencesRequested,
  getBucketPreferencesRequestSucceeded,
  getBucketPreferencesRequestFailed,
} = slice.actions;

export default slice.reducer;
