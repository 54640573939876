import {
  FilterListType,
  IFilterOptions,
  ISliderData,
} from 'components/FilterViewAdvanceV2/filterTyes';
import FilterViewAdvance from 'components/FilterViewAdvanceV2/FilterViewAdvance';
import { FetchOutreachTrackingFilter } from 'features/OutreachConfig/components/TabContainer/CampaignStrategy';
import React, { useEffect, useMemo, useState } from 'react';
import axiosInstance from 'services/api/axios';

const useFilters = (url: string, pickDataFromData = false) => {
  const [filterState, setFilterState] = useState<
    Record<string, string[] | ISliderData>
  >({});
  const [appliedFilterState, setAppliedFilterState] = useState<
    Record<string, ISliderData | string[]>
  >({});
  const [filterOptionsList, setFilterOptionsList] = useState<IFilterOptions[]>(
    []
  );
  const updateFilterState = (
    filterName: string,
    value: ISliderData | string[]
  ) => {
    setFilterState((prevFilterState) => ({
      ...prevFilterState,
      [filterName]: value,
    }));
  };

  const generateFilterList = (
    data: string[] | ISliderData | number[]
  ): FilterListType | ISliderData => {
    if (Array.isArray(data)) {
      return data.reduce(
        (acc, val, index) => ({ ...acc, [index + 1]: val }),
        {}
      );
    } else {
      return data;
    }
  };

  const fetchFilterData = async () => {
    try {
      const filterData: FetchOutreachTrackingFilter = await axiosInstance.get(
        url
      );
      const newFilterOptionsList: IFilterOptions[] = [];

      filterData.data[pickDataFromData ? 'data' : 'filters']?.forEach(
        (filter) => {
          const filterStateKey = filter.name;
          if (filterStateKey) {
            // commented as fetching data from Api will reset all the filters.
            // const selectedFilter = Array.isArray(filter.data)
            //   ? filterState[filterStateKey as keyof typeof filterState] || []
            //   : filterState[filterStateKey as keyof typeof filterState] || {};
            newFilterOptionsList.push({
              name: filter.name,
              type: filter.type,
              label: filter.label,
              selectedFilter: [],
              setSelectedFilter: (value) =>
                updateFilterState(filter.name, value),
              data: generateFilterList(filter.data),
            });

            setFilterOptionsList(newFilterOptionsList);
          }
        }
      );
    } catch (e) {}
  };

  useEffect(() => {
    fetchFilterData();
  }, []);

  const handleResetFilters = () => {
    setAppliedFilterState({});
    setFilterOptionsList(
      filterOptionsList.map((filter) => ({
        ...filter,
        selectedFilter: [],
      }))
    );
  };

  const filterComponent = useMemo(
    () => (
      <FilterViewAdvance
        resetFilterFn={handleResetFilters}
        applyFilterFn={() => {
          setAppliedFilterState(filterState);
        }}
        options={filterOptionsList}
      />
    ),
    [filterOptionsList, filterState]
  );
  return {
    appliedFilterState,
    filterComponent,
    handleResetFilters,
    fetchFilterData,
  };
};

export default useFilters;
