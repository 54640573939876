import { combineReducers } from 'redux';
import {
  IDerivedFieldsState,
  IGetLMSFieldsState,
} from 'redux/types/LoanBook.types';
import getLMSFieldsReducer, {
  getLMSFieldsInitialState,
} from './GetLMSFields.slice';
import getDerivedFieldsReducer, {
  derivedFieldsInitialState,
} from './GetDerivedFileds.slice';

export interface ILoanBookState {
  lmsFields: IGetLMSFieldsState;
  derivedFields: IDerivedFieldsState;
}

export const loanBookInitialState = {
  lmsFields: getLMSFieldsInitialState,
  derivedFields: derivedFieldsInitialState,
};

export const loanBookReducer = combineReducers({
  lmsFields: getLMSFieldsReducer,
  derivedFields: getDerivedFieldsReducer,
});
