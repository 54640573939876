import { message } from "antd";
import { settingsEndpoints } from "services/api/endPoints";
import { HTTPClient } from "services/api/http-client";
import { v4 as uuidv4 } from "uuid";
import {
  IBucketPreferenceData,
  ICampaignPause,
  IContentPreference,
  IDerivedNewField,
  ILobData,
  IPaymentGatewayConfig,
  IPolicyDetails,
  IProductData,
  IUpdateData,
  IWeekCompliance,
} from "./contract";
import { Weeks } from "./constants";
import { IPaymentData } from "../components/PaymentUpdate";

export const getPreferences = async (
  preferenceType: string,
  offset = 0,
  limit = 10,
  searchCriteria = "",
) => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      settingsEndpoints.GET_PREFERENCES(
        preferenceType,
        offset,
        limit,
        searchCriteria,
      ),
    );
    if (response.data?.errors) {
      return {
        errorMessage: `Error while fetching ${preferenceType} preferences`,
      };
    } else {
      return { response: response.data };
    }
  } catch (err: any) {
    return {
      errorMessage: `Error while fetching ${preferenceType} preferences`,
    };
  }
};

export const enableOrDisablePreferences = async (
  preferenceType: string,
  preferenceId: number,
  isEnabled: boolean,
) => {
  message.destroy();
  try {
    const response: any = await HTTPClient.PatchRequest(
      settingsEndpoints.ENABLE_OR_DISABLE_PREFERENCES(
        preferenceType,
        preferenceId,
        isEnabled,
      ),
      {},
      {},
    );
    if (response?.data?.errors) {
      throw new Error(`Error while updating ${preferenceType} preferences`);
    }
    message.success(
      `${preferenceType} preferences ${
        isEnabled ? "enabled" : "disabled"
      } successfully`,
      5,
    );
    return { response: response.data };
  } catch (err: any) {
    const error = `Error while ${
      isEnabled ? "enabled" : "disabled"
    } ${preferenceType} preferences`;
    message.error(error);
    return {
      errorMessage: error,
    };
  }
};

interface INewChannel {
  channel: string;
  channelProduct: string;
  enabled: boolean;
}

export const createChannelPreference = async (data: INewChannel[]) => {
  try {
    const response: any = await HTTPClient.PostRequest(
      settingsEndpoints.CREATE_CHANNEL_PREFERENCES,
      data,
      {},
    );
    if (response.data?.errors) {
      throw new Error(`Error while creating channel preferences`);
    }
    return { response: response.data };
  } catch (err: any) {
    const error = err?.response?.data?.errors;
    return {
      errorMessage: `Error while creating channel preferences`,
    };
  }
};

export const uploadLocationPreferences = async (file: FormData) => {
  try {
    const response: any = await HTTPClient.PostRequest(
      settingsEndpoints.UPLOAD_LOCATION_PREFERENCES,
      file,
      {},
    );
    if (response.data?.errors) {
      throw new Error(`Error while uploading location preferences`);
    }
    return { response: response.data };
  } catch (err: any) {
    const error = err?.response?.data?.errors;
    return {
      errorMessage: `Error while uploading location preferences`,
    };
  }
};

export const getEntityDetails = async () => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      settingsEndpoints.GET_ENTITY_DETAILS,
    );
    return { response: response.data };
  } catch (err: any) {
    // const error = err?.response?.data?.errors;
    // return {
    //   errorMessage: error
    //     ? error?.message || error[0]?.message
    //     : 'Error while fetching organisation profile',
    // };
    return {
      errorMessage: `Error while fetching organization profile`,
    };
  }
};

export const getGlobalPolicies = async () => {
  const requestId = uuidv4();
  const region = process.env.REACT_APP_REGION || "IN";
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.Get_GLOBAL_POLICIES,
      { region },
      { headers: { "request-id": requestId } },
    );
    return response.data;
  } catch (err) {
    message.error("Error while fetching global policies");
    return {
      errorMessage: "Error while fetching default policies",
    };
  }
};

export const getDefaultPolicies = async (entityId: string) => {
  const requestId = uuidv4();
  const region = process.env.REACT_APP_REGION || "IN";
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.GET_DEFAULT_POLICIES,
      { region, lenderId: entityId },
      { headers: { "request-id": requestId } },
    );
    return response.data;
  } catch (err) {
    if ((err as any).response.data.code !== 404)
      message.error("Error while fetching default policies");
    return {
      errorMessage: "Error while fetching default policies",
    };
  }
};

interface IEntityInfo {
  entityId: string;
  entityName: string;
}

export const saveDefaultPolicies = async (
  data: IWeekCompliance,
  isSave: boolean,
  entityInfo: IEntityInfo,
  policyId?: string,
) => {
  const requestId = uuidv4();
  const { entityName, entityId } = entityInfo;
  const activeDays = Object.keys(Weeks);
  const region = process.env.REACT_APP_REGION || "IN";
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.SAVE_DEFAULT_POLICIES(isSave),
      {
        region: region,
        lenderId: entityId,
        lenderName: entityName,
        type: "lender default",
        ...data,
        activeDays,
        id: policyId,
      },
      { headers: { "request-id": requestId } },
    );
    message.success(
      `Default policies ${isSave ? "saved" : "updated"} successfully`,
      3,
    );
    return response.data;
  } catch (err) {
    message.error("Error while saving default policies");
    return {
      errorMessage: "Error while saving default policies",
    };
  }
};

export const getLmsFields = async () => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      settingsEndpoints.LMS_FIELDS,
    );

    return response.data;
  } catch (err) {
    return {
      errorMessage: `Error while fetching LMS fields`,
    };
  }
};

export const getDerivedFields = async () => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      settingsEndpoints.GET_DERIVED_FIELDS,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: `Error while fetching LMS fields`,
    };
  }
};

export const deleteDerivedField = async (id: number) => {
  try {
    const response = await HTTPClient.DeleteRequest<any>(
      settingsEndpoints.DELETE_DERIVED_FIELD,
      {
        data: [id],
      },
    );

    return response.data;
  } catch (err) {
    return {
      errorMessage: `Error while fetching LMS fields`,
    };
  }
};

export const createDerivedField = async (data: IDerivedNewField[]) => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.CREATE_DERIVED_FIELD,
      data,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: `Error while creating derived field`,
    };
  }
};

export const updateLMSFields = async (data: IUpdateData[]) => {
  try {
    const response = await HTTPClient.PatchRequest<any>(
      settingsEndpoints.LMS_FIELDS,
      data,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: `Error while updating LMS fields`,
    };
  }
};

export const updateDerivedFields = async (data: IUpdateData[]) => {
  try {
    const response = await HTTPClient.PatchRequest<any>(
      settingsEndpoints.UPDATE_DERIVED_FIELDS,
      data,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: `Error while updating derived fields`,
    };
  }
};

export const getAllPolicies = async (entityId: string) => {
  const requestId = uuidv4();
  const region = process.env.REACT_APP_REGION || "IN";
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.GET_ALL_POLICIES,
      { region, lenderId: entityId },
      { headers: { "request-id": requestId } },
    );
    return response.data;
  } catch (err) {
    if ((err as any).response.data.code !== 404)
      message.error("Error while fetching default policies");
    return {
      errorMessage: "Error while fetching default policies",
    };
  }
};

export const persistPolicy = async (
  data: IWeekCompliance,
  policyDetails: IPolicyDetails,
  entityInfo: IEntityInfo,
  isActive: boolean,
  policyId?: string,
) => {
  message.destroy();
  const requestId = uuidv4();
  const { entityName, entityId } = entityInfo;
  try {
    const region = process.env.REACT_APP_REGION || "IN";
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.SAVE_LENDER_POLICY(!!policyId),
      {
        region,
        lenderId: entityId,
        lenderName: entityName,
        type: "lender default",
        ...data,
        ...policyDetails,
        id: policyId,
        isActive,
        riskCategory: policyDetails.riskCategory?.toLowerCase() || "",
      },
      { headers: { "request-id": requestId } },
    );
    message.success(`Policy ${policyId ? "updated" : "saved"} successfully`, 3);
    return response.data;
  } catch (err) {
    message.error("Error while saving  policy");
    return {
      errorMessage: "Error while saving  policy",
    };
  }
};

export const updatePolicyStatus = async (
  isActive: boolean,
  lenderId: string,
  id: string,
) => {
  message.destroy();
  const requestId = uuidv4();
  const region = process.env.REACT_APP_REGION || "IN";
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.UPDATE_POLICY_STATUS,
      {
        region,
        lenderId,
        isActive,
        id,
      },
      { headers: { "request-id": requestId } },
    );
    message.success(
      `Policy ${isActive ? "enabled" : "disabled"} successfully`,
      3,
    );
    return response.data;
  } catch (err) {
    message.error("Error while saving  policy");
    return {
      errorMessage: "Error while saving  policy",
    };
  }
};

interface IGetLOBPolicies {
  lenderId: string;
  lob: Array<{
    product: string;
    bucket: string;
  }>;
  risk: Array<string>;
}

export const getLOBPolicies = async (body: IGetLOBPolicies) => {
  try {
    const requestId = uuidv4();
    const region = process.env.REACT_APP_REGION || "IN";
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.GET_LOB_POLICIES,
      { ...body, region },
      { headers: { "request-id": requestId } },
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: "Error while fetching policies",
    };
  }
};

export const getPaymentConfig = async () => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      settingsEndpoints.PAYMENT_CONFIG,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: "Error while fetching policies",
    };
  }
};

interface IPaymentPayload extends IPaymentData {
  paymentStatus: string;
}

export const createPaymentConfig = async (data: IPaymentPayload[]) => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.PAYMENT_CONFIG,
      data,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: "Error while fetching policies",
    };
  }
};

export const createLMSFields = async (data: any) => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.LMS_FIELDS,
      data,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: "Error while fetching policies",
    };
  }
};

export const updatePaymentConfig = async (data: IPaymentPayload[]) => {
  try {
    const response = await HTTPClient.PutRequest<any>(
      settingsEndpoints.PAYMENT_CONFIG,
      data,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: "Error while updating payment config",
    };
  }
};

export const getPaymentGateway = async () => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      settingsEndpoints.PAYMENT_GATEWAY_CONFIG,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: "Error while fetching payment gateway",
    };
  }
};

export const savePaymentGateway = async (data: IPaymentGatewayConfig[]) => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.PAYMENT_GATEWAY_CONFIG,
      data,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: "Error while saving payment gateway",
    };
  }
};

export const updatePaymentGateway = async (data: IPaymentGatewayConfig) => {
  try {
    const response = await HTTPClient.PatchRequest<any>(
      settingsEndpoints.PAYMENT_GATEWAY_CONFIG,
      data,
    );
    return response.data;
  } catch (err) {
    return {
      errorMessage: "Error while updating payment gateway",
    };
  }
};

export const getContentUsers = async () => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      settingsEndpoints.GET_CONTENT_PREFERENCE,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while fetching content preferences",
      };
    }

    return response.data.data;
  } catch (err) {
    return {
      errorMessage: `Error while fetching content preferences`,
    };
  }
};

type ISaveContentPayload = Omit<IContentPreference, "id">;

export const saveContentUsers = async (data: ISaveContentPayload[]) => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.GET_CONTENT_PREFERENCE,
      data,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while creating content preferences",
      };
    }

    return response.data.data;
  } catch (err) {
    return {
      errorMessage: `Error while creating content preferences`,
    };
  }
};

export const editContentUsers = async (data: IContentPreference) => {
  try {
    const response = await HTTPClient.PutRequest<any>(
      settingsEndpoints.GET_CONTENT_PREFERENCE,
      data,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while updating content preferences",
      };
    }

    return response.data.data;
  } catch (err) {
    return {
      errorMessage: `Error while creating updating preferences`,
    };
  }
};

export const deleteContentUsers = async (id: number) => {
  try {
    const response = await HTTPClient.DeleteRequest<any>(
      `${settingsEndpoints.GET_CONTENT_PREFERENCE}/${id}`,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while deleting content preferences",
      };
    }

    return response.data.data;
  } catch (err) {
    return {
      errorMessage: `Error while deleting updating preferences`,
    };
  }
};

export const getPaymentFilePreferences = async () => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      settingsEndpoints.GET_PAYMENT_FILE_PREFERENCES,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while deleting content preferences",
      };
    }

    return response.data.data;
  } catch (err) {
    return {
      errorMessage: `Error while fetching payment file preferences`,
    };
  }
};

export const savePaymentFilePreferences = async (data: ICampaignPause) => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.GET_PAYMENT_FILE_PREFERENCES,
      data,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while saving payment file preferences",
      };
    }

    return response.data.data;
  } catch (err) {
    return {
      errorMessage: `Error while saving payment file preferences`,
    };
  }
};

export const upDatePaymentFilePreferences = async (data: ILobData) => {
  try {
    const response = await HTTPClient.PutRequest<any>(
      settingsEndpoints.GET_PAYMENT_FILE_PREFERENCES,
      data,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while updating payment file preferences",
      };
    }

    return response.data;
  } catch (err) {
    return {
      errorMessage: `Error while updating payment file preferences`,
    };
  }
};

export const createProductPreference = async (data: IProductData[]) => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.PRODUCT_PREFERENCES,
      data,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while saving product preferences",
      };
    }

    return {
      success: true,
    };
  } catch (err) {
    return {
      errorMessage: `Error while saving product preferences`,
    };
  }
};

export const deleteProductPreference = async (id: number) => {
  try {
    const response = await HTTPClient.DeleteRequest<any>(
      `${settingsEndpoints.PRODUCT_PREFERENCES}`,
      {
        data: [id],
      },
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while deleting product preferences",
      };
    }

    return {
      success: true,
    };
  } catch (err) {
    return {
      errorMessage: `Error while deleting product preferences`,
    };
  }
};

export const createBucketPreference = async (data: IBucketPreferenceData[]) => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      settingsEndpoints.BUCKET_PREFERENCES,
      data,
    );
    if (response.data?.errors) {
      return {
        errorMessage:
          response.data?.errors[0]?.message ||
          "Error while saving bucket preferences",
      };
    }

    return {
      success: true,
    };
  } catch (err) {
    return {
      errorMessage: `Error while saving bucket preferences`,
    };
  }
};
