import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILenderInvitesProps } from '../../saga/lenders/Lenders.types';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
  IActionPayload,
  ILenderInviteInitialState,
} from '../../types/Lenders.types';

export const lenderInvitesInitialState: ILenderInviteInitialState = {
  lenderData: [],
  isLoading: false,
  status: APIStatus.INIT,
  error: {},
};

const updatedInvites = (lenderData: any, payload: any) => {
  return lenderData?.filter((data: any) => {
    return data.id !== payload[0]?.id;
  });
};

const lenderInvitesSlice = createSlice({
  name: 'userInformation',
  initialState: lenderInvitesInitialState,
  reducers: {
    fetchLenderInvites: (state: ILenderInviteInitialState) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchLenderInvitesSucceeded: (
      state: ILenderInviteInitialState,
      action: PayloadAction<ILenderInvitesProps[]>
    ) => {
      state.lenderData = action.payload;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchLenderInvitesFailed: (
      state: ILenderInviteInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    rejectLenderInvites: (state: ILenderInviteInitialState, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    rejectLenderInvitesSucceeded: (
      state: ILenderInviteInitialState,
      action: PayloadAction<ILenderInvitesProps[]>
    ) => {
      state.lenderData = updatedInvites(state.lenderData, action.payload);
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    rejectLenderInvitesFailed: (
      state: ILenderInviteInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    acceptLenderInvites: (state: ILenderInviteInitialState, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    acceptLenderInvitesSucceeded: (
      state: ILenderInviteInitialState,
      action: PayloadAction<ILenderInvitesProps[]>
    ) => {
      state.lenderData = updatedInvites(state.lenderData, action.payload);
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    acceptLenderInvitesFailed: (
      state: ILenderInviteInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    resetLenderInvitesState: () => lenderInvitesInitialState,
  },
});

export const {
  fetchLenderInvites,
  fetchLenderInvitesSucceeded,
  fetchLenderInvitesFailed,
  acceptLenderInvites,
  acceptLenderInvitesSucceeded,
  acceptLenderInvitesFailed,
  rejectLenderInvites,
  rejectLenderInvitesFailed,
  rejectLenderInvitesSucceeded,
  resetLenderInvitesState,
} = lenderInvitesSlice.actions;

export default lenderInvitesSlice.reducer;
