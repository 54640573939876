import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampaignListProps } from '../../saga/campaign-planner/Outreach.types';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
  IActionPayload,
  ICampaignListInitialState,
} from '../../types/Outreach.types';
import { ICampaignData } from './CampaignDetails.types';

export const campaignListInitialState: ICampaignListInitialState = {
  campaignList: [],
  totalRecords: 0,
  isLoading: false,
  status: APIStatus.INIT,
  error: {},
};

const campaignListSlice = createSlice({
  name: 'campaignListInformation',
  initialState: campaignListInitialState,
  reducers: {
    fetchCampaignList: (
      state: ICampaignListInitialState,
      action: PayloadAction<
        | {
            offset: number;
            limit: number;
            status: string;
            name: string | undefined;
          }
        | undefined
      >
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchCampaignListSucceeded: (
      state: ICampaignListInitialState,
      action: PayloadAction<{
        campaignList: ICampaignListProps[];
        totalRecords: number;
      }>
    ) => {
      state.campaignList = action.payload.campaignList;
      state.totalRecords = action.payload.totalRecords;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchCampaignListFailed: (
      state: ICampaignListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    pauseOrResumeCampaignList: (
      state: ICampaignListInitialState,
      action: PayloadAction<{
        campaignId: number;
        status: string;
        pauseOrResumeCampaignSuccessCallback?: () => void;
      }>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    pauseOrResumeCampaignListSucceeded: (
      state: ICampaignListInitialState,
      action: PayloadAction<{ campaignId: number; paused: boolean }>
    ) => {
      state.campaignList = state.campaignList.map((campaign) => {
        if (campaign.campaignId === action.payload.campaignId) {
          return {
            ...campaign,
            ...action.payload,
          };
        }
        return campaign;
      });
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    pauseOrResumeCampaignListFailed: (
      state: ICampaignListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    resetOutreachConfigListState: () => campaignListInitialState,
  },
});

export const {
  fetchCampaignList,
  fetchCampaignListSucceeded,
  fetchCampaignListFailed,
  pauseOrResumeCampaignList,
  pauseOrResumeCampaignListSucceeded,
  pauseOrResumeCampaignListFailed,
  resetOutreachConfigListState,
} = campaignListSlice.actions;

export default campaignListSlice.reducer;
