import React, { useState, useEffect } from 'react';
import YbCoreSearchbar from '@yubi/yb-core-searchbar';
import { useStyles } from '@yubi/yb-style-provider';
import {
  DimensionValue,
  NativeSyntheticEvent,
  TextInputChangeEventData,
} from 'react-native';
import { useDebounce } from 'utils/hooks/useDebounce';
interface ListItem$1 {
  label: string;
  [key: string]: any;
}

interface ISearchViewProps {
  placeholder?: string;
  value?: string | undefined;
  onSubmitEditing?: (text: string) => void;
  onTileClick?: (value: ListItem$1) => void;
  onChange?: (text: string) => void;
  onClickCancel?: () => void;
  size?: 's' | 'm' | 'l';
  height?: DimensionValue | undefined;
  debounceTime?: number;
}

const SearchView = (props: ISearchViewProps) => {
  const {
    placeholder,
    value,
    onTileClick,
    onChange,
    height,
    size = 's',
    debounceTime = 500,
  } = props;
  const { styleConnector } = useStyles();
  const [searchValue, setSearchValue] = useState<string | undefined>(value);
  const debounceSearchValue = useDebounce(searchValue, debounceTime);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  useEffect(() => {
    if (debounceSearchValue !== value && onChange)
      onChange(debounceSearchValue);
  }, [debounceSearchValue]);

  return (
    <YbCoreSearchbar
      size={size}
      value={searchValue}
      customStyles={
        height
          ? { container: { height, borderColor: '#9aa5b8' } }
          : { container: { borderColor: '#9aa5b8' } }
      }
      onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) =>
        setSearchValue(e.nativeEvent.text)
      }
      onTileClick={onTileClick}
      onChangeText={(text: string) => setSearchValue(text)}
      styleConnector={styleConnector}
      placeholder={placeholder || 'Search'}
      onClickCancel={() => setSearchValue('')}
      dropdown={false}
    />
  );
};

export default SearchView;
