import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ApiResponse, HTTPClient } from 'services/api/http-client';
import { message } from 'antd';
import {
  ConditionRule,
  Content,
  OutreachConfig,
  campaignPlanSaveDraft,
  campaignPlanSaveDraftSuccess,
  cloneStrategyById,
  cloneStrategyByIdSuccess,
  createCampaignWithAllocation,
  createCampaignWithAllocationSuccess,
  deleteStrategyById,
  deleteStrategyByIdSuccess,
  fetchCampaignPlan,
  fetchCampaignPlanSuccess,
  fetchConditionRules,
  fetchConditionRulesSuccess,
  fetchOutreachConfig,
  fetchOutreachConfigSuccess,
  fetchOutreachDropdownList,
  fetchOutreachDropdownListSuccess,
  fetchSegmentsList,
  fetchSegmentsListSuccess,
  fetchStrategies,
  fetchStrategiesFail,
  fetchStrategiesSuccess,
  fetchStrategyById,
  fetchStrategyByIdSuccess,
  getContentConfig,
  getContentConfigSuccess,
  getSegmentById,
  getSegmentByIdFailure,
  getSegmentByIdSuccess,
  planCampaignPlan,
  setLoadingStrategyState,
} from './strategy';
import { ApiStatusCodes } from 'services/api/apiStatusCode';
import {
  IStrategyData,
  OutreachType,
} from 'features/StrategyConfig/CreatePlan/Flow/types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CampaignSegment,
  NewCampaignResponseData,
} from 'features/StrategyConfig/CreatePlan/Flow/strategyTestData';
import { PayloadSaveDraft } from 'features/CampaignPlanning/types';
import { AllocationStatus } from 'constants/constant';
import { campaignPlanner } from 'services/api/endPoints';
import { ISliderData } from 'components/FilterViewAdvanceV2/filterTyes';
import { store } from 'redux/store/store';
import {
  fetchSelectedStrategyRequested,
  fetchSelectedStrategyRequestFailed,
  fetchSelectedStrategyRequestSucceeded,
} from './StrategyList.slice';

export interface FetchStrategyByIdResponse {
  data: { data: IStrategyData; isMapped: boolean };
  status: ApiStatusCodes;
}

export type FetchCampaignPlanApiSuccessCallback = (
  campaignPlannerCurrentStatus: AllocationStatus
) => void;

export interface FetchConditionsRulesResponse {
  data: { data: ConditionRule[] };
  status: ApiStatusCodes;
}

export interface FetchOutreachDropdownListResponse {
  data: { data: OutreachType[] };
  status: ApiStatusCodes;
}

export type StrategyListType = {
  strategyName: string;
  daysCount: number;
  createdDate: string;
  lastModified: string;
  lastUsed: string;
  strategyId: number;
  isMapped: boolean;
  createdBy: string;
  lastModifiedBy: string;
  totalNoOfOutreaches: number;
};

export interface FetchStrategiesResponse {
  data: {
    data: StrategyListType[];
    totalRecords: number;
  };
  status: ApiStatusCodes;
}

export type FetchCampaignPlanResponseData = {
  campaignData: NewCampaignResponseData;
  campaignDates: Record<string, string>;
};
export interface FetchCampaignPlanResponse {
  data: {
    data: FetchCampaignPlanResponseData;
  };
  status: ApiStatusCodes;
}

export interface DeleteStrategyByIdResponse {
  data: NewCampaignResponseData;
  status: ApiStatusCodes;
}

export type CreateCampaignWithAllocationResponseData = {
  allocationId: string;
  allocationName: string;
  campaignId: number;
};
export interface CreateCampaignWithAllocationResponse {
  data: { data: CreateCampaignWithAllocationResponseData };
  status: ApiStatusCodes;
}

export interface FetchOutreachConfigResponse {
  data: { data: OutreachConfig };
  status: ApiStatusCodes;
}
export interface CloneStrategyByIdResponse {
  data: { data: IStrategyData };
  status: ApiStatusCodes;
}
export interface GetContentConfigResponse {
  data: { data: Content[] };
  status: ApiStatusCodes;
}
export type SegmentsListType = {
  SegmentId: number;
  segmentName: string;
  segmentLogic: string;
  Attributes: string;
  Risk: string;
  CreatedOn: string;
  CreatedBy: string;
  LastUsed: string;
  Efficiency: string;
};

export interface FetchSegmentsListResponse {
  data: SegmentsListType[];
  status: ApiStatusCodes;
}
function* fetchStrategyByIdSaga({
  payload,
}: PayloadAction<{ strategyId: string }>) {
  try {
    const result: FetchStrategyByIdResponse = yield HTTPClient.GetRequest(
      campaignPlanner.FETCH_STRATEGY_DETAILS(payload.strategyId)
    );

    yield put(
      fetchStrategyByIdSuccess({
        strategy: result.data.data,
        isMapped: result.data.isMapped,
      })
    );
  } catch (e) {
    message.open({
      content: 'Error while fetching strategy details',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* fetchSelectedStrategy({
  payload,
}: PayloadAction<{ strategyId: string }>) {
  try {
    const result: FetchStrategyByIdResponse = yield HTTPClient.GetRequest(
      campaignPlanner.FETCH_STRATEGY_DETAILS(payload.strategyId)
    );

    yield put(
      fetchSelectedStrategyRequestSucceeded({
        strategy: result.data.data,
        strategyId: payload.strategyId,
      })
    );
  } catch (e) {
    message.open({
      content: 'Error while fetching strategy details',
      type: 'error',
    });
    yield put(
      fetchSelectedStrategyRequestFailed({ strategyId: payload.strategyId })
    );
  }
}

function* fetchConditionRulesSaga({
  payload,
}: PayloadAction<{ status: string }>) {
  try {
    const result: FetchConditionsRulesResponse = yield HTTPClient.PostRequest(
      campaignPlanner.CONDITION_RULE(payload.status),
      {}
    );
    yield put(fetchConditionRulesSuccess({ conditionRules: result.data.data }));
  } catch (e) {
    message.open({
      content: 'Error while fetching Condition rules List',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* fetchOutreachDropdownListSaga(
  actions: PayloadAction<{ offset: number; limit: number } | undefined>
) {
  try {
    const result: FetchOutreachDropdownListResponse =
      yield HTTPClient.GetRequest(campaignPlanner.OUTREACH(actions.payload));
    yield put(
      fetchOutreachDropdownListSuccess({ outreaches: result.data.data })
    );
  } catch (e) {
    message.open({
      content: 'Error while fetching outreach List',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* fetchStrategiesSaga(
  actions: PayloadAction<
    | {
        offset: number;
        limit: number;
        searchText: string;
        appliedFilterState: Record<string, string[] | ISliderData>;
      }
    | undefined
  >
) {
  try {
    const result: FetchStrategiesResponse = yield HTTPClient.PostRequest(
      campaignPlanner.FETCH_STRATEGY_LIST(actions.payload),
      actions.payload?.appliedFilterState || {}
    );

    yield put(
      fetchStrategiesSuccess({
        strategiesList: result.data.data,
        totalRecords: result.data.totalRecords,
      })
    );
  } catch (e) {
    message.open({
      content: 'Error while fetching Strategies List',
      type: 'error',
    });
    yield put(fetchStrategiesFail());
  }
}

function* fetchCampaignPlanSaga({
  payload,
}: PayloadAction<{
  allocationId: number;
}>) {
  try {
    const result: FetchCampaignPlanResponse = yield HTTPClient.GetRequest(
      campaignPlanner.FETCH_CAMPAIGN_PLAN(payload.allocationId)
    );
    yield put(
      fetchCampaignPlanSuccess({
        campaignPlanner: result.data.data.campaignData,
        campaignDates: result.data.data.campaignDates,
      })
    );
  } catch (e) {
    yield put(setLoadingStrategyState({ isLoading: false }));
    message.open({
      content: 'Error while fetching Campaign Plan',
      type: 'error',
    });
  }
}

function* fetchSegmentsListSaga({
  payload,
}: PayloadAction<{ allocationId: number }>) {
  try {
    const result: FetchSegmentsListResponse = yield HTTPClient.GetRequest(
      campaignPlanner.FETCH_SEGMENTS_LIST(payload.allocationId)
    );
    yield put(
      fetchSegmentsListSuccess({
        segmentsList: result.data,
        allocationId: payload.allocationId,
      })
    );
  } catch (e) {
    message.open({
      content: 'Error while fetching Segments List',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* campaignPlanSaveDraftSaga({
  payload,
}: PayloadAction<{
  payloadSaveDraft: PayloadSaveDraft;
  campaignId: number;
  campaignPlanSaveDraftSuccessCallback?: (val: { isSuccess: boolean }) => void;
  showToast: boolean;
}>) {
  try {
    yield HTTPClient.PostRequest(
      campaignPlanner.DRAFT_SAVE_CAMPAIGN_PLAN(payload.campaignId),
      payload.payloadSaveDraft.segments || []
    );
    yield put(campaignPlanSaveDraftSuccess());
    if (payload.showToast) {
      message.open({
        content: 'Campaign Drafted Successfully',
        type: 'success',
      });
    }

    payload.campaignPlanSaveDraftSuccessCallback?.({ isSuccess: true });
  } catch (error: any) {
    payload.campaignPlanSaveDraftSuccessCallback?.({ isSuccess: false });
    if (payload.showToast) {
      message.open({
        content:
          error?.response?.data?.error || 'Error while drafting Campaign',
        type: 'error',
        duration: 5,
      });
    }

    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* planCampaignPlanSaga({
  payload,
}: PayloadAction<{
  campaignId: number;
  lenderId: string;
  planCampaignPlanCallback: (val: { isSuccess: boolean }) => void;
}>) {
  try {
    yield HTTPClient.PostRequest(
      campaignPlanner.PLAN_CAMPAIGN_PLAN(payload.lenderId),
      {
        campaignId: payload.campaignId,
      }
    );
    yield put(campaignPlanSaveDraftSuccess());
    message.open({
      content: 'Campaign Plan submitted successfully',
      type: 'success',
    });
    payload.planCampaignPlanCallback({ isSuccess: true });
  } catch (error: any) {
    payload.planCampaignPlanCallback({ isSuccess: false });
    message.open({
      content:
        error?.response?.data?.error || 'Error while submitting Campaign plan',
      type: 'error',
      duration: 5,
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* deleteStrategyByIdSaga({
  payload,
}: PayloadAction<{
  strategyId: number;
  deleteStrategyByIdSuccessCallback: () => void;
}>) {
  try {
    yield HTTPClient.DeleteRequest(
      campaignPlanner.DELETE_STRATEGY(payload.strategyId),
      { params: { strategyId: payload.strategyId } }
    );
    yield put(deleteStrategyByIdSuccess({ strategyId: payload.strategyId }));
    payload.deleteStrategyByIdSuccessCallback();
    message.open({
      content: 'Strategy deleted successfully',
      type: 'success',
    });
  } catch (e) {
    message.open({
      content: 'Error while deleting Strategy',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}
function* createCampaignWithAllocationSaga({
  payload,
}: PayloadAction<{
  allocationId: number;
  allocationName: string;
  createCampaignWithAllocationCallback: (campaignId: number) => void;
}>) {
  try {
    const result: CreateCampaignWithAllocationResponse =
      yield HTTPClient.PostRequest(campaignPlanner.CREATE_CAMPAIGN, {
        ...payload,
      });
    yield put(
      createCampaignWithAllocationSuccess({
        allocationId: result.data.data.allocationId,
        allocationName: result.data.data.allocationName,
        campaignId: result.data.data.campaignId,
      })
    );

    payload.createCampaignWithAllocationCallback(result.data.data.campaignId);
  } catch (e) {
    message.open({
      content: 'Error while creating Strategy',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* fetchOutreachConfigSaga(action: PayloadAction<{ lenderId: string }>) {
  try {
    const result: FetchOutreachConfigResponse = yield HTTPClient.GetRequest(
      campaignPlanner.GET_CONFIG(action.payload.lenderId)
    );
    yield put(fetchOutreachConfigSuccess({ ...result.data.data }));
  } catch (e) {
    message.open({
      content: 'Error while fetching Outreach Config',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* cloneStrategyByIdSaga(
  action: PayloadAction<{
    strategyId: number;
    limit: number;
    cloneStrategyByIdSuccessCallback: (clonedStrategyId: number) => void;
  }>
) {
  try {
    const result: CloneStrategyByIdResponse = yield HTTPClient.PostRequest(
      campaignPlanner.CLONE_STRATEGY(action.payload.strategyId)
    );
    yield put(
      cloneStrategyByIdSuccess({
        strategy: result.data.data,
        limit: action.payload.limit,
      })
    );
    message.open({
      content: 'Strategy cloned successfully',
      type: 'success',
    });

    action.payload.cloneStrategyByIdSuccessCallback(
      result.data.data.strategyId || 0
    );
  } catch (e) {
    message.open({
      content: 'Error while cloning the strategy',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* getContentConfigSaga(
  action: PayloadAction<{ language: string; lenderId: string }>
) {
  try {
    const state = store.getState();
    const selectedChannel = state.strategy.selectedChannel;

    const result: GetContentConfigResponse = yield HTTPClient.GetRequest(
      campaignPlanner.GET_CONTENT(
        selectedChannel,
        action.payload.language,
        action.payload.lenderId
      )
    );
    yield put(getContentConfigSuccess({ contents: result.data.data }));
  } catch (e) {
    message.open({
      content: 'Error while fetching the content config',
      type: 'error',
    });
    yield put(setLoadingStrategyState({ isLoading: false }));
  }
}

function* getSegmentByIdSaga(
  action: PayloadAction<{
    campaignId: string;
    segmentId: number;
  }>
) {
  try {
    const result: ApiResponse<{ data: CampaignSegment }> =
      yield HTTPClient.GetRequest(
        campaignPlanner.GET_SEGMENT_DETAILS({
          campaignId: action.payload.campaignId,
          segmentId: action.payload.segmentId,
        })
      );
    yield put(getSegmentByIdSuccess({ campaignSegment: result.data.data }));
  } catch (e) {
    message.open({
      content: 'Error while fetching the segment details',
      type: 'error',
    });
    yield put(getSegmentByIdFailure());
  }
}

export function* watchStrategySagas() {
  yield takeLatest(fetchStrategyById.type, fetchStrategyByIdSaga);
  yield takeLatest(fetchConditionRules.type, fetchConditionRulesSaga);
  yield takeLatest(
    fetchOutreachDropdownList.type,
    fetchOutreachDropdownListSaga
  );
  yield takeLatest(fetchStrategies.type, fetchStrategiesSaga);
  yield takeLatest(fetchCampaignPlan.type, fetchCampaignPlanSaga);
  yield takeLatest(fetchSegmentsList.type, fetchSegmentsListSaga);
  yield takeLatest(campaignPlanSaveDraft.type, campaignPlanSaveDraftSaga);
  yield takeLatest(deleteStrategyById.type, deleteStrategyByIdSaga);
  yield takeLatest(
    createCampaignWithAllocation.type,
    createCampaignWithAllocationSaga
  );
  yield takeLatest(fetchOutreachConfig.type, fetchOutreachConfigSaga);
  yield takeLatest(cloneStrategyById.type, cloneStrategyByIdSaga);
  yield takeLatest(getContentConfig.type, getContentConfigSaga);
  yield takeLatest(planCampaignPlan.type, planCampaignPlanSaga);
  yield takeLatest(getSegmentById.type, getSegmentByIdSaga);
  yield takeEvery(fetchSelectedStrategyRequested.type, fetchSelectedStrategy);
}
