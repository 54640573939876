import React from 'react';
import { V2 as YbCoreModal } from '@yubi/yb-core-modal-custom';
import { useStyles } from '@yubi/yb-style-provider';

interface ICustomModalViewProps {
  open: boolean;
  modalContent: React.ReactElement;
  showCloseIcon?: boolean;
  modalHeight?: string | number | undefined;
  modalWidth?: string | number | undefined;
  onModalClose: () => void;
}

const CustomModalView = (props: ICustomModalViewProps) => {
  const {
    open,
    modalContent,
    showCloseIcon = true,
    modalWidth,
    modalHeight,
    onModalClose,
  } = props;
  const { styleConnector } = useStyles();
  return (
    <YbCoreModal
      modalHeight={modalHeight}
      modalWidth={modalWidth}
      open={open}
      showCloseIcon={showCloseIcon}
      styleConnector={styleConnector}
      onModalClose={onModalClose}
    >
      {modalContent}
    </YbCoreModal>
  );
};

export default CustomModalView;
