import { styled } from 'styled-components';

interface IStyledFlexDiv {
  padding?: string | number;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  gap?: string | number;
}

export const StyledFlexDiv = styled.div<IStyledFlexDiv>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  justify-content: ${(props) => props.justifyContent || 'start'};
  align-items: ${(props) => props.alignItems || 'stretch'};
  padding: ${(props) => props.padding || '0px'};
  gap: ${(props) => props.gap || '0px'};
`;

interface IStyledPaddingDiv {
  padding?: string | number;
}

export const StyledPaddingDiv = styled.div<IStyledPaddingDiv>`
  padding: ${(props) => props.padding || '24px 32px'};
  width: 100%;
`;
