/* eslint-disable @typescript-eslint/no-unused-expressions */
import { message } from 'antd';
import { PostOutreachSaveType } from 'features/StrategyConfig/CreatePlan/CreatePlanSaveWrapper';
import { CampaignSegment } from 'features/StrategyConfig/CreatePlan/Flow/strategyTestData';
import {
  ConditionOutreachType,
  Day,
  OutreachType,
  Plan,
} from 'features/StrategyConfig/CreatePlan/Flow/types';
import { StrategyListType } from './strategy.saga';
import {
  CAMPAIGN_PLANNER_PLAN_PARENT_NODE_AND_OUTREACH_ID,
  CAMPAIGN_PLANNER_PLAN_PARENT_NODE_ID,
} from 'constants/constant';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const strategyMapConvertor = (selectedPlanDay: number, days?: Day[]) => {
  const map = new Map<string, Array<ConditionOutreachType>>();
  if (!days?.length || !days) return { updatedMap: map };

  if (!days.some((value) => value.currentDay === selectedPlanDay)) {
    return { updatedMap: map };
  }
  const newDays = days.filter((value) => value.currentDay === selectedPlanDay);
  if (!newDays.length || !newDays[0].plans.length) return { updatedMap: map };
  const data = newDays[0].plans[0].planConditionoutreachList;
  data.forEach((plan) => {
    plan.conditionOutreach.outreach.forEach((outReach) => {
      data.forEach((nestedPlan) => {
        if (
          nestedPlan.parentOutreachId === outReach.outreachId &&
          nestedPlan.conditionOutreach.parentNode ===
            plan.conditionOutreach.currentNode
        ) {
          const key = `${plan.conditionOutreach.currentNode}-${outReach.outreachId}`;
          if (map.has(key)) {
            const list = map.get(key) || [];
            list.push(nestedPlan);
            map.set(key, list);
          } else {
            const list: ConditionOutreachType[] = [];
            list.push(nestedPlan);
            map.set(key, list);
          }
        }
      });
    });
  });

  const parent = data.filter((plan) => {
    return (
      plan.parentOutreachId === -1 &&
      plan.conditionOutreach.parentNode === CAMPAIGN_PLANNER_PLAN_PARENT_NODE_ID
    );
  });

  parent.length &&
    map.set(CAMPAIGN_PLANNER_PLAN_PARENT_NODE_AND_OUTREACH_ID, parent);
  return { updatedMap: map };
};

export const getFieldsForFirstOutreachFirstDay = (
  payload: PostOutreachSaveType
): { plans: Array<Plan>; currentDay: number } => {
  const currentNodeId = uuidv4();

  return {
    currentDay: payload.current_day,
    plans: [
      {
        planName: '',
        planConditionoutreachList: [
          {
            conditionOutreach: {
              currentNode: currentNodeId,
              parentNode: CAMPAIGN_PLANNER_PLAN_PARENT_NODE_ID,
              outreach: [
                {
                  outreachId: payload.outreachId,
                  name: payload.content,
                  channel: payload.channel,
                  content: payload.content,
                  language: payload.language,
                  slot: payload.slot,
                  day: payload.day,
                  placeholderMsg: '',
                  isMappedToPlan: false,
                  isLibrary: false,
                  count: payload.day,
                },
              ],
            },
            parentOutreachId: -1,
          },
        ],
      },
    ],
  };
};

export const formatDateCampaignPlanner = (date: Date): string => {
  const year: number = date.getFullYear();
  const month: string = String(date.getMonth() + 1).padStart(2, '0');
  const day: string = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const normalDateFormate = (inputStart: string) => {
  const startDatePart: RegExpMatchArray | null = inputStart.match(
    /(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)/
  );
  const startDate: Date = new Date(
    `${startDatePart?.[2]}/${startDatePart?.[1]}/${startDatePart?.[3]} ${startDatePart?.[4]}:${startDatePart?.[5]}:${startDatePart?.[6]}`
  );
  return startDate;
};

export function dateDifferenceInDays(
  startDate: string,
  endDate: string
): number {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const timeDifference = endDateObj.getTime() - startDateObj.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
}

export function increaseDateByOneDay(inputDate: string, count: number) {
  const originalDate = new Date(inputDate);
  const newDate = new Date(originalDate);
  newDate.setDate(originalDate.getDate() + count);
  return newDate.toISOString().split('T')[0];
}
export const checkPlanConflictWithStrategy = (
  campaignSegment: CampaignSegment,
  campaignPlanSelectedDay: string,
  day: number,
  campaignEndDate: string
) => {
  if (
    !campaignSegment.segmentDateMappings?.[
      campaignPlanSelectedDay as keyof typeof campaignSegment.segmentDateMappings
    ]
  ) {
    const nextDay = increaseDateByOneDay(campaignPlanSelectedDay, day);
    if (dayjs(nextDay).isAfter(dayjs(campaignEndDate), 'day')) {
      message.open({
        content: `outreach is overlapping with campaign end date on ${nextDay} `,
        type: 'error',
      });
      return true;
    }
  }

  return false;
};

export const checkPlansConflictWithStrategy = (
  campaignSegment: CampaignSegment,
  campaignPlanSelectedDay: string,
  day: number,
  outreaches: OutreachType[],
  campaignEndDate: string
) => {
  for (const outreach of outreaches) {
    const flag = checkPlanConflictWithStrategy(
      campaignSegment,
      campaignPlanSelectedDay,
      day + outreach.day,
      campaignEndDate
    );
    if (flag) return flag;
  }
  return false;
};

// not in use
export const checkStrategyConflictWithStrategyAndPlan = (
  campaignSegment: CampaignSegment,
  strategyFromLibrary: StrategyListType,
  campaignPlanSelectedDay: string
) => {
  for (let i = 0; i <= strategyFromLibrary.daysCount - 1; i++) {
    const nextDay = increaseDateByOneDay(campaignPlanSelectedDay, i);
    const nextDayData = campaignSegment.segmentDateMappings?.find(
      (segmentDateMapping) => {
        if (
          segmentDateMapping.campaignDate.currentDate === nextDay &&
          (segmentDateMapping.plans.length > 0 ||
            segmentDateMapping.strategy?.strategyId)
        ) {
          return true;
        }
        return false;
      }
    );
    if ((nextDayData?.plans.length || 0) > 0) {
      return {
        isBlocked: true,
        messageInfo: `Strategy is blocked by Plan which is planned on ${nextDayData?.campaignDate.currentDate}`,
      };
    }

    if (nextDayData?.strategy?.strategyId) {
      return {
        isBlocked: true,
        messageInfo: `Strategy is blocked by other strategy which is planned on ${nextDayData?.campaignDate.currentDate}`,
      };
    }
  }

  return { isBlocked: false, messageInfo: '' };
};

export const checkStrategyConflictWithStrategyAndPlanNewRule = (
  strategyFromLibrary: StrategyListType,
  campaignPlanSelectedDay: string,
  campaignEndDate: string
) => {
  const nextDay = increaseDateByOneDay(
    campaignPlanSelectedDay,
    strategyFromLibrary.daysCount - 1
  );
  if (dayjs(nextDay).isAfter(dayjs(campaignEndDate), 'day')) {
    return {
      isBlocked: true,
      messageInfo: `Strategy is overlapping with campaign end date`,
    };
  }
  return {
    isBlocked: false,
    messageInfo: '',
  };
};

export const getAdjacencyListOfStrategy = (
  selectedPlanDay: number,
  days: Day[] | undefined,
  planIndex: number
) => {
  const map = new Map<string, Array<ConditionOutreachType>>();
  if (!days?.some((value) => value.currentDay === selectedPlanDay)) {
    return { updatedMap: map };
  }
  const newDays = days.filter((value) => value.currentDay === selectedPlanDay);

  if (!newDays.length) {
    return { updatedMap: map };
  }
  const selectedPlan = newDays[0].plans.find(
    (plan, index) => index === planIndex
  );
  if (!selectedPlan) return { updatedMap: map };

  const data = selectedPlan.planConditionoutreachList;
  data.forEach((plan) => {
    plan.conditionOutreach.outreach.forEach((outReach) => {
      data.forEach((nestedPlan) => {
        if (
          nestedPlan.parentOutreachId === outReach.outreachId &&
          nestedPlan.conditionOutreach.parentNode ===
            plan.conditionOutreach.currentNode
        ) {
          const key = `${plan.conditionOutreach.currentNode}-${outReach.outreachId}`;
          if (map.has(key)) {
            const list = map.get(key) || [];
            list.push(nestedPlan);
            map.set(key, list);
          } else {
            const list: ConditionOutreachType[] = [];
            list.push(nestedPlan);
            map.set(key, list);
          }
        }
      });
    });
  });
  const parent = data.filter((plan) => {
    return (
      plan.parentOutreachId === -1 &&
      plan.conditionOutreach.parentNode === CAMPAIGN_PLANNER_PLAN_PARENT_NODE_ID
    );
  });

  parent.length &&
    map.set(CAMPAIGN_PLANNER_PLAN_PARENT_NODE_AND_OUTREACH_ID, parent);
  return { updatedMap: map };
};
