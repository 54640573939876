import React from 'react';

function Minus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g>
        <path
          fill="#344054"
          fillRule="evenodd"
          d="M2.667 8c0-.369.298-.667.666-.667h9.334a.667.667 0 110 1.333H3.333A.667.667 0 012.667 8z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Minus;
