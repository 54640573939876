import { AdvancedPagination } from '@yubi/yb-core-pagination';
import { useStyles } from '@yubi/yb-style-provider';
import React from 'react';

interface IPaginationViewProps {
  selectedPage: number;
  totalPages: number;
  title?: string;
  dropdownItems?: Array<string>;
  onPageSelected?: (page: string) => void;
  isBorderedDropdown?: boolean;
  pageSize?: number;
  onPageChange?: (page: number) => void;
}

const PaginationView = (props: IPaginationViewProps) => {
  const {
    selectedPage,
    totalPages,
    title,
    onPageSelected,
    isBorderedDropdown,
    pageSize,
    onPageChange,
  } = props;
  const { styleConnector } = useStyles();
  const onActionClicked = (page: number) => {
    if (onPageChange) onPageChange(page - 1);
  };
  return (
    <AdvancedPagination
      styleConnector={styleConnector}
      selectedPage={selectedPage + 1}
      totalPages={totalPages || 1}
      showPages={pageSize}
      title={title || `Rows per page`}
      dropdownItems={['10', '20', '50']}
      selectedItem={`${pageSize}`}
      onSelectItem={(item) => {
        if (onPageSelected) onPageSelected(item);
      }}
      leftIconAction={onActionClicked}
      rightIconAction={onActionClicked}
      isBorderedDropdown={isBorderedDropdown}
      // dropdownAlignment="top"
    />
  );
};

export default PaginationView;
