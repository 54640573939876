import { NbaCampaignStatus } from 'redux/slice/campaign-planner/CampaignDetails.types';
import { useAppSelector } from 'utils/hooks/useAppSelector';

const useDisableWhenCampaignNotSuccess = () => {
  const nbaStatus = useAppSelector(
    (state) => state.strategy.campaignPlanner.campaignNbaStatus?.nbaStatus
  );
  const isNba = useAppSelector((state) => state.strategy.campaignPlanner.isNba);

  if (!isNba) return isNba;
  return nbaStatus !== NbaCampaignStatus.SUCCESS;
};

export default useDisableWhenCampaignNotSuccess;
