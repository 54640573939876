import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILMSField, IUpdateData } from 'features/Settings/shared/contract';
import { IError } from 'redux/types/Compliance.types';
import { IGetLMSFieldsState } from 'redux/types/LoanBook.types';
import { APIStatus } from 'services/api/apiStatusCode';

export const getLMSFieldsInitialState: IGetLMSFieldsState = {
  status: APIStatus.INIT,
};

const modifyData = (data: ILMSField[], payload: IUpdateData[]) => {
  return data.map((field) => {
    const modifiedField = payload.find((item) => item.id === field.id);
    if (modifiedField) {
      return {
        ...field,
        enabled: modifiedField.enabled,
      };
    }
    return field;
  });
};

const getLMSFieldsSlice = createSlice({
  name: 'lmsFields',
  initialState: getLMSFieldsInitialState,
  reducers: {
    getLMSFieldsRequested(state: IGetLMSFieldsState) {
      state.status = APIStatus.PENDING;
    },
    getLMSFieldsRequestSucceeded(
      state: IGetLMSFieldsState,
      action: PayloadAction<ILMSField[]>
    ) {
      state.status = APIStatus.SUCCESS;
      state.data = action.payload;
    },
    getLMSFieldsRequestFailed(
      state: IGetLMSFieldsState,
      action: PayloadAction<IError>
    ) {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
    updateLMSFieldsStatus(
      state: IGetLMSFieldsState,
      action: PayloadAction<IUpdateData[]>
    ) {
      state.data = modifyData(state.data || [], action.payload);
    },
  },
});

export const {
  getLMSFieldsRequested,
  getLMSFieldsRequestSucceeded,
  getLMSFieldsRequestFailed,
  updateLMSFieldsStatus,
} = getLMSFieldsSlice.actions;

export default getLMSFieldsSlice.reducer;
