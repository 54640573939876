import { IFormikInitialValues } from 'features/CreateConfig/CreateConfig.types';
import { APIStatus, ApiStatusCodes } from 'services/api/apiStatusCode';

export interface IFormikCreateAllocationInitialValues
  extends IFormikInitialValues {
  allocationName?: string;
}

interface ICondition {
  fieldName: string;
  comparison: string;
  values: string[];
  isDerived: boolean;
}

export interface IOperation {
  operator: string;
  conditions: ICondition[];
}

interface IMetaData {
  total_records: number;
  total_outstanding: number;
  principle_outstanding: number;
}

export interface IAllocation {
  allocationId: string;
  collectionId: string | null;
  configId: string | null;
  allocationName: string;
  allocationLogic: IOperation;
  lenderId: string | null;
  clientId: string | null;
  createdDate: string;
  createdBy: string;
  updatedDate: string | null;
  updatedBy: string | null;
  metadata: IMetaData; // Replace with a specific type if available
  fileLocation: string;
  risk: string[] | null;
  delinquency: string | null;
  location: string[] | null;
  assetClass: string | null;
  isDisabled: boolean;
  isDeleted: boolean;
  status: string | null;
  assignedToEntity?: string;
  assignmentType?: string;
  allocationStartDate?: string;
  allocationEndDate?: string;
  executionStatus?: 'PENDING' | 'COMPLETED' | 'ZERO RECORDS';
  lob: string;
  overridePauseConfig?: boolean;
}

export interface ICreateAllocationAPiResponse {
  allocation: IAllocation;
  message: string;
}

export const allocationDetailsInitialValues: IAllocation = {
  allocationName: '',
  allocationId: '',
  collectionId: null,
  configId: null,
  allocationLogic: {
    operator: '',
    conditions: [],
  },
  lenderId: null,
  clientId: null,
  createdDate: '',
  createdBy: '',
  updatedDate: null,
  updatedBy: null,
  metadata: {
    total_records: 0,
    total_outstanding: 0,
    principle_outstanding: 0,
  },
  fileLocation: '',
  risk: null,
  delinquency: null,
  location: null,
  assetClass: null,
  isDisabled: false,
  isDeleted: false,
  status: null,
  lob: '',
};
