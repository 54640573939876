import { TextView } from 'components';
import React from 'react';
import UserInfo from 'templates/UserInfo';
import NoRecords from 'assets/img/noRecords.svg';
import '../index.css';

interface INoData {
  headingText: string;
}

const NoData = (props: INoData) => {
  const { headingText } = props;
  return (
    <UserInfo
      headingText={headingText}
      subText={[]}
      buttonComponent={
        <div className="flex flex-col justify-center items-center ">
          <TextView
            text={`Reach out to your POC from SpoctoX to add data`}
            color="#667085"
          />
          <div>
            <TextView text={` or  mail us at `} color="#667085" />
            <a className="mail_link" href="mailto:help@spocto.com">
              help@spocto.com
            </a>
          </div>
        </div>
      }
      imageUrl={NoRecords}
    />
  );
};

export default NoData;
