import { YbCoreIcon } from '@yubi/yb-core-icon';
import { ButtonViewV3 } from 'assets/components-v3';
import { CustomModal, DividerView, SpinnerView, TextView } from 'components';
import React, { useState } from 'react';
import DeleteComponent from './DeleteComponent';
import ModalInfo from './ModalInfo';
import CreateBucketAtom from './CreateBucketAom';
import { IBucketData } from '../shared/contract';
import FolderPlus from 'assets/svgComponents/FolderPlus';
import { createBucketPreference } from '../shared/api';
import { message } from 'antd';
import { isDataValid } from '../shared/helper';

interface ICreateBucket {
  open: boolean;
  onClose: (isCreateSuccessful: boolean) => void;
}

const CreateBucket = (props: ICreateBucket) => {
  const initialBucketData: IBucketData = {
    bucketName: '',
  };

  const { open, onClose } = props;
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bucketData, setBucketData] = useState<IBucketData[]>([
    initialBucketData,
  ]);
  const modalInfoData = [
    {
      title: 'Bucket',
      description:
        'Bucket refers to a specific categorization or grouping \nof accounts or debtors based on predefined criteria. It\nis used to segment and manage the collection efforts \neffectively. Each bucket may have different\ncharacteristics, risk levels, or prioritization, allowing \nthe organization to allocate resources and strategies \naccordingly',
    },
    // {
    //   title: 'And',
    //   description:
    //     '"AND" connects conditions, ensuring that all values \nhave to be true to get an accurate result.',
    //   children: [
    //     {
    //       title: 'Or',
    //       description:
    //         '"OR" connects conditions in a manner where the result \nwould be true if one or all conditions are true.',
    //     },
    //   ],
    // },
  ];

  const handleAddBucket = () => {
    setBucketData((prev) => {
      const newData = [...prev];
      newData.push(initialBucketData);
      return newData;
    });
  };

  const handleChange = (index: number, data: IBucketData) => {
    setBucketData((prev) => {
      const newData = [...prev];
      newData[index] = data;
      return newData;
    });
  };

  const handleAction = (actionType: 'Reset' | 'Delete', index: number) => {
    if (actionType === 'Reset') {
      setBucketData((prev) => {
        const newData = [...prev];
        newData[index] = initialBucketData;
        return newData;
      });
    } else {
      setBucketData((prev) => {
        const newData = [...prev];
        newData.splice(index, 1);
        return newData;
      });
    }
  };

  const validator = () => {
    if (!bucketData.length) return false;
    let isValid = true;
    bucketData.forEach((data) => {
      if (data.bucketName === '') {
        isValid = false;
      }
    });
    return isValid;
  };

  const isDataValid = validator();
  const disableCreate = isDataValid || !bucketData.length;

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const processedBucketData = bucketData.map((bucket, index) => {
        return {
          bucket_name: bucket.bucketName,
          logics: [
            {
              values: [bucket.bucketName],
              operator: '=',
              field_name: 'Delinquency',
              completeJsonPath: 'loanDetails.collectionInfo.delinquency',
            },
          ],
          enable: false,
        };
      });
      // API call to save data
      const response = await createBucketPreference(processedBucketData);
      if (response.success) {
        message.success("Buckets's created successfully");
        onClose(true);
      } else {
        message.error(response.errorMessage || '');
      }
    } catch (error) {
      message.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {' '}
      <CustomModal open={open}>
        <SpinnerView spinning={isLoading}>
          <article className="flex flex-col h-full overflow-y-auto">
            <header className="flex py-3.5 px-6 justify-between">
              <section
                className="flex gap-2 items-center"
                // onClick={() => setShowDelete(true)}
              >
                {/* <YbCoreIcon size={16} name="chevron-left" /> */}
                <TextView text={'Add buckets & conditions'} />
              </section>
              <section className="flex gap-2">
                <ButtonViewV3
                  variant="tertiary"
                  label="Cancel"
                  onClick={() => setShowDelete(true)}
                />
                <ButtonViewV3
                  label="Save"
                  disabled={!validator()}
                  onClick={handleSave}
                />
              </section>
            </header>
            <DividerView lineColor="#F2F4F7" />
            <body className="flex flex-1 px-6 py-6 gap-6 overflow-y-auto">
              <article className="flex flex-1 flex-col gap-6 ">
                {bucketData.map((bucket, index) => {
                  return (
                    <CreateBucketAtom
                      disableOverflow={bucketData.length === 1}
                      handleChange={handleChange}
                      handleAction={handleAction}
                      data={bucket}
                      index={index}
                    />
                  );
                })}
                <section
                  typeof="button"
                  className={`flex gap-2 items-center pb-6 w-fit ${
                    disableCreate ? 'cursor-pointer' : 'cursor-not-allowed'
                  }`}
                  style={{ opacity: disableCreate ? 1 : 0.5 }}
                  onClick={() => {
                    if (disableCreate) handleAddBucket();
                  }}
                >
                  <FolderPlus />
                  <TextView
                    text="Add another bucket"
                    color="#FD7149"
                    size={12}
                    lineHeight={18}
                    weight="700"
                  />
                </section>
              </article>
              <aside>
                <ModalInfo data={modalInfoData} />
              </aside>
            </body>
          </article>
          {showDelete && (
            <DeleteComponent
              showDelete={showDelete}
              onDelete={(deleteConfirmation) => {
                if (deleteConfirmation) {
                  onClose(false);
                } else {
                  setShowDelete(false);
                }
              }}
              headingText={'Sure you want to exit?'}
              subHeadingText={'All changes will be lost.'}
              cancelText="No"
              confirmText="Yes"
            />
          )}
        </SpinnerView>
      </CustomModal>
    </>
  );
};

export default CreateBucket;
