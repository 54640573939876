import { chevronDown } from 'assets/icon';
import { DropDownView, ImageView, InputView, TextView } from 'components';
import React from 'react';
import { TemplateContent } from 'redux/content/content.slice';
import ContentEditor from './ContentEditor';
import { IDataProps } from 'components/DropDownView/DropDownView.type';

export type ContentAccordionProps = {
  index: number;
  selectedContentIndex: number;
  onClick: (index: number) => void;
  onLanguageSelect: (data: string) => void;
  onDltIdSelect: (data: string) => void;
  contentData: TemplateContent;
  onChangeContent: (value: string) => void;
  languages: IDataProps[];
  onDelete: (values: { hasId: boolean }) => void;
  edit: boolean;
  channel: string;
  onToolIdChange: (data: string) => void;
  onFromNameChange: (data: string) => void;
  onFromAddressChange: (data: string) => void;
  onSubjectChange: (subject: string) => void;
  onSenderIdChange: (data: string) => void;
  onMediaLinkChange: (data: string) => void;
  onEmailCcChange: (data: string) => void;
  onEmailBccChange: (data: string) => void;
};

export const validateEmail = (emails: string) => {
  if (emails === undefined || emails?.trim() === '') return true;
  const emailArray = emails?.split(',');
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  for (let i = 0; i < emailArray.length; i++) {
    const trimmedEmail = emailArray[i]?.trim();
    if (!emailPattern.test(trimmedEmail)) {
      return false;
    }
  }

  return true;
};
const ContentAccordion: React.FC<ContentAccordionProps> = ({
  index,
  selectedContentIndex,
  onClick,
  onLanguageSelect,
  onDltIdSelect,
  contentData,
  onChangeContent,
  languages,
  onDelete,
  edit,
  channel,
  onToolIdChange,
  onFromNameChange,
  onFromAddressChange,
  onSubjectChange,
  onSenderIdChange,
  onMediaLinkChange,
  onEmailCcChange,
  onEmailBccChange,
}) => {
  const onDeleteHandler = () => {
    if (contentData.id) {
      onDelete({ hasId: true });
    } else {
      onDelete({ hasId: false });
    }
  };

  const isChannelIVROrConversationBot =
    channel.toLowerCase() === 'ivr' || channel === 'conversationBot';

  const isChannelSms = channel.toLowerCase() === 'sms';
  const isChannelWhatsapp = channel.toLowerCase() === 'whatsapp';

  const isChannelEmail = channel.toLowerCase() === 'email';

  const getSummary = (contentData: TemplateContent) => {
    switch (channel) {
      case 'sms':
        return `${contentData.language} | DLT ID: ${contentData.templateId} | Sender Id: ${contentData.senderId}`;
      case 'whatsapp':
        return `${contentData.language} | Template ID: ${contentData.templateId}`;
      case 'ivr':
      case 'conversationBot':
        return `${contentData.language} | Tool ID: ${contentData.flowId}`;
      case 'email':
        return `${contentData.language} | From Name: ${contentData.fromName} | From Address: ${contentData.fromAddress} | Subject: ${contentData.subject}`;
      default:
        return '';
    }
  };

  const handleChange = (text: any) => {
    onFromAddressChange(text ? text.toString() : '');
  };

  if (index === selectedContentIndex) {
    return (
      <div className="flex flex-col gap-4 ">
        <div className="flex flex-col gap-4">
          <div
            className="flex flex-row z-[300] gap-4"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <div className="flex flex-1 z-[3000] ">
              <DropDownView
                disable={!edit}
                dropdownData={languages}
                handleClick={(data: { label: string; value: string }) => {
                  onLanguageSelect(data.value);
                }}
                value={contentData.language}
                title="Language*"
                placeholder="Select language"
              />
            </div>
            {(isChannelSms || isChannelWhatsapp) && (
              <div style={{ display: 'flex', flex: 1 }}>
                <InputView
                  disabled={!edit}
                  label={isChannelSms ? 'DLT ID*' : 'Template Id*'}
                  initialValue={contentData.templateId}
                  onChangeText={(text) => {
                    onDltIdSelect(text ? text.toString() : '');
                  }}
                  placeholder={
                    isChannelSms ? 'Enter DLT Id' : 'Enter Template Id'
                  }
                  maxLength={isChannelSms ? 20 : 40}
                />
              </div>
            )}
            {isChannelIVROrConversationBot && (
              <div className="flex w-1/2">
                <InputView
                  disabled={!edit}
                  label={'Tool Id*'}
                  initialValue={contentData.flowId}
                  onChangeText={(text) => {
                    onToolIdChange(text ? text.toString() : '');
                  }}
                  placeholder="Enter Tool Id"
                  maxLength={10}
                />
              </div>
            )}
            {isChannelEmail && (
              <div style={{ display: 'flex', flex: 1 }}>
                <InputView
                  disabled={!edit}
                  label={'From Name'}
                  initialValue={contentData.fromName}
                  onChangeText={(text) => {
                    onFromNameChange(text ? text.toString() : '');
                  }}
                  placeholder="Enter From Name"
                  maxLength={50}
                />
              </div>
            )}
          </div>
          {isChannelEmail && (
            <div
              className="flex flex-row z-[300] gap-4"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                zIndex: 290,
              }}
            >
              <div className="flex flex-col flex-1">
                <InputView
                  disabled={!edit}
                  label={'From Email Address'}
                  initialValue={contentData.fromAddress}
                  onChangeText={(text) => handleChange(text)}
                  placeholder="Enter From Email Address"
                  error={
                    !validateEmail(contentData.fromAddress)
                      ? 'Please enter a valid email address'
                      : ''
                  }
                />
              </div>
              <div style={{ display: 'flex', flex: 1 }}>
                <InputView
                  disabled={!edit}
                  label={'Subject'}
                  initialValue={contentData.subject}
                  onChangeText={(text) => {
                    onSubjectChange(text ? text.toString() : '');
                  }}
                  placeholder="Enter Subject"
                />
              </div>
            </div>
          )}
          {isChannelSms && (
            <div className="w-1/2" style={{ display: 'flex', flex: 1 }}>
              <InputView
                disabled={!edit}
                label={'Sender ID*'}
                initialValue={contentData.senderId}
                onChangeText={(text) => {
                  onSenderIdChange(text ? text.toString() : '');
                }}
                placeholder="Enter SenderId"
                maxLength={20}
              />
            </div>
          )}
          {isChannelWhatsapp && (
            <div className="w-1/2" style={{ display: 'flex', flex: 1 }}>
              <InputView
                disabled={!edit}
                label={'Media Link'}
                initialValue={contentData.mediaLink || ''}
                onChangeText={(text) => {
                  onMediaLinkChange(text ? text.toString() : '');
                }}
                placeholder="Enter Media Link"
              />
            </div>
          )}
          {isChannelEmail && (
            <div
              className="flex flex-row z-[300] gap-4"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                zIndex: 290,
              }}
            >
              <div style={{ display: 'flex', flex: 1 }}>
                <InputView
                  disabled={!edit}
                  label={'Bcc'}
                  initialValue={contentData.emailBcc || ''}
                  onChangeText={(text) => {
                    onEmailBccChange(text ? text.toString() : '');
                  }}
                  placeholder="Enter Bcc"
                  error={
                    !validateEmail(contentData.emailBcc || '')
                      ? 'Please enter a valid email address'
                      : ''
                  }
                />
              </div>
              <div style={{ display: 'flex', flex: 1 }}>
                <InputView
                  disabled={!edit}
                  label={'Cc'}
                  initialValue={contentData.emailCc || ''}
                  onChangeText={(text) => {
                    onEmailCcChange(text ? text.toString() : '');
                  }}
                  placeholder="Enter Cc"
                  error={
                    !validateEmail(contentData.emailCc || '')
                      ? 'Please enter a valid email address'
                      : ''
                  }
                />
              </div>
            </div>
          )}

          {isChannelEmail && (
            <div className="w-1/2" style={{ display: 'flex', flex: 1 }}>
              <InputView
                disabled={!edit}
                label={'Media Link'}
                initialValue={contentData.mediaLink || ''}
                onChangeText={(text) => {
                  onMediaLinkChange(text ? text.toString() : '');
                }}
                placeholder="Enter Media Link"
              />
            </div>
          )}
        </div>
        <div>
          <ContentEditor
            index={index}
            edit={edit}
            channel={channel}
            onDelete={onDeleteHandler}
            onChange={onChangeContent}
            contentData={contentData}
          />
        </div>
      </div>
    );
  }
  return (
    <div onClick={() => onClick(index)}>
      <div
        style={{
          border: '1px solid #D0D5DD',
          borderRadius: '4px',
          minHeight: '80px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            padding: '16px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            <TextView
              textType="body"
              color="#101828"
              size={14}
              fontStyle="normal"
              weight="600"
              lineHeight={20}
              text={getSummary(contentData)}
            />
            {isChannelIVROrConversationBot ? (
              <TextView
                textType="body"
                color="#475467"
                size={12}
                fontStyle="normal"
                weight="500"
                lineHeight={18}
                text={
                  contentData?.rawText
                    ? contentData?.rawText?.slice(0, 100).trim() + '...'
                    : ''
                }
              />
            ) : (
              <TextView
                textType="body"
                color="#475467"
                size={12}
                fontStyle="normal"
                weight="500"
                lineHeight={18}
                text={contentData?.message?.slice(0, 100).trim() + '...'}
              />
            )}
          </div>
          <ImageView src={chevronDown} pointer />
        </div>
      </div>
    </div>
  );
};

export default ContentAccordion;
