import { put, takeLatest } from 'redux-saga/effects';
import { campaignPlanner } from '../../../services/api/endPoints';
import {
  deleteOutreach,
  deleteOutreachFailed,
  deleteOutreachSucceeded,
} from '../../slice/campaign-planner/OutreachList.slice';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { ICreateOutreachResponse } from './Outreach.types';
import { PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';

export function* deleteOutreachSaga({
  payload,
}: PayloadAction<{
  outreachId: number;
}>) {
  try {
    const result: ICreateOutreachResponse = yield HTTPClient.DeleteRequest(
      `${campaignPlanner.OUTREACH()}/${payload.outreachId}`
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(deleteOutreachSucceeded({ outreachId: payload.outreachId }));
      message.success(result?.data?.message || 'Outreach deleted successfully');
    } else {
      yield put(
        deleteOutreachFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      deleteOutreachFailed({
        error: {
          message: 'An error occurred while creating outreaches.',
        },
      })
    );
  }
}

export function* watchDeleteOutreachSaga() {
  yield takeLatest(deleteOutreach.type, deleteOutreachSaga);
}
