import React from 'react';

interface ITrashIcon {
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}

function Plus(props: ITrashIcon) {
  const { color, width, height, viewBox } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      fill="none"
      viewBox={viewBox || '0 0 16 16'}
    >
      <g>
        <g fill={color || '#000'} fillRule="evenodd" clipRule="evenodd">
          <path d="M8 2.666c.369 0 .667.298.667.667v9.333a.667.667 0 11-1.333 0V3.333c0-.369.298-.667.667-.667z"></path>
          <path d="M2.668 8c0-.369.298-.667.667-.667h9.333a.667.667 0 110 1.333H3.335A.667.667 0 012.668 8z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Plus;
