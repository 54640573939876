import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateBreadCrumbsAction } from 'redux/allocation/allocation.slice';
import { capitalizeEachWord } from 'utils/helper';

const useUrlPathToBreadCrumb = () => {
  const dispatch = useDispatch();
  const breadCrumbs = useLocation()
    .pathname.replace('/allocation', '')
    .replace('/agency', '')
    .replace('/lender', '')
    .replace('/campaign', '')
    .split('/')
    .map((value, index) => ({
      name: capitalizeEachWord(value),
      id: index,
    }))
    .filter((value, index) => {
      if (Number.isInteger(Number(value.name))) return false;
      return index !== 0;
    });

  useEffect(() => {
    dispatch(
      updateBreadCrumbsAction({
        breadCrumbs,
        breadCrumbActiveState: breadCrumbs.at(-1)?.name || '',
      })
    );
  }, []);

  return {
    breadCrumbs,
    breadCrumbsActiveState: breadCrumbs.at(-1)?.name || '',
  };
};

export default useUrlPathToBreadCrumb;
