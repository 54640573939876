// ... Other imports ...

import { hierarchyManagementEndPoint } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';
import {
  IFetchDesignationListResponse,
  ISagaDesignationParam,
} from './DesignationManagement.types';
import { ApiStatusCodes } from 'services/api/apiStatusCode';
import {
  fetchDesignationList,
  fetchDesignationListFailed,
  fetchDesignationListSucceeded,
} from 'redux/slice/hierarchy-management/DesignationManagement.slice';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

const { designationEndpoint } = hierarchyManagementEndPoint;
export function* fetchDesignationListSaga({
  payload,
}: PayloadAction<ISagaDesignationParam>) {
  const { offset, limit, searchKeyword } = payload;
  try {
    const result: IFetchDesignationListResponse = yield HTTPClient.GetRequest(
      designationEndpoint.GET_DESIGNATION_LIST(offset, limit, searchKeyword)
    );

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.data) {
      yield put(fetchDesignationListSucceeded(result?.data));
    } else {
      yield put(
        fetchDesignationListFailed({
          error: {
            message: 'Failed to load designation list',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchDesignationListFailed({
        error: {
          message: 'An error occurred while fetching user entities.',
        },
      })
    );
  }
}

export function* watchDesignationListSaga() {
  yield takeLatest(fetchDesignationList.type, fetchDesignationListSaga);
}
