import YbCoreFilter from '@yubi/yb-core-filter';
import { useStyles } from '@yubi/yb-style-provider';

import React, { useState } from 'react';
import { Image } from 'react-native';
import FilterViewChild from './FilterViewChildAdvance';
import './index.css';
import { FilterListType } from './filterTyes';
import useAmplitude from 'hooks/useAmplitude';
import { AmplitudeEvents } from 'constants/constant';

interface ISliderData {
  min?: number;
  max?: number;
}
interface IFilterOptions {
  name: string;
  type: string;
  label: string;
  data: FilterListType | ISliderData;
  selectedFilter: ISliderData | string[];
  setSelectedFilter: (value: string[] | ISliderData) => void;
}
interface IFilterProps {
  options: IFilterOptions[];
  resetFilterFn: () => void;
  applyFilterFn: () => void;
  sendAmplitudeEvent?: boolean;
  amplitudeEventName?: string;
}

const FilterViewAdvance = ({
  options,
  resetFilterFn,
  applyFilterFn,
  sendAmplitudeEvent = false,
  amplitudeEventName,
}: IFilterProps) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const { styleConnector } = useStyles();

  const { sendAmplitudeEvent: sendFilterAmplitudeEvent } = useAmplitude();

  const selectedFiltersCount = options.reduce((accumulator, object) => {
    if (Array.isArray(object.selectedFilter)) {
      return accumulator + object.selectedFilter.length;
    } else if (
      object.selectedFilter &&
      'min' in object.selectedFilter &&
      'max' in object.selectedFilter
    ) {
      return accumulator + 1;
    } else {
      return accumulator;
    }
  }, 0);

  function onClose() {
    setOpenDropdown(false);
    if (filtersApplied === false) {
      options.forEach((obj) => (obj.selectedFilter = []));
    }
  }

  return (
    <div>
      <YbCoreFilter
        type="dropdown"
        styleConnector={styleConnector}
        triggerState={openDropdown}
        triggerName="Filters"
        triggerIcon={
          <Image
            source={{
              uri: 'https://storybook-qa.go-yubi.in/static/media/filter.e0de0230da68c8f2ce06bdf45f3b23c2.svg',
            }}
            resizeMethod="auto"
            resizeMode="center"
            style={{ width: 11, height: 10 }}
          />
        }
        position="left"
        onTriggerClick={() => {
          setOpenDropdown((prev) => !prev);
          if (!sendAmplitudeEvent) return;
          sendFilterAmplitudeEvent(
            amplitudeEventName || AmplitudeEvents.FILTER_EVENT
          );
        }}
        FilterHeadngText="Filters"
        handleClose={() => onClose()}
        selectedFiltersCount={selectedFiltersCount}
        ButtonOnePlaceholder="Reset"
        ButtonTwoPlaceholder="Apply"
        onClickButtonOne={() => {
          resetFilterFn();
          setOpenDropdown(false);
          setFiltersApplied(false);
        }}
        onClickButtonTwo={() => {
          applyFilterFn();
          setOpenDropdown(false);
          setFiltersApplied(true);
        }}
      >
        <>
          <div className="overflow-y-auto max-h-[40vh] w-fit">
            {options &&
              options.map(
                (val, i) =>
                  val.data &&
                  Object.keys(val.data).length > 0 && (
                    <FilterViewChild filterData={val} key={i} />
                  )
              )}
          </div>
        </>
      </YbCoreFilter>
    </div>
  );
};

export default FilterViewAdvance;
