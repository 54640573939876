import React, { useEffect, useState } from 'react';
import { YbCoreIcon } from '@yubi/yb-core-icon';
import { ButtonViewV3 } from 'assets/components-v3';
import { CustomModal, DividerView, SpinnerView, TextView } from 'components';
import ModalInfo from './ModalInfo';
import FolderPlus from 'assets/svgComponents/FolderPlus';
import CreateProductAtom from './CreateProductAtom';
import { IProductData } from '../shared/contract';
import DeleteComponent from './DeleteComponent';
import { createProductPreference } from '../shared/api';
import { message } from 'antd';

const initialProductData: IProductData = {
  assetClassCategory: '',
  assetClassSubCategory: '',
  enabled: false,
  lineOfBusiness: '',
  productName: '',
};

interface ICreateProduct {
  open: boolean;
  onClose: (isCreateSuccessful: boolean) => void;
}

const CreateProduct = (props: ICreateProduct) => {
  const { open, onClose } = props;
  const modalInfoData = [
    {
      title: 'Asset Type',
      description:
        'Asset class refers to a group or category of financial \ninstruments or assets that share similar characteristics\nand behave in a similar manner in the market. In a \ndebt collections context, asset class can refer to the \ntypes of debts being collected, such as credit card \ndebt, personal loans, auto loans, mortgages, or other\ncategories of debts',
    },
  ];

  const [productData, setProductData] = useState<IProductData[]>([
    initialProductData,
  ]);

  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (index: number, data: IProductData) => {
    setProductData((prev) => {
      const newData = [...prev];
      newData[index] = data;
      return newData;
    });
  };

  const handleAddProduct = () => {
    setProductData((prev) => {
      const newData = [...prev];
      newData.push(initialProductData);
      return newData;
    });
  };

  const handleAction = (actionType: 'Reset' | 'Delete', index: number) => {
    if (actionType === 'Reset') {
      setProductData((prev) => {
        const newData = [...prev];
        newData[index] = initialProductData;
        return newData;
      });
    } else {
      setProductData((prev) => {
        const newData = [...prev];
        newData.splice(index, 1);
        return newData;
      });
    }
  };

  const validator = () => {
    if (!productData.length) return false;
    let isValid = true;
    productData.forEach((data) => {
      if (
        data.productName === '' ||
        data.lineOfBusiness === '' ||
        data.assetClassCategory === '' ||
        data.assetClassSubCategory === ''
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      // API call to save data
      const response = await createProductPreference(productData);
      if (response.success) {
        message.success("Product's created successfully");
        onClose(true);
      } else {
        message.error(response.errorMessage || '');
      }
    } catch (error) {
      message.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const isDataValid = validator();
  const disableCreate = isDataValid || !productData.length;

  return (
    <CustomModal open={open}>
      <SpinnerView spinning={isLoading}>
        <article className="flex flex-col h-full overflow-y-auto">
          <header className="flex py-3.5 px-6 justify-between">
            <section
              className="flex gap-2 items-center"
              // onClick={() => setShowDelete(true)}
            >
              {/* <YbCoreIcon size={16} name="chevron-left" /> */}
              <TextView text={'Add products'} />
            </section>
            <section className="flex gap-2">
              <ButtonViewV3
                variant="tertiary"
                label="Cancel"
                onClick={() => setShowDelete(true)}
              />
              <ButtonViewV3
                label="Save"
                disabled={!isDataValid}
                onClick={handleSave}
              />
            </section>
          </header>
          <DividerView lineColor="#F2F4F7" />
          <body className="flex flex-1 px-6 py-6 gap-6 overflow-y-auto">
            <article className="flex flex-1 flex-col gap-6 ">
              {productData.map((data: IProductData, i) => {
                return (
                  <CreateProductAtom
                    disableOverflow={productData.length === 1}
                    key={i}
                    handleChange={handleChange}
                    data={data}
                    index={i}
                    handleAction={handleAction}
                  />
                );
              })}
              <section
                typeof="button"
                className={`flex gap-2 items-center pb-6 w-fit ${
                  disableCreate ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
                style={{
                  opacity: disableCreate ? 1 : 0.5,
                }}
                onClick={() => {
                  if (disableCreate) handleAddProduct();
                }}
              >
                <FolderPlus />
                <TextView
                  text="Add another product"
                  color="#FD7149"
                  size={12}
                  lineHeight={18}
                  weight="700"
                />
              </section>
            </article>
            <aside>
              <ModalInfo data={modalInfoData} />
            </aside>
          </body>
        </article>
        {showDelete && (
          <DeleteComponent
            showDelete={showDelete}
            onDelete={(deleteConfirmation) => {
              if (deleteConfirmation) {
                onClose(false);
              } else {
                setShowDelete(false);
              }
            }}
            headingText={'Sure you want to exit?'}
            subHeadingText={'All changes will be lost.'}
            cancelText="No"
            confirmText="Yes"
          />
        )}
      </SpinnerView>
    </CustomModal>
  );
};

export default CreateProduct;
