import { useParams } from 'react-router-dom';

export const channelsMap = {
  sms: {
    channel: 'sms',
    name: 'SMS',
    imageUrl: '/mobile.svg',
    displayIdText: 'DLT ID, Sender ID',
    templateName: 'Name your SMS template',
  },
  whatsapp: {
    channel: 'whatsapp',
    name: 'Whatsapp',
    imageUrl: '/whatsapp-background.svg',
    displayIdText: 'Template ID',
    templateName: 'Name your Whatsapp template',
  },
  ivr: {
    channel: 'ivr',
    name: 'IVR',
    imageUrl: '/mobile.svg',
    displayIdText: 'Tool ID',
    templateName: 'Name your IVR template',
  },
  email: {
    channel: 'email',
    name: 'Email',
    imageUrl: '',
    displayIdText: 'first name, email address, subject',
    templateName: 'Name your Email template',
  },
  conversationBot: {
    channel: 'conversationBot',
    name: 'Conversation Bot',
    imageUrl: '/mobile.svg',
    displayIdText: 'Tool ID',
    templateName: 'Name your Conversation Bot template',
  },
};
const useChannelDetails = () => {
  const { channel } = useParams();
  if (!channel) return channelsMap.sms;
  return channelsMap[channel as keyof typeof channelsMap] || channelsMap.sms;
};

export default useChannelDetails;
