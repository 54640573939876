import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from '../../../services/api/apiStatusCode';
import { IGetSelectedConfigDetailsInitialState } from 'redux/types/GetSelectedConfigDetails.type';
import { IAllocationConfig } from 'redux/types/GetAllocationConfigList.type';

export const getSelectedConfigInitialState: IGetSelectedConfigDetailsInitialState =
  {
    status: APIStatus.INIT,
    data: {},
    isLoading: false,
    error: {},
  };

const GetSelectedConfigDetailsSlice = createSlice({
  name: 'getSelectedConfigDetails',
  initialState: getSelectedConfigInitialState,
  reducers: {
    setSelectedConfigDetails: (state, action) => {
      state.data = action.payload;
      state.status = APIStatus.SUCCESS;
    },
    resetGetSelectedConfigDetailsState: () => {
      return getSelectedConfigInitialState;
    },
  },
});

export const { setSelectedConfigDetails, resetGetSelectedConfigDetailsState } =
  GetSelectedConfigDetailsSlice.actions;

export default GetSelectedConfigDetailsSlice.reducer;
