import { message } from 'antd';
import { IAllocation } from 'features/CreateAllocation/createAllocation.type';
import { allocationEngineEndpoints } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';

export const getAllocationDetails = async (allocationId: string) => {
  try {
    const allocationData = await HTTPClient.GetRequest<{
      allocation: IAllocation;
    }>(allocationEngineEndpoints.GET_ALLOCATION_BY_ID(allocationId));

    if (allocationData.status === 200) {
      return allocationData.data;
    }
    throw new Error();
  } catch (e) {
    message.error('Error while fetching allocation details');
    return false;
  }
};
