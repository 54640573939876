import { ImageView, TextView } from 'components';
import React from 'react';

interface IUserInfo {
  imageUrl: string;
  headingText: string;
  subText: string[];
  buttonComponent?: React.ReactNode;
}

const UserInfo = (props: IUserInfo) => {
  const { imageUrl, headingText, subText, buttonComponent } = props;
  const x = 10;
  console.log('hello', x);
  return (
    <div className="flex h-full">
      <div className="flex flex-1 flex-col justify-center items-center gap-2">
        <ImageView src={imageUrl} />
        <TextView
          textType={'h4'}
          weight={'700'}
          lineHeight={28}
          size={18}
          text={headingText}
          color="#475467"
        />
        {subText.map((text: string) => (
          <TextView color="#98A2B3" lineHeight={20} size={14} text={text} />
        ))}
        {buttonComponent && <div className="pt-2">{buttonComponent}</div>}
      </div>
    </div>
  );
};

export default UserInfo;
