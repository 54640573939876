import React, { useState, useEffect, useCallback } from 'react';
import { styled } from 'styled-components';
import TextView from '../../components/TextView';
import { useAppDispatch } from '../../utils/hooks/useAppDispatch';
import { SpinnerView } from '../../components';
import { useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import { getLOBPolicies } from 'features/Settings/shared/api';
import { ComplianceTable } from 'features/Settings/Compliance/ComplianceTable';
import { getChannelPreferencesRequested } from 'redux/slice/settings/Preferences/ChannelPreference.slice';
import { isObjectEmpty } from 'utils/helper';
import { ILenderPolicy } from 'redux/types/Compliance.types';
import { modifyPolicyStructure } from 'features/Settings/shared/helper';
import { message } from 'antd';
import dayjs from 'dayjs';
import { useAppSelector } from 'utils/hooks/useAppSelector';
import { APIStatus } from 'services/api/apiStatusCode';
import { getAllocationDetails } from 'features/AssignAllocation/helper';
import {
  IAllocation,
  allocationDetailsInitialValues,
} from 'features/CreateAllocation/createAllocation.type';
import {
  createCampaign,
  fetchCampaignPlan,
  resetCampaignPlan,
  setEditStatus,
  updateCampaign,
} from 'redux/slice/strategy/strategy';
import useShouldEditCampaign from 'features/CampaignPlanning/hooks/useShouldEditCampaign';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'redux/slice/reducer';
import useDisablePlan from 'features/CampaignPlanning/hooks/useDisablePlan';
import Compliance from './components/Compliance';
import NoData from 'features/Settings/components/NoData';
import { getDefaultPolicyRequested } from 'redux/slice/settings/Compliance/GetDefaultPolicy.slice';
import {
  CreateCampaignActionPayloadType,
  HandleSegmentationCallback,
} from 'redux/slice/campaign-planner/types';
import useDisableWhenCampaignNotSuccess from 'features/CampaignPlanning/hooks/useDisableWhenCampaignNotSuccess';
import Header, { showExitModalAtom } from './SegmentRule/CampaignHeader';
import { useAtom } from 'jotai';
import ExitModal from './SegmentRule/ExitModal';
import axiosInstance from 'services/api/axios';
import { campaignPlanner } from 'services/api/endPoints';
import { CampaignSegment } from 'features/StrategyConfig/CreatePlan/Flow/strategyTestData';
import { HTTPClient } from 'services/api/http-client';
import { Input } from '@yubi/yb-core-input-v3';
import { YbCoreDatePicker } from '@yubi/yb-core-date-picker-v3';
import usePromptWithMessage from 'hooks/usePromptWithMessage';
import useUnload from 'hooks/useUnload';
import useAmplitude from 'hooks/useAmplitude';
import { AmplitudeEvents } from 'constants/constant';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
`;

const StyledFixWidth = styled.div`
  width: 440px;
`;

const MAX_LIMIT = 60;

export enum CampaignExecutionStatus {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  EXECUTION_DUE = 'execution-due',
  NONE = '',
}
export function convertDateFormat(inputDate: string): string {
  // Split the input date using the "-" delimiter
  const dateArray: string[] = inputDate.split('-');
  // Rearrange the date components in the desired format
  return `${dateArray[2]}-${dateArray[1].padStart(
    2,
    '0'
  )}-${dateArray[0].padStart(2, '0')}`;
}

export const convertISOToLocaleDateString = (isoDate: string | Date) => {
  return convertDateFormat(
    new Date(isoDate).toLocaleString('en-GB').split(',')[0].replace(/\//g, '-')
  );
};

export interface ILenderPoliciesState {
  [key: string]: {
    [key: string]: ILenderPolicy;
  };
}

export const Campaign = () => {
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const allocationId = params.get('allocation-id');
  const campaignId = params.get('campaign-id');
  const nav = useNavigate();
  const [isExitLoading, setIsExitLoading] = useState(false);
  const region = process.env.REACT_APP_REGION || 'IN';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [campaignNameInput, setCampaignNameInput] = useState('');
  const [selectedFromDate, setSelectedFromDate] = useState<
    dayjs.Dayjs | undefined
  >();
  const [selectedToDate, setSelectedToDate] = useState<
    dayjs.Dayjs | undefined
  >();
  const [policies, setPolicies] = useState<ILenderPoliciesState>();
  const [risks, setRisks] = useState<Array<string>>([]);
  const [selectedAllocation, setSelectedAllocation] = useState<
    IAllocation | undefined
  >(allocationDetailsInitialValues);
  const lenderId = useSelector(
    (state: RootReducerState) => state.userConfiguration.lenderId
  );
  const [selectedPolicy, setSelectedPolicy] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [clickedKey, setClickedKey] = useState<string>('');
  const [, setShowExitModal] = useAtom(showExitModalAtom);

  const {
    defaultPolicy: { data: defaultPolicyData, status: defaultPolicyStatus },
  } = useAppSelector((state) => state.settings.compliance);
  const campaignName = useAppSelector(
    (state) => state.strategy.campaignPlanner.campaignName
  );
  const segmentsCount = useAppSelector(
    (state) => state.strategy.campaignPlanner.campaignSegmentList?.length
  );
  const isNba = useAppSelector((state) => state.strategy.campaignPlanner.isNba);
  const editAllowed = useAppSelector(
    (state) => state.strategy.campaignPlanner.editAllowed
  );

  const startDate = useAppSelector(
    (state) => state.strategy.campaignPlanner.startDate
  );
  const endDate = useAppSelector(
    (state) => state.strategy.campaignPlanner.endDate
  );
  const { data: channels, status: channelsStatus } = useAppSelector(
    (state) => state.settings.preference.channels
  );
  const isLoadingCampaignPlan = useAppSelector(
    (state) => state.strategy.isLoading
  );
  const { entityId = '', entityName = '' } = useAppSelector(
    (state) => state.userConfiguration
  );
  const shouldEditCampaign = useShouldEditCampaign();
  const disablePlan = useDisablePlan();
  const disableWhenCampaignNotSuccess = useDisableWhenCampaignNotSuccess();
  const isEdited = useAppSelector(
    (state) => state.strategy.campaignPlanner.edited
  );

  const { sendAmplitudeEvent } = useAmplitude();

  useBeforeUnload(
    useCallback(
      async (event) => {
        if (isEdited) {
          event.preventDefault();
        }
      },
      [isEdited]
    ),
    { capture: true }
  );

  const { setIsEditingState } = usePromptWithMessage({
    showPopUp: isEdited,
    messageText:
      'You have unplanned campaign changes. Are you sure you want to leave?',
    callback: async function () {
      try {
        await axiosInstance.put(
          campaignPlanner.ROLL_BACK_CAMPAIGN(campaignId || '')
        );
      } catch (e) {
        const err = e as unknown as { response: { data: { error: string } } };
        message.error(
          err.response.data.error || 'Failed to roll back the campaign'
        );
      }
    },
  });

  useUnload({
    url: campaignPlanner.ROLL_BACK_CAMPAIGN(campaignId || ''),
    shouldTriggerUnloadAction: isEdited || false,
  });

  useEffect(() => {
    dispatch(getChannelPreferencesRequested({ offset: 0, limit: 1000 }));
    dispatch(getDefaultPolicyRequested({ region, entityId, entityName }));
    if (campaignId) {
      dispatch(
        fetchCampaignPlan({ allocationId: parseInt(campaignId || '0') })
      );
    }

    return () => {
      setClickedKey('');
      dispatch(resetCampaignPlan());
    };
  }, []);

  const getPolicies = async () => {
    setLoading(true);
    if (!isObjectEmpty(selectedAllocation)) {
      const { lob, risk: allocationRisks } = selectedAllocation || {};

      let modifiedRisks = allocationRisks?.length ? allocationRisks : ['all'];
      modifiedRisks = modifiedRisks.map((risk) => risk.toLowerCase());
      const allLob = lob?.split(',').map((product_bucket) => {
        const [product, bucket] = product_bucket.split('_');
        return {
          product,
          bucket,
        };
      });

      if (allLob?.length && modifiedRisks) {
        const response = await getLOBPolicies({
          lenderId: entityId,
          lob: allLob,
          risk: modifiedRisks,
        });
        setRisks(modifiedRisks);
        const responsePolicies = response.data;
        if (responsePolicies && responsePolicies.length) {
          const newPolicies: ILenderPoliciesState = {};
          responsePolicies.map((policy: ILenderPolicy, index: number) => {
            const { productName, bucketName, riskCategory } = policy;
            newPolicies[`${productName}_${bucketName}`] = {
              ...newPolicies[`${productName}_${bucketName}`],
              [riskCategory]: policy,
            };
            if (index === 0) setSelectedPolicy(`${productName}_${bucketName}`);
            return null;
          });
          setPolicies(newPolicies);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isObjectEmpty(selectedAllocation)) getPolicies();
  }, [selectedAllocation]);

  useEffect(() => {
    const getAllocationDetailsHandler = async () => {
      const result: { allocation: IAllocation } | false =
        await getAllocationDetails(allocationId || '');
      if (result) {
        setSelectedAllocation(result.allocation);
      }
    };
    getAllocationDetailsHandler();
  }, []);

  useEffect(() => {
    if (!endDate || !startDate || !campaignName) return;
    setCampaignNameInput(campaignName);
    setSelectedFromDate(dayjs(startDate));
    setSelectedToDate(dayjs(endDate));
  }, [endDate, startDate]);

  const handleSegmentation = (
    handleSegmentationCallback: HandleSegmentationCallback,
    { submitPlan, isNba }: { submitPlan: boolean; isNba: boolean }
  ) => {
    if (campaignId) {
      const payload = {
        campaignId,
        endDate: convertISOToLocaleDateString(selectedToDate?.toDate() || ''),
        startDate: convertISOToLocaleDateString(
          selectedFromDate?.toDate() || ''
        ),
        lenderId,
      };
      dispatch(
        updateCampaign({
          payload,
          handleSegmentationCallback,
          submitPlan,
        })
      );
    } else {
      const payload: CreateCampaignActionPayloadType = {
        allocationId,
        allocationName: selectedAllocation?.allocationName || '',
        campaignName: campaignNameInput,
        currentStatus: 'CAMPAIGN_CREATION_IN_PROGRESS',
        endDate: convertISOToLocaleDateString(selectedToDate?.toDate() || ''),
        startDate: convertISOToLocaleDateString(
          selectedFromDate?.toDate() || ''
        ),
        lenderId: selectedAllocation?.lenderId || '',
        clientId: selectedAllocation?.clientId || '',
        allocationStartDate: selectedAllocation?.allocationStartDate || '',
        allocationEndDate: selectedAllocation?.allocationEndDate || '',
        lob: selectedAllocation?.lob || '',
        risk: selectedAllocation?.risk ?? [],
        isNba,
        records: selectedAllocation?.metadata.total_records ?? 0,
        tos: selectedAllocation?.metadata.total_outstanding ?? 0,
      };
      dispatch(createCampaign({ payload, handleSegmentationCallback }));
    }
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    const validAllocationStartDate = selectedAllocation?.allocationStartDate
      ? dayjs(selectedAllocation.allocationStartDate).startOf('day')
      : dayjs().startOf('day');

    const validAllocationEndDate = selectedAllocation?.allocationEndDate
      ? dayjs(selectedAllocation.allocationEndDate).endOf('day')
      : dayjs().add(10, 'year').endOf('day');

    const currentDate = dayjs().startOf('day');

    const effectiveStartDate = currentDate.isAfter(validAllocationStartDate)
      ? currentDate
      : validAllocationStartDate;

    const campaignStartDate = dayjs(startDate).isSame(current);
    if (campaignStartDate) {
      return false;
    }
    return (
      current.isBefore(effectiveStartDate) ||
      current.isAfter(validAllocationEndDate)
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetCampaignPlan());
    };
  }, []);

  const isLoading =
    defaultPolicyStatus === APIStatus.PENDING ||
    channelsStatus === APIStatus.PENDING ||
    isLoadingCampaignPlan ||
    loading;

  const isPoliciesLoading =
    defaultPolicyStatus === APIStatus.PENDING || isLoading;

  const handleSegmentationClick = (key: string, isNba = false) => {
    if (key === '1') {
      const handleSegmentationSuccessCallback = () => {
        setIsExitLoading(false);
        navigate('/campaign/campaign-list');
      };

      setIsExitLoading(true);
      handleSegmentation(handleSegmentationSuccessCallback, {
        submitPlan: true,
        isNba,
      });

      return;
    }
    const handleSegmentationSuccessCallback: HandleSegmentationCallback = ({
      campaignId,
      isSuccess,
    }) => {
      if (!isSuccess) return;

      sendAmplitudeEvent(AmplitudeEvents.CREATE_CAMPAIGN_NEXT_CLICK_EVENT, {
        campaignName: campaignNameInput,
        startDate: selectedFromDate,
        endDate: selectedToDate,
        planningMethod: isNba ? 'NBA' : 'Manual',
      });
      setIsEditingState(false);
      dispatch(setEditStatus({ isEdited: false }));
      setShowExitModal(false);
      setTimeout(() => {
        navigate(
          `/campaign/create-campaign/campaign/segmentation?campaign-id=${campaignId}&hide-nav=true`
        );
      }, 50);
    };
    handleSegmentation(handleSegmentationSuccessCallback, {
      submitPlan: false,
      isNba,
    });
  };

  const handleNbaStatus = async (isNbaEnabled: boolean) => {
    handleSegmentationClick(clickedKey, isNbaEnabled);
    setClickedKey('');
  };

  return (
    <SpinnerView spinning={isLoading}>
      <div className="flex flex-col h-full pt-4 flex-1">
        <div className="flex flex-col gap-4">
          <Header
            menuItems={[
              {
                label: 'Skip segmentation',
                key: 1,
                disabled: isNba || !editAllowed,
                onClick: async () => {
                  if (campaignId && segmentsCount) {
                    setIsExitLoading(false);
                    setIsEditingState(false);
                    dispatch(setEditStatus({ isEdited: false }));
                    setShowExitModal(false);
                    setTimeout(() => {
                      nav(
                        `/campaign/create-campaign/campaign/segmentation/planning?campaign-id=${campaignId}&hide-nav=true`
                      );
                    }, 50);
                    return;
                  }

                  await HTTPClient.PostRequest<{
                    data: CampaignSegment[];
                  }>(
                    campaignPlanner.SAVE_SEGMENT_TO_THE_CAMPAIGN(campaignId),
                    []
                  );
                  nav(
                    `/campaign/create-campaign/campaign/segmentation/planning?campaign-id=${campaignId}&hide-nav=true`
                  );
                },
              },
              {
                label: 'Save as draft',
                disabled: isNba || !editAllowed,
                key: 3,
                onClick: async () => {
                  sendAmplitudeEvent(
                    AmplitudeEvents.CREATE_CAMPAIGN_SAVE_AS_DRAFT_CLICK_EVENT,
                    {
                      campaignName: campaignNameInput,
                      startDate: selectedFromDate,
                      endDate: selectedToDate,
                    }
                  );
                  const handleSegmentationSuccessCallback: HandleSegmentationCallback =
                    ({ campaignId }) => {
                      nav(
                        `${location.pathname}?campaign-id=${campaignId}&allocation-id=${allocationId}&hide-nav=true`
                      );
                    };
                  handleSegmentation(handleSegmentationSuccessCallback, {
                    submitPlan: false,
                    isNba: false,
                  });
                },
              },
              // {
              //   label: 'Help and support',
              //   key: 2,
              //   onClick: () => {},
              // },
            ]}
            secondaryButtons={[
              { label: 'Exit', onClick: () => setShowExitModal(true) },
            ]}
            heading={campaignId ? 'Edit Campaign' : 'Create campaign'}
            tags={[`Allocation: ${selectedAllocation?.allocationName ?? ''}`]}
            exitModal={{
              cancel: {
                disabled: isExitLoading,
                onModalCancel: async () => {
                  try {
                    setIsExitLoading(true);
                    if (campaignId && isEdited) {
                      await axiosInstance.put(
                        campaignPlanner.ROLL_BACK_CAMPAIGN(campaignId || '')
                      );
                    }
                  } catch (e) {
                    console.log(e);
                  } finally {
                    setIsExitLoading(false);
                    setIsEditingState(false);
                    dispatch(setEditStatus({ isEdited: false }));
                    setShowExitModal(false);
                    setTimeout(() => {
                      nav('/campaign/campaign-list');
                    }, 50);
                  }
                },
              },
              saveInConfig: {
                isLoading: isExitLoading,
                disabled:
                  !campaignNameInput ||
                  (!selectedFromDate && !selectedToDate) ||
                  disablePlan ||
                  disableWhenCampaignNotSuccess ||
                  !shouldEditCampaign ||
                  !campaignId ||
                  !segmentsCount ||
                  !editAllowed,
                onClick: function (): Promise<boolean> {
                  if (campaignId) {
                    handleSegmentationClick('1');
                  } else {
                    setClickedKey('1');
                  }
                  return Promise.resolve(true);
                },
              },
            }}
            nextButton={{
              name: 'Next',
              disabled:
                !campaignNameInput ||
                (!selectedFromDate && !selectedToDate) ||
                disablePlan ||
                !shouldEditCampaign ||
                !editAllowed,
              onClick: function (): void {
                if (campaignId) {
                  handleSegmentationClick('2');
                } else {
                  setClickedKey('2');
                }
              },
            }}
          />
          <div className="flex flex-row gap-4">
            {[1, 2, 3].map((step) => (
              <div
                className="w-full h-[2px] flex-1"
                style={{ backgroundColor: step <= 1 ? '#FD7149' : '#D0D5DD' }}
              ></div>
            ))}
          </div>
          <div className="flex flex-col items-start gap-2">
            <TextView
              size={16}
              weight="600"
              lineHeight={24}
              color="#121926"
              text="Step 1: Complete campaign setup"
            />
            <TextView
              size={14}
              weight="500"
              lineHeight={20}
              color="#475467"
              text="Get started by filling out the campaign name and duration. Check compliance policies and split your allocation for running experiments if you would like"
            />
          </div>
        </div>
        <BodyContainer className="flex-1 gap-10">
          <div className="flex items-end gap-10">
            <StyledFixWidth>
              <Input
                value={campaignId ? campaignName : campaignNameInput}
                label="Campaign name"
                required
                placeholder="Enter campaign name"
                size="s"
                disabled={!!campaignId}
                onChange={(e: any) => setCampaignNameInput(e.nativeEvent.text)}
              />
            </StyledFixWidth>

            <StyledFixWidth>
              <YbCoreDatePicker
                inputFieldSize="s"
                disableClearDateOnIconClick
                disabled={disableWhenCampaignNotSuccess}
                label="Select campaign duration"
                onSelectDateRange={(
                  fromDate: dayjs.Dayjs | undefined,
                  toDate: dayjs.Dayjs | undefined
                ) => {
                  if (!fromDate || !toDate) {
                    message.error(
                      'Please select both a "From Date" and a "To Date".'
                    );
                    return;
                  }

                  const diffInDays = fromDate.diff(toDate, 'day');
                  const isMoreThan60Days = diffInDays > MAX_LIMIT;
                  if (isMoreThan60Days) {
                    message.error(
                      'The difference between dates cannot be more than 60 days.'
                    );
                    return;
                  }

                  setSelectedFromDate(fromDate);
                  setSelectedToDate(toDate);
                }}
                mode="dual-date-range-picker"
                selectedFromDate={selectedFromDate}
                selectedToDate={selectedToDate}
                disableDate={disabledDate}
              />
            </StyledFixWidth>
          </div>
          <div className="flex-1 pb-4 gap-2">
            <TextView
              size={16}
              weight="600"
              lineHeight={24}
              color="#121926"
              text="Compliance policies"
            />
            {defaultPolicyData && !isPoliciesLoading ? (
              isObjectEmpty(policies) ? (
                <>
                  <ComplianceTable
                    validationData={modifyPolicyStructure(defaultPolicyData)}
                    data={modifyPolicyStructure(defaultPolicyData)}
                    getValidData={() => {}}
                    channels={channels || []}
                    resetClicked={0}
                    handleReset={() => {}}
                    hideAction
                  />
                </>
              ) : (
                <div className="">
                  <Compliance
                    allPolicies={policies}
                    key={selectedPolicy}
                    selectedPolicy={selectedPolicy}
                    setSelectedPolicy={setSelectedPolicy}
                    policies={policies?.[selectedPolicy] || {}}
                    defaultPolicy={defaultPolicyData}
                    risks={risks}
                  />
                </div>
              )
            ) : !isPoliciesLoading ? (
              <NoData headingText={'No default policies set'} />
            ) : (
              <></>
            )}
          </div>
        </BodyContainer>
      </div>
      {clickedKey && !campaignId && (
        <>
          <ExitModal
            onCancel={() => setClickedKey('')}
            visible={true}
            title="Choose planning method"
            subTitle="Choose your planning method here."
            primaryButton={{
              label: 'NBA',
              onClick: function (): void {
                handleNbaStatus(true);
              },
              disabled: undefined,
              isLoading: undefined,
            }}
            secondaryButton={{
              label: 'Manual',
              onClick: function (): void {
                handleNbaStatus(false);
              },
              disabled: undefined,
              isLoading: undefined,
            }}
          />
        </>
      )}
    </SpinnerView>
  );
};
