import React, { useState } from 'react';
import { useStyles } from '@yubi/yb-style-provider';
import YbCoreNotification from '@yubi/yb-core-notification';
import { BellSVG } from 'assets/img';
// import Bell from '../../assets/img/bell.svg';
const { Tile } = YbCoreNotification;

export function NotificationView() {
  const { styleConnector } = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <YbCoreNotification
      size="s"
      open={open}
      onNotificationClick={() => {}}
      onClick={() => {
        setOpen(!open);
      }}
      active={open}
      notificationNumbers={40}
      icon={BellSVG}
      activeIcon={BellSVG}
      styleConnector={styleConnector}
    >
      <Tile
        active
        innerData={{ some: 'some' }}
        variant="avatar"
        avatarTextFront="Kanishk"
        avatarTextBack="Dixit"
        title="Notification goes here"
        subtitle="Support text goes here.."
        traling="1 month ago"
        tralingDot
      />
      <Tile
        variant="avatar"
        size="m"
        title="Notification goes here"
        subtitle="Support text goes here.."
        traling="1 month ago"
        tralingDot
      />
      <Tile
        variant="avatar"
        title="Notification goes here"
        subtitle="Support text goes here.."
        traling="1 month ago"
      />
      <Tile
        variant="avatar"
        title="Notification goes here"
        subtitle="Support text goes here.."
        traling="1 month ago"
        tralingDot
      />
      <Tile
        variant="avatar"
        title="Notification goes here"
        subtitle="Support text goes here.."
        traling="1 month ago"
      />
      <Tile
        variant="avatar"
        title="Notification goes here"
        subtitle="Support text goes here.."
        traling="1 month ago"
        tralingDot
      />
      <Tile
        variant="avatar"
        title="Notification goes here"
        subtitle="Support text goes here.."
        traling="1 month ago"
      />
      <Tile
        variant="avatar"
        title="Notification goes here"
        subtitle="Support text goes here.."
        traling="1 month ago"
        tralingDot
      />
    </YbCoreNotification>
  );
}
