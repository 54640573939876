import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetGlobalPolicyState, IPolicy } from 'redux/types/Compliance.types';
import { IError } from 'redux/types/UserOnboardDetails.types';
import { APIStatus } from 'services/api/apiStatusCode';

export const globalPolicyInitialState: IGetGlobalPolicyState = {
  status: APIStatus.INIT,
};

export interface IGetGlobalPolicyPayload {
  region: string;
  entityId: string;
}

const globalPolicySlice = createSlice({
  name: 'globalPolicy',
  initialState: globalPolicyInitialState,
  reducers: {
    getGlobalPolicyRequested(
      state: IGetGlobalPolicyState,
      _action: PayloadAction<IGetGlobalPolicyPayload>
    ) {
      state.status = APIStatus.PENDING;
    },
    getGlobalPolicyRequestSucceeded(
      state: IGetGlobalPolicyState,
      action: PayloadAction<IPolicy>
    ) {
      state.status = APIStatus.SUCCESS;
      state.data = action.payload;
    },
    getGlobalPolicyRequestFailed(
      state: IGetGlobalPolicyState,
      action: PayloadAction<IError>
    ) {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
  },
});

export const {
  getGlobalPolicyRequested,
  getGlobalPolicyRequestSucceeded,
  getGlobalPolicyRequestFailed,
} = globalPolicySlice.actions;

export default globalPolicySlice.reducer;
