import ReduxToastr from 'react-redux-toastr';
import { styled, createGlobalStyle } from 'styled-components';

const activeTableBackgroundColor = '#F9FAFB';

export const RootContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const StyledReduxToaster = styled(ReduxToastr)`
  .rrt-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const GlobalStyles = createGlobalStyle`
  /* Import the font-face styles from the global CSS file */
  @import './index.css';

  body {
    font-family: 'Sofia Pro', sans-serif;
    /* You can also set other global styles here */
  }
  .ant-table-cell-row-hover{
    background-color: ${activeTableBackgroundColor} !important;
  }
  .ant-table-row-selected{
    background-color: ${activeTableBackgroundColor} !important;
    .ant-table-cell{
      background-color: ${activeTableBackgroundColor} !important;
    }
  }
  /* Add any additional global styles if needed */
  /* e.g., reset styles, body styles, etc. */
`;
