import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from '../../../services/api/apiStatusCode';
import { IActionPayload } from '../../types/UserPermission.types';
import { IMyLenderInitialState } from 'redux/types/Lenders.types';
import { ILenderDataProps } from 'redux/saga/lenders/Lenders.types';

export const myLendersInitialState: IMyLenderInitialState = {
  status: APIStatus.INIT,
  lenderData: [],
  isLoading: false,
  error: {},
};

const lenderSlice = createSlice({
  name: 'lenderPermission',
  initialState: myLendersInitialState,
  reducers: {
    fetchMyLender: (state: IMyLenderInitialState) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchMyLenderSucceeded: (
      state: IMyLenderInitialState,
      action: PayloadAction<ILenderDataProps[]>
    ) => {
      state.lenderData = action.payload;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchMyLenderFailed: (
      state: IMyLenderInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;

      state.status = APIStatus.ERROR;
    },
    resetLenderState: () => myLendersInitialState,
  },
});

export const {
  fetchMyLender,
  fetchMyLenderSucceeded,
  fetchMyLenderFailed,
  resetLenderState,
} = lenderSlice.actions;

export default lenderSlice.reducer;
