import React from 'react';

export const CirclePlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_5876_102261)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.0013 1.99935C4.68759 1.99935 2.0013 4.68564 2.0013 7.99935C2.0013 11.3131 4.68759 13.9993 8.0013 13.9993C11.315 13.9993 14.0013 11.3131 14.0013 7.99935C14.0013 4.68564 11.315 1.99935 8.0013 1.99935ZM0.667969 7.99935C0.667969 3.94926 3.95121 0.666016 8.0013 0.666016C12.0514 0.666016 15.3346 3.94926 15.3346 7.99935C15.3346 12.0494 12.0514 15.3327 8.0013 15.3327C3.95121 15.3327 0.667969 12.0494 0.667969 7.99935Z"
          fill="#101828"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.00065 4.66602C8.36884 4.66602 8.66732 4.96449 8.66732 5.33268V10.666C8.66732 11.0342 8.36884 11.3327 8.00065 11.3327C7.63246 11.3327 7.33398 11.0342 7.33398 10.666V5.33268C7.33398 4.96449 7.63246 4.66602 8.00065 4.66602Z"
          fill="#101828"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.66797 7.99967C4.66797 7.63148 4.96645 7.33301 5.33464 7.33301H10.668C11.0362 7.33301 11.3346 7.63148 11.3346 7.99967C11.3346 8.36786 11.0362 8.66634 10.668 8.66634H5.33464C4.96645 8.66634 4.66797 8.36786 4.66797 7.99967Z"
          fill="#101828"
        />
      </g>
      <defs>
        <clipPath id="clip0_5876_102261">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
