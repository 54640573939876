import { IUniqueMyLenderInitialState } from "redux/types/Lenders.types";
import { APIStatus } from "services/api/apiStatusCode";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUniaueLenderDataProps } from "redux/saga/lenders/Lenders.types";
import { IActionPayload, IUniqueNoDataPayload } from "redux/types/ReportPage.types";


export const myUniqueLendersInitialState: IUniqueMyLenderInitialState = {
    status: APIStatus.INIT,
    lenderData: [],
    isLoading: false,
    error: {},
    noDataError: ""
};

const uniqueLenderSlice = createSlice({
    name: 'getUniqueLender',
    initialState: myUniqueLendersInitialState,
    reducers: {
        fetchUniqueLender: (
            state: IUniqueMyLenderInitialState,
            action: PayloadAction<string[]>
        ) => {
            state.isLoading = true;
            state.status = APIStatus.PENDING;
        },
        fetchUniqueLenderSucceeded: (
            state: IUniqueMyLenderInitialState,
            action: PayloadAction<IUniaueLenderDataProps[]>
        ) => {
            state.lenderData = action.payload;
            state.isLoading = false;
            state.status = APIStatus.SUCCESS;
        },
        fetchUniqueLenderFailed: (
            state: IUniqueMyLenderInitialState,
            action: PayloadAction<IActionPayload>
        ) => {
            const { error } = action.payload;
            state.error = error;
            state.isLoading = false;

            state.status = APIStatus.ERROR;
        },
        resetUniqueLenderState: () => myUniqueLendersInitialState,
        fetchUniqueLenderDataUnavailable: (
            state: IUniqueMyLenderInitialState,
            action: PayloadAction<IUniqueNoDataPayload>
        ) => {
            const { message } = action.payload;
            state.noDataError = message;
            state.status = APIStatus.SUCCESS;
        },
    },
});

export const {
    fetchUniqueLender,
    fetchUniqueLenderSucceeded,
    fetchUniqueLenderFailed,
    resetUniqueLenderState,
    fetchUniqueLenderDataUnavailable,
} = uniqueLenderSlice.actions;

export default uniqueLenderSlice.reducer;