import React from 'react';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import ImageView from '../../components/ImageView/ImageView';
import { horizontalDots } from '../../assets/icon';
import { YbCoreIcon } from '@yubi/yb-core-icon';

interface IOverFlowMenuViewProps {
  items: MenuProps['items'];
  icon?: React.ReactElement;
  disabled?: boolean;
}

const OverFlowMenuView = ({
  items,
  icon,
  disabled = false,
}: IOverFlowMenuViewProps) => (
  <Dropdown
    menu={{ items }}
    overlayStyle={{
      maxHeight: '200px',
      overflow: 'auto',
      border: '1px solid #ccc',
      borderRadius: 10,
      minWidth: '100px',
      zIndex: 9999,
    }}
    disabled={disabled}
  >
    <a
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Space>{icon || <ImageView src={horizontalDots} />}</Space>
    </a>
  </Dropdown>
);

export default OverFlowMenuView;
