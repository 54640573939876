import React, { useState, useRef, useEffect } from 'react';
import ImageView from '../../components/ImageView/ImageView';
import TextView from '../../components/TextView';
import { pencilIcon } from 'assets/icon';
import styled from 'styled-components';
import {
  FontStyle,
  Padding,
  TextTransform,
  TypeStyle,
  TypographyWeight,
} from 'components/TextView/TextView';
import DividerView from 'components/DividerView';

interface EditableTextViewProps {
  initialValue: string;
  onChange: (text: string) => void;
  variant?: 'icon' | 'text';
  id?: string;
  placeHolder?: string;
  testID?: string;
  textType?: TypeStyle;
  weight?: TypographyWeight;
  transform?: TextTransform;
  fontStyle?: FontStyle;
  color?: string;
  size?: number;
  editing?: boolean;
  lineHeight?: number;
  padding?: Padding;
}

type StyledInputProps = { size?: number; weight?: TypographyWeight };

const StyledInput = styled.input<StyledInputProps>`
  border-bottom: solid#fd7149;
  outline: none;
  width: -webkit-fill-available;
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size}px !important;
`;

const EditableTextView: React.FC<EditableTextViewProps> = ({
  initialValue,
  onChange,
  textType,
  weight,
  transform,
  fontStyle,
  id,
  lineHeight,
  testID,
  color,
  placeHolder,
  size,
  padding,
  editing,
  variant = 'icon',
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleEditClick = () => {
    if (editing === undefined) setIsEditing(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (editing === undefined) setIsEditing(false);
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleClickOutside = (e: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      if (!editing) setIsEditing(false);
    }
  };

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  return (
    <div className="flex items-center gap-2">
      {editing || isEditing ? (
        <StyledInput
          type="text"
          value={initialValue}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={handleKeyPress}
          onBlur={(e) => onChange(e.target.value)}
          ref={inputRef}
          size={size}
          weight={weight}
        />
      ) : (
        <div
          className="flex gap-1 "
          onClick={() => {
            if (variant === 'text' || (placeHolder && !initialValue)) {
              handleEditClick();
            }
          }}
        >
          {!initialValue && placeHolder && (
            <div className="flex flex-col gap-1">
              <TextView text={placeHolder} color="#98A2B3" /> <DividerView />
            </div>
          )}

          <TextView
            text={initialValue}
            textType={textType}
            weight={weight}
            transform={transform}
            fontStyle={fontStyle}
            id={id}
            lineHeight={lineHeight}
            testID={testID}
            color={color}
            size={size}
            padding={padding}
            onClick={handleEditClick}
          />

          {variant === 'icon' && (
            <ImageView
              src={pencilIcon} // Use your pencil icon source here
              onClick={handleEditClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EditableTextView;
