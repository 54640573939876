import { YbCoreButton } from '@yubi/yb-core-button';
import { ModalView, TextView } from 'components';
import { StyledFlexDiv } from 'features/Settings/shared/styledComponents';
import React from 'react';

type ExitModalButtonType = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
};
type ExitModalProps = {
  visible: boolean;
  title: string;
  subTitle: string;
  primaryButton: ExitModalButtonType;
  secondaryButton: ExitModalButtonType;
  onCancel?: () => void;
};
const ExitModal: React.FC<ExitModalProps> = ({
  visible,
  title,
  subTitle,
  primaryButton,
  secondaryButton,
  onCancel,
}) => {
  return (
    <ModalView
      containerPadding={{ top: 0, bottom: 0 }}
      modalContent={
        <StyledFlexDiv
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          gap="32px"
        >
          <div className="text-center pt-6">
            <div className="pr-12 pl-12">
              <TextView
                textType="h1"
                text={title}
                size={16}
                lineHeight={24}
                weight="900"
              />
            </div>
            <div className="pr-10 pl-10">
              <TextView text={subTitle} weight="Light" color="#475467" />
            </div>
          </div>
          <StyledFlexDiv gap="8px">
            <YbCoreButton
              size="s"
              label={secondaryButton.label}
              variant="tertiary"
              onClick={() => secondaryButton.onClick()}
              disabled={secondaryButton.disabled || false}
              loading={secondaryButton.isLoading}
            />
            <YbCoreButton
              loading={primaryButton.isLoading}
              size="s"
              label={primaryButton.label}
              variant="primary"
              onClick={() => {
                primaryButton.onClick();
              }}
              disabled={primaryButton.disabled || false}
            />
          </StyledFlexDiv>
        </StyledFlexDiv>
      }
      modalWidth="395px"
      modalHeight="248px"
      visible={visible}
      onCancel={() => onCancel?.()}
    />
  );
};

export default ExitModal;
