import { Popover } from 'antd';
import { pauseIcon, resumeIcon } from 'assets/icon';
import { ButtonView, ImageView } from 'components';
import React, { useState } from 'react';

type PauseResumeCampaignProps = {
  record: any;
  pauseResumeCampaign: (record: any) => void;
};
const PauseResumeCampaign: React.FC<PauseResumeCampaignProps> = ({
  record,
  pauseResumeCampaign,
}) => {
  const [clicked, setClicked] = useState(false);

  return (
    <Popover
      content={
        record.currentStatus === 'Execution' ? (
          <div>
            <div className="flex flex-row gap-6 justify-center items-center">
              <div>
                <ButtonView
                  variant="tertiary"
                  label={'Cancel'}
                  onClick={() => {
                    setClicked(!clicked);
                  }}
                />
              </div>
              <div>
                <ButtonView
                  label={record?.paused ? 'Resume' : 'Pause'}
                  onClick={() => {
                    pauseResumeCampaign(record);
                    setClicked(!clicked);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )
      }
      title={
        record.currentStatus === 'Execution'
          ? `Do you want to ${
              record?.paused ? 'resume' : 'pause'
            } this campaign?`
          : 'Campaign is not in execution'
      }
      trigger="click"
      open={clicked}
      onOpenChange={() => {
        setClicked(!clicked);
      }}
    >
      <div>
        <ImageView
          disabled={record.currentStatus !== 'Execution'}
          pointer
          src={record?.paused ? resumeIcon : pauseIcon}
        />
      </div>
    </Popover>
  );
};

export default PauseResumeCampaign;
