import { TextView } from 'components';
import React from 'react';
import { IRule } from './contract';
import Copy from 'assets/icon/copy.svg';
import EditPencil from 'assets/svgComponents/EditPencil';
import { convertFirstLetterToCapital } from './helper';
import { YbCoreIcon } from '@yubi/yb-core-icon';
import { Tooltip } from 'antd';

function formatDate(dateStr: string): string {
  if (!dateStr) return '-';
  // Parse the date string into a Date object
  const dateObj = new Date(dateStr);

  // Create an array of month abbreviations
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Extract the day, month, and year from the date object
  const day = dateObj.getUTCDate();
  const month = months[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear().toString().slice(-2);

  // Format and return the date in 'DD Mon, YY' format
  return `${day < 10 ? '0' + day : day} ${month}, ${year}`;
}

export const conditionRulesColumns = (
  handelUserAction: (action: string, ruleId: number) => void
) => [
  {
    title: 'Condition rule name',
    dataIndex: 'ruleName',
    key: 'ruleName',
    render: (ruleName: string, record: IRule) => {
      return (
        <div
          onClick={() => handelUserAction('view', record.ruleId)}
          className="flex flex-col cursor-pointer"
        >
          <TextView text={ruleName} size={14} lineHeight={20} color="#101828" />
          <TextView
            text={`${record.groupsCount} groups, ${record.conditionsCount} conditions`}
            size={12}
            lineHeight={18}
            color="#667085"
          />
        </div>
      );
    },
  },
  {
    title: 'Conditions',
    dataIndex: 'conditions',
    key: 'conditions',
    render: (conditions: string, record: IRule) => {
      return (
        <div className="flex gap-2">
          {conditions.split(',').map((condition) => {
            return (
              <div
                className="px-2 py-1 rounded-full"
                style={{ background: '#DEEAFC' }}
              >
                <TextView
                  text={condition}
                  size={12}
                  lineHeight={18}
                  color="#2C43AB"
                />
              </div>
            );
          })}
          {record.conditionsCount > 2 && (
            <div
              className="px-2 py-1 rounded-full"
              style={{ background: '#DEEAFC' }}
            >
              <TextView
                text={`+${record.conditionsCount - 2} more`}
                size={12}
                lineHeight={18}
                color="#2C43AB"
              />
            </div>
          )}
        </div>
      );
    },
  },
  {
    title: 'Last used',
    dataIndex: 'lastUsed',
    key: 'lastUsed',
    width: '100px',
    render: (date: string) => formatDate(date),
  },
  {
    title: 'Created on',
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: '100px',
    render: (date: string) => formatDate(date),
  },
  {
    title: 'Created by',
    dataIndex: 'createdBy',
    key: 'createdBy',
    align: 'right' as const,
    width: '120px',
    render: (createdBy: string, record: IRule) => {
      const isSystemGenerated = record.systemGenerated;
      return (
        <div className="flex w-full justify-end">
          <div className="min-w-min w-min">
            <TextView
              text={isSystemGenerated ? 'System generated' : createdBy || '-'}
              size={14}
              lineHeight={20}
              color="#667085"
            />
          </div>
        </div>
      );
    },
  },
  {
    title: 'Actions',
    dataIndex: 'name',
    align: 'right' as const,
    key: 'name',
    width: '120px',
    render: (_: unknown, record: IRule) => {
      const isUserGenerated = !record.systemGenerated;
      const { ruleId, mapped, ruleStatus } = record;

      return (
        <div className="flex gap-2 justify-end">
          <Tooltip placement="top" title="Clone">
            <img
              className="cursor-pointer"
              src={Copy}
              alt="clone"
              onClick={() => handelUserAction('clone', ruleId)}
            />
          </Tooltip>
          {isUserGenerated && (
            <>
              <Tooltip placement="top" title="Edit">
                <div
                  className={`${
                    mapped ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (!mapped) handelUserAction('edit', ruleId);
                  }}
                >
                  <EditPencil color={mapped ? 'gray' : ''} />
                </div>
              </Tooltip>
              <Tooltip placement="top" title="Delete">
                <div
                  className={`${
                    mapped ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (!mapped) handelUserAction('delete', ruleId);
                  }}
                >
                  <YbCoreIcon
                    size={16}
                    name={'trash-04'}
                    color={mapped ? 'gray' : ''}
                  />
                </div>
              </Tooltip>
            </>
          )}
        </div>
      );
    },
  },
];

export const getConditionPill = (condition: string, key: string) => {
  const colorCodes = {
    mainDisposition: {
      color: '#26581A',
      background: '#DDF6C7',
    },
    conditionOption: {
      color: '#B54708',
      background: '#F9EDBF',
    },
    dispositionType: {
      color: '#3554A4',
      background: '#D9E0F3',
    },
  };
  const codes = colorCodes[key as keyof typeof colorCodes];

  return (
    <div
      className="px-2 py-1 rounded-lg"
      style={{ background: codes.background }}
    >
      <TextView
        text={convertFirstLetterToCapital(condition)}
        size={12}
        lineHeight={18}
        color={codes.color}
      />
    </div>
  );
};

export const showViewLogic = (logic: string) => {
  return (
    <div>
      <div
        className="px-2 py-1 w-fit rounded-lg"
        style={{ background: '#121926' }}
      >
        <TextView text={convertFirstLetterToCapital(logic)} color="#fff" />
      </div>
    </div>
  );
};

export const RoundedPoint = () => {
  return (
    <div
      style={{
        height: 'fit-content',
        width: 'fit-content',
        border: '3px solid #fff',
        marginRight: '-12.5px',
        zIndex: 1,
      }}
      className="rounded-full"
    >
      <div
        className="flex justify-center items-center rounded-full"
        style={{
          height: 'fit-content',
          width: 'fit-content',
          border: '5px solid #9AA4B2',
        }}
      >
        <div
          className="h-[5px] w-[5px] rounded-full"
          style={{ background: '#FCFDFD' }}
        ></div>
      </div>
    </div>
  );
};

export const sampleCondition = {
  conditionOption: '',
  mainDisposition: '',
  dispositionType: '',
};
export const sampleGroup = {
  logicalOperator: 'AND',
  groups: [],
  conditions: [],
};
