import React, { useEffect, useState } from 'react';
import YbCoreTimePicker from '@yubi/yb-core-time-picker';
import { useStyles } from '@yubi/yb-style-provider';
import TextView from '../../components/TextView';
import dayjs from 'dayjs';

type HHMMFormat = `${string}:${string}`;

interface ITimePickerViewProps {
  label?: string;
  overlayVisible?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  minTime?: string;
  maxTime?: string;
  selectedTime: string | undefined;
  onSelectTime: (time: string | undefined) => void;
  disableTime?: (time: dayjs.Dayjs) => boolean;
  onOpenOverlay?: () => void;
  onCloseOverlay?: () => void;
}
const TimePickerView = ({
  label,
  onSelectTime,
  selectedTime,
  minTime,
  maxTime,
}: ITimePickerViewProps) => {
  const [newSelectedTime, setNewSelectedTime] = useState<
    dayjs.Dayjs | undefined
  >(undefined);
  const { styleConnector } = useStyles();

  useEffect(() => {
    const newSelectedTimeIs = selectedTime
      ? dayjs(selectedTime, 'HH:mm:ss')
      : undefined;
    setNewSelectedTime(newSelectedTimeIs);
  }, [selectedTime]);

  const handleDateTime = (time: dayjs.Dayjs | undefined) => {
    if (time) {
      const newDate = time.format('HH:mm:ss') as HHMMFormat;
      onSelectTime(newDate);
      setNewSelectedTime(time);
    }
  };

  return (
    <>
      {label && (
        <div className="mb-2">
          <TextView
            text={label}
            color="#4A5567"
            weight="600"
            size={14}
            lineHeight={24}
          />
        </div>
      )}
      <YbCoreTimePicker
        disabled={false}
        onOpenOverlay={() => {}}
        onCloseOverlay={() => {}}
        minTime={minTime ? dayjs(minTime, 'HH:mm:ss') : undefined}
        maxTime={maxTime ? dayjs(maxTime, 'HH:mm:ss') : undefined}
        selectedTime={newSelectedTime}
        onSelectTime={handleDateTime}
        styleConnector={styleConnector as any}
      />
    </>
  );
};

export default TimePickerView;
