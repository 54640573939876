import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  syncHierarchyRequested,
  syncHierarchyRequestFailed,
  syncHierarchyRequestSucceeded,
} from 'redux/slice/hierarchy-management/syncHierarchySlice.slice';
import { hierarchyManagementEndPoint } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';

function* syncHierarchySaga() {
  try {
    const result: AxiosResponse<any> = yield HTTPClient.GetRequest(
      hierarchyManagementEndPoint.SYNC_USERS,
      { timeout: 180000 }
    );
    if (result?.status === 200) {
      yield put(syncHierarchyRequestSucceeded());
    } else {
      yield put(syncHierarchyRequestFailed());
    }
  } catch (e) {
    yield put(syncHierarchyRequestFailed());
  }
}

export function* watchSyncHierarchySaga() {
  yield takeLatest(syncHierarchyRequested.type, syncHierarchySaga);
}
