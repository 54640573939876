import React from 'react';
import ToolTip from '@yubi/yb-core-tooltip';
import { useStyles } from '@yubi/yb-style-provider';

type ToolTipViewProps = {
  children: React.ReactNode;
  title: string;
};

const ToolTipView = ({ children, title }: ToolTipViewProps) => {
  const { styleConnector } = useStyles();
  return (
    <ToolTip styleConnector={styleConnector} title={title} placement="top"  >
      {children}
    </ToolTip>
  );
};

export default ToolTipView;
