import YbCoreTypography from '@yubi/yb-core-typography';
import { Popover } from 'antd';
import { TextView } from 'components';
import {
  StyledView,
  Wrapper,
  getTextColor,
} from 'features/StrategyConfig/DayTab/DayTab';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  DateList,
  selectCampaignPlanDate,
} from 'redux/slice/strategy/strategy';

export enum State {
  PAST,
  PRESENT,
  FUTURE,
}

export const getFormattedDate = (date: Date) => {
  const options = {
    day: '2-digit' as 'numeric' | '2-digit' | undefined,
    month: '2-digit' as
      | 'numeric'
      | '2-digit'
      | 'long'
      | 'short'
      | 'narrow'
      | undefined,
    year: 'numeric' as 'numeric' | '2-digit' | undefined,
  };
  const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(date);

  return formattedDate;
};

type TabProps = {
  tabData: DateList;
};
const Tab: React.FC<TabProps> = ({ tabData }) => {
  const [day, , dayNumber] = new Date(tabData.date).toDateString().split(' ');
  const dispatch = useDispatch();

  return (
    <Popover
      content={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 100,
          }}
        >
          <TextView text={getFormattedDate(new Date(tabData.date))} />
        </div>
      }
    >
      <Wrapper
        onClick={() =>
          dispatch(selectCampaignPlanDate({ campaignPlanSelectedDay: tabData }))
        }
      >
        <StyledView state={tabData.state}>
          <TextView
            color={getTextColor(tabData.state)}
            text={dayNumber.toString()}
          />
        </StyledView>
        <YbCoreTypography color="#475467" size={12} lineHeight={18}>
          {day}
        </YbCoreTypography>
      </Wrapper>
    </Popover>
  );
};

export default Tab;
