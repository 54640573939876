import { put, takeLatest } from 'redux-saga/effects';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { userEndpoints } from '../../../services/api/endPoints';
import { HTTPClient } from '../../../services/api/http-client';
import {
  fetchUserPermission,
  fetchUserPermissionFailed,
  fetchUserPermissionSucceeded,
} from '../../slice/user/UserPermission.slice';
import { IUserPermissionResponsePayload } from './User.types';
import { AxiosResponse } from 'axios';

export function* fetchUserPermissionSaga() {
  try {
    // Simulate API call with a 10-second delay using Redux Saga's `delay` effect
    const result: AxiosResponse<IUserPermissionResponsePayload> =
      yield HTTPClient.GetRequest(userEndpoints.GET_USER_PERMISSION);

    const { data, status } = result;

    if (status === ApiStatusCodes.SUCCESS && data.data) {
      yield put(fetchUserPermissionSucceeded(data.data));
    } else {
      throw new Error();
    }
  } catch (error) {
    // Handle API call failure or network errors

    yield put(
      fetchUserPermissionFailed({
        error: {
          message: 'An error occurred while fetching user permissions.',
        },
      })
    );
  }
}

export function* watchUserPermissionSaga() {
  yield takeLatest(fetchUserPermission.type, fetchUserPermissionSaga);
}
