import React, { Dispatch } from 'react';
import { store } from 'redux/store/store';
import {
  AgencyCols,
  AgencyDataResponse,
  AgencyFilterParam,
  AgencyFilterParamCust,
  SendAssociationResponseData,
} from 'redux/types/ManageAgency.type';
import { IFilterOptions, InviteData } from './lenderTypes';
import { isPermissionEnabled } from 'utils/helper';
import { Action, Area } from 'rbac/permissions';
import { LENDER_VIEW_ALL_AGENCIES, LENDER_VIEW_INVITED_AGENCIES, LENDER_VIEW_MY_AGENCIES } from 'rbac/constants';

export function formatDate(datetime: string) {
  if (datetime) {
    const date = datetime.toString().split('T');
    const localstring = new Date(date[0])
      .toLocaleTimeString('en-GB', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
      .replace(/ /g, '/');

    const finalDate = localstring ? localstring.split(',') : null;
    const formatted = finalDate ? finalDate[0] : null;
    return formatted;
  }
}

export function getDateDifference(date_1: string, date_2: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const date1: any = new Date(date_1);
  const date2: any = new Date(date_2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export const formatResponse = (resData: AgencyDataResponse[]) => {
  const formatData: AgencyCols[] = [];

  const state = store.getState();
  const lenderId = state.userConfiguration.entityId; // Adjust the path to the EntityId according to your store structure
  for (let i = 0; i < resData.length; i++) {
    const entityData = resData[i]?.collectionAgency;
    const lenderAgencyInvitation = resData[i]?.lenderAgencyInvitation;

    const entityId = entityData.entityID;
    const entityName = entityData.entityInfo.entityName;
    const entityEmail = '';
    const entityAddress = Object.values(entityData.entityInfo?.address)
      .filter((val) => val)
      .toString();
    const entityCin = entityData.entityInfo?.cin;
    const entityLocation = resData[i].locationPreference?.state || '-';
    const inviteValidtill = lenderAgencyInvitation?.inviteValidTill;
    const associatedDate = resData[i]?.lenderAgencyAssociation?.createdAt;

    // Code to check if duplicate entry for same entity present.
    const valExist = formatData.findIndex((val) => val.entity_id === entityId);

    if (valExist > -1 && formatData.length > 0) {
      let channel =
        resData[i].channelProduct?.channelDisplayName &&
        `${formatData[valExist].channel},${resData[i].channelProduct?.channelDisplayName}`;

      // remove duplicate
      channel = channel && Array.from(new Set(channel.split(','))).toString();
      formatData[valExist].channel = channel || '-';

      // code to keep location comma separated
      let location =
        resData[i]?.locationPreference?.state &&
        `${formatData[valExist].location},${resData[i].locationPreference?.state}`;

      // remove duplicate
      location =
        location && Array.from(new Set(location.split(','))).toString();
      formatData[valExist].location = location || '-';

      // update status incase of acceptance pending for current lender only
      if (
        inviteValidtill &&
        new Date(inviteValidtill).getTime() <= new Date().getTime() &&
        lenderAgencyInvitation?.lender?.entityID === lenderId
      ) {
        formatData[valExist].status = 'Invite Expired';
      } else if (
        lenderAgencyInvitation?.associationStatus === 'acceptance_pending' &&
        lenderAgencyInvitation?.lender?.entityID === lenderId
      ) {
        formatData[valExist].status = 'Acceptance Pending';
      }

      formatData[valExist].status =
        inviteValidtill &&
        new Date(inviteValidtill).getTime() <= new Date().getTime()
          ? 'Invite Expired'
          : formatData[valExist].status;
    } else {
      let associationStatus;

      // update status for invite
      if (
        inviteValidtill &&
        new Date(inviteValidtill).getTime() <= new Date().getTime() &&
        lenderAgencyInvitation?.lender?.entityID === lenderId
      ) {
        associationStatus = 'Invite Expired';
      } else if (
        lenderAgencyInvitation?.associationStatus === 'acceptance_pending' &&
        lenderAgencyInvitation?.lender?.entityID === lenderId
      ) {
        associationStatus = 'Acceptance Pending';
      } else {
        associationStatus = 'Not Associated';
      }

      formatData[formatData.length] = {
        key: entityId,
        entity_id: entityId,
        agency_name: entityName,
        channel: resData[i].channelProduct?.channelDisplayName || '-',
        location: entityLocation,
        address: entityAddress.replace(/,/g, ', '),
        cin: entityCin,
        status: associationStatus,
        associated_since: associatedDate ? formatDate(associatedDate) : '-',
        agency_email: entityEmail,
        website: '-',
      };
    }
  }
  return formatData;
};

export const formatInviteResponse = (
  resData: SendAssociationResponseData[]
) => {
  const formatData: InviteData[] = [];

  for (let i = 0; i < resData.length; i++) {
    const entityName = resData[i]?.agencyName;
    const entityEmail = resData[i]?.agencyEmail;

    let inviteSenton = resData[i]?.inviteInitiatedOn;
    const inviteValidtill = resData[i]?.inviteValidTill;
    let associationStatus = resData[i]?.associationStatus;

    // update status for invite by checking invite valid till date & time (BE date converted to avoid timezone issue)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (
      inviteValidtill &&
      new Date(inviteValidtill.split('.')[0]).setHours(0, 0, 0, 0) < today.getTime()
    ) {
      associationStatus = 'Invite Expired';
      inviteSenton = resData[i]?.updatedAt;
    } else {
      associationStatus = 'Acceptance Pending';
    }

    formatData[formatData.length] = {
      key: resData[i]?.id,
      agency_name: entityName,
      invite_senton: inviteSenton ? formatDate(inviteSenton) : '-',
      invite_validtill: inviteValidtill ? formatDate(inviteValidtill) : '-',
      invite_status: associationStatus,
      agency_email: entityEmail,
      invite_id: resData[i]?.id,
    };
  }
  return formatData;
};

export const stateList: object = {
  '1': 'California',
  '2': 'Ontario',
  '3': 'Maharashtra',
};

export const channelList: object = {
  '1': 'SMS',
  '2': 'call+',
  '3': 'email',
};

export const agencyList: object = {
  '64f46d79841418004b2410d2': 'Arrise',
  '64f467c7841418004a2410d2': 'Flock',
};

export function fetchResetFilterOptions(
  filterOptions: AgencyFilterParam,
  setters: {
    setChannelFilter: Dispatch<React.SetStateAction<string[]>>;
    setStateFilter: Dispatch<React.SetStateAction<string[]>>;
    setAgencyFilter: Dispatch<React.SetStateAction<string[]>>;
  }
) {
  const filterOptionsList: IFilterOptions[] = [];
  if (
    filterOptions?.channels &&
    Object.keys(filterOptions?.channels).length > 0
  ) {
    filterOptionsList.push({
      name: 'channel',
      selectedFilter: [],
      setSelectedFilter: setters.setChannelFilter,
      filterList: filterOptions.channels,
    });
  }

  if (
    filterOptions.locations &&
    Object.keys(filterOptions?.locations).length > 0
  ) {
    filterOptionsList.push({
      name: 'state',
      selectedFilter: [],
      setSelectedFilter: setters.setStateFilter,
      filterList: filterOptions.locations,
    });
  }
  if (
    filterOptions.agencies &&
    Object.keys(filterOptions?.agencies).length > 0
  ) {
    filterOptionsList.push({
      name: 'agency',
      selectedFilter: [],
      setSelectedFilter: setters.setAgencyFilter,
      filterList: filterOptions.agencies,
    });
  }
  return filterOptionsList;
}

export function customFilterFunction(
  ArrayToFilter: AgencyCols[],
  filter: Partial<AgencyFilterParamCust>
) {
  return ArrayToFilter.filter((el: AgencyCols) => {
    const channels = el.channel.split(',');
    const locations = el.location.split(',');

    if (filter?.agencies && !filter.agencies.includes(el.entity_id)) {
      return false;
    }

    if (
      filter?.locations &&
      !locations.some((item) => filter?.locations?.includes(item))
    ) {
      return false;
    }

    if (
      filter?.channels &&
      !channels.some((item) => filter?.channels?.includes(item))
    ) {
      return false;
    }

    return true;
  });
}

export function fetchActiveTabKey(userPermissions: {[k in Area]: Action[]}) {
  if (
    isPermissionEnabled(userPermissions, LENDER_VIEW_MY_AGENCIES)
  ) {
    return '1';
  }
  if (
    isPermissionEnabled(
      userPermissions,
      LENDER_VIEW_ALL_AGENCIES
    )
  ) {
    return '2';
  }
  if (
    isPermissionEnabled(
      userPermissions,
      LENDER_VIEW_INVITED_AGENCIES
    )
  ) {
    return '3';
  }
  return '1';
}


export function allAgencyListWithoutActive(activeEntityId: string | undefined, allAgencyList: AgencyCols[]) {
  return allAgencyList.filter((item) => item.entity_id !== activeEntityId);
}

export function convertEntityGroupTypefromValue(value: string) {
  switch (value) {
    case 'lender':
      return 'Lender';
    case 'collection_agency':
      return 'Collection Agency';
    default:
      return value;
  }
}
