import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  sendAssociationForm,
  sendAssociationFormSuccess,
} from '../../slice/manage-agency/sendAssociation.slice';
import {
  IAgencyListPayload,
  ISendAssociationRequest,
} from '../../types/ManageAgency.type';
import { HTTPClient } from 'services/api/http-client';
import { manageAgency } from 'services/api/endPoints';
import { fetchAgency } from 'redux/slice/manage-agency/agency.slice';
import { message } from 'antd';

function* sendAssociateAction({
  payload,
}: PayloadAction<ISendAssociationRequest>) {
  try {
    const result: IAgencyListPayload = yield HTTPClient.PostRequest(
      `${manageAgency.SEND_ASSOCIATION_REQUEST_URL}`,
      payload.data
    );
    if (result?.data?.errors) {
      let msg;
      if (result.data.errors[0].error.includes('ALREADY_EXISTS')) {
        msg = 'A previously sent invite already exist for this agency';
      } else if (
        result.data.errors[0].message.includes('AGENCY_CANNOT_BE_INVITED')
      ) {
        msg = 'Previously sent invite hasn’t yet expired';
      } else {
        msg = 'Association with agency failed';
      }
      message.error(msg);
    } else {
      message.success(`Association request sent successfully`);
      yield put(sendAssociationFormSuccess('success'));
      yield put(fetchAgency({ listType: 'all_agencies' }));
    }
  } catch (err) {
    message.error('Association with agency failed');
  }
}

export function* watchSendAssociationSagas() {
  yield takeLatest(sendAssociationForm.type, sendAssociateAction);
}
