// store.js
import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Local storage
import AppReducer, { RootReducerState } from '../slice/reducer';
import rootSaga from '../saga';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const persistConfig: PersistConfig<RootReducerState> = {
  key: 'root', // The key used in local storage
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_PERSISTENCE_SECRET_KEY as string, // Secure key from environment
      onError: function (error) {
        // Handle the error.
        console.error('Encryption Error:', error);
      },
    }),
  ],
  // Whitelist the reducers you want to persist
  whitelist: ['userEntities', 'userConfiguration'], // Add the names of your reducers to persist here
};

export const persistedReducer = persistReducer<RootReducerState>(
  persistConfig,
  AppReducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      middleware
    ),
});

// Create a persistor
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);

export { store, persistor };
