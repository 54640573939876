import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from 'services/api/apiStatusCode';
import { IDerivedFieldData, ISagaGetDerivedFieldParam } from './types';

interface IGetDerivedFieldError {
  error: string;
}

export interface IGetDerivedFieldInitialState {
  status: APIStatus;
  data: IDerivedFieldData[] | null;
  isLoading: boolean;
  error: IGetDerivedFieldError | null;
}

export const getDerivedFieldSliceInitialState: IGetDerivedFieldInitialState = {
  status: APIStatus.INIT,
  data: null,
  isLoading: false,
  error: null,
};

const getDerivedFieldSlice = createSlice({
  name: 'getDerivedField',
  initialState: getDerivedFieldSliceInitialState,
  reducers: {
    fetchGetDerivedField: (
      state,
      payload: PayloadAction<ISagaGetDerivedFieldParam>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchGetDerivedFieldSucceeded: (
      state,
      action: PayloadAction<IDerivedFieldData[]>
    ) => {
      state.data = action.payload;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchGetDerivedFieldFailed: (
      state,
      action: PayloadAction<IGetDerivedFieldError>
    ) => {
      state.error = action.payload;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    resetGetDerivedFieldState: () => getDerivedFieldSliceInitialState,
  },
});

export const {
  fetchGetDerivedField,
  fetchGetDerivedFieldSucceeded,
  fetchGetDerivedFieldFailed,
  resetGetDerivedFieldState,
} = getDerivedFieldSlice.actions;

export default getDerivedFieldSlice.reducer;
