import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IReportInitialState,
  IActionPayload,
  IReportPageParam,
  IFetchReportSagaParam,
} from '../../redux/types/ReportPage.types';
import { APIStatus } from '../../services/api/apiStatusCode';

export const reportPageSliceInitialState: IReportInitialState = {
  entityId: '',
  status: APIStatus.INIT,
  integration: {
    type: 'superset',
    option: 'embed',
    arguments: {
      accessToken: '',
      dashboardId: '',
      dashBoardUrl: '',
    },
  },
  isLoading: false,
  error: {},
};

// create one more entitiy
// spocto for primary user
// primary user does not have dashboard

const ReportPageSlice = createSlice({
  name: 'report',
  initialState: reportPageSliceInitialState,
  reducers: {
    fetchReportToken: (
      state: IReportInitialState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<IFetchReportSagaParam>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchReportTokenSucceeded: (
      state: IReportInitialState,
      action: PayloadAction<IReportPageParam>
    ) => {
      const { integration, entityId } = action.payload;
      state.integration = integration;
      state.entityId = entityId;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchReportTokenFailed: (
      state: IReportInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      // state = initialState;
      state.status = APIStatus.ERROR;
    },
    resetReportTokenState: () => reportPageSliceInitialState,
  },
});

export const {
  fetchReportToken,
  fetchReportTokenSucceeded,
  fetchReportTokenFailed,
  resetReportTokenState,
} = ReportPageSlice.actions;

export default ReportPageSlice.reducer;
