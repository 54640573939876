import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchMyLender,
  fetchMyLenderFailed,
  fetchMyLenderSucceeded,
} from 'redux/slice/lenders/MyLenders.slice';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { lender } from '../../../services/api/endPoints';
import { HTTPClient } from '../../../services/api/http-client';
import { IFetchMyLendersResponse } from './Lenders.types';

export function* fetchMyLenderSaga() {
  try {
    // Simulate API call with a 10-second delay using Redux Saga's `delay` effect
    const result: IFetchMyLendersResponse = yield HTTPClient.GetRequest(
      lender.GET_LENDERS
    );

    const { data, status } = result;

    if (status === ApiStatusCodes.SUCCESS && data) {
      yield put(fetchMyLenderSucceeded(data?.data));
    } else {
      throw new Error();
    }
  } catch (error) {
    // Handle API call failure or network errors

    yield put(
      fetchMyLenderFailed({
        error: {
          message: 'An error occurred while fetching lenders.',
        },
      })
    );
  }
}

export function* watchMyLenderSaga() {
  yield takeLatest(fetchMyLender.type, fetchMyLenderSaga);
}
