import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  fetchAgency,
  fetchAllAgencySuccess,
  fetchMyAgencySuccess,
  fetchInvitedAgencySuccess,
  fetchMyFilterOptionsSuccess,
  fetchAllFilterOptionsSuccess,
  fetchFiltersOptions,
  fetchFilterOptionsFail,
} from '../../slice/manage-agency/agency.slice';
import {
  IAgencyListPayload,
  AgencyListParam,
  IFilterOptions,
  IFilterResponse,
  IinviteAgencyListPayload,
} from '../../types/ManageAgency.type';
import { HTTPClient } from 'services/api/http-client';
import { manageAgency } from 'services/api/endPoints';
import {
  formatInviteResponse,
  formatResponse,
} from 'features/LenderAgency/function';
import { message } from 'antd';
function* fetchAgencyListSaga({ payload }: PayloadAction<AgencyListParam>) {
  try {
    if (payload.listType === 'all_agencies') {
      const result: IAgencyListPayload = yield HTTPClient.PostRequest(
        `${manageAgency.AGENCY_LIST_URL}/all`,
        payload.filters
      );

      const formatData = formatResponse(result?.data?.data);
      yield put(fetchAllAgencySuccess(formatData));
    } else if (payload.listType === 'my_agency') {
      const result: IAgencyListPayload = yield HTTPClient.PostRequest(
        `${manageAgency.AGENCY_LIST_URL}/my-agency`,
        payload.filters
      );
      const formatData = formatResponse(result?.data?.data);
      yield put(fetchMyAgencySuccess(formatData));
    } else if (payload.listType === 'invitedagency') {
      const result: IinviteAgencyListPayload = yield HTTPClient.GetRequest(
        `${manageAgency.AGENCY_LIST_URL}/invited`
      );
      const formatData = formatInviteResponse(result?.data?.data);
      yield put(fetchInvitedAgencySuccess(formatData));
    }
  } catch (err) {
    message.error('Failed to fetch Agency List');
  }
}

function* filterOptionsAction({ payload }: PayloadAction<IFilterOptions>) {
  try {
    const result: IFilterResponse = yield HTTPClient.GetRequest(
      `${manageAgency.FILTER_OPTIONS}?category=${payload.filterName}`
    );

    if (result?.errors) {
      throw new Error('Error has occurred while fetching Filters');
    }
    if (payload.filterName === 'my') {
      yield put(fetchMyFilterOptionsSuccess(result?.data));
    }
    if (payload.filterName === 'all') {
      yield put(fetchAllFilterOptionsSuccess(result?.data));
    }
  } catch (err) {
    yield put(
      fetchFilterOptionsFail({
        error: {
          message:
            'Sorry, we encountered an issue, Please re-try after sometime.',
        },
        type: payload.filterName,
      })
    );
  }
}
export function* watchAgencySagas() {
  yield takeLatest(fetchAgency.type, fetchAgencyListSaga);
  yield takeLatest(fetchFiltersOptions.type, filterOptionsAction);
}
