import { TextView } from 'components';
import React from 'react';

interface IWrapperCmpProps {
  label: string;
  component?: React.ReactNode;
  isMandatory: boolean;
  children?: React.ReactNode;
}

const WrapperCmp = (props: IWrapperCmpProps) => {
  const { label, component, children, isMandatory } = props;
  return (
    <>
      <section className="flex pr-4 items-center">
        <TextView
          text={label}
          size={14}
          lineHeight={20}
          weight="500"
          color="#667085"
        />
        {isMandatory && <TextView text="*" color="#B22D3C" />}
      </section>
      {component && <>{component}</>}
      {children && <>{children}</>}
    </>
  );
};

export default WrapperCmp;
