import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ICreateConfigError,
  ICreateConfigInitialState,
  ICreateConfigResponse,
} from '../../types/CreateConfig.type';
import { APIStatus } from '../../../services/api/apiStatusCode';

export const createConfigSliceInitialState: ICreateConfigInitialState = {
  status: APIStatus.INIT,
  data: {
    allocationId: '',
    message: '',
  },
  isLoading: false,
  error: {},
};

const CreateConfigSlice = createSlice({
  name: 'createConfig',
  initialState: createConfigSliceInitialState,
  reducers: {
    fetchCreateConfig: (state: ICreateConfigInitialState) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchCreateConfigSucceeded: (
      state: ICreateConfigInitialState,
      action: PayloadAction<ICreateConfigResponse>
    ) => {
      const { allocationId, message } = action.payload;
      state.data.allocationId = allocationId;
      state.data.message = message;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchCreateConfigFailed: (
      state: ICreateConfigInitialState,
      action: PayloadAction<ICreateConfigError>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;

      state.status = APIStatus.ERROR;
    },
    resetCreateConfigState: () => createConfigSliceInitialState,
  },
});

export const {
  fetchCreateConfig,
  fetchCreateConfigSucceeded,
  fetchCreateConfigFailed,
  resetCreateConfigState,
} = CreateConfigSlice.actions;

export default CreateConfigSlice.reducer;
