import React from 'react';
import CASwitch from '@yubi/yb-core-switch';
import { useStyles } from '@yubi/yb-style-provider';

type SwitchSize = 'small' | 'medium' | 'large';
interface ISwitchViewProps {
  checked: boolean;
  onClick: (checked: boolean) => void;
  label: string;
  size?: SwitchSize;
  disabled?: boolean;
}
const SwitchView = ({
  checked,
  onClick,
  label,
  size = 'medium',
  disabled,
}: ISwitchViewProps) => {
  const { styleConnector } = useStyles();

  return (
    <CASwitch
      styleConnector={styleConnector}
      checked={checked}
      onClick={onClick}
      label={label}
      size={size}
      disabled={disabled}
    />
  );
};

export default SwitchView;
