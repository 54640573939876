import React, { useEffect, useState } from 'react';
import YbCoreCheckbox from '@yubi/yb-core-checkbox';
import { useStyles } from '@yubi/yb-style-provider';
import { IFilterOptions, ISliderData } from './filterTyes';
import SearchView from 'components/SearchView';

import SliderView from 'components/SliderView';
// import ImageView from 'components/ImageView/ImageView';
// import DropDown from 'assets/img/drop-down.svg';
// import UpArrow from 'assets/img/up-arrow.svg';

interface Values {
  filterData: IFilterOptions;
}
interface EventTarget {
  value: string;
  checked: boolean;
}
interface CheckBoxEvent {
  target: EventTarget;
}

const FilterViewChildAdvance = ({ filterData }: Values) => {
  const { styleConnector } = useStyles();
  const [openFilterView, setOpenFilterView] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');

  let uniqueFilterList: string[] | ISliderData = [];
  if (filterData.type !== 'slider') {
    uniqueFilterList = Array.from(new Set(Object.values(filterData.data)));
  } else {
    uniqueFilterList = filterData.data;
  }

  const [uniqueSearchFilterList, setUniqueSearchFilterList] =
    useState(uniqueFilterList);

  const checkboxCheckFn = (e: CheckBoxEvent, val: string) => {
    let item: string[] | ISliderData = filterData.selectedFilter;
    if (Array.isArray(item)) {
      if (e.target.checked) {
        item.push(val);
        item = Array.from(new Set(item));
      } else {
        const index = item.indexOf(val);
        if (index !== undefined && index > -1) item.splice(index, 1);
      }

      filterData.setSelectedFilter([...item]);
    }
  };

  useEffect(() => {
    if (Array.isArray(uniqueFilterList))
      setUniqueSearchFilterList(
        uniqueFilterList.filter((item: string) =>
          item.toString().toLowerCase().includes(searchInput.toLowerCase())
        )
      );
  }, [searchInput]);
  const onSearchTextChange = (text = '') => {
    setSearchInput(text);
  };
  return (
    <div>
      <div
        className="filter-item "
        onClick={() => setOpenFilterView(!openFilterView)}
      >
        <div className="txt-span"> {filterData.label}</div>
        {Array.isArray(filterData.selectedFilter) &&
          filterData.selectedFilter.length > 0 && (
            <div className="filter-select-icon">
              {filterData.selectedFilter.length}
            </div>
          )}
        {!Array.isArray(filterData.selectedFilter) &&
          (filterData.selectedFilter.min || filterData.selectedFilter.max) && (
            <div className="filter-select-icon">1 </div>
          )}
        {openFilterView === false ? (
          <span>{/* <ImageView src={DropDown} /> */}</span>
        ) : (
          <span>{/* <ImageView src={UpArrow} className="arrow-up" /> */}</span>
        )}
      </div>

      {openFilterView && (
        <div className="filter-item-content ">
          {Array.isArray(uniqueSearchFilterList) && (
            <div className="pt-[8px] pb-[20px]">
              <SearchView
                placeholder="Search"
                value={searchInput}
                onChange={onSearchTextChange}
              />
            </div>
          )}

          {Array.isArray(uniqueSearchFilterList) &&
            uniqueSearchFilterList.map((val: string) => (
              <YbCoreCheckbox
                size="m"
                label={val}
                value={val}
                styleConnector={styleConnector}
                onChange={(e) => checkboxCheckFn(e, val)}
                checked={
                  Array.isArray(filterData.selectedFilter) &&
                  filterData.selectedFilter.includes(val)
                }
              />
            ))}
          {!Array.isArray(uniqueSearchFilterList) &&
            !Array.isArray(filterData.selectedFilter) && (
              <SliderView
                min={uniqueSearchFilterList?.min || 0}
                max={uniqueSearchFilterList?.max || 0}
                dataLow={
                  filterData.selectedFilter?.min ||
                  uniqueSearchFilterList?.min ||
                  0
                }
                dataHigh={
                  filterData.selectedFilter?.max ||
                  uniqueSearchFilterList?.max ||
                  0
                }
                setSelectedFilter={filterData.setSelectedFilter}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default FilterViewChildAdvance;
