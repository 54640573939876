import React from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
interface IAccordionViewProps {
  items: CollapseProps['items'];
}

const AccordionView = ({ items }: IAccordionViewProps) => {
  return (
    <Collapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => {
        return (
          <DownOutlined
            rotate={isActive ? 180 : 360}
            style={{ color: '#98A2B3', width: 20, height: 10, marginTop: 12 }}
          />
        );
      }}
      accordion
      items={items}
      bordered={false}
      ghost
    />
  );
};

export default AccordionView;
