import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateBreadActiveStateAction } from 'redux/allocation/allocation.slice';
import { RootState } from 'redux/store/store';

import YbCoreBreadcrumb from '@yubi/yb-core-breadcrumb';
import { useStyles } from '@yubi/yb-style-provider';
import useUrlPathToBreadCrumb from 'hooks/useUrlPathToBreadCrumb';
import { backIcon } from 'assets/icon';

const BreadcrumbView: React.FC = () => {
  const { styleConnector } = useStyles();
  const breadCrumbUrls = useSelector(
    (store: RootState) => store.allocation.breadCrumbUrls
  );
  const history = useNavigate();
  const breadCrumbs = useSelector(
    (store: RootState) => store.allocation.breadCrumbs
  );
  const dispatch = useDispatch();

  const breadCrumbState = useSelector(
    (store: RootState) => store.allocation.breadCrumbActiveState
  );

  useUrlPathToBreadCrumb();

  return (
    <YbCoreBreadcrumb
      showBottomComponent={breadCrumbState === 'Allocation Summary'}
      type="avatar-with-link"
      size="small"
      onBackPress={() => {
        history('/report');
      }}
      styleConnector={styleConnector}
      onClick={(z, label, s) => {
        dispatch(
          updateBreadActiveStateAction({ breadCrumbActiveState: label })
        );
        if (breadCrumbUrls[label.toLowerCase()]) {
          history(
            breadCrumbUrls[label.toLowerCase() as keyof typeof breadCrumbUrls]
          );
          return;
        }
        if (label === 'Home') {
          history('/report');
          return;
        }
        if(label === 'Segment List'){
          history('/campaign/segment-list');
          return;
        }
        history(s.toLowerCase());
      }}
      leftArrowIcon={<img src={backIcon} alt="back-icon" />}
    >
      {[{ name: 'Home' }, ...breadCrumbs].map(({ name }: { name: string }) => {
        return (
          <YbCoreBreadcrumb.Item
            hideAvatarImage
            keyId={name}
            name={name}
            key={name}
            iconName={name}
            avatarImage=""
            active={breadCrumbState === name}
          />
        );
      })}
    </YbCoreBreadcrumb>
  );
};
export default BreadcrumbView;
