import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IChannelPreferenceData } from 'features/Settings/shared/contract';
import { IError } from 'redux/types/Compliance.types';
import {
  IChannelPreferenceState,
  IGetPreferencePayload,
} from 'redux/types/Preference.types';

import { APIStatus } from 'services/api/apiStatusCode';

export const channelsInitialState: IChannelPreferenceState = {
  status: APIStatus.INIT,
};

const slice = createSlice({
  name: 'channels',
  initialState: channelsInitialState,
  reducers: {
    getChannelPreferencesRequested: (
      state: IChannelPreferenceState,
      _action: PayloadAction<IGetPreferencePayload>
    ) => {
      state.status = APIStatus.PENDING;
    },
    getChannelPreferencesRequestSucceeded: (
      state: IChannelPreferenceState,
      action: PayloadAction<IChannelPreferenceData[]>
    ) => {
      state.status = APIStatus.SUCCESS;
      state.data = action.payload;
    },
    getChannelPreferencesRequestFailed: (
      state: IChannelPreferenceState,
      action: PayloadAction<IError>
    ) => {
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
  },
});

export const {
  getChannelPreferencesRequested,
  getChannelPreferencesRequestSucceeded,
  getChannelPreferencesRequestFailed,
} = slice.actions;

export default slice.reducer;
