import React, { useEffect, useState } from 'react';
import Grid from '@yubi/yb-core-grid';
import { editIcon } from '../../../../assets/icon';
import {
  TableView,
  ImageView,
  TextView,
  SpinnerView,
  SearchView,
} from '../../../../components';
import { generalColor, inputColor } from '../../../../assets/color';
import { createDebouncedFunction, formatDate } from '../../../../utils/helper';
import { useAppSelector } from 'utils/hooks/useAppSelector';
import {
  fetchCampaignList,
  pauseOrResumeCampaignList,
  resetOutreachConfigListState,
} from 'redux/slice/campaign-planner/CamapignList.slice';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import PauseResumeCampaign from './PauseResumeCampaign';
import useAmplitude from 'hooks/useAmplitude';
import { AmplitudeEvents } from 'constants/constant';
import { useGetQueryFromUrlUpdated } from 'hooks/useGetQueryFromUrl';
import { ICampaignListProps } from 'redux/saga/campaign-planner/Outreach.types';

const InProgress = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [replaceUrl, setReplaceUrl] = useState(false);
  const { campaignList, totalRecords, isLoading } = useAppSelector(
    (state) => state.campaignList
  );
  const { sendAmplitudeEvent } = useAmplitude(
    AmplitudeEvents.CAMPAIGN_LIST_IN_PROGRESS_TAB_VIEW_EVENT
  );

  const { offset = 0, limit = 10 } = useGetQueryFromUrlUpdated<{
    limit?: number;
    offset?: number;
  }>();

  const [searchText, setSearchText] = useState<string>('');

  const delayedAmplitudeSearchEvent = createDebouncedFunction(
    (text: string) => {
      sendAmplitudeEvent(AmplitudeEvents.CAMPAIGN_LIST_SEARCH_TYPE_EVENT, {
        campaignName: text,
      });
    }
  );

  const pauseResumeCampaign = (record: ICampaignListProps) => {
    dispatch(
      pauseOrResumeCampaignList({
        campaignId: record?.campaignId,
        status: record?.paused ? 'RESUME' : 'PAUSE',
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchCampaignList({
        offset,
        limit,
        status: 'in-progress',
        name: searchText,
      })
    );
  }, [offset, limit]);

  const editRecord = (record: ICampaignListProps) => {
    navigate(
      `/campaign/create-campaign/campaign?allocation-id=${record?.allocationId}&campaign-id=${record?.campaignId}&isEdit=true&hide-nav=true`
    );
  };

  const handleSearchTextChange = (newText: string) => {
    setSearchText(newText);
    dispatch(
      fetchCampaignList({
        offset,
        limit,
        status: 'in-progress',
        name: newText,
      })
    );
    if (!newText.length) return;
    delayedAmplitudeSearchEvent(newText);
  };

  const disableEdit = (endDate: string) => {
    const end = new Date(endDate);
    const today = new Date();
    end.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (end < today) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetOutreachConfigListState());
    };
  }, []);

  const columns = [
    {
      title: 'Campaign name',
      width: 100,
      key: 'campaignName',
      render: (record: ICampaignListProps) => {
        return (
          <div>
            <TextView
              text={record?.campaignName || '-'}
              onClick={() => {
                navigate(
                  `/campaign/monitoring?campaign-id=${record?.campaignId}`
                );
                if (!record?.campaignName) return;
                sendAmplitudeEvent(
                  AmplitudeEvents.CAMPAIGN_LIST_CAMPAIGN_NAME_CLICK_EVENT,
                  {
                    campaignName: record?.campaignName,
                  }
                );
              }}
              color={inputColor}
            />
          </div>
        );
      },
    },
    {
      title: 'LOB',
      width: 100,
      key: 'lob',
      render: (record: ICampaignListProps) => {
        return (
          <TextView text={record?.lob || '-'} color={generalColor}></TextView>
        );
      },
    },
    {
      title: 'Risk',
      width: 100,
      key: 'risks',
      render: (record: ICampaignListProps) => {
        return (
          <TextView
            text={record?.risk?.join(', ') || '-'}
            color={generalColor}
          ></TextView>
        );
      },
    },
    {
      title: 'Records',
      width: 100,
      key: 'records',
      render: (record: ICampaignListProps) => {
        return (
          <TextView
            text={record?.records || '-'}
            color={generalColor}
          ></TextView>
        );
      },
    },
    {
      title: 'Duration',
      width: 100,
      key: 'createdDate',
      render: (record: ICampaignListProps) => {
        return (
          <TextView
            text={`${formatDate(record?.startDate, true) || '-'} - ${
              formatDate(record?.endDate, true) || '-'
            }`}
            color={generalColor}
          ></TextView>
        );
      },
    },
    {
      title: 'Current status',
      width: 100,
      key: 'currentStatus',
      render: (record: ICampaignListProps) => {
        return (
          <TextView
            text={record?.currentStatus || '-'}
            color={generalColor}
          ></TextView>
        );
      },
    },
    {
      title: 'Actions',
      width: 100,
      key: 'address',
      render: (record: ICampaignListProps) => {
        return (
          <Grid>
            <div className="pr-2">
              <ImageView
                pointer
                disabled={disableEdit(record?.endDate)}
                src={editIcon}
                onClick={() => {
                  editRecord(record);
                }}
              />
            </div>

            <PauseResumeCampaign
              record={record}
              pauseResumeCampaign={(record) => {
                pauseResumeCampaign(record);
              }}
            />
          </Grid>
        );
      },
    },
  ];

  return (
    <div style={{ marginTop: '23px' }}>
      <div
        className="float-right absolute top-[-30px] right-0 flex gap-4"
        style={{ zIndex: 2 }}
      >
        <Grid container columns={12} rowGap={16}>
          <Grid style={{ alignItems: 'center' }}>
            <SearchView
              value={searchText}
              onSubmitEditing={(newText) => handleSearchTextChange(newText)}
              onChange={(newText) => handleSearchTextChange(newText)}
              placeholder={'Search'}
            />
          </Grid>
        </Grid>
      </div>
      <TableView
        isLoading={isLoading}
        columns={columns}
        dataSource={campaignList}
        pagination={{
          offset,
          limit,
          totalElements: totalRecords,
          onPaginationChange: (offset: number, limit: number) => {
            const newOffset = Math.floor(offset / limit);
            navigate(
              `/campaign/campaign-list?offset=${newOffset}&limit=${limit}`,
              { replace: replaceUrl }
            );
            setReplaceUrl(true);
          },
        }}
      />
    </div>
  );
};

export default InProgress;
