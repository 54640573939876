import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  fetchAllocation,
  fetchAllocationFailure,
  fetchAllocationOverviewAction,
  fetchAllocationOverviewSuccessAction,
  fetchAllocationSuccess,
  fetchGroupedAllocationNumbers,
  fetchGroupedAllocationNumbersFailure,
  fetchGroupedAllocationNumbersSuccess,
  fetchSftpAllocation,
  setLoadingFalse,
  updateSftpAllocationStatus,
  uploadAllocationFileOnSFTP,
} from './allocation.slice';
import { HTTPClient } from 'services/api/http-client';
import {
  FetchAllocationListResponse,
  FetchAllocationOverViewResponse,
  FetchGroupedAllocationNumbersResponse,
  IAgencyApiResponse,
  IUploadAlloacationSftpPayload,
  IUploadAlloacationSftpResponse,
} from './types';
import { message } from 'antd';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  allocationEngineEndpoints,
  manageAgency,
} from 'services/api/endPoints';
import { AllocationType } from 'testData/allocationLIst';
import { FiltersType2 } from 'features/Allocation/AllocationListTable/AllocatedListTable';
import { ApiStatusCodes } from 'services/api/apiStatusCode';
import { blankDiv, toastMessagee } from 'components/antd/AntdToastMessage/DynamicMessage';
export type FetchAllocationRequestType = {
  allocationStatus: string;
  assignmentType: string;
  limit: number;
  offset: number;
  lenderId: string;
  filterRequestPayload: FiltersType2[];
  entityId?: string;
};

function* fetchAllocationDetailsSaga(
  action: PayloadAction<FetchAllocationRequestType>
) {
  try {
    let agencyList: any[] = [];

    if (
      action.payload.allocationStatus !== 'unallocated' &&
      action.payload.assignmentType !== 'Self'
    ) {
      try {
        const result: IAgencyApiResponse = yield HTTPClient.PostRequest(
          manageAgency.GET_AGENCY_LIST
        );

        const { data, status } = result;

        const uniqueValues = new Set(); // Set to store unique values
        agencyList = data.data.reduce((acc: any[], inputValue: any) => {
          const name: string =
            inputValue.collectionAgency.entityInfo.entityName;
          const value: string = inputValue.collectionAgency.entityID;
          const newItem = { label: name, value };
          if (!uniqueValues.has(value)) {
            uniqueValues.add(value);
            acc.push(newItem); // Add unique item to the accumulator array
          }
          return acc;
        }, []);
      } catch (e) {}
    }

    const result: FetchAllocationListResponse = yield HTTPClient.PostRequest(
      allocationEngineEndpoints.GET_ALLOCATION_LIST(action.payload.lenderId),
      {
        limit: action.payload.limit,
        offset: action.payload.offset,
        allocationStatus: action.payload.allocationStatus,
        assignmentType: action.payload.assignmentType,
        filters: action.payload.filterRequestPayload,
      }
    );
    const allocationType = action.payload
      .allocationStatus as keyof typeof result.data.groupedAllocations;
    const allocation = result.data.groupedAllocations[allocationType];

    const assignmentType2 = action.payload
      .assignmentType as keyof typeof allocation;

    const allocations: AllocationType[] =
      result.data.groupedAllocations[allocationType][assignmentType2];

    const mappedAllocations = allocations.map((allocation: any) => {
      const entity = agencyList.find(
        (entity) => entity.value === allocation.assignedToEntity
      );
      if (entity) {
        return {
          ...allocation,
          assignedToEntityLabel: entity.label, // Add assignedToEntityLabel property
        };
      }

      if (action.payload.assignmentType === 'Self') {
        return {
          ...allocation,
          assignedToEntityLabel: 'Self', // Add assignedToEntityLabel property
        };
      }
      return allocation;
    });

    yield put(
      fetchAllocationSuccess({
        allocatedData: mappedAllocations,
      })
    );
  } catch (e) {
    yield put(fetchAllocationFailure());
  }
}
//

function* fetchAllocationOverViewSaga() {
  try {
    yield delay(1000);
    const result: FetchAllocationOverViewResponse = yield HTTPClient.GetRequest(
      'http://localhost:3000/allocationOverview'
    );
    yield put(
      fetchAllocationOverviewSuccessAction({ allocationOverview: result.data })
    );
  } catch (e) {
    message.error('Error while fetching allocation overview details');
    yield put(setLoadingFalse());
  }
}

function* fetchGroupedAllocationNumbersSaga(
  action: PayloadAction<{
    lenderId: string;
    filterRequestPayload: FiltersType2[];
  }>
) {
  try {
    const result: FetchGroupedAllocationNumbersResponse =
      yield HTTPClient.PostRequest(
        allocationEngineEndpoints.GET_GROUPED_ALLOCATIONS_NUMBERS(
          action.payload.lenderId
        ),
        action.payload.filterRequestPayload
      );
    yield put(
      fetchGroupedAllocationNumbersSuccess({
        groupedAllocationNumbers: result.data.groupedAllocationNumbers,
      })
    );
  } catch (e) {
    yield put(fetchGroupedAllocationNumbersFailure());
  }
}


function* fetchSftpAllocationDetailsSaga(
  action: PayloadAction<FetchAllocationRequestType>
) {
  try {
    const urlPayload = {
      limit: action.payload.limit,
      offset: action.payload.offset,
      allocationStatus: action.payload.allocationStatus,
      assignmentType: action.payload.assignmentType,
      filters: action.payload.filterRequestPayload,
      lenderId: action.payload.lenderId,
    };
    const urlParams = `?lenderId=${action.payload.lenderId}&entityId=${action.payload.entityId}`;
    const sftpAllocationResponse: FetchAllocationListResponse = yield HTTPClient.PostRequest(allocationEngineEndpoints.GET_SFTP_ALLOCATION_LIST + urlParams, urlPayload);
    const allocationType = action.payload
      .allocationStatus as keyof typeof sftpAllocationResponse.data.groupedAllocations;
    const allocation = sftpAllocationResponse.data.groupedAllocations[allocationType];

    const assignmentType2 = action.payload
      .assignmentType as keyof typeof allocation;

    const allocations: AllocationType[] =
      sftpAllocationResponse.data.groupedAllocations[allocationType][assignmentType2];
    
    const mappedAllocations = allocations.map((allocation: any) => {
      if (action.payload.assignmentType === 'Self') {
        return {
          ...allocation,
          assignedToEntityLabel: 'Self', // Add assignedToEntityLabel property
        };
      }
      return allocation;
    });

    yield put(
      fetchAllocationSuccess({
        allocatedData: mappedAllocations,
      })
    );
  } catch (e) { 
    yield put(fetchAllocationFailure());
  }
}

function* uploadAllocationFileToSftpSaga(
  action: PayloadAction<IUploadAlloacationSftpPayload>
) {
  try {
    const urlParams = `?agencyId=${action.payload.entityId}&allocationId=${action.payload.allocationId}&lenderId=${action.payload.lenderId}`;
    yield HTTPClient.GetRequest(
      allocationEngineEndpoints.UPLOAD_ALLOCATION_TO_SFTP + urlParams
    );
  }catch(e) {
  }
}

function* pollSftpAllocationStatusSaga(action: PayloadAction<IUploadAlloacationSftpPayload>) {
  yield delay(3000);

  const MAX_POLLING_TIME = 3 * 60 * 1000; // 3 minutes in milliseconds
  const POLLING_INTERVAL = 6000; // 6 seconds in milliseconds
  const MAX_ATTEMPTS = Math.floor(MAX_POLLING_TIME / POLLING_INTERVAL);

  try {
    for (let i=0; i < MAX_ATTEMPTS; i++) { 
      const urlParams = `?allocationId=${action.payload.allocationId}`;
      const result: IUploadAlloacationSftpResponse = yield call(HTTPClient.GetRequest, allocationEngineEndpoints.GET_SFTP_UPLOAD_STATUS + urlParams);
  
      const { SUCCESS } = ApiStatusCodes;
      const { status, data } = result;
  
      if (status === SUCCESS) {
        yield put(updateSftpAllocationStatus({ sftpStatus: data, allocationId: action.payload.allocationId }));
        if (data === "SUCCESS" || data === "FAILED") {
          return; // Exit the saga if the status is SUCCESS or FAILED
        }
      }
  
      if (i < MAX_ATTEMPTS - 1) {
        yield delay(POLLING_INTERVAL); // Wait for the polling interval before the next attempt
      }
    }
    message.info({
      key: 3,
      icon: blankDiv(),
      content: toastMessagee({
        type: "info",
        color: "#2254B5",
        background: "#D6F4F5",
        text: "SFTP transfer is in progress. Please refresh after some time.",
        key: 3,
        styleClass: { filter: "invert(26%) sepia(82%) saturate(3205%) hue-rotate(196deg) brightness(90%) contrast(98%)" }
      })
    });
  }catch(e){}
}

export function* watchAllocationSagas() {
  yield takeLatest(fetchAllocation.type, fetchAllocationDetailsSaga);
  yield takeLatest(
    fetchAllocationOverviewAction.type,
    fetchAllocationOverViewSaga
  );
  yield takeLatest(
    fetchGroupedAllocationNumbers.type,
    fetchGroupedAllocationNumbersSaga
  );
  yield takeLatest(fetchSftpAllocation.type, fetchSftpAllocationDetailsSaga);
  yield takeLatest(
    uploadAllocationFileOnSFTP.type,
    uploadAllocationFileToSftpSaga
  );
  yield takeLatest(
    uploadAllocationFileOnSFTP.type,
    pollSftpAllocationStatusSaga
  );
}
