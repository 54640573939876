import React from 'react';
import TextView from 'components/TextView';
import SearchView from 'components/SearchView';
import { StyledFlexDiv } from 'features/Settings/shared/styledComponents';

interface ISettingsTableHeaderProps {
  caption?: string;
  noOfRecords?: number;
  onSearchTextChange?: (text: string) => void;
  noOfRecordsAfterSearch?: number;
  hideSearch?: boolean;
  placeHolder?: string;
  searchText?: string;
  rightComponent?: React.ReactNode;
  hideNoOfRecords?: boolean;
  debounceTime?: number;
}

const getRecordsText = (
  totalRecords?: number,
  recordsAfterSearch?: number
): string => {
  if (recordsAfterSearch || recordsAfterSearch === 0) {
    return `(${recordsAfterSearch} of ${totalRecords})`;
  }
  return typeof totalRecords === 'number' ? `(${totalRecords})` : '';
};

const TableHeader = (props: ISettingsTableHeaderProps) => {
  const {
    caption,
    noOfRecords,
    onSearchTextChange,
    noOfRecordsAfterSearch,
    hideSearch,
    placeHolder,
    searchText,
    rightComponent,
    debounceTime,
  } = props;

  const noOfRecordsText = getRecordsText(noOfRecords, noOfRecordsAfterSearch);
  return (
    <StyledFlexDiv justifyContent="space-between">
      <div>
        {caption && (
          <TextView
            textType={'h4'}
            weight={'700'}
            fontStyle={'normal'}
            lineHeight={28}
            size={18}
            color="#475467"
            text={caption}
          />
        )}
        {noOfRecordsText && (
          <TextView
            weight={'400'}
            fontStyle={'normal'}
            lineHeight={18}
            size={12}
            color="#475467"
            padding={8}
            text={noOfRecordsText}
          />
        )}
      </div>
      <div className="flex gap-3">
        {!hideSearch && (
          <StyledFlexDiv>
            <SearchView
              value={searchText}
              onSubmitEditing={onSearchTextChange}
              onChange={onSearchTextChange}
              placeholder={placeHolder || 'Search'}
              debounceTime={debounceTime}
            />
          </StyledFlexDiv>
        )}
        {rightComponent}
      </div>
    </StyledFlexDiv>
  );
};

export default TableHeader;
