import YbCoreNavBar from '@yubi/yb-core-nav-bar';
import { useStyles } from '@yubi/yb-style-provider';
import React from 'react';
import { View } from 'react-native';
import DividerView from '../../components/DividerView';
import { NavItem } from './NavBarView.type';
import { SpoctoxSVG } from 'assets/img';
import { dividerColorCode } from 'constants/constant';
import useGetQueryFromUrl from 'hooks/useGetQueryFromUrl';

interface INavBarViewProps {
  rightItem?: React.ReactElement;
  leftIcon?: React.ReactElement;
  items?: NavItem[];
}

export const NavBarView = ({
  rightItem = <></>,
  items = [],
  leftIcon,
}: INavBarViewProps) => {
  const { styleConnector } = useStyles();
  const [hideNav = 'false'] = useGetQueryFromUrl({
    queries: ['hide-nav'],
  });
  return (
    <>
      <div
        className="sticky top-0 h-18 bg-white"
        style={{
          zIndex: 5000,
          width: '100%',
          position: 'fixed',
          display: hideNav === 'true' ? 'none' : 'block',
        }}
      >
        <YbCoreNavBar
          showSearch={false}
          componentType="Web"
          containerStyle={{ zIndex: 1 }}
          logoIcon={
            leftIcon || (
              <View style={{ marginRight: 70 }}>
                <img
                  width="114"
                  height="40"
                  src={SpoctoxSVG}
                  alt="spocto-x-logo"
                />
              </View>
            )
          }
          items={items}
          showUser={false}
          // rightItems={[<NavigationRightView key="1NavigationRightView" />]}
          rightItems={[rightItem]}
          styleConnector={styleConnector}
        />
        <DividerView lineColor={dividerColorCode} lineWidth={1} />
      </div>
      <div
        className="w-full"
        style={{
          minHeight: '4.5rem',
          display: hideNav === 'true' ? 'none' : 'block',
        }}
      ></div>
    </>
  );
};
