import { AnyAction, Reducer, combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import {
  AllocationInitialState,
  allocationInitialState,
} from 'redux/allocation/allocation.slice';
import { ICreateConfigInitialState } from 'redux/types/CreateConfig.type';
import { IGetAllocationConfigListInitialState } from 'redux/types/GetAllocationConfigList.type';
import { IGetSelectedConfigDetailsInitialState } from 'redux/types/GetSelectedConfigDetails.type';
import { IReportInitialState } from 'redux/types/ReportPage.types';
import { IUserEntityInitialState } from 'redux/types/UserEntities.types';
import { IUserPermissionInitialState } from 'redux/types/UserPermission.types';
import ReportPageSlice, {
  reportPageSliceInitialState,
} from './ReportPage.slice';
import CreateConfigSlice, {
  createConfigSliceInitialState,
} from './allocation-config/CreateConfig.slice';
import GetAllocationConfigListSlice, {
  getAllocationConfigListSliceInitialState,
} from './allocation-config/GetAllocationConfigList.slice';
import GetSelectedConfigDetailsSlice, {
  getSelectedConfigInitialState,
} from './allocation-config/GetSelectedConfigDetails.slice';
import UserConfigurationSlice, {
  IUserConfigurationInitialState,
  userConfigurationInitialState,
} from './user/UserConfiguration.slice';
import UserEntitiesSlice, {
  userEntitiesSliceInitialState,
} from './user/UserEntities.slice';
import UserPermissionSlice, {
  userPermissionSliceInitialState,
} from './user/UserPermission.slice';
import LenderInvitesSlice, {
  lenderInvitesInitialState,
} from './lenders/LendersInvites.slice';
import MyLendersSlice, {
  myLendersInitialState,
} from './lenders/MyLenders.slice';
import AllocationSlice from '../allocation/allocation.slice';
import OutreachListSlice, {
  outreachListInitialState,
} from './campaign-planner/OutreachList.slice';
import AgencySlice, {
  AgencyInitialState,
  agencyInitialState,
} from '../slice/manage-agency/agency.slice';
import {
  ILenderInviteInitialState,
  IMyLenderInitialState,
  IUniqueMyLenderInitialState,
} from 'redux/types/Lenders.types';
import Strategy, {
  StrategyStateInitialStateTypes,
  strategyInitialState,
} from './strategy/strategy';
import {
  IOutreachListInitialState,
  IOutreachConfigListInitialState,
  ICampaignListInitialState,
  IAllocationListInitialState,
  IOutreachContentListInitialState,
} from '../types/Outreach.types';
import ConfigListSlice, {
  outreachConfigListInitialState,
} from './campaign-planner/ConfigList.slice';
import ContentListSlice, {
  outreachContentListInitialState,
} from './campaign-planner/ContentList.slice';
import UserOnboardSlice, {
  onboardInitialState,
} from './user/UserOnboard.slice';
import { IOnboardInitialState } from 'redux/types/UserOnboardDetails.types';
import InviteAgencySlice, {
  InviteAgencyInitialState,
  inviteAgencyInitialState,
} from './manage-agency/inviteAgency.slice';
import sendAssociationSlice, {
  SendAssociationInitialState,
  sendAssociationInitialState,
} from './manage-agency/sendAssociation.slice';
import CamapignListSlice, {
  campaignListInitialState,
} from './campaign-planner/CamapignList.slice';
import AllocationListSlice, {
  allocationListInitialState,
} from './campaign-planner/AllocationList.slice';
import GetDerivedFieldSlice, {
  IGetDerivedFieldInitialState,
  getDerivedFieldSliceInitialState,
} from './derived-field/GetDerivedField.slice';
import DesignationManagementSlice, {
  IDesignationManagementInitialState,
  designationManagementInitialState,
} from './hierarchy-management/DesignationManagement.slice';
import hierarchySetupSlice, {
  IHierarchySetupInitialState,
  hierarchySetupInitialState,
} from './hierarchy-management/hierarchySetup.slice';
import EmployeeProfileSlice, {
  employeeProfileInitialState,
} from './hierarchy-management/EmployeeProfile.slice';
import { IEmployeeProfileInitialState } from './hierarchy-management/types';
import {
  ISettingsState,
  settingsInitialState,
  settingsReducer,
} from './settings';
import content, {
  ContentInitialState,
  contentInitialState,
} from 'redux/content/content.slice';
import uniqueLenderSlice, {
  myUniqueLendersInitialState,
} from './lenders/unqueLender.slice';
import StrategyListSlice, {
  strategyListInitialState,
} from './strategy/StrategyList.slice';
import { IStrategyListState } from './strategy/types';

// Define the root state shape
export interface RootReducerState {
  reportPage: IReportInitialState;
  toastr: any; // Adjust the type as needed for toastrReducer
  userEntities: IUserEntityInitialState;
  userPermission: IUserPermissionInitialState;
  userConfiguration: IUserConfigurationInitialState;
  createConfig: ICreateConfigInitialState;
  getAllocationConfigList: IGetAllocationConfigListInitialState;
  getSelectedConfigDetails: IGetSelectedConfigDetailsInitialState;
  allocation: AllocationInitialState;
  agency: AgencyInitialState;
  inviteAgency: InviteAgencyInitialState;
  sendAgencyAssociation: SendAssociationInitialState;
  lenderInvites: ILenderInviteInitialState;
  myLenders: IMyLenderInitialState;
  uniqueLenders: IUniqueMyLenderInitialState;
  strategy: StrategyStateInitialStateTypes;
  outreachList: IOutreachListInitialState;
  outreachConfigList: IOutreachConfigListInitialState;
  outreachContentList: IOutreachContentListInitialState;
  derivedField: IGetDerivedFieldInitialState;
  userOnboardDetails: IOnboardInitialState;
  campaignList: ICampaignListInitialState;
  allocationList: IAllocationListInitialState;
  designationManagement: IDesignationManagementInitialState;
  hierarchySetupList: IHierarchySetupInitialState;
  employeeProfile: IEmployeeProfileInitialState;
  settings: ISettingsState;
  derivedFiled: IGetDerivedFieldInitialState;
  content: ContentInitialState;
  strategyList: IStrategyListState;
}

export const appInitialState: RootReducerState = {
  reportPage: reportPageSliceInitialState,
  toastr: {},
  userEntities: userEntitiesSliceInitialState,
  agency: agencyInitialState,
  inviteAgency: inviteAgencyInitialState,
  sendAgencyAssociation: sendAssociationInitialState,
  userPermission: userPermissionSliceInitialState,
  userConfiguration: userConfigurationInitialState,
  createConfig: createConfigSliceInitialState,
  getAllocationConfigList: getAllocationConfigListSliceInitialState,
  getSelectedConfigDetails: getSelectedConfigInitialState,
  allocation: allocationInitialState,
  lenderInvites: lenderInvitesInitialState,
  myLenders: myLendersInitialState,
  uniqueLenders: myUniqueLendersInitialState,
  strategy: strategyInitialState,
  outreachList: outreachListInitialState,
  outreachConfigList: outreachConfigListInitialState,
  outreachContentList: outreachContentListInitialState,
  userOnboardDetails: onboardInitialState,
  campaignList: campaignListInitialState,
  allocationList: allocationListInitialState,
  derivedField: getDerivedFieldSliceInitialState,
  hierarchySetupList: hierarchySetupInitialState,
  designationManagement: designationManagementInitialState,
  employeeProfile: employeeProfileInitialState,
  settings: settingsInitialState,
  derivedFiled: getDerivedFieldSliceInitialState,
  content: contentInitialState,
  strategyList: strategyListInitialState,
};

const AppReducer: Reducer<RootReducerState> = combineReducers<RootReducerState>(
  {
    reportPage: ReportPageSlice,
    hierarchySetupList: hierarchySetupSlice,
    toastr: toastrReducer,
    userEntities: UserEntitiesSlice,
    userPermission: UserPermissionSlice,
    userConfiguration: UserConfigurationSlice,
    createConfig: CreateConfigSlice,
    getAllocationConfigList: GetAllocationConfigListSlice,
    allocation: AllocationSlice,
    agency: AgencySlice,
    inviteAgency: InviteAgencySlice,
    sendAgencyAssociation: sendAssociationSlice,
    getSelectedConfigDetails: GetSelectedConfigDetailsSlice,
    lenderInvites: LenderInvitesSlice,
    myLenders: MyLendersSlice,
    uniqueLenders: uniqueLenderSlice,
    userOnboardDetails: UserOnboardSlice,
    strategy: Strategy,
    outreachList: OutreachListSlice,
    outreachConfigList: ConfigListSlice,
    outreachContentList: ContentListSlice,
    campaignList: CamapignListSlice,
    allocationList: AllocationListSlice,
    derivedField: GetDerivedFieldSlice,
    designationManagement: DesignationManagementSlice,
    employeeProfile: EmployeeProfileSlice,
    settings: settingsReducer,
    derivedFiled: GetDerivedFieldSlice,
    content,
    strategyList: StrategyListSlice,
  }
);

const rootReducer: Reducer<RootReducerState, AnyAction> = (state, action) => {
  if (action.type === 'RESET') {
    // Assuming 'storage' has a method 'removeItem' and you are using 'redux-persist'
    localStorage.removeItem('persist:root');
  }
  return AppReducer(state, action);
};
export default rootReducer;
