import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from 'services/api/apiStatusCode';
import { IStrategyListState } from './types';
import { IStrategyData } from 'features/StrategyConfig/CreatePlan/Flow/types';

export const strategyListInitialState: IStrategyListState = {
  strategyRequests: [],
};

export interface IStrategyRequestPayload {
  strategyId: string;
}

const strategyListSlice = createSlice({
  name: 'strategyList',
  initialState: strategyListInitialState,
  reducers: {
    fetchSelectedStrategyRequested: (state, action) => {
      const { strategyId } = action.payload;
      const strategyRequest = state.strategyRequests.find(
        (strategy) => strategy.strategyId === strategyId
      );
      if (strategyRequest) {
        strategyRequest.status = APIStatus.PENDING;
      } else {
        state.strategyRequests.push({
          strategyId,
          status: APIStatus.PENDING,
          strategyData: {} as IStrategyData,
        });
      }
    },
    fetchSelectedStrategyRequestSucceeded: (state, action) => {
      const { strategyId } = action.payload;
      const strategyRequest = state.strategyRequests.find(
        (strategy) => strategy.strategyId === strategyId
      );
      if (strategyRequest) {
        strategyRequest.status = APIStatus.SUCCESS;
        strategyRequest.strategyData = action.payload.strategy;
      }
    },
    fetchSelectedStrategyRequestFailed: (state, action) => {
      const { strategyId } = action.payload;
      const strategyRequest = state.strategyRequests.find(
        (strategy) => strategy.strategyId === strategyId
      );
      if (strategyRequest) {
        strategyRequest.status = APIStatus.ERROR;
      }
    },
  },
});

export const {
  fetchSelectedStrategyRequested,
  fetchSelectedStrategyRequestSucceeded,
  fetchSelectedStrategyRequestFailed,
} = strategyListSlice.actions;

export default strategyListSlice.reducer;
