import { YbCoreButton } from '@yubi/yb-core-button-v3';
import React from 'react';

interface IButtonViewV3Props {
  label?: string;
  size?: 's' | 'l' | 'm';
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'error'
    | 'special';
  leadingIcon?: string;
  trailingIcon?: string;
  loading?: boolean;
  loadingPosition?: 'start' | 'end';
  disabled?: boolean;
  onClick?: () => void;
}

export const ButtonViewV3 = ({
  label,
  size = 's',
  variant = 'primary',
  leadingIcon,
  trailingIcon,
  loading = false,
  loadingPosition,
  disabled = false,
  onClick,
}: IButtonViewV3Props) => {
  return (
    <YbCoreButton
      label={label}
      size={size}
      variant={variant}
      leadingIcon={leadingIcon}
      trailingIcon={trailingIcon}
      loading={loading}
      loadingPosition={loadingPosition}
      disabled={disabled}
      onClick={onClick}
    />
  );
};
