import React, { useEffect, useRef, useState } from 'react';
import {
  IConditionRules,
  IDispositionOption,
  IGroup,
} from '../shared/contract';
import ConditionalGroup from './ConditionalGroup';
import { TextView } from 'components';
import './Condition.css';
import { RoundedPoint, showViewLogic } from '../shared/constants';
import { conditionOptions } from '../shared/helper';
import { YbCoreIcon } from '@yubi/yb-core-icon';
import DropDownViewV3 from 'componentsV3/DropDownViewV3';

interface IGroupProps {
  group: IGroup;
  path: string;
  index: number;
  addORRemoveConditionAndGroup: (
    pathToChange: string,
    isAdd: boolean,
    isCondition: boolean
  ) => void;
  onChange: (newGroup: IGroup) => void;
  isLastGroup: boolean;
  dispositionOptions: IDispositionOption[];
  conditionRule: IConditionRules;
  isView: boolean;
  updateLastDivHeight: (height: number) => void;
}

const Group = (props: IGroupProps) => {
  const {
    group,
    path,
    addORRemoveConditionAndGroup,
    onChange,
    isLastGroup,
    dispositionOptions,
    conditionRule,
    isView,
    updateLastDivHeight,
  } = props;
  const [isOpen, setOpen] = useState(true);
  const divRef = useRef<HTMLDivElement>(null);
  const [divHeight, setDivHeight] = useState<number | null>(null);
  useEffect(() => {
    if (divRef.current && isLastGroup) {
      // Access the height of the div when the component is mounted
      setDivHeight(divRef.current.offsetHeight);
    }
  }, [group]);
  useEffect(() => {
    if (isLastGroup) {
      updateLastDivHeight(divHeight || 0);
    }
  }, [divHeight]);

  useEffect(() => {
    onChange(JSON.parse(JSON.stringify(group)));
  }, [isOpen]);

  const { logicalOperator, groups, conditions } = group;

  const handleChange = (newLogicalOperator: string) => {
    onChange({ logicalOperator: newLogicalOperator, conditions, groups });
  };

  function convertToGroupName(path: string[]): string {
    const groupHierarchy: string[] = [];

    for (let i = 0; i < path.length; i += 2) {
      // Extract the group index from '[n]'
      const index: number = parseInt(path[i + 1].slice(1, -1), 10) + 1;
      groupHierarchy.push(index.toString());
    }

    return 'Group ' + groupHierarchy.join('.');
  }

  return (
    <div ref={divRef} className={`flex py-2 flex-row `}>
      <div
        className="flex h-[56px] justify-end items-center rounded-md"
        style={{
          marginLeft: '-2px',
          borderLeft: '2px solid #EEF2F6',
          borderImage: `linear-gradient(to bottom, #EEF2F6 50%, transparent 0) 1 100%`,
        }}
      >
        <div className={`flex items-center w-[30px]`}>
          <div
            className="h-[2px] w-full"
            style={{
              borderBottom: '2px solid #EEF2F6',
            }}
          ></div>
        </div>
        <RoundedPoint />
      </div>
      <div
        className="rounded-md flex-1"
        style={{
          minWidth: 'max-content',
        }}
      >
        <div
          className="p-2 rounded-tl-md rounded-tr-md"
          style={{ background: '#F2F4F7' }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 pl-2">
              <TextView
                text={convertToGroupName(path.split('.'))}
                color="#121926"
              />
              {isView ? (
                showViewLogic(logicalOperator || '')
              ) : (
                <div className="">
                  <DropDownViewV3
                    triggerWidth={90}
                    size="s"
                    label={''}
                    value={logicalOperator}
                    options={conditionOptions}
                    onChange={(value: string) => {
                      handleChange(value);
                    }}
                    type={'standard'}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center gap-2">
              {!isView && (
                <div
                  onClick={() => {
                    addORRemoveConditionAndGroup(path, false, false);
                  }}
                >
                  <YbCoreIcon size={16} name={'trash-04'} />
                </div>
              )}
              <div onClick={() => setOpen((prev) => !prev)}>
                <YbCoreIcon
                  size={16}
                  name={isOpen ? 'chevron-up' : 'chevron-down'}
                />
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <ConditionalGroup
            dispositionOptions={dispositionOptions}
            conditionGroup={group}
            path={path}
            addORRemoveConditionAndGroup={addORRemoveConditionAndGroup}
            onChange={onChange}
            conditionRule={conditionRule}
            isView={isView}
          />
        )}
      </div>
    </div>
  );
};

export default Group;
