/* eslint-disable no-extend-native */
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './styles/index.css';
import './styles/tailwind.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthWrapper } from '@yubi/yb-module-auth';
import { GlobalStyles, RootContainer, StyledReduxToaster } from './App.styled';
import Navigation from './navigation/Navigation';
import { persistor, store } from './redux/store/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import Smartlook from 'smartlook-client';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import * as amplitude from '@amplitude/analytics-browser';

const queryClient = new QueryClient();

export const AuthMain = () => {
  return (
    <AuthWrapper
      organizationDetails={{
        organisationId: process.env.REACT_APP_ORGANIZATION_ID || '',
        imagePayload: {
          alt: 'spocto logo',
          src: 'https://assets.credavenue.com/logos/spoctox-logo.png',
          style: {
            width: '135px',
            display: 'unset',
            height: '30px',
          },
        },
        email: process.env.REACT_APP_SUPPORT_EMAIL || 'help@spocto.com',
        supportMessage:
          'If you have trouble verifying your email, please contact Spocto Support at',
      }}
      navigator={() => {}}
    >
      <Navigation />
    </AuthWrapper>
  );
};

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_SMART_LOOK_ANALYTICS) {
      Smartlook.init(process.env.REACT_APP_SMART_LOOK_ANALYTICS || '');
    }
  }, []);

  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || '', {
      autocapture: false,
    });
  }, []);

  return (
    <ErrorBoundary>
      <RootContainer>
        <ToastContainer />
        <GlobalStyles />
        <Provider store={store}>
          <StyledReduxToaster
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar={false}
          />
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <AuthMain />
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </RootContainer>
    </ErrorBoundary>
  );
};

export default App;
