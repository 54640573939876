import { InputNumber } from 'antd';
import TextView from 'components/TextView';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'utils/hooks/useDebounce';

type MinMaxInputProps = {
  minRange: string;
  maxRange: string;
  onValuesChange: (min: number | string, max: number | string) => void;
};

const MinMaxInput: React.FC<MinMaxInputProps> = ({
  minRange,
  maxRange,
  onValuesChange,
}) => {
  const [minValue, setMinValue] = useState<string>(minRange);
  const [maxValue, setMaxValue] = useState<string>(maxRange);
  const debounceMinValue = useDebounce(minValue, 1500);
  const debounceMaxValue = useDebounce(maxValue, 1500);

  useEffect(() => {
    // if (debounceMaxValue !== maxRange && debounceMinValue !== minRange)
    onValuesChange(debounceMinValue, debounceMaxValue);
  }, [debounceMinValue, debounceMaxValue]);

  const handleMinChange = (value: number | null): void => {
    setMinValue(value?.toString() || '');
  };

  const handleMaxChange = (value: number | null): void => {
    setMaxValue(value?.toString() || '');
  };

  return (
    <div className="flex gap-4">
      <div className="flex flex-col gap-1">
        <TextView
          text="Min"
          color="#4A5567"
          weight="500"
          size={14}
          lineHeight={20}
        />
        <InputNumber
          min={Number(minRange)}
          max={Number(maxRange)}
          style={{ width: '100%' }}
          size="large"
          width={50}
          onBlur={() => {
            if (minValue === '') {
              setMinValue(minRange);
            }
          }}
          type="number"
          value={minValue === '' ? null : Number(minValue)}
          onChange={handleMinChange}
        />
      </div>
      <div className="flex flex-col gap-1">
        <TextView
          text="Max"
          color="#4A5567"
          weight="500"
          size={14}
          lineHeight={20}
        />
        <InputNumber
          min={Number(minRange)}
          max={Number(maxRange)}
          type="number"
          onBlur={() => {
            if (maxValue === '') {
              setMaxValue(maxRange);
            }
          }}
          style={{ width: '100%' }}
          size="large"
          width={50}
          value={maxValue === '' ? null : Number(maxValue)}
          onChange={handleMaxChange}
        />
      </div>
    </div>
  );
};

export default MinMaxInput;
