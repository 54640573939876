let env = 'qa';
let hostName = `https://${env}-mumbai.spocto.com`;

if (process.env.REACT_APP_ENV === 'qa') {
  hostName = window.origin;
  env = 'qa';
}

if (process.env.REACT_APP_ENV === 'production') {
  hostName = window.origin;
  env = 'prod';
}

if (process.env.REACT_APP_ENV === 'staging') {
  hostName = window.origin;
  env = 'stg';
}

const allocationEngineBaseURL = `${hostName}/collections/allocations`;

const contentManagementBaseURL = `${hostName}/collections`;

const emsBaseUrl = `${hostName}/collections/ems`;

const epsBaseUrl = `${hostName}/collections/eps`;

const baseUrl = `${hostName}/collections`;

interface UserEndpoints {
  GET_USER_PERMISSION: string;
  GET_USER_ENTITIES: string;
  GET_SUPERSET_TOKEN: (dashboardType: string) => string;
  SEARCH_USER: (searchCriteria: string) => string;
}

interface AllocationEngineEndpoints {
  CREATE_ALLOCATION_CONFIG: string;
  GET_CREATED_ALLOCATION_CONFIG_LIST: (lenderId: string) => string;
  UPDATE_ALLOCATION_CONFIG: (configId: string) => string;
  CREATE_ALLOCATION: string;
  GET_ALLOCATION_BY_ID: (allocationId: string) => string;
  ASSIGN_ALLOCATION: string;
  RE_ASSIGN_ALLOCATION: string;
  GET_DERIVED_FIELDS: (lenderId: string, allocationId?: string) => string;
  GET_ALLOCATION_CONFIG: (configId: string) => string;
  GET_GROUPED_ALLOCATIONS_NUMBERS: (lenderId: string) => string;
  GET_ALLOCATION_LIST: (lenderId: string) => string;
  GET_FILTER_OPTIONS: (lenderId: string) => string;
  GET_EXTRACTION_STATUS: string;
  DEALLOCATE_ALLOCATION: string;
  GET_SFTP_ALLOCATION_LIST: string;
  UPLOAD_ALLOCATION_TO_SFTP: string;
  GET_SFTP_UPLOAD_STATUS: string;
  GET_PAYMENT_STATUS: string;
  GET_COVERED_RECORDS: string;
}

const userEndpoints: UserEndpoints = {
  GET_USER_PERMISSION: `${hostName}/collections/rbac/v1/user/permissions`,
  GET_USER_ENTITIES: `${hostName}/collections/rbac/v1/user/entities`,
  SEARCH_USER: (searchCriteria: string) =>
    `${epsBaseUrl}/api/v1/users/search?keyword=${searchCriteria}`,
  GET_SUPERSET_TOKEN: (dashboardType) =>
    `${hostName}/collections/dashboard/integration/token?dashboardType=${dashboardType}`,
};

const manageAgency = {
  REMIND_URL: `${emsBaseUrl}/api/v1/lender/agencies/invite/remind`,
  GET_AGENCY_LIST: `${emsBaseUrl}/api/v1/lender/association/agencies/my-agency`,
  REINVITE_URL: `${emsBaseUrl}/api/v1/lender/agencies/invite/re-invite`,
  INVITE_ACTION_URL: `${emsBaseUrl}/api/v1/lender/agencies/invite`,
  AGENCY_LIST_URL: `${emsBaseUrl}/api/v1/lender/association/agencies`,
  SEND_ASSOCIATION_REQUEST_URL: `${emsBaseUrl}/api/v1/lender/agencies/associate`,
  FILTER_OPTIONS: `${emsBaseUrl}/api/v1/lender/association/agencies/filter`,
};
const allocationEngineEndpoints: AllocationEngineEndpoints = {
  GET_ALLOCATION_BY_ID: (allocationId: string) =>
    `${allocationEngineBaseURL}/allocationConfig/getAllocationForAllocationId/${allocationId}`,
  CREATE_ALLOCATION: `${allocationEngineBaseURL}/allocationConfig/createAllocation`,
  CREATE_ALLOCATION_CONFIG: `${allocationEngineBaseURL}/allocationConfig/saveAllocationAsConfig`,
  UPDATE_ALLOCATION_CONFIG: (configId) =>
    `${allocationEngineBaseURL}/allocationConfig/updateAllocationConfig/${configId}`,
  GET_CREATED_ALLOCATION_CONFIG_LIST: (lenderId) =>
    `${allocationEngineBaseURL}/allocationConfig/getCreatedAllocationConfigs/${lenderId}`,
  ASSIGN_ALLOCATION: `${allocationEngineBaseURL}/allocationConfig/assignAllocation`,
  RE_ASSIGN_ALLOCATION: `${allocationEngineBaseURL}/allocationConfig/reallocateAllocation`,
  GET_ALLOCATION_CONFIG: (configId: string) =>
    `${allocationEngineBaseURL}/allocationConfig/getAllocationConfigForAllocationConfigId/${configId}`,

  GET_DERIVED_FIELDS: (lenderId: string, allocationId?: string) =>
    allocationId
      ? `${allocationEngineBaseURL}/allocationConfig/getAllColumnsWithDistinctValue/${lenderId}?allocationId=${allocationId}`
      : `${allocationEngineBaseURL}/allocationConfig/getAllColumnsWithDistinctValue/${lenderId}`,
  GET_GROUPED_ALLOCATIONS_NUMBERS: (lenderId: string) =>
    `${allocationEngineBaseURL}/allocationConfig/listAllocationNumbersGroupedByType/${lenderId}`,
  GET_ALLOCATION_LIST: (lenderId: string) =>
    `${allocationEngineBaseURL}/allocationConfig/listAllocationsGroupedByType/${lenderId}`,
  GET_FILTER_OPTIONS: (lenderId: string) =>
    `${allocationEngineBaseURL}/allocationConfig/getFilters/${lenderId}`,
  GET_EXTRACTION_STATUS: `${allocationEngineBaseURL}/allocationConfig/getExtractionRunStatus`,
  DEALLOCATE_ALLOCATION: `${allocationEngineBaseURL}/allocationConfig/deAllocateAllocation`,
  GET_SFTP_ALLOCATION_LIST: `${emsBaseUrl}/sftp/v1/allocation`,
  UPLOAD_ALLOCATION_TO_SFTP: `${emsBaseUrl}/sftp/v1/uploadFile`,
  GET_SFTP_UPLOAD_STATUS: `${emsBaseUrl}/sftp/v1/allocation/status`,
  GET_PAYMENT_STATUS: `${allocationEngineBaseURL}/allocationConfig/getPaymentRunStatus`,
  GET_COVERED_RECORDS: `${allocationEngineBaseURL}/allocationConfig/getFilterResultForAllocationRecords`,
};

const settingsEndpoints = {
  /* Location, Channel, Product, Bucket, Payment, Content preference end point */
  GET_PREFERENCES: (
    preferenceType: string,
    offset = 0,
    limit = 10,
    searchCriteria = ''
  ) =>
    `${baseUrl}/eps/config/api/v1/${preferenceType}?offset=${offset}&limit=${limit}&searchCriteria=${searchCriteria}`,
  ENABLE_OR_DISABLE_PREFERENCES: (
    preferenceType: string,
    preferenceId: number,
    isEnabled: boolean
  ) =>
    `${baseUrl}/eps/config/api/v1/${preferenceType}/${preferenceId}?enabled=${isEnabled}`,
  CREATE_CHANNEL_PREFERENCES: `${baseUrl}/eps/config/api/v1/channels`,
  UPLOAD_LOCATION_PREFERENCES: `${baseUrl}/eps/config/api/v1/locations`,
  PAYMENT_CONFIG: `${baseUrl}/eps/config/api/v1/payment-config`,
  PAYMENT_GATEWAY_CONFIG: `${baseUrl}/eps/config/api/v1/payment-gateway-config`,
  GET_CONTENT_PREFERENCE: `${baseUrl}/eps/config/api/v1/content-preference`,
  GET_PAYMENT_FILE_PREFERENCES: `${baseUrl}/eps/config/api/v1/payment-file-config`,
  PRODUCT_PREFERENCES: `${baseUrl}/eps/config/api/v1/products`,
  BUCKET_PREFERENCES: `${baseUrl}/eps/config/api/v1/buckets`,

  // Onboarding endpoint
  GET_ENTITY_DETAILS: `${emsBaseUrl}/api/v1/entity/onboard?status=active`,

  // LMS fields endpoint
  LMS_FIELDS: `${baseUrl}/eps/config/api/v1/lms-fields`,
  GET_DERIVED_FIELDS: `${baseUrl}/eps/config/api/v1/derived-fields`,
  DELETE_DERIVED_FIELD: `${baseUrl}/eps/config/api/v1/derived-fields`,
  CREATE_DERIVED_FIELD: `${baseUrl}/eps/config/api/v1/derived-fields`,
  UPDATE_LMS_FIELDS: `${baseUrl}/eps/config/api/v1/lms-fields`,
  UPDATE_DERIVED_FIELDS: `${baseUrl}/eps/config/api/v1/derived-fields`,

  // Compliance endpoint
  Get_GLOBAL_POLICIES: `${hostName}/collections/compliance/v1/config/global`,
  GET_DEFAULT_POLICIES: `${hostName}/collections/compliance/v1/config/lender/default`,
  GET_ALL_POLICIES: `${hostName}/collections/compliance/v1/config/lender/policy/all`,
  SAVE_DEFAULT_POLICIES: (isSave: boolean) =>
    `${hostName}/collections/compliance/v1/config/lender/default/${
      isSave ? 'create' : 'update'
    }`,
  SAVE_LENDER_POLICY: (isEdit: boolean) =>
    `${hostName}/collections/compliance/v1/config/lender/policy/${
      isEdit ? 'update' : 'create'
    }`,
  UPDATE_POLICY_STATUS: `${hostName}/collections/compliance/v1/config/lender/policy/update/status`,
  GET_LOB_POLICIES: `${hostName}/collections/compliance/v1/config/plan`,
};

const lender = {
  GET_LENDER_INVITES: `${emsBaseUrl}/api/v1/agency/association/lender/invites`,
  GET_LENDERS: `${emsBaseUrl}/api/v1/agency/association/lenders/my-lender`,
  ACCEPT_INVITES: `${emsBaseUrl}/api/v1/agency/association/lender/invites/accept`,
  REJECT_INVITES: `${emsBaseUrl}/api/v1/agency/association/lender/invites/reject`,
  GET_UNIQUE_LENDERS: `${emsBaseUrl}/api/v1/user/entities/association/lenders/all`,
};

const campaignPlanner = {
  OUTREACH: (pagination?: { offset: number; limit: number }) =>
    pagination
      ? `${hostName}/collections/api/v1/campaign-planner/outreaches?pageNo=${pagination.offset}&recordsPerPage=${pagination.limit}`
      : `${hostName}/collections/api/v1/campaign-planner/outreaches`,
  GET_CONFIG: (lenderId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/getConfig?lenderId=${lenderId}`,
  GET_CONTENT: (channel: string, language: string, lenderId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/getContent/${channel}/${language}?lenderId=${lenderId}`,
  GET_CONDITION: `${hostName}/collections/api/v1/campaign-planner/condition`,
  GET_COMPLIANCE_POLICIES: `${hostName}/compliance/compliance-policies/by-name`,
  CREATE_CAMPAIGN: `${hostName}/collections/api/v1/campaign-planner/campaigns`,
  UPDATE_CAMPAIGN: (
    campaignId: string,
    lenderId: string,
    submitPlan: boolean
  ) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/editCampaign/${campaignId}?lenderId=${lenderId}&submitPlan=${submitPlan}`,
  GET_CAMPAIGN_LIST: (
    name: string,
    status: string,
    pagination?: { offset: number; limit: number }
  ) =>
    pagination
      ? `${hostName}/collections/api/v1/campaign-planner/campaigns/fetchAllCampaigns/${status}?pageNo=${pagination.offset}&recordsPerPage=${pagination.limit}&name=${name}`
      : `${hostName}/collections/api/v1/campaign-planner/campaigns/fetchAllCampaigns/${status}?name=${name}`,
  GET_ALLOCATION_LIST: (lenderId: string, status: string, name: string) =>
    `${hostName}/collections/api/v1/campaign-planner/getAllocationList?lenderId=${lenderId}&status=${status}&name=${name}`,
  DELETE_STRATEGY: (strategyId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/strategy/${strategyId}`,
  CREATE_STRATEGY: `${hostName}/collections/api/v1/campaign-planner/strategy`,
  DRAFT_SAVE_CAMPAIGN_PLAN: (campaignId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/saveDraft/${campaignId}`,
  PLAN_CAMPAIGN_PLAN: (lenderId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/submitPlan?lenderId=${lenderId}`,
  FETCH_SEGMENTS_LIST: (allocationId: number) =>
    `${hostName}/collections/segments/${allocationId}`,
  FETCH_CAMPAIGN_PLAN: (campaignId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/${campaignId}?planRequired=false`,
  FETCH_STRATEGY_LIST: (pagination?: {
    offset: number;
    limit: number;
    searchText: string;
  }) =>
    pagination
      ? `${hostName}/collections/api/v1/campaign-planner/strategy/fetchStrategyList?pageNo=${pagination.offset}&recordsPerPage=${pagination.limit}&searchText=${pagination.searchText}`
      : `${hostName}/collections/api/v1/campaign-planner/strategy/fetchStrategyList`,
  FETCH_STRATEGY_DETAILS: (strategyId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/strategy/${strategyId}`,
  POST_STRATEGY: `${hostName}/collections/api/v1/campaign-planner/strategy`,
  UPDATE_STRATEGY: (strategyId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/strategy/${strategyId}`,
  POST_SEGMENTS_LIST: (campaignId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/segments/${campaignId}`,
  CLONE_STRATEGY: (strategyId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/strategy/clone/${strategyId}`,
  FETCH_OUTREACH_LIST: (
    name: string,
    pagination?: { offset: number; limit: number }
  ) =>
    pagination
      ? `${hostName}/collections/api/v1/campaign-planner/outreaches/fetchOutreachList?name=${name}&pageNo=${pagination.offset}&recordsPerPage=${pagination.limit}`
      : `${hostName}/collections/api/v1/campaign-planner/outreaches/fetchOutreachList?name=${name}`,
  GET_OUTREACH_FILTERS: `${hostName}/collections/api/v1/campaign-planner/outreaches/getOutreachFilters`,
  STRATEGY_LIST_FILTER_API: `${hostName}/collections/api/v1/campaign-planner/strategy/fetchStrategyFilter`,
  CREATE_BULK_OUTREACH: `${hostName}/collections/api/v1/campaign-planner/bulk-outreaches`,
  PAUSE_CAMPAIGN: (campaignId: number, status: string) =>
    `${hostName}/collections/api/v1/campaign-planner/pauseOrActivatePlan/${campaignId}?status=${status}`,
  ROLL_BACK_CAMPAIGN: (campaignId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/${campaignId}/rollBackCampaign`,
  FETCH_CAMPAIGN_SUMMARY: (campaignId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/${campaignId}/campaign-summary`,
  SAVE_SEGMENT_TO_THE_CAMPAIGN: (campaignId?: string | null) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/${campaignId}/add-segment`,
  CONDITION_RULE: (status: string) =>
    `${hostName}/collections/api/v1/campaign-planner/condition-rule/fetchConditionRuleList/${status}`,
  RAISE_NBA_FAILURE_TICKET: `${hostName}/collections/api/v1/campaign-planner/campaigns/raiseNBAFailureTicket`,
  DELETE_SEGMENTS_FROM_CAMPAIGN: (campaignId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/${campaignId}/delete-segment`,
  GET_SEGMENT_DETAILS: ({
    segmentId,
    campaignId,
  }: {
    segmentId: number;
    campaignId: string;
  }) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/segments/${campaignId}?segmentId=${segmentId}`,
};

const hierarchyManagementEndPoint = {
  designationEndpoint: {
    ADD_DESIGNATION: `${epsBaseUrl}/api/v1/designations`,
    DELETE_DESIGNATION: (designationId: string) =>
      `${epsBaseUrl}/api/v1/designations/${designationId}`,
    UPDATE_DESIGNATION: (designationId: string) =>
      `${epsBaseUrl}/api/v1/designations/${designationId}`,
    GET_DESIGNATION_LIST: (offset = 0, limit = 10, searchKeyword = '') =>
      `${epsBaseUrl}/api/v1/designations?limit=${limit}&offset=${offset}&searchKeyword=${searchKeyword}`,
    ADD_DESIGNATION_BY_FILE: `${epsBaseUrl}/api/v1/designations/file-upload`,
  },
  hierarchySetupEndpoint: {
    GET_REPORTING_HIERARCHY: (caUserId: string) =>
      `${epsBaseUrl}/api/v1/hierarchy/${caUserId}`,
    ADD_HIERARCHY_DETAILS: ({
      isRootUser,
      userId,
    }: {
      isRootUser: boolean;
      userId: string;
    }) => `${epsBaseUrl}/api/v1/hierarchy/${userId}?isRootUser=${isRootUser}`,
    GET_TEAM_MEMBERS: (
      filterQueryString: string,
      offset = 0,
      limit = 10,
      searchKeyword = ''
    ) => {
      if (filterQueryString) {
        return `${epsBaseUrl}/api/v1/hierarchy/team-members?limit=${limit}&offset=${offset}&${filterQueryString}&searchKeyword=${searchKeyword}`;
      }

      return `${epsBaseUrl}/api/v1/hierarchy/team-members?limit=${limit}&offset=${offset}&searchKeyword=${searchKeyword}`;
    },
  },
  GET_PRODUCT_BUCKET_LOCATION: (caUserId: string) =>
    `${epsBaseUrl}/api/v1/user-configuration/${caUserId}`,
  GET_PRODUCT_BUCKET_LIST: `${epsBaseUrl}/config/api/v1/products/product-bucket-list`,

  SET_PRODUCT_BUCKET: `${epsBaseUrl}/api/v1/user-configuration`,
  UPLOAD_USER_BY_FILE: `${epsBaseUrl}/api/v1/hierarchy/file-upload`,
  SEARCH_DESIGNATION: (searchCriteria: string) =>
    `${epsBaseUrl}/api/v1/designations/search?keyword=${searchCriteria}`,
  SYNC_USERS: `${epsBaseUrl}/api/v1/users/sync-platform-users`,
};

const locationManagementEndPoint = {
  getLocations: (locationType: string, state?: string) =>
    state
      ? `${epsBaseUrl}/config/api/v1/${locationType}/locations?state=${state}`
      : `${epsBaseUrl}/config/api/v1/${locationType}/locations`,
  GET_LOCATION_TYPE: `${epsBaseUrl}/config/api/v1/locations/location-types`,
};

const segmentsEndPoints = {
  SET_SEGMENT_LIST: `${hostName}/collections/api/v1/campaign-planner/segments`,
  CREATE_SEGMENT: `${hostName}/collections/api/v1/campaign-planner/segments`,
  UPDATE_SEGMENT: (segmentId: string) =>
    segmentId
      ? `${hostName}/collections/api/v1/campaign-planner/segments/${segmentId}`
      : `${hostName}/collections/api/v1/campaign-planner/segments`,
  GET_SEGMENTS_LIST: (offset = 0, limit = 10) =>
    `${hostName}/collections/api/v1/campaign-planner/segments?pageNo=${offset}&recordsPerPage=${limit}`,
  GET_SEGMENT: (segmentId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/segments/${segmentId}`,
  DELETE_SEGMENT: (segmentId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/segments/${segmentId}`,
};

const campaignEndPoints = {
  CREATE_SEGMENT: (campaignId: number, submitPlan: boolean, lenderId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/segments/${campaignId}?submitPlan=${submitPlan}&lenderId=${lenderId}`,
};

const contentManagement = {
  POST_TEMPLATE_CREATION: (channel: string) =>
    `${contentManagementBaseURL}/cms/v1/api/${channel}`,
  POST_TEST_TEMPLATE: `${contentManagementBaseURL}/cms/v1/api/content/test`,
  POST_CONTENT_LIST: (contentType: string, pageNo: number, pageSize: number) =>
    `${contentManagementBaseURL}/cms/v1/api/${contentType}/filter?pageSize=${pageSize}&pageNumber=${pageNo}`,
  DELETE_CONTENT: (channel: string) =>
    `${contentManagementBaseURL}/cms/v1/api/${channel}/delete`,
  FETCH_CONTENT_TEMPLATE_DETAILS: (channel: string) =>
    `${contentManagementBaseURL}/cms/v1/api/${channel}/templateDetails`,
  DELETE_CONTENT_BY_ID: (channel: string, id: number) =>
    `${contentManagementBaseURL}/cms/v1/api/${channel}/${id}`,
  FETCH_CONFIG: `${contentManagementBaseURL}/cms/v1/api/config`,
  POST_CLONE_TEMPLATE: (channel: string) =>
    `${contentManagementBaseURL}/cms/v1/api/${channel}/clone`,
  DISABLE_PUBLISH_CONTENT: (channel: string) =>
    `${contentManagementBaseURL}/cms/v1/api/${channel}/update-status`,
};

const conditionRuleEndpoint = {
  GET_CONDITIONS: `${hostName}/collections/api/v1/campaign-planner/condition`,
  GET_RULES: (
    status: string,
    pageNo: number,
    recordsPerPage: number,
    searchCriteria: string
  ) => {
    const url = `${hostName}/collections/api/v1/campaign-planner/condition-rule/fetchConditionRuleList/${status}?pageNo=${pageNo}&recordsPerPage=${recordsPerPage}`;
    if (searchCriteria) return url + `&name=${searchCriteria}`;
    return url;
  },
  SAVE_RULE: `${hostName}/collections/api/v1/campaign-planner/condition-rule`,
  EDIT_RULE: (ruleId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/condition-rule/${ruleId}`,
  UPDATE_STATUS: (ruleId: number, status: string) =>
    `${hostName}/collections/api/v1/campaign-planner/condition-rule/${ruleId}/${status}`,
  GET_RULE: (id: number) =>
    `${hostName}/collections/api/v1/campaign-planner/condition-rule/${id}`,
  CLONE_RULE: (ruleId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/condition-rule/clone/${ruleId}`,
  DELETE_RULE: (ruleId: number) =>
    `${hostName}/collections/api/v1/campaign-planner/condition-rule/${ruleId}`,
  GET_FILTERS: (status: string) =>
    `${hostName}/collections/api/v1/campaign-planner/condition-rule/getConditionRuleFilters/${status}`,
};
const crmEndpoints = {
  CRM: `https://crm${env !== 'prod' ? `-${env}` : ''}.spocto.com`,
  UNSUB: `https://unsubscribe${
    env !== 'prod' ? `-${env}` : ''
  }-collections.spocto.com`,
};
type CampaignOutreachDroppedMetrics = {
  nodeId: string;
  segmentId: number;
};

const campaignMetrics = {
  GET_CAMPAIGN_OUTREACH_METRICS: ({
    campaignId,
    query,
  }: {
    campaignId: string;
    query: string;
  }) =>
    `${hostName}/collections/api/v1/sentinel/metrics/campaign/${campaignId}/outreach?${query}`,
  GET_OUTREACH_DROPPED_DETAILS: ({
    nodeId,
    segmentId,
    campaignId,
  }: CampaignOutreachDroppedMetrics & {
    campaignId: string;
  }) =>
    `${hostName}/collections/api/v1/sentinel/metrics/campaign/${campaignId}/outreach/dropped/${nodeId}?segmentId=${segmentId}`,
  GET_CAMPAIGN_EVENTS_SUMMARY: ({
    campaignId,
    query,
  }: {
    campaignId: number;
    query: string;
  }) =>
    `${hostName}/collections/api/v1/sentinel/metrics/campaign/${campaignId}/summary?${query}`,
  GET_CAMPAIGN_SEGMENTS: ({ campaignId }: { campaignId: string }) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/${campaignId}/segment-details`,
  GET_DISPOSITION_LIST: ({
    query,
    campaignId,
  }: {
    query: string;
    campaignId: number;
  }) =>
    `${hostName}/collections/api/v1/sentinel/metrics/campaign/${campaignId}/dispositions?${query}`,

  GET_CAMPAIGN_DETAILS_BY_ID: (campaignId: string) =>
    `${hostName}/collections/api/v1/campaign-planner/campaigns/${campaignId}/campaign-summary`,
  GET_OUTREACH_TRACKING_FILTERS: (campaignId?: string | null) =>
    `${hostName}/collections/api/v1/sentinel/metrics/campaign/${campaignId}/outreach/filters`,
  GET_DISPOSITION_LIST_FILTERS: (campaignId: string | null, query?: string) =>
    `${hostName}/collections/api/v1/sentinel/metrics/campaign/${campaignId}/dispositions/filters?${
      query || ''
    }`,
};

export {
  userEndpoints,
  hierarchyManagementEndPoint,
  allocationEngineEndpoints,
  settingsEndpoints,
  lender,
  manageAgency,
  campaignPlanner,
  segmentsEndPoints,
  campaignEndPoints,
  locationManagementEndPoint,
  contentManagement,
  crmEndpoints,
  campaignMetrics,
  conditionRuleEndpoint,
};
