import sessionStorageService from '../../services/storage/sessionStorageService';
import { IGenericParam } from './api.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from './axios';
import { ApiStatusCodes } from './apiStatusCode';
export interface ApiResponse<T> {
  data: T;
  status: ApiStatusCodes;
  // Add more fields as needed
}

async function GetRequest<T>(
  url: string,
  config?: AxiosRequestConfig,
  isCache?: boolean,
  cacheExpirationMS = 60000
): Promise<ApiResponse<T>> {
  const requestURL = url;

  // Check if the data exists in session storage and is not expired
  if (isCache) {
    const cachedResponse: ApiResponse<T> | null =
      sessionStorageService.getItem(requestURL);

    if (cachedResponse) {
      return cachedResponse; // Return the cached data
    }
  }

  // Fetch the data from the server using async/await
  const response: AxiosResponse<T> = await axios.get<T>(requestURL, config);

  // Cache the response if cacheExpirationMS is provided
  if (isCache && cacheExpirationMS) {
    sessionStorageService.setItem(requestURL, response.data, cacheExpirationMS);
  }

  return { data: response.data, status: response.status };
}

async function PostRequest<T>(
  url: string,
  params?: IGenericParam,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> {
  const requestURL = url;

  // Send the POST request using async/await
  const response: AxiosResponse<T> = await axios.post<T>(
    requestURL,
    params,
    config
  );
  return { data: response.data, status: response.status };
}

async function PutRequest<T>(
  url: string,
  params?: IGenericParam,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> {
  const requestURL = url;

  // Send the PUT request using async/await
  const response: AxiosResponse<T> = await axios.put<T>(
    requestURL,
    params,
    config
  );
  return { data: response.data, status: response.status };
}

async function PatchRequest<T>(
  requestURL: string,
  params?: IGenericParam,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> {
  // Send the PATCH request using async/await
  const response: AxiosResponse<T> = await axios.patch<T>(
    requestURL,
    params,
    config
  );
  return { data: response.data, status: response.status };
}

async function DeleteRequest<T>(
  url: string,
  config?: IGenericParam
): Promise<ApiResponse<T>> {
  const axiosConfig: AxiosRequestConfig = {
    ...config,
  };
  const requestURL = url;

  // Send the DELETE request using async/await
  const response: AxiosResponse<T> = await axios.delete<T>(
    requestURL,
    axiosConfig
  );
  return { data: response.data, status: response.status };
}

export const HTTPClient = {
  GetRequest,
  PostRequest,
  PutRequest,
  DeleteRequest,
  PatchRequest,
};
