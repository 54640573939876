import { put, takeLatest } from 'redux-saga/effects';
import {
  AllocationConfigPayload,
  fetchGetAllocationConfigList,
  fetchGetAllocationConfigListFailed,
  fetchGetAllocationConfigListSucceeded,
} from '../../slice/allocation-config/GetAllocationConfigList.slice';
import { HTTPClient } from '../../../services/api/http-client';
import { IAllocationConfigListResponse } from 'redux/types/GetAllocationConfigList.type';
import { allocationEngineEndpoints } from 'services/api/endPoints';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

export function* getAllocationConfigListSaga({
  payload,
}: PayloadAction<AllocationConfigPayload>) {
  try {
    const { recordLimit, lenderId, filters = [] } = payload;
    const { offset, limit } = recordLimit;
    const result: AxiosResponse<IAllocationConfigListResponse> =
      yield HTTPClient.PostRequest(
        allocationEngineEndpoints.GET_CREATED_ALLOCATION_CONFIG_LIST(lenderId),
        {
          offset,
          limit,
          filters,
        }
      );
    if (result?.data) {
      const { allocationConfigs, ...restData } = result?.data;

      yield put(
        fetchGetAllocationConfigListSucceeded({
          allocationConfigs,
          ...restData,
        })
      );
    } else {
      throw new Error('Invalid response');
    }
  } catch (error) {
    yield put(
      fetchGetAllocationConfigListFailed({
        error: { message: 'Failed to get allocation config list' },
      })
    );
  }
}

export function* watchGetAllocationConfigListSaga() {
  yield takeLatest(
    fetchGetAllocationConfigList.type,
    getAllocationConfigListSaga
  );
}
