export const AGENCY_UPDATE_LENDER_INVITES = 'agency_update_lender_invites';
export const AGENCY_VIEW_MY_LENDERS = 'agency_view_my_lenders';
export const AGENCY_VIEW_LENDER_INVITES = 'agency_view_lender_invites';
export const LENDER_VIEW_MY_AGENCIES = 'lender_view_my_agencies';
export const LENDER_VIEW_ALL_AGENCIES = 'lender_view_all_agencies';
export const LENDER_UPDATE_ALL_AGENCIES = 'lender_update_all_agencies';
export const LENDER_VIEW_INVITED_AGENCIES = 'lender_view_invited_agencies';
export const LENDER_UPDATE_INVITED_AGENCIES = 'lender_update_invited_agencies';
export const LENDER_INVITE_AGENCIES = 'lender_invite_agencies';
export const VIEW_PREFERENCES = 'view_preferences';
export const VIEW_ORG_PROFILE = 'view_org_profile';
export const UPDATE_PREFERENCES = 'update_preferences';
export const VIEW_ENGAGE_DASHBOARD = 'view_engage_dashboard';
export const VIEW_ALLOCATIONS = 'view_allocations';
export const EDIT_ALLOCATION = 'edit_allocation';
export const CREATE_ALLOCATION = 'create_allocation';
export const ASSIGN_ALLOCATION = 'assign_allocation';
export const VIEW_ALLOCATION_CONFIGS = 'view_allocation_configs';
export const SAVE_ALLOCATION_CONFIG = 'save_allocation_config';
export const VIEW_LENDER_COMPLIANCE = 'view_lender_compliance';
export const VIEW_COMPLIANCE = 'view_compliance';
export const EDIT_COMPLIANCE = 'edit_compliance';
export const CAMPAIGN_PLANNER = 'campaign_planning';
export const EDIT_CONTENT = 'edit_content';
export const VIEW_CONTENT = 'view_content';
export const TEST_CONTENT = 'test_content';
export const PUBLISH_CONTENT = 'publish_content';
export const CREATE_CONTENT = 'create_content';
export const DELETE_CONTENT = 'delete_content';
export const VIEW_DESIGNATION = 'view_designations';
export const EDIT_DESIGNATION = 'edit_designations';
export const VIEW_HIERARCHY = 'view_hierarchy_setup';
export const EDIT_HIERARCHY = 'edit_hierarchy_setup';
export const CRM_ACCESS = 'access_call_plus_app';
export const UN_SUBSCRIBE_ACCESS = 'access_unsubscribe_app';