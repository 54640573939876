import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchUserOnboardDetailsFailed,
  fetchUserOnboardDetailsRequested,
  fetchUserOnboardDetailsSuccess,
} from 'redux/slice/user/UserOnboard.slice';
import { HTTPClient } from 'services/api/http-client';
import { settingsEndpoints } from 'services/api/endPoints';
import { IOnboardDetails } from 'redux/types/UserOnboardDetails.types';

function* getOnboardDetailsSaga() {
  try {
    const response: { data: IOnboardDetails } = yield HTTPClient.GetRequest(
      settingsEndpoints.GET_ENTITY_DETAILS
    );
    if (response.data.success) {
      yield put(fetchUserOnboardDetailsSuccess(response.data));
    } else {
      yield put(
        fetchUserOnboardDetailsFailed(
          (response.data?.errors && response.data?.errors[0]) ||
            'Entity not on boarded'
        )
      );
    }
  } catch (err: any) {
    yield put(fetchUserOnboardDetailsFailed(err.response?.data?.errors?.[0]));
  }
}

export function* watchGetOnboardDetailsSaga() {
  yield takeLatest(
    fetchUserOnboardDetailsRequested.type,
    getOnboardDetailsSaga
  );
}
