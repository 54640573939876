import { VIEW_PREFERENCES } from 'rbac/constants';
import React, { useState } from 'react';
import AuthProvider from 'templates/AuthProvider';
import SettingsHeader from '../components/SettingsHeader';
import { ButtonViewV3 } from 'assets/components-v3';
import { SearchView, TabsView, TextView } from 'components';
import ProductPreferences from './ProductPreferencesView';
import BucketPreferences from './BucketPreferencesView';
import { useAppSelector } from 'utils/hooks/useAppSelector';

const ProductAndBucketPreferences = () => {
  const [tab, setTab] = useState('product_preferences');
  const [productCreateClicked, setCreateProductClicked] = useState(0);
  const [bucketCreateClicked, setBucketCreateClicked] = useState(0);
  const [productSearch, setProductSearch] = useState('');
  const [bucketSearch, setBucketSearch] = useState('');

  const productsData = useAppSelector(
    (store) => store.settings.preference.product.data
  );
  const bucketsData = useAppSelector(
    (store) => store.settings.preference.bucket.data
  );

  const heading = 'Product & bucket';
  const description =
    'Add product and bucket as per your requirement or customise from our existing repository.';

  const tabsData = [
    {
      key: 'product_preferences',
      title: 'Product preferences',
    },
    {
      key: 'bucket_preferences',
      title: 'Bucket preferences',
    },
  ];

  const componentsData = {
    product_preferences: (
      <ProductPreferences
        onProductCreateClicked={productCreateClicked}
        resetProductCreateClicked={() => {
          setCreateProductClicked(0);
        }}
        searchText={productSearch}
      />
    ),
    bucket_preferences: (
      <BucketPreferences
        onBucketCreateClicked={bucketCreateClicked}
        resetBucketCreateClicked={() => {
          setBucketCreateClicked(0);
        }}
        searchText={bucketSearch}
      />
    ),
  };

  const onSearchTextChange = (text: string) => {
    if (tab === 'product_preferences') {
      setProductSearch(text);
    } else {
      setBucketSearch(text);
    }
  };

  const showSearchAndAddButton =
    tab === 'product_preferences' ? productsData?.length : bucketsData?.length;

  return (
    <AuthProvider permission={VIEW_PREFERENCES} showFriendlyScreen>
      <article className="flex flex-col w-full h-full">
        <header>
          <SettingsHeader heading={heading} description={description} />
        </header>
        <body className="pt-6">
          <article className="flex justify-between">
            <section>
              <TabsView
                activeKey={tab}
                tabItems={tabsData}
                onTabClick={(key: string) => {
                  setTab(key);
                  setCreateProductClicked(0);
                  setBucketCreateClicked(0);
                  setBucketSearch('');
                  setProductSearch('');
                }}
              />
            </section>
            {showSearchAndAddButton && (
              <section className="flex gap-2">
                <div>
                  <SearchView
                    value={
                      tab === 'product_preferences'
                        ? productSearch
                        : bucketSearch
                    }
                    onSubmitEditing={onSearchTextChange}
                    onChange={onSearchTextChange}
                    placeholder={
                      tab === 'product_preferences'
                        ? 'Search Products'
                        : 'Search Bucket'
                    }
                  />
                </div>
                <ButtonViewV3
                  label={
                    tab === 'product_preferences'
                      ? 'Add Products'
                      : 'Add Buckets'
                  }
                  onClick={() => {
                    if (tab === 'product_preferences') {
                      setCreateProductClicked((prev) => prev + 1);
                    } else {
                      setBucketCreateClicked((prev) => prev + 1);
                    }
                  }}
                />
              </section>
            )}
          </article>
          <section className="w-full h-full">
            {componentsData[tab as keyof typeof componentsData]}
          </section>
        </body>
      </article>
    </AuthProvider>
  );
};

export default ProductAndBucketPreferences;
