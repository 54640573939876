import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIStatus } from 'services/api/apiStatusCode';
import { IEmployeeProfileInitialState } from './types';

export const employeeProfileInitialState: IEmployeeProfileInitialState = {
  data: {
    reportingData: undefined,
    productBucketData: undefined,
    lenderEntities: undefined,
    locationConfigurations: undefined,
  },
  status: APIStatus.INIT,
  isLoading: false,
  error: {},
};

const employeeProfileSlice = createSlice({
  name: 'employeeProfile',
  initialState: employeeProfileInitialState,
  reducers: {
    fetchEmployeeProfileData: (
      state,
      action: PayloadAction<{ userId: string }>
    ) => {
      state.isLoading = true;
    },
    fetchEmployeeProfileDataSucceeded: (state, action: PayloadAction<any>) => {
      const { data, message } = action.payload;
      state.data.reportingData = data;
      state.status = APIStatus.SUCCESS;
      state.isLoading = false;
    },
    fetchEmployeeProfileDataFailed: (
      state,
      action: PayloadAction<{ message: string }>
    ) => {
      state.isLoading = false;
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },

    fetchSavedProductBucketData: (
      state,
      action: PayloadAction<{ userId: string }>
    ) => {
      state.isLoading = true;
    },
    fetchSavedProductBucketDataSucceeded: (
      state,
      action: PayloadAction<any>
    ) => {
      const { lenderEntities, uniqueLOBs, locationConfigurations } =
        action.payload;
      state.data.productBucketData = uniqueLOBs;
      state.data.lenderEntities = lenderEntities;
      state.data.locationConfigurations = locationConfigurations;
      state.status = APIStatus.SUCCESS;
      state.isLoading = false;
    },
    fetchSavedProductBucketDataFailed: (
      state,
      action: PayloadAction<{ message: string }>
    ) => {
      state.isLoading = false;
      state.status = APIStatus.ERROR;
      state.error = action.payload;
    },
    resetEmployeeProfileState: () => employeeProfileInitialState,
  },
});

export const {
  fetchSavedProductBucketData,
  fetchSavedProductBucketDataSucceeded,
  fetchSavedProductBucketDataFailed,
  fetchEmployeeProfileData,
  fetchEmployeeProfileDataSucceeded,
  fetchEmployeeProfileDataFailed,
  resetEmployeeProfileState,
} = employeeProfileSlice.actions;

export default employeeProfileSlice.reducer;
