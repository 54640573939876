import { conditionRuleEndpoint } from 'services/api/endPoints';
import { HTTPClient } from 'services/api/http-client';
import {
  IConditionListResponse,
  IConditionRule,
  IConditionsResponse,
  IGetConditionRulesPayload,
} from './contract';

export const getConditionRules = async (
  payload: IGetConditionRulesPayload
): Promise<{ response?: IConditionListResponse; errorMessage?: string }> => {
  const { status, offset, limit, searchCriteria, filters } = payload;
  const pageNo = offset / limit;
  const recordsPerPage = limit;
  try {
    const response = await HTTPClient.PostRequest<IConditionListResponse>(
      conditionRuleEndpoint.GET_RULES(
        status,
        pageNo,
        recordsPerPage,
        searchCriteria || ''
      ),
      filters
    );
    if (response.status === 200) {
      return { response: response.data };
    }
    return { errorMessage: response.data.message };
  } catch (err) {
    return { errorMessage: (err as Error).message };
  }
};

export const getConditionsList = async (): Promise<{
  response?: IConditionsResponse;
  errorMessage?: string;
}> => {
  try {
    const response = await HTTPClient.GetRequest<IConditionsResponse>(
      conditionRuleEndpoint.GET_CONDITIONS
    );
    if (response.status === 200) {
      return { response: response.data };
    }
    return { errorMessage: response.data.message };
  } catch (err) {
    return { errorMessage: (err as Error).message };
  }
};

export const saveCondition = async (
  payload: IConditionRule
): Promise<{ errorMessage?: string }> => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      conditionRuleEndpoint.SAVE_RULE,
      payload
    );
    if (response.status === 200) {
      return {};
    }
    return { errorMessage: response.data.message };
  } catch (err: any) {
    return { errorMessage: err.response.data.error };
  }
};

export const editCondition = async (
  payload: IConditionRule
): Promise<{ errorMessage?: string }> => {
  try {
    const response = await HTTPClient.PutRequest<any>(
      conditionRuleEndpoint.EDIT_RULE(payload.ruleId || 0),
      payload
    );
    if (response.status === 200) {
      return {};
    }
    return { errorMessage: response.data.message };
  } catch (err: any) {
    return { errorMessage: err.response.data.error };
  }
};

export const fetchCondition = async (
  id: number
): Promise<{ response?: IConditionRule; errorMessage?: string }> => {
  try {
    const response = await HTTPClient.GetRequest<any>(
      conditionRuleEndpoint.GET_RULE(id)
    );
    if (response.status === 200) {
      return { response: response.data.data };
    }
    return { errorMessage: response.data.message };
  } catch (err) {
    return { errorMessage: (err as Error).message };
  }
};

export const updateConditionStatus = async (
  ruleId: number,
  status: string
): Promise<{
  errorMessage?: string;
}> => {
  try {
    const response = await HTTPClient.PutRequest<any>(
      conditionRuleEndpoint.UPDATE_STATUS(ruleId, status),
      {}
    );
    if (response.status === 200) {
      return {};
    }
    return { errorMessage: response.data.message };
  } catch (err: any) {
    return { errorMessage: err.response.data.error };
  }
};

export const cloneCondition = async (
  ruleId: number
): Promise<{ errorMessage?: string }> => {
  try {
    const response = await HTTPClient.PostRequest<any>(
      conditionRuleEndpoint.CLONE_RULE(ruleId),
      {}
    );
    if (response.status === 200) {
      return {};
    }
    return { errorMessage: response.data.message };
  } catch (err: any) {
    return { errorMessage: err.response.data.error };
  }
};

export const deleteCondition = async (
  ruleId: number
): Promise<{ errorMessage?: string }> => {
  try {
    const response = await HTTPClient.DeleteRequest<any>(
      conditionRuleEndpoint.DELETE_RULE(ruleId)
    );
    if (response.status === 200) {
      return {};
    }
    return { errorMessage: response.data.message };
  } catch (err: any) {
    return { errorMessage: err.response.data.error };
  }
};
