import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAllocationListProps } from '../../saga/campaign-planner/Outreach.types';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
    IActionPayload,
    IAllocationListInitialState,
} from '../../types/Outreach.types';

export const allocationListInitialState: IAllocationListInitialState = {
    allocationList: [],
    totalRecords: 0,
    isLoading: false,
    status: APIStatus.INIT,
    error: {},
};

const allocationListSlice = createSlice({
    name: 'outreachConfigInformation',
    initialState: allocationListInitialState,
    reducers: {
        fetchAllocationList: (state: IAllocationListInitialState, action: PayloadAction<
            | {
                name: string | undefined;
                status: string | undefined;
                lenderId: string;
            }
            | undefined
        >) => {
            state.isLoading = true;
            state.status = APIStatus.PENDING;
        },
        fetchAllocationListSucceeded: (
            state: IAllocationListInitialState,
            action: PayloadAction<
                {
                    allocationList: IAllocationListProps[];
                    totalRecords: number;
                }
            >
        ) => {
            state.allocationList = action.payload.allocationList;
            state.totalRecords = action.payload.totalRecords;
            state.isLoading = false;
            state.status = APIStatus.SUCCESS;
        },
        fetchAllocationListFailed: (
            state: IAllocationListInitialState,
            action: PayloadAction<IActionPayload>
        ) => {
            const { error } = action.payload;
            state.error = error;
            state.isLoading = false;
            state.status = APIStatus.ERROR;
        },
        resetAllocationListState: () => allocationListInitialState,
    },
});

export const {
    fetchAllocationList,
    fetchAllocationListSucceeded,
    fetchAllocationListFailed,
    resetAllocationListState,
} = allocationListSlice.actions;

export default allocationListSlice.reducer;
