import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from '../../../services/api/apiStatusCode';
import {
  IActionPayload,
  IOutreachConfigListInitialState,
  OutreachConfigDataType,
} from '../../types/Outreach.types';

export const outreachConfigListInitialState: IOutreachConfigListInitialState = {
  outreachConfigData: {
    channel: undefined,
    languages: undefined,
  },
  isLoading: false,
  status: APIStatus.INIT,
  error: {},
};

const outreachConfigListSlice = createSlice({
  name: 'outreachConfigInformation',
  initialState: outreachConfigListInitialState,
  reducers: {
    fetchConfig: (
      state: IOutreachConfigListInitialState,
      action: PayloadAction<{ lenderId: string }>
    ) => {
      state.isLoading = true;
      state.status = APIStatus.PENDING;
    },
    fetchConfigSucceeded: (
      state: IOutreachConfigListInitialState,
      action: PayloadAction<OutreachConfigDataType>
    ) => {
      state.outreachConfigData = action.payload;
      state.isLoading = false;
      state.status = APIStatus.SUCCESS;
    },
    fetchConfigFailed: (
      state: IOutreachConfigListInitialState,
      action: PayloadAction<IActionPayload>
    ) => {
      const { error } = action.payload;
      state.error = error;
      state.isLoading = false;
      state.status = APIStatus.ERROR;
    },
    resetOutreachConfigListState: () => outreachConfigListInitialState,
  },
});

export const {
  fetchConfig,
  fetchConfigSucceeded,
  fetchConfigFailed,
  resetOutreachConfigListState,
} = outreachConfigListSlice.actions;

export default outreachConfigListSlice.reducer;
