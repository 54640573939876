import { createSlice } from '@reduxjs/toolkit';
import { APIStatus } from 'services/api/apiStatusCode';

export interface ISyncHierarchyInitialState {
  status: APIStatus;
}

export const syncHierarchyInitialState: ISyncHierarchyInitialState = {
  status: APIStatus.INIT,
};

const syncHierarchySlice = createSlice({
  name: 'syncHierarchy',
  initialState: syncHierarchyInitialState,
  reducers: {
    syncHierarchyRequested: (state) => {
      state.status = APIStatus.PENDING;
    },
    syncHierarchyRequestSucceeded: (state) => {
      state.status = APIStatus.SUCCESS;
    },
    syncHierarchyRequestFailed: (state) => {
      state.status = APIStatus.ERROR;
    },
  },
});

export const {
  syncHierarchyRequested,
  syncHierarchyRequestSucceeded,
  syncHierarchyRequestFailed,
} = syncHierarchySlice.actions;

export default syncHierarchySlice.reducer;
