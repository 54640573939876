import { AuthService, MFAService } from '@yubi/yb-module-auth';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'redux/slice/reducer';
import { store } from 'redux/store/store';

const useUnload = ({
  url,
  shouldTriggerUnloadAction,
}: {
  url: string;
  shouldTriggerUnloadAction: boolean;
}) => {
  const entityId = useSelector(
    (state: RootReducerState) => state.userConfiguration.entityId
  );
  const userGroup = useSelector(
    (state: RootReducerState) => state.userConfiguration.userGroup
  );
  const lenderId = useSelector(
    (state: RootReducerState) => state.userConfiguration.userGroup
  );
  useEffect(() => {
    window.addEventListener('unload', () => {
      if (shouldTriggerUnloadAction) {
        console.log('onLocationChange Sending beacon');

        const userId = AuthService.getUserId();
        const currentEntityId = Cookies.get('Current-Entity-Id');
        const currentGroup = Cookies.get('Current-Group');
        const jwtToken = AuthService.getAccessToken();
        const mfaToken = MFAService.getMFAToken();

        const headers: Record<string, string> = {
          'User-Id': userId,
          'Entity-Id': entityId || currentEntityId || '',
          Group: userGroup || currentGroup || '',
          'Mfa-Token': mfaToken || '',
          Authorization: `Bearer ${jwtToken}`,
          'Content-Security-Policy': "default-src 'self'",
          'Strict-Transport-Security':
            'max-age=31536000; includeSubDomains; preload',
          'X-Content-Type-Options': 'nosniff',
          'X-Frame-Options': 'DENY',
          'Referrer-Policy': 'strict-origin-when-cross-origin',
          'User-Name': store.getState().userEntities?.userData?.name || '',
          'Lender-Id': lenderId || '',
        };

        if (!url) return;

        try {
          fetch(url, {
            method: 'PUT',
            headers,
            keepalive: true,
          })
            .then(() => {})
            .catch((error) => {
              console.error('Error:', error);
            });
        } catch (e) {
          console.error(e);
        }
      }
    });

    return () => {
      window.removeEventListener('unload', () => {
        navigator.sendBeacon(url);
      });
    };
  }, [shouldTriggerUnloadAction]);
};

export default useUnload;
