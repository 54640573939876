import Plus from 'assets/svgComponents/Plus';
import { OverFlowMenuView } from 'components';
import * as React from 'react';

interface IAddGroup {
  onAddClicked: (isCondition: boolean) => void;
}

const AddGroup = (props: IAddGroup) => {
  const { onAddClicked } = props;
  return (
    <div className="test">
      <OverFlowMenuView
        items={[
          {
            key: 'add_Condition',
            label: 'Add condition',
            onClick: () => {
              onAddClicked(true);
            },
          },
          {
            key: 'add_group',
            label: 'Add group',
            onClick: () => {
              onAddClicked(false);
            },
          },
        ]}
        icon={
          <div
            className="p-2 rounded-md"
            style={{ border: '1px solid #E3E8EF' }}
          >
            <Plus color="#697586" />
          </div>
        }
      />
    </div>
  );
};

export default AddGroup;
