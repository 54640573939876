import { put, takeLatest } from 'redux-saga/effects';
import { campaignPlanner } from '../../../services/api/endPoints';
import { ApiStatusCodes } from '../../../services/api/apiStatusCode';
import { HTTPClient } from '../../../services/api/http-client';
import { IFetchOutreachListResponse, IFetchSingleOutreachResponse, IGetOutreachFiltersResponse } from './Outreach.types';
import {
  fetchOutreachById, fetchOutreachByIdFailed, fetchOutreachByIdSucceeded, fetchOutreachList, fetchOutreachListFailed,
  fetchOutreachListSucceeded, getOutreachFiltersFailed, getOutreachFiltersSucceeded, getOutreachFilters
} from '../../slice/campaign-planner/OutreachList.slice';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { message } from 'antd';
import { parseAxiosError } from 'utils/helper';

export function* fetchOutreachListSaga(actions: any) {
  try {
    const { offset, limit, searchText, appliedFilterState } = actions.payload;
    const result: IFetchOutreachListResponse = yield HTTPClient.PostRequest(campaignPlanner.FETCH_OUTREACH_LIST(searchText, { limit, offset }), appliedFilterState);

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(fetchOutreachListSucceeded({
        outreachData: result.data.data,
        totalRecords: result.data.totalRecords,
      }));
    } else {
      yield put(
        fetchOutreachListFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchOutreachListFailed({
        error: {
          message: 'An error occurred while fetching outreaches.',
        },
      })
    );
    if (axios.isAxiosError(error)) { // Ensure the error is an AxiosError
      const parsedError = parseAxiosError(error);
      message.error(parsedError?.response.data.error || 'Failed to fetch outreaches');
    }
  }
}

export function* fetchOutreachByIdSaga({
  payload,
}: PayloadAction<{ outreachId: string }>) {
  try {
    const result: IFetchSingleOutreachResponse = yield HTTPClient.GetRequest(`${campaignPlanner.OUTREACH()}/${payload.outreachId}`);

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(fetchOutreachByIdSucceeded(result?.data?.data));
    } else {
      yield put(
        fetchOutreachByIdFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      fetchOutreachByIdFailed({
        error: {
          message: 'An error occurred while fetching outreach.',
        },
      })
    );
  }
}


export function* getOutreachFiltersSaga() {
  try {
    const result: IGetOutreachFiltersResponse = yield HTTPClient.GetRequest(campaignPlanner.GET_OUTREACH_FILTERS);

    if (result?.status === ApiStatusCodes.SUCCESS && result?.data?.data) {
      // eslint-disable-next-line @typescript-eslint/naming-convention

      // For now we are processing only lender entities
      yield put(getOutreachFiltersSucceeded(result.data.data));
    } else {
      yield put(
        getOutreachFiltersFailed({
          error: {
            message: 'No Token Found',
          },
        })
      );
    }
  } catch (error) {
    // Handle API call failure or network errors
    yield put(
      getOutreachFiltersFailed({
        error: {
          message: 'An error occurred while fetching outreaches.',
        },
      })
    );
  }
}

export function* watchOutreachListSaga() {
  yield takeLatest(fetchOutreachList.type, fetchOutreachListSaga);
  yield takeLatest(fetchOutreachById.type, fetchOutreachByIdSaga);
  yield takeLatest(getOutreachFilters.type, getOutreachFiltersSaga);
}
