import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AgencyCols,
  AgencyListParam,
  IFilterOptions,
  IFilterResponse,
  IFilterAgencyFailedPayload,
} from '../../types/ManageAgency.type';
import { APIStatus } from 'services/api/apiStatusCode';
import { IErrorParam } from 'redux/types/types';
import { FilterListType, InviteData } from 'features/LenderAgency/lenderTypes';

export interface AgencyInitialState {
  isLoading: boolean;
  allAgencyList: AgencyCols[] | [];
  myAgencyList: AgencyCols[] | [];
  invitedAgencyList: InviteData[] | [];
  sendAssociateFormStatus: APIStatus;
  myfilterOptions: {
    agencies: FilterListType;
    channels: FilterListType;
    locations: FilterListType;
  };
  allfilterOptions: {
    agencies: FilterListType;
    channels: FilterListType;
    locations: FilterListType;
  };
  filterOptionsStatus: {
    myagency: APIStatus;
    allagency: APIStatus;
  };
  status: {
    myagency: APIStatus;
    allagency: APIStatus;
    inviteagency: APIStatus;
  };
  error: IErrorParam;
}

export const agencyInitialState: AgencyInitialState = {
  isLoading: false,
  allAgencyList: [],
  myAgencyList: [],
  invitedAgencyList: [],
  sendAssociateFormStatus: APIStatus.INIT,
  myfilterOptions: {
    agencies: {},
    channels: {},
    locations: {},
  },
  allfilterOptions: {
    agencies: {},
    channels: {},
    locations: {},
  },
  filterOptionsStatus: {
    myagency: APIStatus.INIT,
    allagency: APIStatus.INIT,
  },
  status: {
    myagency: APIStatus.INIT,
    allagency: APIStatus.INIT,
    inviteagency: APIStatus.INIT,
  },
  error: {},
};

const agency = createSlice({
  name: 'agency',
  initialState: agencyInitialState,
  reducers: {
    fetchAgency: (
      state: AgencyInitialState,
      action: PayloadAction<AgencyListParam>
    ) => {
      state.isLoading = true;
      state.status.myagency =
        action.payload.listType === 'my_agency'
          ? APIStatus.PENDING
          : APIStatus.INIT;
      state.status.allagency =
        action.payload.listType === 'all_agencies'
          ? APIStatus.PENDING
          : APIStatus.INIT;
      state.status.inviteagency =
        action.payload.listType === 'invitedagency'
          ? APIStatus.PENDING
          : APIStatus.INIT;
    },
    fetchMyAgencySuccess: (
      state: AgencyInitialState,
      action: PayloadAction<AgencyCols[]>
    ) => {
      return {
        ...state,
        isLoading: false,
        status: {
          ...state.status,
          myagency: APIStatus.SUCCESS,
        },
        myAgencyList: action.payload,
      };
    },
    fetchAllAgencySuccess: (
      state: AgencyInitialState,
      action: PayloadAction<AgencyCols[]>
    ) => {
      return {
        ...state,
        isLoading: false,
        status: {
          ...state.status,
          allagency: APIStatus.SUCCESS,
        },
        allAgencyList: action.payload,
      };
    },
    fetchInvitedAgencySuccess: (
      state: AgencyInitialState,
      action: PayloadAction<InviteData[]>
    ) => {
      return {
        ...state,
        isLoading: false,
        status: {
          ...state.status,
          inviteagency: APIStatus.SUCCESS,
        },
        invitedAgencyList: action.payload,
      };
    },
    addInvitedAgencyList: (
      state: AgencyInitialState,
      action: PayloadAction<InviteData>
    ) => {
      state.invitedAgencyList = [...state.invitedAgencyList, action.payload];
    },
    fetchFiltersOptions: (
      state: AgencyInitialState,
      action: PayloadAction<IFilterOptions>
    ) => {
      const type = action.payload.filterName;
      if (type === 'my') {
        state.filterOptionsStatus.myagency = APIStatus.PENDING;
      }
      if (type === 'all') {
        state.filterOptionsStatus.allagency = APIStatus.PENDING;
      }
    },
    fetchMyFilterOptionsSuccess: (
      state: AgencyInitialState,
      action: PayloadAction<IFilterResponse>
    ) => {
      state.filterOptionsStatus.myagency = action.payload?.errors
        ? APIStatus.ERROR
        : APIStatus.SUCCESS;
      state.myfilterOptions.agencies = action.payload.data[0]?.agencies;
      state.myfilterOptions.locations = action.payload.data[1]?.locations;
      state.myfilterOptions.channels = action.payload.data[2]?.channels;
    },
    fetchAllFilterOptionsSuccess: (
      state: AgencyInitialState,
      action: PayloadAction<IFilterResponse>
    ) => {
      state.filterOptionsStatus.allagency = action.payload?.errors
        ? APIStatus.ERROR
        : APIStatus.SUCCESS;
      state.allfilterOptions.agencies = action.payload.data[0]?.agencies;
      state.allfilterOptions.locations = action.payload.data[1]?.locations;
      state.allfilterOptions.channels = action.payload.data[2]?.channels;
    },
    fetchFilterOptionsFail: (
      state: AgencyInitialState,
      action: PayloadAction<IFilterAgencyFailedPayload>
    ) => {
      if (action.payload.type === 'my') {
        state.filterOptionsStatus.myagency = APIStatus.ERROR;
      }
      if (action.payload.type === 'all') {
        state.filterOptionsStatus.allagency = APIStatus.ERROR;
      }
    },
  },
});

export const {
  fetchAgency,
  fetchAllAgencySuccess,
  fetchMyAgencySuccess,
  fetchInvitedAgencySuccess,
  fetchFiltersOptions,
  fetchMyFilterOptionsSuccess,
  fetchAllFilterOptionsSuccess,
  fetchFilterOptionsFail,
  addInvitedAgencyList,
} = agency.actions;

export default agency.reducer;
