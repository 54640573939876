import { isObjectEmpty, isPermissionEnabled } from 'utils/helper';
import { useAppSelector } from 'utils/hooks/useAppSelector';

interface UseUserPermissionsProps {
  permission: string | string[];
}
const useUserPermissions = ({ permission }: UseUserPermissionsProps) => {
  const userOnboardDetails = useAppSelector(
    (state) => state.userOnboardDetails
  );

  const userPermissions = useAppSelector(
    (state) => state.userPermission.userPermissions
  );

  if (isObjectEmpty(userOnboardDetails?.onboardData?.data?.[0] || {})) {
    return false;
  }
  return isPermissionEnabled(userPermissions, permission);
};

export default useUserPermissions;
