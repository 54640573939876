import React from 'react';

interface ITrashIcon {
  color?: string;
}

function Trash(props: ITrashIcon) {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g>
        <g fill={color || '#101828'} fillRule="evenodd" clipRule="evenodd">
          <path d="M1.333 4c0-.369.298-.667.667-.667h12a.667.667 0 110 1.333H2A.667.667 0 011.333 4z"></path>
          <path d="M6.667 2A.667.667 0 006 2.665v.667h4v-.667a.667.667 0 00-.666-.667H6.667zm4.667 1.333v-.667a2 2 0 00-2-2H6.667a2 2 0 00-2 2v.667H3.334a.667.667 0 00-.667.666v9.334a2 2 0 002 2h6.667a2 2 0 002-2V3.999a.667.667 0 00-.667-.666h-1.333zM4 4.666v8.667a.667.667 0 00.667.666h6.667a.667.667 0 00.666-.666V4.666H4z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Trash;
