import React from 'react';
import CALink from '@yubi/yb-core-link';
import { useStyles } from '@yubi/yb-style-provider';

// Define an interface for the props

type Type =
  | 'primary'
  | 'secondary'
  | 'contrastPrimary'
  | 'negative'
  | 'success';
type Size = 's' | 'm' | 'l';
interface LinkViewProps {
  title: string;
  type: Type; // Replace with the actual types
  size: Size; // Replace with the actual types
  onPress: () => void; // Replace with the actual type
  underlineOnHover?: boolean;
  underlined?: boolean;
}

const LinkView: React.FC<LinkViewProps> = ({
  title,
  type,
  size,
  onPress,
  underlineOnHover,
  underlined,
}) => {
  const { styleConnector } = useStyles(); // Avoid naming conflicts
  return (
    <CALink
      title={title}
      type={type}
      size={size}
      onPress={onPress}
      styleConnector={styleConnector} // Use the provided styleConnector or a default value
      underlineOnHover={underlineOnHover}
      underlined={underlined}
    />
  );
};

export default LinkView;
