import React from 'react';
import YbCoreTypography from '@yubi/yb-core-typography';
import ToolTipView from 'components/ToolTipView';

export type TypographyWeight =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'Thin'
  | 'Extra Light'
  | 'Light'
  | 'Regular'
  | 'regular'
  | 'Medium'
  | 'medium'
  | 'Semi Bold'
  | 'semi-bold'
  | 'Bold'
  | 'bold'
  | 'Extra Bold'
  | 'Black';
export type FontStyle = 'italic' | 'normal';
export type TextTransform = 'capitalize' | 'uppercase' | 'lowercase' | 'none';
export type TypeStyle =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'subHeading'
  | 'body'
  | 'small'
  | 'tiny';

export type MarginType = {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
};

export type Padding = number | string | MarginType;

interface TextViewProps {
  text: React.ReactNode;
  id?: string;
  testID?: string;
  textType?: TypeStyle;
  weight?: TypographyWeight;
  transform?: TextTransform;
  fontStyle?: FontStyle;
  color?: string;
  size?: number;
  lineHeight?: number;
  padding?: Padding;
  onClick?: () => void;
  align?: 'auto' | 'left' | 'center' | 'right' | 'justify';
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
  numberOfLines?: number;
  truncate?: number;
}

const TextView: React.FC<TextViewProps> = ({
  textType,
  weight,
  transform,
  fontStyle,
  text,
  id,
  lineHeight,
  testID,
  color,
  size,
  padding,
  onClick,
  align,
  ellipsizeMode,
  numberOfLines,
  truncate,
}) => {
  const typography = (
    <YbCoreTypography
      typeStyle={textType}
      weight={weight}
      transform={transform}
      fontStyle={fontStyle}
      id={id}
      testIDObject={{ parent: testID }}
      size={size}
      lineHeight={lineHeight}
      color={color}
      onClick={onClick}
      padding={padding}
      align={align}
      ellipsizeMode={ellipsizeMode}
      numberOfLines={numberOfLines}
    >
      {text}
    </YbCoreTypography>
  );
  if (truncate)
    return (
      <ToolTipView
        children={
          <div className="d-flex word-wrap">
            {typeof text === 'string'
              ? text.length > truncate
                ? text.substring(0, truncate).trim() + '...'
                : text
              : text}
          </div>
        }
        title={typeof text === 'string' ? text : ''}
      />
    );
  return typography;
};

export default TextView;
