import { useStyles } from '@yubi/yb-style-provider';
import YbCoreTabs from '@yubi/yb-core-tabs';
import React from 'react';
import ExecutionDue from '../TabContainer/ExecutionDue';
import InProgress from '../TabContainer/InProgress';
import Completed from '../TabContainer/Completed';

interface CampaignListProps {
  activeKey: string;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
}

const CampaignTabs = ({ activeKey, setActiveKey }: CampaignListProps) => {
  const { styleConnector } = useStyles();

  const tabs: React.ComponentProps<typeof YbCoreTabs>['tabItems'] = [
    {
      key: '1',
      title: 'In-progress',
      children: <InProgress />,
    },
    {
      key: '2',
      title: 'Execution due',
      children: <ExecutionDue />,
    },
    {
      key: '3',
      title: 'Completed',
      children: <Completed />,
    },
  ];

  return (
    <div className="py-2">
      <YbCoreTabs
        activeKey={activeKey}
        tabItems={tabs}
        onTabClick={(tab: string) => setActiveKey(tab)}
        styleConnector={styleConnector}
      />
    </div>
  );
};

export default CampaignTabs;
